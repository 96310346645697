import {defaultPresentationId, GraphPresentationPropsLoaded} from './use-graph-presentation-props';
import {
    PresentationCreatedResponse,
    useCreatePresentation
} from './requests/use-create-presentation';
import {useQueryGraphProps} from './use-query-graph-props';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {assert} from '../../../../../utils/assert';
import {useSavePresentationDetails} from './requests/use-save-presentation-details';
import {useSetFavoritePresentation} from './requests/use-set-favorite-presentation';
import {useDeletePresentation} from './requests/use-delete-presentation';
import {useRenamePresentation} from './requests/use-rename-presentation';
import {useConfirm} from '../../../../../components/confirm/use-confirm';

interface Props {
    queryId: number;
    graphPresentationProps: GraphPresentationPropsLoaded;
    graphProps: ReturnType<typeof useQueryGraphProps>;
}

function getPresDetailsFromGraphProps(
    props: ReturnType<typeof useQueryGraphProps>
): GraphPresentationDetails {
    assert(props.layoutAndCameraProps.nodePositions, 'No node positions');

    return {
        layoutAlgorithm: props.layoutAndCameraProps.layoutAlgorithm,
        viewKind: props.graphSettings.selectedViewType,
        graphType: props.graphSettings.graphType,
        maxPathLength: props.graphSettings.maxPathLength,
        isLayoutCustom: props.layoutAndCameraProps.isCustomLayout,
        layout: JSON.stringify(props.layoutAndCameraProps.nodePositions)
    };
}

function generatePresentationName(presentationList: GraphPresentation[]) {
    let index = presentationList.length;
    // eslint-disable-next-line no-loop-func
    while (presentationList.find((p) => p.name === `Presentation ${index}`)) {
        index++;
    }
    return `Presentation ${index}`;
}

export function useGraphPresentationActions({queryId, graphPresentationProps, graphProps}: Props) {
    const onPresentationCreatedSuccess = (response: PresentationCreatedResponse) => {
        graphPresentationProps.selectPresentation(response.presentation.id);
        graphProps.clearUnsavedDetails();
    };

    const savePresentationDetailsMutation = useSavePresentationDetails(
        graphProps.clearUnsavedDetails,
        graphProps.setUnsavedDetails
    );
    const createPresentationMutation = useCreatePresentation(onPresentationCreatedSuccess);
    const setFavoritePresentationMutation = useSetFavoritePresentation();
    const renamePresentationMutation = useRenamePresentation();

    const onPresentationDeleted = () => {
        const favoritePresentation = graphPresentationProps.presentations.find(
            (p) => p.isFavorite
        )!;
        if (favoritePresentation.id === graphPresentationProps.selectedPresentation.id) {
            graphPresentationProps.selectPresentation(defaultPresentationId);
        } else {
            graphPresentationProps.selectPresentation(favoritePresentation.id);
        }
        graphProps.clearUnsavedDetails();
    };
    const onPresentationDeletedFailed = (
        deletedPresentationId: number,
        unsavedDetails?: GraphPresentationDetails
    ) => {
        graphPresentationProps.selectPresentation(deletedPresentationId);
        if (unsavedDetails) {
            graphProps.setUnsavedDetails(unsavedDetails);
        }
    };
    const deletePresentationMutation = useDeletePresentation(
        onPresentationDeleted,
        onPresentationDeletedFailed
    );

    const saveSelectedPresentationAsNew = () => {
        const name = generatePresentationName(graphPresentationProps.presentations);
        const details = getPresDetailsFromGraphProps(graphProps);
        createPresentationMutation.mutate({queryId, name, details});
    };

    const saveSelectedPresentation = () => {
        const details = getPresDetailsFromGraphProps(graphProps);
        savePresentationDetailsMutation.mutate({
            queryId,
            presentationId: graphPresentationProps.selectedPresentation.id,
            details
        });
    };

    const setFavoritePresentation = () => {
        setFavoritePresentationMutation.mutate({
            queryId,
            presentationId: graphPresentationProps.selectedPresentation.id
        });
    };

    const deletePresentation = useConfirm({
        title: 'Delete presentation?',
        message: `"${graphPresentationProps.selectedPresentation.name}" will be deleted`,
        confirmBtnText: 'Delete',
        cancelBtnText: 'Cancel',
        onConfirm: () =>
            deletePresentationMutation.mutate({
                queryId,
                presentationId: graphPresentationProps.selectedPresentation.id,
                unsavedDetails: graphProps.presentationEditedProps.isPresentationEdited
                    ? getPresDetailsFromGraphProps(graphProps)
                    : undefined
            })
    });

    const renamePresentation = (name: string) => {
        renamePresentationMutation.mutate({
            queryId,
            presentationId: graphPresentationProps.selectedPresentation.id,
            name
        });
    };

    return {
        saveSelectedPresentationAsNew,
        saveSelectedPresentation,
        setFavoritePresentation,
        deletePresentation,
        renamePresentation
    };
}
