import React from 'react';
import {SidebarLoadingState} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';

export function useSearchSidebarLoadingState() {
    const [sidebarLoadingState, setSidebarLoadingState] = React.useState<SidebarLoadingState>({
        isLoading: false,
        isLoadingNextPage: false
    });

    const setLoadingState = React.useCallback(
        (loadingState: Partial<SidebarLoadingState>) => {
            setSidebarLoadingState({
                ...sidebarLoadingState,
                ...loadingState
            });
        },
        [sidebarLoadingState]
    );

    return {
        loadingState: sidebarLoadingState,
        setLoadingState
    };
}
