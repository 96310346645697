import {Typography, ControlsItem, Controls, ControlsProps} from '@genestack/ui';
import * as React from 'react';

import styles from './shortcut-label.module.css';

interface Props extends ControlsProps {
    keys: string[];
}

/** Shortcut label */
export function ShortcutsLabel({keys, ...restProps}: Props) {
    const lastKeyIndex = keys.length - 1;

    return (
        <Controls gap={1} {...restProps}>
            {keys.map((key, index) => (
                <React.Fragment key={key}>
                    <ControlsItem>
                        <Typography variant="caption" className={styles.key}>
                            {key}
                        </Typography>
                    </ControlsItem>

                    {index !== lastKeyIndex ? (
                        <ControlsItem>
                            <Typography variant="caption" className={styles.plus}>
                                +
                            </Typography>
                        </ControlsItem>
                    ) : null}
                </React.Fragment>
            ))}
        </Controls>
    );
}
