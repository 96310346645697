import anime, {AnimeAnimParams, AnimeInstanceParams} from 'animejs';
import * as React from 'react';
import {Flipper, Flipped} from 'react-flip-toolkit';

interface Props {
    children: React.ReactElement[];
    showMoreElement?: React.ReactElement | null;
}

const handleEnterUpdateDelete: Flipper['props']['handleEnterUpdateDelete'] = (steps) => {
    steps.hideEnteringElements();
    steps.animateExitingElements();
    steps.animateFlippedElements();
    steps.animateEnteringElements();
};

const slideIn = (element: AnimeAnimParams['targets']) => {
    anime({
        targets: element,
        translateX: ['100%', '0%'],
        opacity: [0, 1],
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        duration: 300
    });
};

const fadeIn = (element: AnimeAnimParams['targets']) => {
    anime({
        targets: element,
        opacity: [0, 1],
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        duration: 300
    });
};

const fadeOut = (
    element: AnimeAnimParams['targets'],
    index: number,
    removeElement: AnimeInstanceParams['complete']
) => {
    anime({
        targets: element,
        opacity: 0,
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        duration: 300,
        complete: removeElement
    });
};

/**
 * Run transitions over notifications to smooth showing and hiding notifications
 * according its real positions.
 */
export const NotificationsStack = (props: Props) => {
    const items = React.Children.toArray(props.children).map((item, index) => {
        if (!React.isValidElement(item)) {
            return null;
        }

        const key = (item.key || index).toString();

        return (
            <Flipped key={key} flipId={key} onAppear={slideIn} onExit={fadeOut}>
                {item}
            </Flipped>
        );
    });

    if (props.showMoreElement) {
        items.unshift(
            <Flipped
                key="showMoreElement"
                flipId="showMoreElement"
                onAppear={fadeIn}
                onExit={fadeOut}
            >
                {props.showMoreElement}
            </Flipped>
        );
    }

    const flipKey = items.map((item) => item?.key ?? '').join('');

    return (
        <Flipper flipKey={flipKey} handleEnterUpdateDelete={handleEnterUpdateDelete}>
            {items}
        </Flipper>
    );
};
