import {useMutation} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphPresentation} from '../interface';
import {useGetPresentationsCache} from './use-get-presentations';
import {showErrorNotification} from '../../../../../../components/notifications-center/notifications-store';

interface MutationParams {
    queryId: number;
    presentationId: number;
}

function setFavoritePresentationRequest({queryId, presentationId}: MutationParams) {
    return apiClient.patch<GraphPresentation[]>({
        path: `queries-service/api/queries/${queryId}/graph-presentations/${presentationId}/mark-favorite`
    });
}

export function useSetFavoritePresentation() {
    const getPresentationsCache = useGetPresentationsCache();

    return useMutation({
        mutationFn: setFavoritePresentationRequest,
        onMutate: (params: MutationParams) => {
            return getPresentationsCache.setFavorite(params.queryId, params.presentationId);
        },
        onError: (error, params, context) => {
            getPresentationsCache.setPresentations(params.queryId, context!);
            showErrorNotification('Request error, try again later')(error);
        }
    });
}
