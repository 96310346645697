import React from 'react';

import {getInitials} from '../../utils/initials';
import {CircledLabel} from '../circled-label';

interface Props {
    fullName: string;
}

/** A component for displaying user badges */
export const UserBadge = ({fullName}: Props) => <CircledLabel text={getInitials(fullName)} />;
