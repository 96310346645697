import {DividerProps, PageContent, Button, ButtonProps} from '@genestack/ui';
import React from 'react';

const SMALL_GAP: DividerProps = {startGap: 1, endGap: 0};

interface Props extends ButtonProps {
    isLoadingNextPage?: boolean;
}

/** A "show more" button for paginated lists */
export const LoadNextPageButton = ({onClick, isLoadingNextPage, ...buttonProps}: Props) => (
    <PageContent noEndDivider startDividerProps={SMALL_GAP}>
        <Button size="small" onClick={onClick} disabled={isLoadingNextPage} {...buttonProps}>
            {isLoadingNextPage ? 'Loading...' : 'Show more results'}
        </Button>
    </PageContent>
);
