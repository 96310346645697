import * as React from 'react';
import {Core} from 'cytoscape';
import popper from '@popperjs/core';
import {
    getDefaultEdgeStyles,
    getUnselectedNodeStyles,
    getHoveredNodeStyles,
    getSelectedNodeStyles,
    getUnhoveredNodeStyles,
    getSelectedEdgeStyles
} from './get-dynamic-styles';
import {createPopper} from './create-popper';
import {useGraphStateDiff} from './use-graph-state-diff';
import {GraphEdge} from '../interface';
import {getEdgeCytoscapeId} from './utils';

interface Props {
    selectedNodes: number[];
    hoveredNodes: number[];
    edges: GraphEdge[];
    csRef: React.MutableRefObject<Core | null>;
    containerElementRef: React.MutableRefObject<HTMLDivElement | null>;
}

export function useDynamicStyles(props: Props) {
    const {selectedNodes, hoveredNodes, edges, csRef} = props;
    const popperMapRef = React.useRef<{[id: number]: popper.Instance}>({});
    const {
        newHoveredNodes,
        newSelectedNodes,
        newUnhoveredNodes,
        newUnselectedNodes,
        newSelectedEdges,
        newUnselectedEdges
    } = useGraphStateDiff({
        selectedNodes,
        hoveredNodes,
        edges
    });

    React.useEffect(() => {
        csRef.current?.batch(() => {
            newSelectedNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getSelectedNodeStyles());
            });

            newUnselectedNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getUnselectedNodeStyles());
            });

            newHoveredNodes.forEach((nodeId) => {
                const el = csRef.current?.getElementById(String(nodeId));
                if (!el || !el.length) {
                    return;
                }

                el.style(getHoveredNodeStyles());

                const popperInstance = createPopper(el, props.containerElementRef);

                if (popperInstance) {
                    popperMapRef.current[nodeId] = popperInstance;
                }
            });

            newUnhoveredNodes.forEach((nodeId) => {
                csRef.current?.getElementById(String(nodeId)).style(getUnhoveredNodeStyles());

                if (popperMapRef.current[nodeId]) {
                    try {
                        // for some reason it crashes in this line sometimes, so I wrapped
                        // it into try...catch to suppress it
                        props.containerElementRef.current?.removeChild(
                            popperMapRef.current[nodeId].state.elements.popper
                        );
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                    popperMapRef.current[nodeId].destroy();
                }
            });

            newSelectedEdges.forEach((id) => {
                csRef.current
                    ?.getElementById(getEdgeCytoscapeId(id))
                    .style(getSelectedEdgeStyles());
            });

            newUnselectedEdges.forEach((id) => {
                csRef.current?.getElementById(getEdgeCytoscapeId(id)).style(getDefaultEdgeStyles());
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        newSelectedNodes,
        newHoveredNodes,
        newUnhoveredNodes,
        newUnselectedNodes,
        newSelectedEdges,
        newUnselectedEdges
    ]);
}
