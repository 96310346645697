import axios, {AxiosResponse} from 'axios';
import {useMutation} from 'react-query';

interface Props {
    jsonFormData: {[key: string]: FormDataEntryValue | string};
    action: string;
}

/** Returns relative path for Identity actions for proper proxying */
export function getRelativeIdentityPath(path: string) {
    return path.slice(path.indexOf('/self'));
}

/** Sends request after submitting a Settings form */
export function useIdentityFormMutation<Response>() {
    return useMutation((props: Props) => {
        const action = getRelativeIdentityPath(props.action);

        return axios
            .post<Response>(action, JSON.stringify(props.jsonFormData), {
                withCredentials: true
            })
            .catch((error) => {
                if (error.response?.data) {
                    return error.response as AxiosResponse<Response>;
                }

                throw error;
            });
    });
}
