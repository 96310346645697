import {useQuery} from 'react-query';

import {useQueryListCache} from '../queries-sidebar/hooks/update-query-list-cache';
import {apiClient} from '../../../utils/api-client';

const PAGE_LIMIT = 100;

function getMPUnresolvedQueriesRequest() {
    return apiClient.get<(RunningQuery | PendingQuery)[]>({
        path: 'queries-service/api/queries/admin/queued',
        query: {limit: PAGE_LIMIT}
    });
}

export function useMPUnresolvedQueries() {
    return useQuery('unresolved-queries', getMPUnresolvedQueriesRequest);
}

export function useMPUnresolvedQueriesCache() {
    return useQueryListCache<RunningQuery>('unresolved-queries');
}
