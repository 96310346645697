import React from 'react';
import {useLocation} from 'react-router-dom';

import {useGlobalState, Variables} from '../providers/global-state';

interface Props {
    path: string;
    lsKey: keyof Variables;
}

/** Hook to update the latest location in local storage */
export function useLastLocationEffect(props: Props) {
    const location = useLocation();
    const globalState = useGlobalState();

    // FIXME too dirty, use regex instead
    const currentQueryAccession = location.pathname
        .replace(`${props.path}/`, '')
        .replace(props.path, '');

    React.useEffect(() => {
        globalState.setVariable(props.lsKey, currentQueryAccession);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQueryAccession !== globalState.state[props.lsKey]]);
}
