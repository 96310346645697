import equal from 'fast-deep-equal';
import {useQuery, useQueryClient} from 'react-query';
import {UseQueryOptions} from 'react-query/types/react/types';

import {apiClient} from '../../../../../utils/api-client';

import {StringSearch, NodeReferenceSearch} from './use-categories-query';

interface ResolvedCategory {
    categoryId: string;
    name: string;
}

/** Structure representing CategoriesSearch for search history */
export interface ResolvedCategoriesSearch {
    categories: ResolvedCategory[];
    includeUncategorized: boolean;
}

/** Search history record type */
export interface SearchHistoryRecordDto {
    stringSearch: StringSearch | null;
    categoriesSearch: ResolvedCategoriesSearch | null;
    referencedNode: NodeReferenceSearch | null;
}

function getSearchHistoryRequest() {
    return apiClient.get<SearchHistoryRecordDto[]>({
        path: 'documents-revisions-service/api/user/documents/search-history'
    });
}

/** Search history in explorer view */
export function useExploreViewSearchHistoryQuery(
    queryOptions: UseQueryOptions<SearchHistoryRecordDto[]> = {}
) {
    return useQuery<SearchHistoryRecordDto[]>(
        'getSearchHistoryRequest',
        getSearchHistoryRequest,
        queryOptions
    );
}

/** Search history query cache in explorer view */
export function useExploreViewSearchHistoryQueryCache() {
    const queryClient = useQueryClient();

    function invalidate() {
        queryClient.invalidateQueries('getSearchHistoryRequest');
    }

    function addItem(data: SearchHistoryRecordDto) {
        queryClient.setQueryData<SearchHistoryRecordDto[]>(
            'getSearchHistoryRequest',
            (currentState = []) => {
                const state = currentState.filter((i) => !equal(i, data));

                return [data, ...state];
            }
        );
    }

    return {addItem, invalidate};
}
