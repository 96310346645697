import {TooltipHandler, Button, LinkIcon, Tooltip} from '@genestack/ui';
import React from 'react';

import {objectToHash} from '../../../../../hooks/object-hash';
import {useCopyLink} from '../../../../../hooks/use-copy-link';
import {UrlSearchParam} from '../../../../../hooks/use-search-params';

import styles from './copy-search-button.module.css';
import {SearchData} from '../../../common/sidebar-search-field/interface';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';

interface Props {
    searchData: SearchData;
    selectedGlobalVersion: GlobalVersionData;
}

export function CopySearchButton(props: Props) {
    const {searchData, selectedGlobalVersion} = props;
    const copyLink = useCopyLink({
        pathname: '/documents/explore',
        searchParams: {
            [UrlSearchParam.GV]: String(selectedGlobalVersion.id),
            [UrlSearchParam.search]: objectToHash<SearchData>(searchData)
        }
    });

    return (
        <TooltipHandler
            tooltip={
                <Tooltip placement="top" portalContainer={document.body} positionFixed>
                    Copy link to this search
                </Tooltip>
            }
        >
            <Button
                ghost
                size="small"
                iconStart={<LinkIcon />}
                className={styles.root}
                onClick={copyLink}
            />
        </TooltipHandler>
    );
}
