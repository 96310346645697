import React from 'react';
import {assert} from '../../utils/assert';
import {ConfirmComponent, ConfirmSettings} from './confirm-component';

interface ConfirmContextValue {
    showConfirm: (settings: ConfirmSettings) => void;
}

const ConfirmContext = React.createContext<ConfirmContextValue>({showConfirm: () => null});

export function ConfirmContextProvider(props: {children: React.ReactNode}) {
    const [currentSettings, setCurrentSettings] = React.useState<ConfirmSettings>();
    const contextValue = React.useMemo(
        () => ({showConfirm: setCurrentSettings}),
        [setCurrentSettings]
    );

    const onClose = React.useCallback(() => setCurrentSettings(undefined), []);

    return (
        <ConfirmContext.Provider value={contextValue}>
            {props.children}
            <ConfirmComponent settings={currentSettings} onClose={onClose} />
        </ConfirmContext.Provider>
    );
}

export function useConfirmContext() {
    const value = React.useContext<ConfirmContextValue>(ConfirmContext);
    assert(value, "Couldn't use `ResizableLayoutContext` outside `ResizableLayoutContextProvider`");

    return value;
}
