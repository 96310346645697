import {isQueryResultIncomplete, isQueryResultUnmanageable} from './helpers';
import {useQueryGraphProps} from './use-query-graph-props';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';

interface Props {
    graphProps: ReturnType<typeof useQueryGraphProps>;
    maxPathLengthOptions: MaxPathLengthOptionResult;
}

export function useIsCantDisplayGraph(props: Props) {
    const isQueryResultsUnmanageable = isQueryResultUnmanageable(
        props.graphProps.graphSettings.graphType,
        props.maxPathLengthOptions
    );

    const isResultIncomplete = isQueryResultIncomplete(props.maxPathLengthOptions);

    return {
        isQueryResultsUnmanageable,
        isResultIncomplete,
        isCantDisplayGraph: isQueryResultsUnmanageable || isResultIncomplete
    };
}
