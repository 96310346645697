import {Typography, TypographyProps} from '@genestack/ui';
import React from 'react';

import {pluralize} from '../../../utils/pluralize';

interface Props extends TypographyProps {
    pathLength: number;
    score: number;
}

const PERCENTAGE_MULTIPLIER = 100;

export const ResultChainLabel = ({pathLength, score, ...restProps}: Props) => {
    const label = `Result chain: ${pathLength} ${pluralize('node', pathLength)}, score ${Math.round(
        score * PERCENTAGE_MULTIPLIER
    )}%`;

    return (
        <Typography ellipsis title={label} {...restProps}>
            {label}
        </Typography>
    );
};
