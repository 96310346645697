import {debounce} from '@genestack/ui';
import React from 'react';

import {DOC_SHORTCUT_TYPE, TEXT_OPERATOR} from './use-suggestions';
import {useSearchDataFromUrl} from '../../../explore-view/hooks/use-search-data-from-url';
import {useGlobalStateVariable, Variables} from '../../../../../providers/global-state';
import {SidebarSuggestion, SearchData, InputValueType} from '../interface';
import {parseInputValue} from '../suggestion-helpers';

const DEBOUNCE_WAIT_MS = 250;

export interface SearchInputValue {
    prefix: string;
    value: string;
    type: InputValueType;
}

export interface SidebarLoadingState {
    isLoading: boolean;
    isLoadingNextPage: boolean;
}

interface Props {
    useDefaultSearch?: typeof useSearchDataFromUrl;
    onSearch?: (data: SearchData) => void;
    searchDataGlobalStateVar: keyof Variables;
}

function getInputValueFromSearchData(searchData: SearchData | undefined) {
    if (!searchData) {
        return '';
    }

    if (searchData.mode === 'node' || searchData.mode === 'category') {
        return `${searchData.type}${TEXT_OPERATOR}${searchData.name}`;
    }

    return `${DOC_SHORTCUT_TYPE}${TEXT_OPERATOR}${searchData.value}`;
}

export const useSearchSidebarContext = ({
    useDefaultSearch,
    onSearch,
    searchDataGlobalStateVar
}: Props) => {
    // search data is used to query search results from BE
    const [searchDataState, setSearchDataState] = useGlobalStateVariable(
        searchDataGlobalStateVar,
        undefined
    ) as [SearchData | undefined, (value: SearchData | undefined) => void];

    const searchData =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (useDefaultSearch && useDefaultSearch({setSearchData: setSearchDataState})) ||
        searchDataState;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialInputValue = React.useMemo(() => getInputValueFromSearchData(searchData), []);

    // value displayed in the input as string
    const [inputValue, setInputValue] = React.useState<string>(initialInputValue);
    // parsed value from the input, is used to query suggestions from BE
    const parsedInputValue = parseInputValue(inputValue);

    const search = React.useCallback(
        (nextSearchData: SearchData) => {
            setSearchDataState(nextSearchData);
            setInputValue(getInputValueFromSearchData(nextSearchData));

            onSearch && onSearch(nextSearchData);
        },
        [setSearchDataState, onSearch]
    );

    const onClearClick = React.useCallback(() => {
        setSearchDataState(undefined);

        if (parsedInputValue.value) {
            setInputValue(`${parsedInputValue.prefix}${TEXT_OPERATOR}`);
        } else {
            setInputValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedInputValue]);

    const handleSuggestionSelected = React.useCallback(
        (suggestion: SidebarSuggestion) => {
            if (suggestion.mode === 'nodeType') {
                setInputValue(`${suggestion.value}${TEXT_OPERATOR}`);
                return;
            }

            if (suggestion.mode === 'category') {
                search({
                    mode: suggestion.mode,
                    type: suggestion.type,
                    name: suggestion.label,
                    categoryId: suggestion.value
                });
                return;
            }

            if (suggestion.mode === 'node') {
                search({
                    mode: suggestion.mode,
                    type: suggestion.type,
                    name: suggestion.label,
                    nodeId: suggestion.value,
                    documentAccession: suggestion.documentAccession
                });
                return;
            }

            if (suggestion.mode === 'doc') {
                search({mode: 'doc', value: suggestion.value});
                return;
            }

            throw new Error('unknown suggestion mode');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSearchDataState, search]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deferredSearchDocument = React.useCallback(
        debounce((value: string) => {
            setSearchDataState({mode: 'doc', value});
        }, DEBOUNCE_WAIT_MS),
        []
    );

    const onInputValueChange = React.useCallback(
        (value: string) => {
            if (parsedInputValue.prefix) {
                setInputValue(`${parsedInputValue.prefix}${TEXT_OPERATOR}${value}`);
            } else {
                setInputValue(value);
            }

            if (parsedInputValue.type === 'searchByDocName') {
                deferredSearchDocument(value);
            }
        },
        [deferredSearchDocument, parsedInputValue]
    );

    const handleInputSeparatorDeleted = React.useCallback(() => {
        setInputValue(parsedInputValue.prefix);
        setSearchDataState(undefined);
    }, [parsedInputValue, setSearchDataState]);

    return {
        searchData,
        search,
        onClearClick,
        onInputValueChange,
        handleSuggestionSelected,
        parsedInputValue,
        handleInputSeparatorDeleted
    };
};
