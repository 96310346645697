import {useQuery} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {
    GraphViewType,
    MergedFactPanelEntry,
    SimpleFactPanelEntry
} from '../../../../../../components/graph/interface';
import {NeighborhoodGraphDirection} from '../interface';

interface Props {
    documentId: string;
    viewType: GraphViewType;
    selectedFacts: string[];
    maxDistance: number;
    globalVersionId: number;
    direction: NeighborhoodGraphDirection;
    selectedNodeIds: number[];
}

function nodesStructureRequest(props: Props) {
    return apiClient.post<Array<SimpleFactPanelEntry | MergedFactPanelEntry>>({
        path: `/queries-service/api/documents/${props.documentId}/connection-graph/${props.viewType}/node-structures`,
        query: {
            depth: props.maxDistance,
            'fact-ids': props.selectedFacts,
            'global-version': props.globalVersionId,
            direction: props.direction
        },
        parameters: props.selectedNodeIds
    });
}

export function useRequestNodesStructure(props: Props) {
    return useQuery({
        queryKey: [
            'neighborhood-node-structure',
            props.documentId,
            props.selectedFacts,
            props.viewType,
            props.maxDistance,
            props.globalVersionId,
            props.direction,
            props.selectedNodeIds
        ],
        queryFn: () => nodesStructureRequest(props)
    });
}
