import React from 'react';

export function getContainerSize<WrapperElement extends HTMLElement>(
    containerRef: React.MutableRefObject<WrapperElement | null>,
    dimension: 'width' | 'height'
) {
    if (!containerRef.current) {
        return dimension === 'width' ? window.innerWidth : window.innerHeight;
    }

    return containerRef.current.getBoundingClientRect()[dimension];
}
