import {Select, Option, Preloader} from '@genestack/ui';
import {Props as SelectNativeProps} from '@genestack/ui/dist/components/select/select-native';
import React from 'react';

import styles from './version-select.module.css';
import {GlobalVersionData} from '../../hooks/global-version/use-global-version-query';

interface Props extends Partial<SelectNativeProps> {
    isLoading?: boolean;
    value?: number;
    onChangeValue: (value: number) => void;
    globalVersions: GlobalVersionData[];
}

export function VersionSelect({
    isLoading,
    value,
    onChangeValue,
    globalVersions,
    ...restProps
}: Props) {
    const handleChangeValue = (version: string | number) => {
        onChangeValue(Number(version));
    };

    return (
        <Preloader show={isLoading} className={styles.selectPreloader}>
            <Select
                data-qa="version-select"
                size="small"
                value={value}
                onValueChange={handleChangeValue}
                {...restProps}
                native
            >
                {(globalVersions ?? []).map(({id, label}) => (
                    <Option key={id} value={id} label={label}>
                        {label}
                    </Option>
                ))}
            </Select>
        </Preloader>
    );
}
