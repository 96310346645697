import {Divider, MenuItem, PopoverProps, Tooltip, TooltipHandler, Typography} from '@genestack/ui';
import React from 'react';

import styles from './version-menu.module.css';
import {useCopyLinkToThisPage} from '../../../../hooks/use-copy-link-to-this-page';

const modifiers: PopoverProps['modifiers'] = [
    {
        name: 'preventOverflow',
        options: {
            altAxis: true
        }
    }
];

export interface CopyToDraftBtnProps {
    onClick: () => void;
    disabledReason?: string;
}

interface Props {
    copyToDraftBtnProps?: CopyToDraftBtnProps;
}

export const VersionMenuItems = (props: Props) => {
    const onCopyLink = useCopyLinkToThisPage();

    const onKeyPress = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'C') {
            onCopyLink();
        }
    };

    React.useEffect(() => {
        document.addEventListener('keypress', onKeyPress);

        return function cleanup() {
            document.removeEventListener('keypress', onKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isCreatingWorkingCopyDisabled = !!props.copyToDraftBtnProps?.disabledReason;

    return (
        <>
            <MenuItem
                key="copyLink"
                onClick={onCopyLink}
                append={
                    <Typography intent="quiet" variant="caption" box="inline" as="span">
                        Control + ⇧ + C
                    </Typography>
                }
                appendProps={{className: styles.menuAppend}}
            >
                Copy link
            </MenuItem>

            {props.copyToDraftBtnProps && (
                <React.Fragment>
                    <Divider key="divider" />
                    <TooltipHandler
                        tooltip={
                            props.copyToDraftBtnProps.disabledReason ? (
                                <Tooltip positionFixed modifiers={modifiers} placement="bottom">
                                    {props.copyToDraftBtnProps.disabledReason}
                                </Tooltip>
                            ) : null
                        }
                    >
                        <MenuItem
                            key="newDraft"
                            style={{pointerEvents: 'initial'}}
                            onClick={props.copyToDraftBtnProps.onClick}
                            disabled={isCreatingWorkingCopyDisabled}
                        >
                            Copy to new draft
                        </MenuItem>
                    </TooltipHandler>
                </React.Fragment>
            )}
        </>
    );
};
