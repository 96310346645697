import {Button, Divider, Typography} from '@genestack/ui';
import classNames from 'classnames';
import * as React from 'react';
import {Link} from 'react-router-dom';

import {ValidatedInput} from '../../../components/validated-input';
import {
    ErrorResponse,
    FormQueryResponse,
    SessionResponse
} from '../../../hooks/user/auth-form-query';
import {buildFormData} from '../../../utils/build-form-data';
import {IdentityFormMessage} from '../identity-form-message';
import {getSubmitNode} from '../utils';

import styles from './login-page.module.css';

interface Props {
    data: FormQueryResponse;
    onSubmit: (jsonFormData: {[key: string]: FormDataEntryValue}) => void;
    result?: FormQueryResponse | ErrorResponse | SessionResponse;
    isLoading?: boolean;
}

/** Login form component */
export function LoginForm(props: Props) {
    const formRef = React.createRef<HTMLFormElement>();

    const submitNode = getSubmitNode(props.data.ui.nodes);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (formRef.current) {
            const jsonFormData = buildFormData(formRef.current);

            if (submitNode?.attributes.name && submitNode?.attributes.value) {
                jsonFormData[String(submitNode.attributes.name)] = String(
                    submitNode.attributes.value
                );
            }

            props.onSubmit(jsonFormData);
        }
    };

    const [emailValue, setEmailValue] = React.useState('');
    const [passwordValue, setPasswordValue] = React.useState('');

    const emailNode = props.data.ui.nodes.find((node) => node.attributes.name === 'identifier');
    const passwordNode = props.data.ui.nodes.find((node) => node.attributes.name === 'password');
    const hiddenNodes = props.data.ui.nodes.filter((node) => node.attributes.type === 'hidden');

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const {value, defaultValue, ...restEmailAttributes} = emailNode?.attributes || {};
    const {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        value: pwdValue,
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        defaultValue: defaultPwdValue,
        ...restPasswordAttributes
    } = passwordNode?.attributes || {};

    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            {hiddenNodes.map((node) => {
                return <input key={node.attributes.name} {...node.attributes} />;
            })}
            <Typography variant="section" box="paragraph" as="label" intent="quiet">
                Email
            </Typography>

            <ValidatedInput
                className={styles.input}
                value={emailValue}
                onValueChange={setEmailValue}
                validationText={emailNode?.messages?.[0]?.text}
                {...restEmailAttributes}
            />

            <Typography
                variant="section"
                box="paragraph"
                as="label"
                htmlFor="password"
                intent="quiet"
            >
                Password
            </Typography>

            <ValidatedInput
                id="password"
                className={styles.input}
                value={passwordValue}
                onValueChange={setPasswordValue}
                validationText={emailNode?.messages?.[0]?.text}
                {...restPasswordAttributes}
            />

            <IdentityFormMessage response={props.result} />

            <Divider variant="transparent" gap={2} />
            <div className={styles.footer}>
                <Button type="submit" intent="accent" disabled={props.isLoading}>
                    Sign in
                </Button>
                <Link to="/recovery" className={classNames(styles.link, styles.formLink)}>
                    Restore password
                </Link>
            </div>
        </form>
    );
}
