import {ListItem} from '@genestack/ui';
import classNames from 'classnames';
import React, {PropsWithChildren} from 'react';

import styles from './suggestion-item.module.css';
import {AutocompletionValue, SuggestionHandler, SuggestionMap} from './common-typings';

/** OnChange suggestion handler */
export type OnChangeSuggestionHandler<Suggestion> = (
    suggestion: Suggestion,
    e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface AutocompletionProps<Suggestion = unknown> {
    inputValue: AutocompletionValue;
    selectedIndex: number;
    indexBySuggestionMap: SuggestionMap<Suggestion>;
    onChangeSuggestion: SuggestionHandler<Suggestion>;
    onChangeSelectedItem: (ndx: number) => void;
    updateScrollViewPositionByElement: (el: HTMLElement | null) => void;
}

interface Props<Suggestion> {
    value: Suggestion;
    autocompletionProps: AutocompletionProps;
    prepend?: React.ReactNode;
}

/** Autocomplete item. Used in conjunction with autocomplete */
export function SuggestionItem<Suggestion = unknown>(props: PropsWithChildren<Props<Suggestion>>) {
    const {value, autocompletionProps, prepend, children} = props;

    const {
        selectedIndex,
        indexBySuggestionMap,
        onChangeSuggestion,
        onChangeSelectedItem,
        updateScrollViewPositionByElement
    } = autocompletionProps;

    const itemRef = React.useRef<HTMLButtonElement>(null);
    const suggestionIndex = indexBySuggestionMap.get(value);
    const selected = selectedIndex === suggestionIndex;
    // TODO used selectionCallback which causes the selection
    //  blink when accessed from the keyboard (think about how to fix it)
    const [isSelected, setSelectedStatus] = React.useState<boolean>(selected);

    const handleSuggestion: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        onChangeSuggestion(value, e);
    };

    const onMouseEnter = React.useCallback(() => {
        if (suggestionIndex !== undefined) {
            onChangeSelectedItem(suggestionIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestionIndex]);

    React.useEffect(() => {
        if (selected) {
            updateScrollViewPositionByElement(itemRef.current);
        }

        setSelectedStatus(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <ListItem
            as="button"
            interactive
            focused={isSelected}
            prepend={prepend}
            ref={itemRef}
            onClick={handleSuggestion}
            onMouseEnter={onMouseEnter}
            className={classNames(styles.root, {
                [styles.inactive]: !isSelected
            })}
        >
            {children}
        </ListItem>
    );
}
