import {debounce} from '@genestack/ui';
import React from 'react';

import {useGlobalStateVariable} from '../../../providers/global-state';

import {getContainerSize} from './utils';
import {FlexibleLayoutSettings, PermittedStorageKeys} from './interface';

const RESIZE_DEBOUNCE_MS = 1000;

interface Props {
    storageKey: PermittedStorageKeys;
    settings: FlexibleLayoutSettings;
    containerRef: React.MutableRefObject<HTMLElement | null>;
    isVertical?: boolean;
}

/** Hook for storing layout size data */
export function useSizeParamsStore(props: Props) {
    const [savedShare, setSavedShare] = useGlobalStateVariable(
        props.storageKey,
        props.settings.initialFirstPanelShare
    );

    const [currentShare, setCurrentShare] = React.useState<number>(savedShare);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deferredUpdatingSizeParams = React.useCallback(
        debounce((value: number) => {
            setSavedShare(value);
        }, RESIZE_DEBOUNCE_MS),
        [props.storageKey]
    );

    const setShare = (share: number) => {
        setCurrentShare(share <= 0 ? 0 : share);
        deferredUpdatingSizeParams(share <= 0 ? 0 : share);
    };

    const changeFirstPanelSizeInPx = (value: number) => {
        const sizeInShares =
            value / getContainerSize(props.containerRef, props.isVertical ? 'height' : 'width');
        setShare(currentShare + sizeInShares);
    };

    return {firstPanelShare: currentShare, setFirstPanelShare: setShare, changeFirstPanelSizeInPx};
}
