import {SearchIcon, Input} from '@genestack/ui';
import React, {FocusEventHandler, KeyboardEventHandler} from 'react';

import {WithClasses} from '@genestack/ui/dist/utils/styles';
import styles from './search-field.module.css';

export interface Props extends WithClasses<keyof typeof styles> {
    value?: string;
    busy?: boolean;
    onValueChange?: (value: string) => void;
    placeholder?: string;
    prepend?: React.ReactNode;
    disabled?: boolean;
    autoFocus?: boolean;
    onKeyDown: KeyboardEventHandler;
    onFocus: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onClearButtonClick: () => void;
    clearable: boolean;
}

// eslint-disable-next-line react/display-name
export const SearchField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {placeholder = 'Search by name', value, busy = false, onValueChange, ...rest} = props;

    const handleValueChange = React.useCallback(
        (inputValue: string) => {
            if (onValueChange) {
                onValueChange(inputValue);
            }
        },
        [onValueChange]
    );

    return (
        <Input
            inputRef={ref}
            placeholder={placeholder}
            value={value}
            fullWidth
            onValueChange={handleValueChange}
            onClearButtonClick={props.onClearButtonClick}
            loading={busy}
            classes={{
                prependCell: styles.prependCell,
                ...rest.classes
            }}
            onKeyDown={props.onKeyDown}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            clearable={props.clearable}
            prepend={
                <>
                    <SearchIcon className={styles.icon} />
                    {rest.prepend}
                </>
            }
        />
    );
});
