import {
    CheckMarkIcon,
    CrossIcon,
    MenuItem,
    Tooltip,
    Typography,
    useTooltipHandler,
    WarningIcon
} from '@genestack/ui';
import React from 'react';

import {isGraphProblematic, isGraphUnmanageable} from '../results-graph/helpers';
import {ResultMaxLengthOption, GraphType} from '../../../../../components/graph/interface';

import styles from './query-results-header.module.css';

interface Props {
    option: ResultMaxLengthOption;
    isLastOption: boolean;
    isSelected: boolean;
    graphType: GraphType;
}

/** Label on the last option */
export const unlimitedLengthOptionLabel = 'Complete graph';
const problematicOptionTooltipText =
    "This subgraph has too many nodes. If you select this option, you'll face application performance problems.";
const unmanageableOptionTooltipText =
    'This subgraph has too many nodes. The option is unavailable.';

/** Item in the max path length selection menu */
export function MaxPathLengthMenuItem(props: Props) {
    const {
        option: {pathLength, minimalValuable, amountOfNodes, amountOfEdges},
        isLastOption,
        isSelected,
        graphType
    } = props;

    const isUnmanageableNodesAmount = isGraphUnmanageable(amountOfNodes, graphType);
    const isProblematicNodesAmount = isGraphProblematic(amountOfNodes, graphType);
    const [subtitleIntent, subtitleIcon, tooltipText] = (function () {
        if (isUnmanageableNodesAmount) {
            return [
                'alarm' as const,
                /* eslint-disable-next-line react/jsx-key */
                <CrossIcon className={styles.unmanageableGraphCrossIcon} />,
                unmanageableOptionTooltipText
            ];
        }

        if (isProblematicNodesAmount) {
            return [
                'warning' as const,
                <WarningIcon key="icon" className={styles.problematicGraphWarningIcon} />,
                problematicOptionTooltipText
            ];
        }

        return ['quiet' as const];
    })();

    const tooltipReferenceRef = React.useRef<HTMLDivElement>(null);
    const tooltip = useTooltipHandler({
        referenceElement: tooltipReferenceRef.current
    });

    return (
        <>
            <MenuItem
                value={pathLength}
                key={pathLength}
                subtitle={
                    <div
                        className={styles.problematicGraphLabel}
                        {...tooltip.getReferenceProps<object>({ref: tooltipReferenceRef})}
                    >
                        {subtitleIcon}{' '}
                        <Typography intent={subtitleIntent} variant="caption">
                            {amountOfNodes} nodes,
                            {amountOfEdges} edges
                        </Typography>
                    </div>
                }
                disabled={isUnmanageableNodesAmount}
                append={
                    minimalValuable &&
                    !isLastOption && (
                        <Typography intent="success" as="span">
                            Min. valuable
                        </Typography>
                    )
                }
                prepend={isSelected && <CheckMarkIcon />}
            >
                {isLastOption ? unlimitedLengthOptionLabel : `${pathLength} edges`}
            </MenuItem>
            {(function () {
                if (tooltipText) {
                    return <Tooltip {...tooltip.getTooltipProps()}>{tooltipText}</Tooltip>;
                }
            })()}
        </>
    );
}
