import {NodeSingular} from 'cytoscape';
import * as React from 'react';
import popoverStyle from './cytoscape-graph-view.module.css';

export function createPopper(
    el: NodeSingular,
    containerElementRef: React.MutableRefObject<HTMLDivElement | null>
) {
    return el?.popper({
        content: () => {
            const div = document.createElement('div');

            div.innerHTML = el.data().label;
            div.className = popoverStyle.popover;

            containerElementRef.current?.appendChild(div);

            return div;
        },
        popper: {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10]
                    }
                }
            ]
        }
    });
}
