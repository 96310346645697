import React from 'react';

export function cancellablePromise(promise: Promise<unknown>) {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (value) => (isCanceled ? reject({isCanceled, value}) : resolve(value)),
            (error: Error) => {
                reject({isCanceled, error});
            }
        );
    });

    return {
        promise: wrappedPromise,
        // eslint-disable-next-line no-return-assign
        cancel: () => (isCanceled = true)
    };
}

type CancellablePromiseValue = ReturnType<typeof cancellablePromise>;

export function useCancellablePromises() {
    const pendingPromises = React.useRef<CancellablePromiseValue[]>([]);

    // eslint-disable-next-line no-return-assign
    const appendPendingPromise = (promise: CancellablePromiseValue) =>
        (pendingPromises.current = [...pendingPromises.current, promise]);

    // eslint-disable-next-line no-return-assign
    const removePendingPromise = (promise: CancellablePromiseValue) =>
        (pendingPromises.current = pendingPromises.current.filter((p) => p !== promise));

    const clearPendingPromises = () => pendingPromises.current.map((p) => p.cancel());

    return {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises
    };
}
