import React from 'react';
import {ChevronDownIcon, ChevronRightIcon, ListItem, Typography} from '@genestack/ui';
import classNames from 'classnames';
import style from './graph-fact-panel-entry.module.css';
import {DocumentContent} from '../document-content/document-content';
import styles from '../document-content/documents-content.module.css';
import {GraphNodeContent} from '../../interface';

interface Props {
    contents: GraphNodeContent[];
    label: string;
    globalVersionId: number;
}

export function FactPanelEntryContentGroup(props: Props) {
    const [isUnfolded, setIsUnfolded] = React.useState(false);
    const toggleIsUnfolded = React.useCallback(() => setIsUnfolded((prev) => !prev), []);

    return (
        <React.Fragment>
            <ListItem
                className={classNames(style.graphFactPanelEntry, style.collapsibleItemLvl2)}
                prepend={isUnfolded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                onClick={toggleIsUnfolded}
            >
                <Typography variant="section" box="inline">
                    {props.label}
                </Typography>
                {` (${props.contents.length} fact${props.contents.length > 1 ? 's' : ''})`}
            </ListItem>
            {isUnfolded && (
                <ol className={styles.list}>
                    {props.contents.map((editorContent) => (
                        <DocumentContent
                            entry={editorContent}
                            globalVersionId={props.globalVersionId}
                            key={JSON.stringify(editorContent)}
                            includeCounter={props.contents.length > 1}
                            indentation={2}
                        />
                    ))}
                </ol>
            )}
        </React.Fragment>
    );
}
