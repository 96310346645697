import {useMutation} from 'react-query';

import {apiClient} from '../../utils/api-client';

import {GlobalVersionData} from './use-global-version-query';

function getVersionLabelRequest(label: string) {
    return apiClient.get<GlobalVersionData[]>({
        path: 'documents-revisions-service/api/global-versions',
        query: {label}
    });
}

/** Get global versions by label */
export function useGetVersionsByLabelMutation() {
    return useMutation(getVersionLabelRequest);
}
