import axios from 'axios';
import {useMutation} from 'react-query';

import {getRelativeIdentityPath} from './use-identity-form-mutation';

function getLogoutUrlRequest() {
    return axios.get<{logout_url: string}>('/logout').then((res) => res.data);
}

function logoutRequest(logoutUrl: string) {
    return fetch(logoutUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json'
        }
    });
}

/** Logout request */
export function useLogoutMutation() {
    return useMutation(async () => {
        const logoutData = await getLogoutUrlRequest();
        const logoutUrl = getRelativeIdentityPath(logoutData.logout_url);

        return logoutRequest(logoutUrl);
    });
}
