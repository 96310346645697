import * as React from 'react';

/** Hook for detection mounted state */
export function useMounted() {
    const mountedRef = React.useRef(false);

    React.useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return React.useCallback(() => mountedRef.current, []);
}
