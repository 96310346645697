import {Typography, ScrollView, Divider} from '@genestack/ui';
import React from 'react';

import {DocumentStatusIcon} from '../../../../../components/doc-status-icon';
import {PaginatedList} from '../../../../../components/pagination';
import {Preloader} from '../../../../../components/preloader';
import {SidebarResultsHeader} from '../../../../../components/sidebar-results-header';
import {UsageItemsGroup} from '../../../../../components/usage-items-group';
import {pluralize} from '../../../../../utils/pluralize';

import {EditModeDocumentNodes} from './document-nodes';
import {useFindUsagesQuery, DOC_PAGE_SIZE, UsageItemType} from './hooks/use-find-usages-query';
import {useLoadNextFindUsagesPageMutation} from './hooks/use-load-next-find-usages-page-mutation';
import {NodeSearchData} from '../../../common/sidebar-search-field/interface';

interface Props {
    searchData: NodeSearchData;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

function getSearchLabel(totalDocuments = 0, totalNodes = 0) {
    if (!totalDocuments) {
        return 'No results found';
    }

    const usagesLabel = `${totalNodes} ${pluralize('usage', totalNodes)}`;
    const documentLabel = `${totalDocuments} ${pluralize('document', totalDocuments)}`;

    return `${usagesLabel} in ${documentLabel}`;
}

export function FindUsages({searchData, getDocumentLink}: Props) {
    const {isLoading, data} = useFindUsagesQuery(searchData);

    const {isLoading: isLoadingNextPage, mutate: loadNextFindUsagesPage} =
        useLoadNextFindUsagesPageMutation(searchData);

    const renderFindUsage = React.useCallback(
        (usage: UsageItemType) => {
            const {accession, type, name, readOnly} = usage.document;

            const element = (
                <UsageItemsGroup
                    key={accession}
                    type={type}
                    readOnly={readOnly}
                    label={name}
                    count={usage.nodes.total}
                    append={<DocumentStatusIcon document={usage.document} />}
                >
                    <EditModeDocumentNodes
                        docAccession={usage.document.accession}
                        nodesData={usage.nodes}
                        getDocumentLink={getDocumentLink}
                    />
                </UsageItemsGroup>
            );

            return [usage.document.accession, element] as [React.Key, React.ReactElement];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const handleLoadNextPage = () => {
        loadNextFindUsagesPage();
    };

    return (
        <Preloader show={isLoading}>
            {data && (
                <>
                    <SidebarResultsHeader>
                        <Typography variant="section">
                            {getSearchLabel(data?.totalDocuments, data?.totalNodes)}
                        </Typography>
                    </SidebarResultsHeader>

                    <ScrollView>
                        <PaginatedList
                            pageSize={DOC_PAGE_SIZE}
                            items={data.items}
                            isLoadingNextPage={isLoadingNextPage}
                            hasNextPage={data.hasNextPage}
                            onLoadNext={handleLoadNextPage}
                            renderItem={renderFindUsage}
                        />

                        <Divider variant="transparent" gap={4} />
                    </ScrollView>
                </>
            )}
        </Preloader>
    );
}
