import {useQuery} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

function getDocumentMetadataRequest(
    accession: string,
    globalVersionId: number,
    revisionId?: string
) {
    return apiClient.get<ViewModeDocInfo>({
        path: `documents-revisions-service/api/viewer/documents/${accession}/metadata`,
        query: {'global-version': globalVersionId, revision: revisionId}
    });
}

export function useViewableMetadataQuery(props: {
    accession: string;
    globalVersionId: number;
    enabled?: boolean;
    revisionId?: string;
}) {
    return useQuery(
        ['view-metadata', props.accession, props.globalVersionId, props.revisionId],
        () => {
            return getDocumentMetadataRequest(
                props.accession,
                props.globalVersionId,
                props.revisionId
            );
        },
        {enabled: props.enabled}
    );
}
