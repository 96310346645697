import {Typography, Controls, ControlsItem} from '@genestack/ui';
import React from 'react';

import {DocumentTypeLabel} from '../../../../components/document-type-label';

import styles from './document-unit.module.css';

interface DocInfoLike {
    name: string;
    type: DocType;
    readOnly?: boolean;
}

interface Props {
    docInfo: DocInfoLike;
    selected?: boolean;
    append?: React.ReactNode;
}

/** A universal component for rendering stylized document type and title */
export const DocumentUnit = ({docInfo, selected, append}: Props) => (
    <Controls gap={1}>
        <ControlsItem>
            <DocumentTypeLabel
                type={docInfo.type}
                readOnly={docInfo.readOnly}
                selected={selected}
            />
        </ControlsItem>
        {/* FIXME `flexHack` adds `flex-basis: 0px` style to `ControlsItem` to make component
          stretch to max width of parent component. Consider bringing this to UIKit */}
        <ControlsItem grow className={styles.flexHack}>
            <Typography ellipsis title={docInfo.name} data-qa-name="document name">
                {docInfo.name}
            </Typography>
        </ControlsItem>

        {!!append && <ControlsItem>{append}</ControlsItem>}
    </Controls>
);
