import React from 'react';
import useDebounce from 'react-use/lib/useDebounce';

import {extractInfiniteQueryItems} from '../../../../../utils/infinite-query-items';

import {DocumentResultsHeader, DocumentResultsLayout} from './document-results';
import {useSearchDocumentsInfiniteQuery} from './search-docs-infinite-query';
import {SearchResultComponentProps} from './interface';
import {DocSearchData} from '../../../common/sidebar-search-field/interface';
import {CategoryTreeElement} from './categories/adapt-tree';

const DEBOUNCE_INTERVAL = 200;

export function DocResults({
    searchData,
    categoriesFilters,
    expanded,
    versionId,
    onToggleExpand,
    loadingState,
    setLoadingState,
    selectedGlobalVersion,
    getDocumentLink
}: SearchResultComponentProps<DocSearchData>) {
    const searchText = searchData.value ?? '';

    const [debouncedValue, setDebouncedValue] = React.useState('');

    useDebounce(
        () => {
            setDebouncedValue(searchText);
        },
        DEBOUNCE_INTERVAL,
        [searchText]
    );

    const docsQuery = useSearchDocumentsInfiniteQuery({
        searchText: debouncedValue,
        versionId,
        categories: categoriesFilters.appliedFilters
    });

    const items = extractInfiniteQueryItems(
        docsQuery,
        (result: PageResponse<ViewModeDocInfo>) => result.data
    );

    const handleChangeFilters = React.useCallback(
        (
            toAddVisual: CategoryTreeElement[],
            toRemoveVisual: CategoryTreeElement[],
            toAddApplied: string[],
            toRemoveApplied: string[]
        ) => {
            categoriesFilters.onChangeAppliedFilters(toAddApplied, toRemoveApplied);
            categoriesFilters.onChangeVisualFilters(toAddVisual, toRemoveVisual);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        categoriesFilters.onResetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    return (
        <>
            <DocumentResultsHeader
                isLoading={docsQuery.isFetching}
                hasItems={Boolean(items)}
                total={docsQuery.data?.pages[0]?.total ?? 0}
                expanded={expanded}
                onToggleExpand={onToggleExpand}
                searchData={searchData}
                loadingState={loadingState}
                selectedGlobalVersion={selectedGlobalVersion}
            />

            <DocumentResultsLayout
                expanded={expanded}
                paginationState={{
                    items,
                    total: docsQuery.data?.pages[0]?.total,
                    isLoading: docsQuery.isFetching,
                    hasNextPage: docsQuery.hasNextPage,
                    loadNextPage: docsQuery.fetchNextPage,
                    isLoadingNextPage: docsQuery.isFetchingNextPage,
                    reset: docsQuery.remove
                }}
                appliedFilters={categoriesFilters.appliedFilters}
                visualFilters={categoriesFilters.visualFilters}
                onChangeFilters={handleChangeFilters}
                onLoadNextPage={docsQuery.fetchNextPage}
                onResetFilters={categoriesFilters.onResetFilters}
                versionId={versionId}
                searchText={debouncedValue}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                getDocumentLink={getDocumentLink}
            />
        </>
    );
}
