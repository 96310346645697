import {
    Drawer,
    Typography,
    Controls,
    ControlsItem,
    DrawerProps,
    DrawerFullWidth,
    Button,
    CrossIcon,
    Divider
} from '@genestack/ui';
import classNames from 'classnames';
import * as React from 'react';

import {ShortcutsLabel} from './shortcut-label';
import styles from './shortcuts-drawer.module.css';
import {ShortcutsSection, Shortcut} from './shortcuts-section';

const EDITOR_SHORTCUTS: Shortcut[] = [
    {
        keys: ['Ctrl', 'B'],
        label: 'Go to reference'
    },
    {
        keys: ['Ctrl', 'U'],
        label: 'Find Usages'
    },
    {
        keys: ['Ctrl / Option', 'Space'],
        label: 'Show autocomplete'
    },
    {
        keys: ['Ctrl', 'P'],
        label: 'Import PubMed article metadata'
    }
];

const COMMON_SHORTCUTS: Shortcut[] = [
    {
        keys: ['Ctrl', '/'],
        label: 'Display keyboard shortcuts'
    }
];

/** List of shortcuts in a drawer component */
export function ShortcutsDrawer(props: DrawerProps) {
    const handleCloseButton = () => {
        props.onClose?.('backdrop-click');
    };

    return (
        <Drawer
            side="right"
            overlayProps={{invisible: true}}
            {...props}
            className={classNames(styles.root, props.className)}
        >
            <Button
                ghost
                size="tiny"
                component="button"
                className={styles.closeButton}
                onClick={handleCloseButton}
            >
                <CrossIcon />
            </Button>

            <DrawerFullWidth>
                <Controls className={styles.header}>
                    <ControlsItem className={styles.shortcut}>
                        <ShortcutsLabel keys={['?']} justify="end" />
                    </ControlsItem>
                    <ControlsItem>
                        <Typography variant="title">Keyboard shortcuts</Typography>
                    </ControlsItem>
                </Controls>

                <ShortcutsSection title="OT Editor" shortcuts={EDITOR_SHORTCUTS} />
                <Divider gap={2} variant="transparent" />
                <ShortcutsSection shortcuts={COMMON_SHORTCUTS} />
            </DrawerFullWidth>
        </Drawer>
    );
}
