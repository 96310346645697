import {useQuery} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphViewType} from '../../../../../../components/graph/interface';

function getQueryViewTypes(queryId: number) {
    return apiClient.get<GraphViewType[]>({
        path: `queries-service/api/queries/${queryId}/connection-graph/views`
    });
}

export function useGetQueryViewTypes(queryId: number) {
    return useQuery(['get-query-view-types', queryId], {
        queryFn: () => getQueryViewTypes(queryId)
    });
}
