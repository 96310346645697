// tslint:disable: no-magic-numbers
import React from 'react';

/** Skeleton SVG for `LastRevisionLabel` */
export const DocumentMetadataSkeleton = () => (
    <svg width={268} height={24} fill="none">
        <rect width={24} height={24} fill="#C7CAD1" rx={12} />
        <path fill="#C7CAD1" d="M28 2h240v20H28z" />
    </svg>
);
