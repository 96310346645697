import {
    MenuItem,
    Divider,
    Tooltip,
    TooltipHandler,
    PopoverProps,
    TrashIcon,
    PublishIcon
} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useLocationParams} from '../../../../../../hooks/use-location';
import {useUserPermittedEditingDoc} from '../../../../../../hooks/user/use-has-permission';
import {
    isDeletedDocument,
    isDocumentDraft,
    isDocumentBeingEdited
} from '../../../../../../utils/document';
import {useStartEditingDocument} from '../use-start-editing-document';

import {DeleteItem} from './delete-item';
import {DiscardItem} from './discard-item';
import {RestoreItem} from './restore-item';

interface Props {
    docInfo: DocInfo;
    onClickPublish: () => void;
    onClickRename: () => void;
}

const modifiers: PopoverProps['modifiers'] = [
    {
        name: 'preventOverflow',
        options: {
            altAxis: true
        }
    }
];

/** Controls for menu */
export const EditViewMenuItems = ({docInfo, onClickPublish, onClickRename}: Props) => {
    const {push} = useHistory();
    const locationParams = useLocationParams();

    const isUserPermittedToEdit = useUserPermittedEditingDoc(docInfo);

    const handleOpenRevisions = () => {
        push(`/documents/edit/${locationParams.documentId}/revisions`);
    };

    const {startEditing, isDocEditable, editingDisabledReason} = useStartEditingDocument({
        documentMetadata: docInfo
    });

    if (isDeletedDocument(docInfo)) {
        return (
            <>
                <RestoreItem accession={docInfo.accession}>
                    <MenuItem disabled={!isUserPermittedToEdit}>Restore</MenuItem>
                </RestoreItem>
                <Divider key="divider" />
                <MenuItem key="showHistory" onClick={handleOpenRevisions}>
                    Show revision history
                </MenuItem>
            </>
        );
    }

    if (isDocumentBeingEdited(docInfo)) {
        return (
            <>
                <MenuItem
                    key="publish"
                    onClick={onClickPublish}
                    prepend={<PublishIcon />}
                    disabled={!isUserPermittedToEdit}
                >
                    Publish
                </MenuItem>
                <MenuItem key="rename" onClick={onClickRename} disabled={!isUserPermittedToEdit}>
                    Rename
                </MenuItem>
                <MenuItem
                    key="showHistory"
                    onClick={handleOpenRevisions}
                    disabled={isDocumentDraft(docInfo)}
                >
                    Show revision history
                </MenuItem>
                <Divider key="divider" />
                <DiscardItem docMetadata={docInfo} disabled={!isUserPermittedToEdit} />
            </>
        );
    }

    return (
        <>
            <TooltipHandler
                tooltip={
                    !isDocEditable ? (
                        <Tooltip positionFixed modifiers={modifiers} placement="bottom">
                            {editingDisabledReason}
                        </Tooltip>
                    ) : null
                }
            >
                <MenuItem
                    key="edit"
                    disabled={!isDocEditable}
                    onClick={startEditing}
                    style={{pointerEvents: 'initial'}}
                >
                    Edit
                </MenuItem>
            </TooltipHandler>
            <MenuItem key="showHistory" onClick={handleOpenRevisions}>
                Show revision history
            </MenuItem>
            <Divider key="divider" />
            <DeleteItem accession={docInfo.accession}>
                <MenuItem
                    key="delete"
                    disabled={docInfo.readOnly || !isUserPermittedToEdit}
                    prepend={<TrashIcon />}
                >
                    Delete
                </MenuItem>
            </DeleteItem>
        </>
    );
};
