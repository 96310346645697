import {RelationPresentation} from '../interface';

export enum GraphEntityStatus {
    default = 'default',
    hovered = 'hovered',
    selected = 'selected'
}

export interface CytoscapeEdgeData {
    id: string;
    source: string;
    target: string;
    presentation: RelationPresentation;
}

export enum QueryGraphLayoutAlgorithm {
    LAYERED_HORIZONTAL = 'layered (horizontal)',
    LAYERED_VERTICAL = 'layered (vertical)',
    DAGRE = 'dagre',
    MRTREE = 'mr tree',
    COSE = 'cose',
    FCOSE = 'fcose',
    COSE_BILKENT = 'cose-bilkent',
    BREADTHFIRST = 'breadthfirst',
    COLA = 'cola',
    SPREAD = 'spread'
}
