import React from 'react';

interface Props {
    ctrlKey?: boolean;
    optionKey?: boolean;
    key: string;
    handler: () => void;
    isDisabledOnFields?: boolean;
    disabled?: boolean;
}

function isField(value: unknown): value is HTMLInputElement | HTMLTextAreaElement {
    for (const item of [HTMLInputElement, HTMLTextAreaElement]) {
        if (value instanceof item) {
            return true;
        }
    }

    return false;
}

/** Hotkey interceptor */
export function useHotkey<T extends HTMLElement>(
    {ctrlKey = false, key, handler, isDisabledOnFields, disabled}: Props,
    deps: React.DependencyList = []
) {
    const handleKeyPress = (e: KeyboardEvent) => {
        const element = e.target as T;

        if (isDisabledOnFields && isField(element) && !element.disabled) {
            return;
        }

        if (!disabled && e.ctrlKey === ctrlKey && e.key === key) {
            handler();
        }
    };

    React.useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);

        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctrlKey, key, handler, disabled, ...deps]);
}
