import {ScrollView} from '@genestack/ui';
import React from 'react';
import {FoldingItem} from '../folding-item';
import {useFoldingItemsState} from './use-folding-items-state';
import {RightPanelListItem} from '../right-panel-list-item';
import {ProcessStatRecord} from '../interface';
import {EmptyList} from '../../../../../../components/empty-list/empty-list';

interface Props {
    processes: ProcessStatRecord[];
    foldingStateProps: ReturnType<typeof useFoldingItemsState>;
    selectedGraphNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
}

export function ProcessesStatistics(props: Props) {
    if (!props.processes.length) {
        return <EmptyList message="Nothing found" />;
    }

    return (
        <ScrollView>
            {props.processes.map((process) => (
                <FoldingItem
                    key={process.label}
                    label={process.label}
                    count={process.occurrencesCount}
                    foldingStateProps={props.foldingStateProps}
                    foldingStateKey={process.label}
                    padding={1}
                >
                    {process.params.map((param) => (
                        <FoldingItem
                            key={param.label}
                            label={param.label}
                            foldingStateProps={props.foldingStateProps}
                            foldingStateKey={`${process.label}.${param.label}`}
                            padding={2}
                        >
                            {param.values.map((continuantStatRecord) => (
                                <RightPanelListItem
                                    label={continuantStatRecord.label}
                                    count={continuantStatRecord.occurrencesCount}
                                    key={`${continuantStatRecord.documentAccession}${continuantStatRecord.elementNodeId}`}
                                    active={
                                        // if graph is empty graphViewNodeIds length is 0
                                        !!continuantStatRecord.graphViewNodeIds.length &&
                                        continuantStatRecord.graphViewNodeIds.every((id) =>
                                            props.selectedGraphNodes.includes(id)
                                        )
                                    }
                                    setHoveredNodes={props.setHoveredNodes}
                                    selectGraphNodes={props.selectGraphNodes}
                                    graphViewNodeIds={continuantStatRecord.graphViewNodeIds}
                                    padding={3}
                                />
                            ))}
                        </FoldingItem>
                    ))}
                </FoldingItem>
            ))}
        </ScrollView>
    );
}
