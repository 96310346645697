import classNames from 'classnames';
import React from 'react';

import styles from './editor-footer.module.css';

/** Document editor footer */
export function EditorFooter(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className={classNames(styles.root, props.className)}>
            {props.children}
        </div>
    );
}
