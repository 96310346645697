import classNames from 'classnames';
import React from 'react';

import {
    exploredDocumentSource,
    FindUsagesEditorEvent,
    historyPanelDocumentSource
} from '../../../../../../webeditor/biokb-editor-adapters';
import {BelEditorWrapper} from '../../../../../components/editor';
import {useGoToDeclarationHandler} from '../../../../../hooks/use-go-to-declaration-handler';

import {EditorFooterViewMode} from './editor-footer';
import styles from './explore-view-editor.module.css';
import {useEditorModel} from '../model';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';
import {BusyIndicator} from '../../../../../components/busy-indicator';

type EditorState = ReturnType<typeof useEditorModel>;

interface Props {
    accession: string;
    nodeId?: string;
    revisionId?: string;
    selectedGlobalVersion: GlobalVersionData;

    onFindUsages: (event: FindUsagesEditorEvent) => void;
    onFactsSelectionChanged: (factIds: string[]) => void;
    selectedFacts: string[];

    isLoadingLastRevision: EditorState['isLoadingLastRevision'];
    revisionInfo: EditorState['revisionInfo'];
    documentInfo: EditorState['documentInfo'];
}

function exploreViewDocumentSource(accession: string, revisionId?: string, versionId?: number) {
    if (revisionId) {
        return historyPanelDocumentSource(accession, revisionId);
    }
    if (versionId) {
        return exploredDocumentSource(accession, versionId);
    }
}

// tslint:disable-next-line: cyclomatic-complexity
export function ExploreViewEditor({
    accession,
    nodeId,
    revisionId,
    onFindUsages,
    isLoadingLastRevision,
    revisionInfo,
    documentInfo,
    selectedGlobalVersion,
    onFactsSelectionChanged,
    selectedFacts
}: Props) {
    const goToDeclarationHandler = useGoToDeclarationHandler();
    const [isLoadingEditor, setIsLoadingEditor] = React.useState(false);

    return (
        <React.Fragment>
            {isLoadingEditor && <BusyIndicator />}

            <div className={styles.root}>
                <BelEditorWrapper
                    className={classNames(styles.belEditorWrapper, {
                        [styles.belEditorHidden]: isLoadingEditor
                    })}
                    documentSource={exploreViewDocumentSource(
                        accession,
                        revisionId,
                        selectedGlobalVersion.id
                    )}
                    nodeId={nodeId}
                    onLoadingStateChange={setIsLoadingEditor}
                    onNavigate={goToDeclarationHandler}
                    onFindUsages={onFindUsages}
                    onFactSelectionChanged={onFactsSelectionChanged}
                    selectedFacts={selectedFacts}
                />
            </div>

            <EditorFooterViewMode
                isLoading={isLoadingLastRevision}
                documentId={accession}
                documentRevision={documentInfo?.revision}
                revisionInfo={revisionInfo}
                selectedGlobalVersion={selectedGlobalVersion}
            />
        </React.Fragment>
    );
}
