import React from 'react';
import useIntersection from 'react-use/esm/useIntersection';

interface Props {
    onLoadNext: () => void;
}

/** Pagination next page accessor */
export function NextPageAutoExecutor(props: Props) {
    const nextPageAccessorRef = React.useRef<HTMLDivElement>(null);
    const intersectionObserverEntry = useIntersection(nextPageAccessorRef, {});

    React.useEffect(() => {
        if (intersectionObserverEntry?.isIntersecting) {
            props.onLoadNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersectionObserverEntry, props.onLoadNext]);

    return <div ref={nextPageAccessorRef} />;
}
