import {InfiniteData, useQueryClient} from 'react-query';
import {QueryKey} from 'react-query/types/core/types';
import {QueryFilters} from 'react-query/types/core/utils';

import {UNCATEGORIZED_CATEGORY_ID} from '../../../../utils/get-categories-search';
import {
    addDocumentToCache,
    removeDocumentFromCache,
    updateDocumentInCache
} from '../../../../utils/infinite-query-items';
import {DocumentStatus} from '../api';

import {
    useEditModeDocsInfiniteQuery,
    Props as UseEditModeDocsInfiniteQueryProps
} from './use-edit-mode-docs-infinite-query';

interface Props {
    docType: DocType;
    categories?: UseEditModeDocsInfiniteQueryProps['categories'];
    pageSize?: UseEditModeDocsInfiniteQueryProps['pageSize'];
    options?: UseEditModeDocsInfiniteQueryProps['options'];
}

const categoryParamIndex = 3;

/** Hook to request documents of "Ontology" tree */
export function useOntologyDocsQuery(props: Props) {
    return useEditModeDocsInfiniteQuery({
        ...props,
        queryKey: 'ontology',
        pageSize: props.pageSize ?? 'variable',
        statuses: [DocumentStatus.DRAFT, DocumentStatus.EDITABLE, DocumentStatus.PUBLISHED]
    });
}

/** Hook to update search cache for documents of an appropriate type */
export function useOntologyDocsQueryCache() {
    const queryClient = useQueryClient();
    const getQueryFilters = (docType: DocType, categoryId?: string): QueryFilters => {
        const queryKey: QueryKey[] = ['searchEditMode', 'ontology', docType];

        if (categoryId) {
            queryKey.push([categoryId]);
        }

        return {queryKey, exact: false};
    };

    function invalidate(docType?: DocType) {
        return queryClient.invalidateQueries({
            queryKey: ['searchEditMode', 'ontology', docType],
            exact: false
        });
    }

    function refetch(docType: DocType, categoryId?: string) {
        return queryClient.refetchQueries({
            queryKey: getQueryFilters(docType, categoryId).queryKey,
            exact: false
        });
    }

    // FIXME Use uncategorized for ontology only
    function addDocument(document: DocInfo, categoryId = UNCATEGORIZED_CATEGORY_ID) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            getQueryFilters(document.type, categoryId),
            (currentState) => currentState && addDocumentToCache(currentState, document)
        );
    }

    function updateDocument(updatedData: Partial<DocInfo> & {accession: string; type: DocType}) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            getQueryFilters(updatedData.type),
            (currentState) => currentState && updateDocumentInCache(currentState, updatedData)
        );
    }

    function deleteDocument(document: DocInfo, categoryId?: string) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            getQueryFilters(document.type, categoryId),
            (currentState) =>
                currentState && removeDocumentFromCache(currentState, document.accession)
        );
    }

    function getCategoriesByDocument(targetDoc: DocInfo) {
        const queryFilters = getQueryFilters(targetDoc.type);

        const queriesData =
            queryClient.getQueriesData<InfiniteData<PageResponse<DocInfo>>>(queryFilters);

        return queriesData
            .filter(([, response]) => {
                return !!response?.pages.find(
                    (page) => !!page.data.find((doc) => doc.id === targetDoc.id)
                );
            })
            .map(([requestOptions]) => (requestOptions[categoryParamIndex] as string[])[0]);
    }

    return {
        invalidate,
        addDocument,
        updateDocument,
        deleteDocument,
        getCategoriesByDocument,
        refetch
    };
}
