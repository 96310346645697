import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import {DocumentsRoute} from './application/document-workflow/index-route';
import {ManageUsersPage} from './application/manage-users-page';
import {ProfilePage} from './application/profile-page';
import {QueryWorkflow} from './application/query-workflow';
import {QueryManagementPanel} from './application/query-workflow/query-management-panel/query-management-panel';
import {QueryWebsocketEventsManager} from './application/query-workflow/websocket-events-manager/query-websocket-events-manager';
import {AppHeader} from './components/application-header/application-header';
import {LocalErrorBoundary} from './components/error-boundary';
import {Sandbox} from './components/sandbox/sandbox';
import {DefaultRoute} from './default-route';
import {useUrlSearchValue, UrlSearchParam} from './hooks/use-search-params';
import {useIsCanManageQueries, useIsCanManageUsers} from './hooks/user/use-has-permission';
import {useGlobalVersion} from './providers/version/use-global-version';

export function AuthenticatedApp() {
    const history = useHistory();
    const urlSearchValue = useUrlSearchValue(UrlSearchParam.originUrl);
    const isCanManageUsers = useIsCanManageUsers();
    const isCanManageQueries = useIsCanManageQueries();
    const globalVersionProps = useGlobalVersion();

    React.useEffect(() => {
        if (urlSearchValue.value && urlSearchValue.value !== '/') {
            history.replace(urlSearchValue.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <AppHeader
                currentGlobalVersion={globalVersionProps.currentGlobalVersionData}
                globalVersions={globalVersionProps.globalVersions}
            />

            <LocalErrorBoundary>
                <Switch>
                    <Route
                        path="/queries/:queryId?"
                        render={() => <QueryWorkflow globalVersionProps={globalVersionProps} />}
                    />

                    <Route
                        path="/documents"
                        render={(routeProps) => (
                            <DocumentsRoute
                                {...routeProps}
                                globalVersionProps={globalVersionProps}
                            />
                        )}
                    />
                    <Route path="/profile" component={ProfilePage} />

                    {isCanManageUsers && (
                        <Route path="/users/:userId?" component={ManageUsersPage} />
                    )}

                    {isCanManageQueries && (
                        <Route path="/manage-queries" component={QueryManagementPanel} />
                    )}

                    {!process.env.production && <Route path="/sandbox" component={Sandbox} />}

                    <DefaultRoute />
                </Switch>

                <QueryWebsocketEventsManager
                    notifyOnlyOnMyQueries={history.location.pathname !== '/manage-queries'}
                />
            </LocalErrorBoundary>
        </React.Fragment>
    );
}
