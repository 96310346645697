import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

import {useRelativePath} from './relative-path';

/** A link that can be used in combination with RelativePath context to generate relative links */
export const RelativeLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    function RelativeLinkComponent(props, ref) {
        const {path} = useRelativePath();

        return <Link ref={ref} {...props} to={`${path}${props.to}`} />;
    }
);
