const THOUSANDS_DELIMITER = '\u2009'; // thin space
const DELIMITER_FREQUENCY = 3;

/** Render an integer with a thin space as a thousands separator */
export const formatInteger = (value: number) =>
    Math.round(value)
        .toString()
        .split('') // Make the array
        // Reduce from right side and insert thin spaces:
        .reduceRight(
            (acc, character, index, characters) =>
                `${
                    (characters.length - index) % DELIMITER_FREQUENCY === 0
                        ? `${THOUSANDS_DELIMITER}${character}`
                        : character
                }${acc}`,
            ''
        )
        .trim(); // trim redundant delimiters e.g. from ' 333'
