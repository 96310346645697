import {useMutation} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphPresentation, GraphPresentationDetails} from '../interface';
import {useGetPresentationsCache} from './use-get-presentations';
import {showErrorNotification} from '../../../../../../components/notifications-center/notifications-store';

interface MutationParams {
    queryId: number;
    presentationId: number;
    unsavedDetails?: GraphPresentationDetails;
}

function deletePresentationRequest({queryId, presentationId}: MutationParams) {
    return apiClient.delete<GraphPresentation>({
        path: `queries-service/api/queries/${queryId}/graph-presentations/${presentationId}`
    });
}

export function useDeletePresentation(
    onPresentationDeleted: () => void,
    onPresentationDeleteFailed: (id: number, unsavedDetails?: GraphPresentationDetails) => void
) {
    const getPresentationsCache = useGetPresentationsCache();

    return useMutation({
        mutationFn: deletePresentationRequest,
        onMutate: ({queryId, presentationId, unsavedDetails}: MutationParams) => {
            onPresentationDeleted();
            const previousPresentations = getPresentationsCache.deletePresentation(
                queryId,
                presentationId
            );
            return {previousPresentations, unsavedDetails};
        },
        onError: (error, params, context) => {
            getPresentationsCache.setPresentations(params.queryId, context!.previousPresentations!);
            showErrorNotification('Request error, try again later')(error);
            onPresentationDeleteFailed(params.presentationId, params.unsavedDetails);
        }
    });
}
