import React from 'react';

import {SearchInputValue} from './use-search-sidebar-context';

import {
    getDocSuggestions,
    getCommonSuggestions,
    getNodeTypeSuggestions,
    getHistoryRecordSuggestion
} from '../suggestion-helpers';
import {
    SidebarCategorySuggestion,
    SidebarDocSuggestion,
    SidebarNodeSuggestion,
    SidebarNodeTypeSuggestion
} from '../interface';
import {useNodeSuggestions} from './use-node-suggestions';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';
import {SearchHistoryRecordDto} from '../../../explore-view/hooks/requests/use-explore-view-search-history-query';

export const DOC_SHORTCUT_TYPE = 'document';
export const CATEGORY_SHORTCUT_TYPE = 'category';
export const TEXT_OPERATOR = ':';

interface Props {
    inputValue: SearchInputValue;
    currentGlobalVersion?: GlobalVersionData;
    searchHistory?: SearchHistoryRecordDto[];
}

export const useSuggestions = (props: Props) => {
    const {inputValue, currentGlobalVersion, searchHistory} = props;
    const {prefix, value, type} = inputValue;

    const {
        isLoadingNodes,
        nodes,
        loadNodes,
        resetNodes,

        isLoadingNodeTypes,
        nodeTypes,
        invalidateNodeTypesQuery
    } = useNodeSuggestions({
        debounceDelay: 500,
        inputValue,
        globalVersionId: currentGlobalVersion?.id
    });

    const isLoading = isLoadingNodes || isLoadingNodeTypes;
    const isValidNodeType = React.useMemo(
        () =>
            nodeTypes?.some((nodeType) => nodeType.toLowerCase() === prefix.toLowerCase()) || false,
        [prefix, nodeTypes]
    );

    const getSuggestions = React.useCallback(() => {
        if (
            type !== 'searchNodeTypes' &&
            type !== 'searchByDocName' &&
            /[^ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
        ) {
            // use null instead of empty string for all types of suggestions
            loadNodes(value, prefix || null, currentGlobalVersion?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, prefix, currentGlobalVersion, type]);

    React.useEffect(() => {
        getSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!value) {
            resetNodes();
        }

        getSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefix, value]);

    const historySuggestions = React.useMemo(
        () =>
            (type === 'emptyInput' &&
                searchHistory?.map((searchRecord) => getHistoryRecordSuggestion(searchRecord))) ||
            [],
        [type, searchHistory]
    );

    const docSuggestions = React.useMemo<SidebarDocSuggestion[]>(
        () => getDocSuggestions(type, value),
        [type, value]
    );

    const nodeTypeSuggestions = React.useMemo<SidebarNodeTypeSuggestion[]>(
        () => (nodeTypes ? getNodeTypeSuggestions(type, nodeTypes, value) : []),
        [type, nodeTypes, value]
    );

    const commonSuggestions = React.useMemo<
        Array<SidebarNodeSuggestion | SidebarCategorySuggestion>
    >(() => getCommonSuggestions(type, nodes, value), [type, nodes, value]);

    return {
        isLoading,
        isValidNodeType,

        docSuggestions,
        commonSuggestions,
        nodeTypeSuggestions,
        historySuggestions,
        invalidateNodeTypesQuery
    };
};
