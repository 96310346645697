import {useQuery} from 'react-query';
import {GraphViewType} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';
import {StartEndNodesResponse} from '../interface';

function getStartEndNodesRequest(queryId: number, viewType: GraphViewType, maxPathLength: number) {
    return apiClient.get<StartEndNodesResponse>({
        path: `queries-service/api/queries/${queryId}/terminal-nodes/${viewType}`,
        query: {
            'path-length-limit': maxPathLength
        }
    });
}

export function useGetStartEndNodes(
    queryId: number,
    viewType: GraphViewType,
    maxPathLength: number,
    onError: (error: unknown) => void
) {
    return useQuery({
        queryKey: ['query-graph-start-end-nodes', queryId, viewType, maxPathLength],
        queryFn: () => getStartEndNodesRequest(queryId, viewType, maxPathLength),
        onError
    });
}
