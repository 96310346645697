import axios from 'axios';
import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';

import {UserIdentity} from '../../../typings/user';

function getCurrentUserRequest() {
    return axios.get<UserIdentity>('/users-service/api/users/self').then((res) => res.data);
}

/** Returns user info */
export function useCurrentUserQuery(options?: UseQueryOptions<UserIdentity>) {
    return useQuery<UserIdentity>('getCurrentUserRequest', getCurrentUserRequest, options);
}

/** User info query cache manager */
export function useCurrentUserQueryCache() {
    const queryClient = useQueryClient();

    function updateCurrentUser(userData: Partial<UserIdentity>) {
        queryClient.setQueriesData<UserIdentity | undefined>(
            'getCurrentUserRequest',
            (currentState) =>
                currentState && {
                    ...currentState,
                    ...userData
                }
        );
    }

    return {updateCurrentUser};
}
