import React from 'react';
import {Menu, MenuItem, StarIcon, TrashIcon, SubMenu, Divider} from '@genestack/ui';
import {GraphPresentationPropsLoaded} from '../results-graph/use-graph-presentation-props';
import {usePresentationEditedProps} from '../results-graph/use-presentation-edited-props';
import {PresentationItem, unsavedChangesBadge} from './presentation-item';
import {useGraphPresentationActions} from '../results-graph/use-graph-presentation-actions';
import {EditableTitle, EditableTitleApi} from '../../../../../components/editable-title';

interface Props {
    queryId: number;
    presentationProps: GraphPresentationPropsLoaded;
    presentationEditedProps: ReturnType<typeof usePresentationEditedProps>;
    clearUnsavedPresentationDetails: () => void;
    presentationActionsProps: ReturnType<typeof useGraphPresentationActions>;
    isCalculatingLayout: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PresentationMenu({
    presentationProps,
    presentationEditedProps,
    clearUnsavedPresentationDetails,
    presentationActionsProps,
    isCalculatingLayout,
    queryId
}: Props) {
    const editableTitleRef = React.useRef<EditableTitleApi>(null);
    const {isPresentationEdited} = presentationEditedProps;

    const onRenameClick = () => {
        editableTitleRef.current?.rename();
    };

    const selectPresentationSubMenu = (
        <SubMenu>
            {presentationProps.presentations.map((presentation) => (
                <PresentationItem
                    presentation={presentation}
                    presentationProps={presentationProps}
                    queryId={queryId}
                    key={presentation.id}
                />
            ))}
        </SubMenu>
    );

    const menu = (
        <Menu>
            <MenuItem key="select" subMenu={selectPresentationSubMenu}>
                Select presentation
            </MenuItem>
            <MenuItem
                key="rename"
                disabled={presentationProps.isDefaultPresentation}
                onClick={onRenameClick}
            >
                Rename
            </MenuItem>
            <MenuItem
                key="save"
                disabled={
                    presentationProps.isDefaultPresentation ||
                    !isPresentationEdited ||
                    isCalculatingLayout
                }
                onClick={presentationActionsProps.saveSelectedPresentation}
            >
                Save changes
            </MenuItem>
            <MenuItem
                key="saveAsNew"
                disabled={isCalculatingLayout}
                onClick={presentationActionsProps.saveSelectedPresentationAsNew}
            >
                Make a copy
            </MenuItem>
            <MenuItem
                key="discard"
                disabled={!isPresentationEdited}
                onClick={clearUnsavedPresentationDetails}
            >
                Discard changes
            </MenuItem>
            <MenuItem
                key="favorite"
                disabled={presentationProps.selectedPresentation.isFavorite}
                prepend={<StarIcon />}
                onClick={presentationActionsProps.setFavoritePresentation}
            >
                Mark as favorite
            </MenuItem>
            <Divider />
            <MenuItem
                key="delete"
                disabled={presentationProps.isDefaultPresentation}
                prepend={<TrashIcon />}
                onClick={presentationActionsProps.deletePresentation}
            >
                Delete
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <EditableTitle
                menu={menu}
                value={presentationProps.selectedPresentation.name}
                onSubmitValue={presentationActionsProps.renamePresentation}
                ref={editableTitleRef}
                disableEditing={presentationProps.selectedPresentation.isFavorite}
            />

            {isPresentationEdited ? unsavedChangesBadge : null}
        </React.Fragment>
    );
}
