import * as DocumentInfoUtils from '../../utils/document';

/** Document status enum */
export enum DocStatus {
    // tslint:disable-next-line: completed-docs
    READONLY = 'readOnly',
    // tslint:disable-next-line: completed-docs
    OWN_WORKING_COPY = 'ownWorkingCopy',
    // tslint:disable-next-line: completed-docs
    WORKING_COPY = 'workingCopy'
}

/** Returns the status of the document */
export function getDocumentStatus(doc: DocInfo) {
    if (doc.readOnly) {
        return DocStatus.READONLY;
    }

    if (doc.editedByCurrentUser) {
        return DocStatus.OWN_WORKING_COPY;
    }

    if (DocumentInfoUtils.isDocumentBeingEdited(doc)) {
        return DocStatus.WORKING_COPY;
    }
}
