import {
    Divider,
    Link,
    ListItem,
    Popover,
    PopoverProps,
    PageContent,
    Typography,
    debounce,
    DarkContext
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {CommitAction} from '../../../../providers/version';
import {getFormattedDate} from '../../../../utils/common';

import styles from './global-version-item.module.css';

interface Props {
    versions: GlobalVersionData[];
    lastAction?: CommitAction;
    isLastElement?: boolean;
    onClickVersionLink: (UID: number) => void;
}

const modifiers: PopoverProps['modifiers'] = [
    {
        name: 'preventOverflow',
        options: {
            altAxis: true
        }
    }
];

const CLOSE_TIMEOUT = 300;

const VersionContent = ({
    version,
    onClick
}: {
    version: GlobalVersionData;
    onClick: (versionId: number) => void;
}) => {
    const handleClickLink = () => {
        onClick(version.id);
    };

    return (
        <>
            <Typography className={styles.title}>
                Knowledge Base version: {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={handleClickLink}>{version.label}</Link>
            </Typography>
            <Typography as="p">
                {getFormattedDate({date: version.createdAt, showTime: true})} by{' '}
                {version.author.name || version.author.email}
            </Typography>
            <Divider gap={1} variant="transparent" />
            <Typography intent="quiet" variant="caption" className={styles.description}>
                {version.description}
            </Typography>
        </>
    );
};

export const GlobalVersionItem = ({
    versions,
    lastAction,
    isLastElement,
    onClickVersionLink
}: Props) => {
    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        setReferenceElement(target);
    };

    const handleHidePopover = () => {
        setReferenceElement(null);
    };

    /**
     * Popover hiding is delayed to make possible for a user to move the mouse to the popover
     * and interact with its content (copy, click on the links, etc.)
     */
    const hidePopoverDebounced = debounce(handleHidePopover, CLOSE_TIMEOUT);

    const handleMouseLeave = () => {
        hidePopoverDebounced();
    };

    const handlePopoverMouseEnter = () => {
        hidePopoverDebounced.cancel();
    };

    const handlePopoverMouseLeave = () => {
        hidePopoverDebounced();
    };

    const handleClickLink = (versionId: number) => {
        handleHidePopover();
        onClickVersionLink(versionId);
    };

    const isOneVersion = versions.length === 1;
    const otherVersionsCount = versions.length - 2;
    const firstVersion = versions[0];
    const lastVersion = versions[versions.length - 1];

    return (
        <>
            <ListItem
                as="div"
                className={classNames(styles.item, {
                    [styles.inLine]: lastAction !== undefined && !isLastElement,
                    [styles.afterDeleted]:
                        (lastAction === 'RESTORE' || lastAction === 'DELETE') && !isLastElement
                })}
            >
                <div
                    style={{display: 'inline-flex'}}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Typography as="div" className={styles.label} intent="quiet">
                        <Typography
                            as="div"
                            ellipsis
                            className={classNames(styles.shortText, {
                                [styles.longText]: isOneVersion
                            })}
                        >
                            {firstVersion.label}
                        </Typography>
                        {!isOneVersion && (
                            <Typography as="div" ellipsis className={styles.shortText}>
                                {' – '}
                                {lastVersion.label}
                            </Typography>
                        )}
                    </Typography>
                </div>
            </ListItem>

            <DarkContext.Provider value={false}>
                <Popover
                    open={Boolean(referenceElement)}
                    referenceElement={referenceElement}
                    placement="left"
                    positionFixed
                    disableTransition
                    modifiers={modifiers}
                    portalContainer={document.body}
                    containerProps={{className: styles.withArrow}}
                    classes={{popper: styles.popoverContainer}}
                    withArrow
                >
                    <PageContent
                        onMouseEnter={handlePopoverMouseEnter}
                        onMouseLeave={handlePopoverMouseLeave}
                    >
                        <VersionContent version={firstVersion} onClick={handleClickLink} />
                        {!isOneVersion && (
                            <>
                                <Divider gap={2} variant="transparent" />
                                {otherVersionsCount > 0 && (
                                    <>
                                        <Typography intent="quiet">
                                            ... {otherVersionsCount} other
                                            {otherVersionsCount === 1 ? ' version' : ' versions'}
                                        </Typography>
                                        <Divider gap={2} variant="transparent" />
                                    </>
                                )}
                                <VersionContent version={lastVersion} onClick={handleClickLink} />
                            </>
                        )}
                    </PageContent>
                </Popover>
            </DarkContext.Provider>
        </>
    );
};
