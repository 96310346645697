import * as React from 'react';
import {MarginBox, Typography} from '@genestack/ui';

export function BrowserNotSupportedView() {
    return (
        <MarginBox>
            <Typography intent="warning">
                Your browser does not support this feature. Please use the latest version of Chrome
                or Safari
            </Typography>
        </MarginBox>
    );
}
