import {Controls, ControlsItem, TimeReverseIcon, ListItem, Typography} from '@genestack/ui';
import React from 'react';

import {SearchHistoryRecordDto} from '../../../hooks/requests/use-explore-view-search-history-query';

import styles from './search-history-record.module.css';

interface Payload {
    prefix: string;
    value: string;
    data: SearchHistoryRecordDto;
}

export interface SearchHistoryRecordProps {
    prefix: string;
    value: string;
    data: SearchHistoryRecordDto;
    onClick: (payload: Payload, e: React.MouseEvent<HTMLDivElement>) => void;
}

export function SearchHistoryRecord({
    prefix,
    value,
    data,
    onClick,
    ...restProps
}: SearchHistoryRecordProps) {
    const handleListItemClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        onClick({prefix, value, data}, e);
    };

    return (
        <ListItem
            as="div"
            className={styles.root}
            interactive
            {...restProps}
            prepend={<TimeReverseIcon />}
            onClick={handleListItemClick}
        >
            <Controls justify="space-between">
                <ControlsItem>
                    <i>{prefix}: </i>
                    <Typography as="span" variant="body" intent="success">
                        {value}
                    </Typography>
                </ControlsItem>
            </Controls>
        </ListItem>
    );
}
