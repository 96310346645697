import React from 'react';

type ClientOs = 'Windows' | 'MacOS' | 'Unix' | 'Linux' | 'Other';

export function getClientOs(): ClientOs {
    if (navigator.appVersion.indexOf('Win') !== -1) {
        return 'Windows';
    }

    if (navigator.appVersion.indexOf('Mac') !== -1) {
        return 'MacOS';
    }

    if (navigator.appVersion.indexOf('X11') !== -1) {
        return 'Unix';
    }

    if (navigator.appVersion.indexOf('Linux') !== -1) {
        return 'Linux';
    }

    return 'Other';
}

export function getLineSeparator() {
    const clientOs = getClientOs();

    if (clientOs === 'MacOS' || clientOs === 'Windows') {
        return '\r\n';
    }

    return '\n';
}

export function isSelectMultipleMouseEvent(event: MouseEvent | React.MouseEvent) {
    if (getClientOs() === 'MacOS') {
        return event.metaKey;
    }

    return event.ctrlKey;
}
