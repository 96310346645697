import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {useDeleteQueryInCache, useRestoreQueryInCache} from '../cache-control-hooks';
import {showErrorNotification} from '../../../../components/notifications-center/notifications-store';

function deleteQueryRequest(queryId: number) {
    return apiClient.patch<unknown>({
        path: `queries-service/api/queries/${queryId}`,
        parameters: {trash: true}
    });
}

export function useDeleteQueryMutation() {
    const deleteQueryInCache = useDeleteQueryInCache();
    const restoreQueryInCache = useRestoreQueryInCache();

    return useMutation<unknown, Error, QueryInfo, QueryInfo>(
        (query: QueryInfo) => {
            deleteQueryInCache(query);
            return deleteQueryRequest(query.id);
        },
        {
            onError: (err, payload) => {
                restoreQueryInCache(payload);
                showErrorNotification()(err);
            }
        }
    );
}
