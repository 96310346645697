import React from 'react';

import {OwnWorkingCopyTooltipIcon, WorkingCopyTooltipIcon} from './tooltip-icons';
import {getDocumentStatus, DocStatus} from './utils';

interface Props {
    document: DocInfo;
}

export function EditableDocumentStatus({document}: Props) {
    const status = getDocumentStatus(document);

    switch (status) {
        case DocStatus.OWN_WORKING_COPY:
            return <OwnWorkingCopyTooltipIcon />;
        case DocStatus.WORKING_COPY:
            return <WorkingCopyTooltipIcon />;
        default:
            return null;
    }
}
