import {Divider, List, ListItem, Preloader, DarkContext} from '@genestack/ui';
import React from 'react';

import {Sidebar} from '../../../../components/sidebar';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {useLocationParams} from '../../../../hooks/use-location';
import {CommitAction, CommitData, DocRevisionsContextValue} from '../../../../providers/version';

import {GlobalVersionItem} from './global-version-item';
import {RevisionItem} from './revision-item';
import {RevisionsSidebarHeader} from './revisions-sidebar-header';
import styles from './version-sidebar.module.css';
import {numericSortComparator} from '../../../../utils/array';
import {useGlobalVersionDialogsGlobalValue} from '../../../../providers/version/use-global-version-dialogs-global-value';

interface GlobalUIVersionData {
    type: 'global-ui-version-data';
    versions: GlobalVersionData[];
    lastAction?: CommitAction;
}

interface Props {
    docRevisionsProps: DocRevisionsContextValue;
    globalVersions: GlobalVersionData[] | null;
    closePanelLink: string;
    getSelectedRevisionLink: (revision: string) => string;
}

export const DocRevisionsPanel = (props: Props) => {
    const {docRevisionsProps, closePanelLink, globalVersions} = props;
    const {documentId, revisionId, documentsViewMode} = useLocationParams();
    const {openShowVersionsDialog} = useGlobalVersionDialogsGlobalValue();

    /**
     * If global versions are in a row, they should be shown in one item.
     * Commit data of the local history is always shown in its own item.
     */
    const uiRevisions = React.useMemo(() => {
        const versions: Array<CommitData | GlobalUIVersionData> = [];
        let lastAction: CommitAction | undefined;
        let globalVersionSequence: GlobalVersionData[] = [];
        const allVersions = [
            ...(docRevisionsProps.revisions || []),
            ...(globalVersions || [])
        ].sort(numericSortComparator('createdAt'));

        allVersions.forEach((item, index) => {
            if (item.type === 'commit-data') {
                if (globalVersionSequence.length) {
                    versions.push({
                        type: 'global-ui-version-data',
                        versions: [...globalVersionSequence],
                        lastAction
                    });
                }
                globalVersionSequence = [];
                lastAction = item.action;
                versions.push(item);
            } else {
                globalVersionSequence.push(item);
            }

            if (index === allVersions.length - 1 && globalVersionSequence.length !== 0) {
                versions.push({
                    type: 'global-ui-version-data',
                    versions: [...globalVersionSequence],
                    lastAction
                });
            }
        });

        return versions;
    }, [docRevisionsProps.revisions, globalVersions]);

    if (!documentId) {
        return null;
    }

    return (
        <DarkContext.Provider value>
            <div className={styles.root}>
                <Sidebar header={<RevisionsSidebarHeader closePanelLink={closePanelLink} />}>
                    <Preloader
                        show={!docRevisionsProps.revisions}
                        count={3} // tslint:disable-line: no-magic-numbers
                        wrapAll={List}
                        wrapEach={ListItem}
                        box="block"
                        variant="caption"
                    >
                        {uiRevisions.map((item, i) => {
                            if (item.type === 'commit-data') {
                                return (
                                    <RevisionItem
                                        key={item.revision}
                                        commit={item}
                                        isActive={revisionId === item.revision}
                                        mode={documentsViewMode}
                                        getSelectedRevisionLink={props.getSelectedRevisionLink}
                                    />
                                );
                            }

                            return (
                                <GlobalVersionItem
                                    key={item.versions[0].createdAt}
                                    versions={item.versions}
                                    lastAction={item.lastAction}
                                    isLastElement={i === uiRevisions.length - 1}
                                    onClickVersionLink={openShowVersionsDialog}
                                />
                            );
                        })}

                        <Divider
                            variant="transparent"
                            gap={4} // tslint:disable-line: no-magic-numbers
                        />
                    </Preloader>
                </Sidebar>
            </div>
        </DarkContext.Provider>
    );
};
