import React from 'react';

import styles from './bottom-bar.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean;
}

/** Status from the bottom bar in edit mode */
export function Status(props: Props) {
    const {isLoading, children, ...restProps} = props;

    if (isLoading) {
        return <div className={styles.statusLabelPreloader} {...restProps} />;
    }

    return (
        <div className={styles.statusLabel} {...restProps}>
            {children}
        </div>
    );
}
