import React from 'react';
import {useConfirmContext} from './confirm-context';
import {ConfirmSettings} from './confirm-component';

export function useConfirm(props: ConfirmSettings) {
    const {showConfirm} = useConfirmContext();

    return React.useCallback(() => {
        showConfirm(props);
    }, [props, showConfirm]);
}
