import {Divider, Typography, TimeReverseIcon} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {Autocompletion, SuggestionItem} from '../../../../components/autocompletion';
import {useSearchSidebarContext} from './hooks/use-search-sidebar-context';

import {CustomSuggestionItem} from './custom-suggestion-item';
import styles from './sidebar-seach-field.module.css';
import {DOC_SHORTCUT_TYPE, CATEGORY_SHORTCUT_TYPE, useSuggestions} from './hooks/use-suggestions';
import {AutocompletionProps} from '../../../../components/autocompletion/suggestion-item';
import {InputValueType, SidebarSuggestion} from './interface';

interface Props {
    disabled: boolean;
    busy: boolean;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
    suggestionsProps: ReturnType<typeof useSuggestions>;
}

function getAutocompletionPlaceholder(type: string, isValidNodeType: boolean) {
    if (!type) {
        return 'Search';
    }

    if (type === DOC_SHORTCUT_TYPE) {
        return 'Search by document name';
    }

    if (type === CATEGORY_SHORTCUT_TYPE) {
        return 'Search by category';
    }

    if (isValidNodeType) {
        return `Search by ${type}`;
    }

    return 'This search operator is not supported';
}

function getOpenStatusBySearchType(inputValueType: InputValueType, isOpen: boolean) {
    return isOpen && inputValueType !== 'searchByDocName';
}

function getEmptyListText(searchType: InputValueType, isValidNodeType: boolean) {
    if (searchType === 'emptyInput') {
        return '';
    }

    if (isValidNodeType) {
        return 'No results available';
    }

    return 'This search operator is not supported';
}

export function SidebarSearchField(props: Props) {
    const {disabled, onBlur, searchSidebarContext, suggestionsProps, busy} = props;

    const [isOpen, setOpenStatus] = React.useState<boolean>(false);

    const handleOpenSuggestions = React.useCallback(() => {
        setOpenStatus(true);
    }, []);

    const handleCloseSuggestions = React.useCallback(() => {
        setOpenStatus(false);
    }, []);

    const handleInputValueChange = (value: string) => {
        if (searchSidebarContext.parsedInputValue.type !== 'searchByDocName') {
            setOpenStatus(Boolean(value));
        }

        searchSidebarContext.onInputValueChange(value);
    };

    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (
                searchSidebarContext.parsedInputValue.prefix &&
                !searchSidebarContext.parsedInputValue.value &&
                e.key === 'Backspace'
            ) {
                e.preventDefault();
                searchSidebarContext.handleInputSeparatorDeleted();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            searchSidebarContext.parsedInputValue.value,
            searchSidebarContext.parsedInputValue.prefix,
            searchSidebarContext.handleInputSeparatorDeleted
        ]
    );

    const handleAutocompletionFocus = React.useCallback(() => {
        // invalidating cache on every focus so that user
        // doesn't miss node types that might have been added recently
        suggestionsProps.invalidateNodeTypesQuery();

        if (searchSidebarContext.parsedInputValue.type === 'emptyInput') {
            handleOpenSuggestions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchSidebarContext.parsedInputValue.type]);

    const hasDocSuggestions = suggestionsProps.docSuggestions.length > 0;
    const hasNodeTypesSuggestions = suggestionsProps.nodeTypeSuggestions.length > 0;

    return (
        <Autocompletion<SidebarSuggestion>
            autoFocus
            classes={{
                input: classNames(styles.root, {
                    [styles.green]: Boolean(searchSidebarContext.parsedInputValue.prefix)
                })
            }}
            disabled={disabled}
            busy={busy}
            placeholder={getAutocompletionPlaceholder(
                searchSidebarContext.parsedInputValue.prefix,
                suggestionsProps.isValidNodeType
            )}
            isOpen={getOpenStatusBySearchType(searchSidebarContext.parsedInputValue.type, isOpen)}
            onClose={handleCloseSuggestions}
            value={searchSidebarContext.parsedInputValue.value}
            onValueChange={handleInputValueChange}
            onFocus={handleAutocompletionFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onClearButtonClick={searchSidebarContext.onClearClick}
            suggestions={
                [
                    ...suggestionsProps.historySuggestions,
                    ...suggestionsProps.docSuggestions,
                    ...suggestionsProps.nodeTypeSuggestions,
                    ...suggestionsProps.commonSuggestions
                ] as SidebarSuggestion[]
            }
            prepend={
                searchSidebarContext.parsedInputValue.prefix && (
                    <span className={styles.prepend}>
                        {searchSidebarContext.parsedInputValue.prefix}:{' '}
                    </span>
                )
            }
            suggestionHandler={searchSidebarContext.handleSuggestionSelected}
            emptyListText={getEmptyListText(
                searchSidebarContext.parsedInputValue.type,
                suggestionsProps.isValidNodeType
            )}
            clearable={searchSidebarContext.parsedInputValue.type !== 'emptyInput'}
            // FIXME fix behaviour in UI-library
            shortcutHandlers={{
                '\u00A0': handleOpenSuggestions
            }}
        >
            {(autocompleteProps: AutocompletionProps) => (
                <React.Fragment>
                    {!suggestionsProps.commonSuggestions.length &&
                        !suggestionsProps.nodeTypeSuggestions.length &&
                        !suggestionsProps.docSuggestions.length && (
                            <Typography
                                className={classNames(styles.caption, styles.rightCaption)}
                                intent="quiet"
                                variant="caption"
                            >
                                Type "
                                <Typography as="span" variant="caption">
                                    :
                                </Typography>
                                " for search operators
                            </Typography>
                        )}

                    {suggestionsProps.historySuggestions.length > 0 &&
                        suggestionsProps.historySuggestions.map((historySuggestion, i) => (
                            <SuggestionItem
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={i}
                                value={historySuggestion}
                                prepend={<TimeReverseIcon />}
                                autocompletionProps={autocompleteProps}
                            >
                                <CustomSuggestionItem suggestion={historySuggestion} />
                            </SuggestionItem>
                        ))}

                    {suggestionsProps.docSuggestions.map((suggestion, i) => (
                        <SuggestionItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            value={suggestion}
                            autocompletionProps={autocompleteProps}
                        >
                            <CustomSuggestionItem suggestion={suggestion} />
                        </SuggestionItem>
                    ))}

                    {hasNodeTypesSuggestions && (
                        <>
                            <Typography className={styles.caption} intent="quiet" variant="caption">
                                Operators to narrow search results by type:
                            </Typography>

                            {suggestionsProps.nodeTypeSuggestions.map((suggestion, i) => (
                                <SuggestionItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    value={suggestion}
                                    autocompletionProps={autocompleteProps}
                                >
                                    <CustomSuggestionItem suggestion={suggestion} />
                                </SuggestionItem>
                            ))}
                        </>
                    )}

                    {suggestionsProps.commonSuggestions.length > 0 && (
                        <>
                            {(hasDocSuggestions || hasNodeTypesSuggestions) && <Divider />}

                            {suggestionsProps.commonSuggestions.map((suggestion, i) => (
                                <SuggestionItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    value={suggestion}
                                    autocompletionProps={autocompleteProps}
                                >
                                    <CustomSuggestionItem suggestion={suggestion} />
                                </SuggestionItem>
                            ))}
                        </>
                    )}
                </React.Fragment>
            )}
        </Autocompletion>
    );
}
