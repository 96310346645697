import React from 'react';

import {StubMessage} from '../../../../../components/stub-message';
import {UniversalBackdrop} from '../../../../../components/universal-backdrop';

interface Props {
    accession?: string;
    isDocumentLoading?: boolean;
    isVersionNotFound?: boolean;
    isDocumentNotFound?: boolean;
    hasError?: boolean;
}

function getBackdropMessage(
    isDocumentNotFound?: boolean,
    isVersionNotFound?: boolean,
    accession?: string
) {
    if (isDocumentNotFound) {
        return (
            <StubMessage caption="Document not found">
                Requested document ({accession}) is not available or does not exist.
            </StubMessage>
        );
    }

    if (isVersionNotFound) {
        return (
            <StubMessage caption="Revision not found">
                Requested revision of the document is not available or does not exist.
            </StubMessage>
        );
    }

    return null;
}

/** Editor backdrop */
export function EditorBackdrop(props: Props) {
    const {accession, isDocumentLoading, isVersionNotFound, isDocumentNotFound, hasError} = props;

    return (
        <UniversalBackdrop
            open={hasError}
            isLoading={isDocumentLoading}
            variant={isDocumentNotFound || isVersionNotFound ? 'zerostate' : 'accent'}
            message={getBackdropMessage(isDocumentNotFound, isVersionNotFound, accession)}
        />
    );
}
