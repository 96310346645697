import ELK from 'elkjs';
import {QueryGraphLayoutAlgorithm} from './interface';
import {getStaticFileUrl} from '../../../utils/get-static-file-url';
import {nodeHeight, nodeWidth} from './constants';
import {GraphLayout, GraphNode, GraphEdge} from '../interface';
import {ApplyLayoutWorkerInitData, ApplyLayoutWorkerResponseData} from '../../../workers/types';

interface Props {
    layoutAlgorithm: QueryGraphLayoutAlgorithm;
    nodes: GraphNode[];
    edges: GraphEdge[];
    setNodePositions: (pos: string) => void;
}

interface Result {
    terminateCalculation: () => void;
}

export function calculateLayout({layoutAlgorithm, nodes, edges, setNodePositions}: Props): Result {
    if (
        layoutAlgorithm === QueryGraphLayoutAlgorithm.LAYERED_HORIZONTAL ||
        layoutAlgorithm === QueryGraphLayoutAlgorithm.LAYERED_VERTICAL ||
        layoutAlgorithm === QueryGraphLayoutAlgorithm.MRTREE
    ) {
        const elk = new ELK({workerUrl: getStaticFileUrl('js/elk-worker.js')});

        elk.layout({
            id: 'root',
            layoutOptions: {
                'elk.algorithm':
                    layoutAlgorithm === QueryGraphLayoutAlgorithm.MRTREE ? 'mrtree' : 'layered',
                'elk.direction':
                    layoutAlgorithm === QueryGraphLayoutAlgorithm.LAYERED_HORIZONTAL
                        ? 'RIGHT'
                        : 'DOWN',
                'elk.spacing.nodeNode': '100'
            },
            children: nodes.map((node) => ({
                id: `${node.id}`,
                width: nodeWidth,
                height: nodeHeight
            })),
            edges: edges.map((edge) => ({
                id: `${edge.id}`,
                sources: [`${edge.startViewNodeId}`],
                targets: [`${edge.endViewNodeId}`]
            }))
        }).then((result) => {
            const layout: GraphLayout = {};
            result.children!.forEach((node) => {
                layout[node.id] = {x: node.x!, y: node.y!};
            });
            setNodePositions(JSON.stringify(layout));
        });

        // @ts-ignore
        return {terminateCalculation: () => elk.worker.worker.terminate()};
    }

    const worker = new Worker(getStaticFileUrl('js/apply-layout-worker.js'));
    worker.postMessage({nodes, edges, layoutAlgorithm} as ApplyLayoutWorkerInitData);

    worker.onmessage = (event: MessageEvent<ApplyLayoutWorkerResponseData>) => {
        setNodePositions(JSON.stringify(event.data.layout));
        worker.terminate();
    };

    return {terminateCalculation: () => worker.terminate()};
}
