import {NotificationProps, Notification, Typography} from '@genestack/ui';
import React from 'react';

const DEFAULT_COUNTDOWN_DURATION = 3000;

/** Component for displaying a notification about a copied link */
export function LinkCopiedNotification({
    children = 'Link copied to clipboard',
    ...restProps
}: NotificationProps) {
    return (
        <Notification countdownDuration={DEFAULT_COUNTDOWN_DURATION} {...restProps}>
            <Typography>{children}</Typography>
        </Notification>
    );
}
