import {InputProps} from '@genestack/ui';
import React from 'react';

import {useObserveElementWidth} from '../../hooks/use-resize-observer';

interface Props<C extends HTMLElement, F extends HTMLElement> {
    containerNode: C | null;
    fieldNode: F | null;
    focusWidth?: number;
    disabled?: boolean;
}

interface UseResizeFocusedField {
    isValueChanged: boolean;
    fieldHandlers: Pick<InputProps, 'onBlur' | 'onValueChange'>;
    styles: React.CSSProperties;
}

// eslint-disable-next-line default-param-last
function getFieldWidth(containerWidth = 0, focusWidth = 600, focused?: boolean) {
    if (containerWidth > focusWidth) {
        return containerWidth;
    }

    if (focused) {
        return focusWidth;
    }

    if (!containerWidth) {
        return '100%';
    }

    return containerWidth;
}

export function useResizeChangedField<C extends HTMLElement, F extends HTMLElement>(
    props: Props<C, F>
): UseResizeFocusedField {
    const [isValueChanged, setIsValueChanged] = React.useState(false);
    const fieldWidth = useObserveElementWidth(props.containerNode);

    const handleFieldChanged = () => {
        setIsValueChanged(true);
    };

    const handleFieldBlur: React.FocusEventHandler = (e) => {
        if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget as HTMLElement)) {
            setIsValueChanged(false);
        }
    };

    // https://github.com/facebook/react/issues/9142
    React.useEffect(() => {
        if (props.disabled) {
            setIsValueChanged(false);
        }
    }, [props.disabled]);

    return {
        isValueChanged,
        fieldHandlers: {
            onBlur: handleFieldBlur,
            onValueChange: handleFieldChanged
        },
        styles: {
            width: getFieldWidth(fieldWidth, props.focusWidth, isValueChanged)
        }
    };
}
