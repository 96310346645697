import {useQuery} from 'react-query';

import {GraphResponse, GraphViewType} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';

function getQueryResultsSubGraphRequest(
    queryId: number,
    maxPathLength: number,
    viewType: GraphViewType
) {
    return apiClient.get<GraphResponse>({
        path: `queries-service/api/queries/${queryId}/connection-graph/${viewType}`,
        query: {
            'path-length-limit': maxPathLength
        }
    });
}

export function useRequestQueryResultsSubgraph(
    viewType: GraphViewType,
    queryId: number,
    maxPathLength: number
) {
    return useQuery({
        queryKey: ['query-results-graph', queryId, maxPathLength, viewType],
        queryFn: () => getQueryResultsSubGraphRequest(queryId, maxPathLength, viewType)
    });
}
