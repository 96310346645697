import React from 'react';

export const CriticalErrorView = () => (
    <svg width="80" height="80" fill="none">
        <path fill="#F47171" d="M56 0H24L0 24v32l24 24h32l24-24V24L56 0z" />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M28.121 31l2.94-2.94-2.121-2.12L26 28.878l-2.94-2.94-2.12 2.122L23.878 31l-2.94 2.938 2.122 2.122L26 33.12l2.938 2.94 2.122-2.121L28.12 31zm27.88 2.121l-2.94 2.94-2.121-2.122L53.879 31l-2.94-2.94 2.122-2.12L56 28.878l2.939-2.94 2.12 2.122L58.122 31l2.94 2.939-2.121 2.12L56 33.122zM58 58c-12-3-23-3-36 0l1 3c13.134-2.857 20.59-2.756 34 0l1-3z"
            clipRule="evenodd"
        />
    </svg>
);
