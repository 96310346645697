import React from 'react';

import {Core} from 'cytoscape';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';
import {useRequestQueryResultsSubgraph} from './requests/use-request-query-results-subgraph';

import {GraphNode, GraphType, GraphViewType} from '../../../../../components/graph/interface';
import {QueryGraph} from '../../../../../components/graph/biokb-graph/query-graph';
import {useLayoutAndCameraProps} from './use-layout-and-camera-props';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {usePresentationEditedProps} from './use-presentation-edited-props';
import {useGraphSettings} from './use-graph-settings';
import {useUnsavedPresentationDetailsActions} from './use-unsaved-presentation-details-actions';

interface Props {
    selectedQuery: CompletedQuery;
    maxPathLengthOptions: MaxPathLengthOptionResult;
    viewTypesOptions: GraphViewType[];
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    onNodesLoaded: (nodes: GraphNode[]) => void;
}

export function useQueryGraphProps({
    selectedQuery,
    maxPathLengthOptions,
    viewTypesOptions,
    selectedPresentation,
    selectedPresentationDetails,
    onNodesLoaded
}: Props) {
    const csRef = React.useRef<Core>(null);

    const presentationEditedProps = usePresentationEditedProps({
        queryId: selectedQuery.id,
        presentation: selectedPresentation
    });

    const graphSettings = useGraphSettings({
        queryId: selectedQuery.id,
        maxPathLengthOptions,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited: presentationEditedProps.handlePresentationEdited
    });
    const {selectedViewType, maxPathLength, graphType} = graphSettings;

    const queryGraphRequest = useRequestQueryResultsSubgraph(
        selectedViewType,
        selectedQuery.id,
        maxPathLength
    );

    // tanstack query doesn't seem to have an option to fire a callback on every query data change,
    // only on data loaded from server, so I have to use this hack here, otherwise
    // this code wouldn't run on data loaded from cache
    const onNodesLoadedRef = React.useRef(onNodesLoaded);
    onNodesLoadedRef.current = onNodesLoaded;
    React.useEffect(() => {
        if (queryGraphRequest.data) {
            onNodesLoadedRef.current(queryGraphRequest.data.nodes);
        }
    }, [queryGraphRequest.data]);

    const layoutAndCameraProps = useLayoutAndCameraProps({
        queryId: selectedQuery.id,
        maxPathLength: Number(maxPathLength),
        selectedViewType,
        nodes: queryGraphRequest.data?.nodes,
        edges: queryGraphRequest.data?.edges,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited: presentationEditedProps.handlePresentationEdited,
        isPresentationEdited: presentationEditedProps.isPresentationEdited
    });

    const graph = React.useMemo(() => {
        return queryGraphRequest.data && graphType === GraphType.biokb
            ? new QueryGraph(queryGraphRequest.data)
            : undefined;
    }, [queryGraphRequest.data, graphType]);

    const {clearUnsavedDetails, setUnsavedDetails} = useUnsavedPresentationDetailsActions({
        presentationEditedProps,
        graphSettings,
        layoutAndCameraProps
    });

    return {
        queryGraphRequest,
        graph,
        layoutAndCameraProps,
        graphSettings,
        canSelectViewTypes: viewTypesOptions.includes(GraphViewType.MERGED),
        csRef,
        presentationEditedProps,
        clearUnsavedDetails,
        setUnsavedDetails
    };
}
