import {useQuery} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

interface Props {
    accession: string;
    globalVersionId: number;
    enabled: boolean;
}

function getDocumentStatusInThisGVRequest(accession: string, globalVersionId: number) {
    return apiClient.get<DocViewableStatus>({
        path: `documents-revisions-service/api/viewer/documents/${accession}/viewable-status`,
        query: {'global-version': globalVersionId}
    });
}

export function useDocumentStatusInThisGVQuery(props: Props) {
    return useQuery(
        [getDocumentStatusInThisGVRequest.name, props.accession, props.globalVersionId],
        () => {
            return getDocumentStatusInThisGVRequest(props.accession, props.globalVersionId);
        },
        {
            enabled: props.enabled
        }
    );
}
