import React from 'react';
import classNames from 'classnames';
import styles from './query-layout.module.css';
import {EditorHeaderSkeleton} from '../../../components/editor-header';
import {QueryEditorSkeleton} from './query-editor';

export function QueryLayoutLoading() {
    return (
        <div className={classNames(styles.queryLayout, styles.viewMode)}>
            <EditorHeaderSkeleton />
            <QueryEditorSkeleton />
        </div>
    );
}
