import * as React from 'react';

import {useMounted} from './use-mounted';
import {useTracker} from './use-tracker';

/** Means that we do not make any requests. */
export interface InitialState {
    settled: false;
    initial: true;
    pending: false;
    rejected: false;
    fulfilled: false;
    args: null;
}

/** Means that request is in progress at the moment. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PendingState<A extends any[] = any[]> {
    settled: false;
    initial: false;
    pending: true;
    rejected: false;
    fulfilled: false;
    args: A;
}

/** Means that request has been failed. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RejectedState<A extends any[] = any[]> {
    settled: true;
    initial: false;
    pending: false;
    rejected: true;
    fulfilled: false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reason: any;
    args: A;
}

/** Means that request has been successfully completed. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FulfilledState<R, A extends any[] = any[]> {
    settled: true;
    initial: false;
    pending: false;
    rejected: false;
    fulfilled: true;
    value: R;
    args: A;
}

/** Async state */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncState<R, A extends any[] = any[]> =
    | InitialState
    | PendingState<A>
    | RejectedState<A>
    | FulfilledState<R, A>;

/** Function that returns promise */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncFactory<R, A extends any[] = any[]> = (...args: A) => Promise<R>;

/** Async actions */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AsyncActions<R, A extends any[] = any[]> {
    /** Run promise and subscribe state. */
    run: AsyncFactory<R, A>;
    /** Reset state to `initialState` */
    reset: () => void;
}

/** Merged `useAsync` result type. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Async<R, A extends any[] = any[]> = AsyncState<R, A> & AsyncActions<R, A>;

/**
 * Hook to use async functions.
 * The basic usage of hook is to make some request and show its state.
 *
 * @example
 *  function MyComponent() {
 *      const request = useAsync(() => fetchSomeData());
 *
 *      // run request once on mount
 *      React.useEffect(() => {
 *          request.run();
 *      }, []);
 *
 *      if (!request.settled) {
 *          return 'Data is loading...';
 *      }
 *
 *      if (requestState.rejected) {
 *          return 'Error has been ocurred.'
 *      }
 *
 *      return (
 *          <div>
 *              {requestState.value}
 *              <button onClick={() => requestActions.reset()}>Clear loaded data</button>
 *              <button onClick={() => requestActions.run()}>Refresh data</button>
 *          </div>
 *      )
 *  }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAsync<R, A extends any[] = any[]>(
    factory: AsyncFactory<R, A>,
    initialState: AsyncState<R, A> = {
        settled: false,
        initial: true,
        pending: false,
        rejected: false,
        fulfilled: false,
        args: null
    }
): Async<R, A> {
    const factoryRef = React.useRef(factory);
    factoryRef.current = factory;

    const [state, setState] = React.useState(initialState);
    const isMounted = useMounted();
    const tracker = useTracker();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const run: AsyncFactory<R, A> = React.useCallback(async (...args: any) => {
        const id = tracker.generateId();

        setState({
            settled: false,
            initial: false,
            pending: true,
            rejected: false,
            fulfilled: false,
            args
        });

        try {
            const value = await factoryRef.current(...args);

            if (tracker.isMostRecentId(id) && isMounted()) {
                setState({
                    settled: true,
                    initial: false,
                    pending: false,
                    rejected: false,
                    fulfilled: true,
                    value,
                    args
                });
            }

            return value;
        } catch (reason) {
            if (tracker.isMostRecentId(id) && isMounted()) {
                setState({
                    settled: true,
                    initial: false,
                    pending: false,
                    rejected: true,
                    fulfilled: false,
                    reason,
                    args
                });
            }

            throw reason;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reset = React.useCallback(() => {
        tracker.generateId();
        setState(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return React.useMemo(
        () => ({
            ...state,
            run,
            reset
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );
}
