import React from 'react';
import {useHistory, useLocation, matchPath} from 'react-router-dom';

/** View mode of the app documents */
export type DocumentViewMode = 'explore' | 'edit';

/** Parameters parsed from URL */
export interface LocationParams {
    documentsViewMode?: DocumentViewMode;
    type?: DocType;
    documentId?: string;
    mode?: 'revisions';
    revisionId?: string;
    nodeId?: string;
    queryId?: string;
    connectionId?: string;
    userId?: string;
}

/** A hook to get location params from URL */
export const useLocationParams = () => {
    const {pathname} = useLocation();

    const match = React.useMemo(
        () =>
            matchPath<LocationParams>(pathname, {
                exact: true,
                path: [
                    '/users/:userId?',
                    '/queries/:queryId?/:connectionId?',
                    '/documents/:documentsViewMode(explore|edit)',
                    '/documents/edit/new/:type',
                    '/documents/:documentsViewMode(explore|edit)/:documentId',
                    '/documents/:documentsViewMode(explore|edit)/:documentId/@:nodeId',
                    '/documents/:documentsViewMode(explore|edit)/:documentId/:mode(revisions)/:revisionId?',
                    '/documents/:documentsViewMode(explore|edit)/:documentId/:mode(revisions)/:revisionId/@:nodeId'
                ]
            }),
        [pathname]
    );

    return match?.params ?? {};
};

export const useQueryId = (): number | null => {
    const {queryId} = useLocationParams();

    return queryId ? Number(queryId) : null;
};

interface NavigationActions {
    push: (pathname: string) => void;
    replace: (pathname: string) => void;
}

export interface WithNavigationProps {
    navigationActions: NavigationActions;
    locationParams: LocationParams;
}

/** A HOC which provides props containing nivigation actions and location params */
export const withNavigation = <P extends WithNavigationProps>(
    Component: React.ComponentType<P>
): React.FunctionComponent<Omit<P, keyof WithNavigationProps>> =>
    function WithLocation(props) {
        const navigationActions = useHistory();
        const locationParams = useLocationParams();

        return (
            <Component
                // FIXME: remove "as P"
                // this is a known TS bug
                // see: https://git.io/fj7iZ
                {...(props as P)}
                navigationActions={navigationActions}
                locationParams={locationParams}
            />
        );
    };
