import {useMutation} from 'react-query';

import {UiPermission, UserRole} from '../../../typings/user';
import {showErrorNotification} from '../../components/notifications-center/notifications-store';
import {apiClient} from '../../utils/api-client';
import {useCurrentUserQuery, useCurrentUserQueryCache} from '../user/use-current-user-query';

import {useUserListQueryCache} from './use-user-list-query';

interface UserUpdateRequest {
    active?: boolean;
    permissions?: UiPermission[];
    role?: UserRole;
    name?: string;
}

type UpdateUserPayload = UserUpdateRequest & {id: string};

function updateUserRequest(userId: string, updatedData: UserUpdateRequest) {
    return apiClient.patch({
        path: `users-service/api/users/${userId}`,
        parameters: updatedData
    });
}

/** Helps to update user data */
export function useUpdateUserMutation() {
    const userListQueryCache = useUserListQueryCache();

    const currentUserQuery = useCurrentUserQuery();
    const currentUserQueryCache = useCurrentUserQueryCache();

    return useMutation(
        (userData: UpdateUserPayload) => {
            const {id, ...updatedData} = userData;
            userListQueryCache.updateUser(userData);

            if (currentUserQuery.data?.id === id) {
                currentUserQueryCache.updateCurrentUser(updatedData);
            }

            return updateUserRequest(id, updatedData);
        },
        {
            onError(error, payload) {
                userListQueryCache.updateUser(payload);

                if (currentUserQuery.data?.id === payload.id) {
                    currentUserQueryCache.updateCurrentUser(payload);
                }

                showErrorNotification("Can't change data of user")(error);
            }
        }
    );
}
