import React from 'react';
import {
    Button,
    CollapseVerticalIcon,
    ControlsItem,
    ExpandVerticalIcon,
    Tooltip,
    TooltipHandler
} from '@genestack/ui';

export interface Props {
    isCollapsed: boolean;
    toggleCollapsed: () => void;
}

export const CollapseButton = (props: Props) => (
    <ControlsItem>
        <TooltipHandler
            tooltip={
                <Tooltip placement="left">
                    {props.isCollapsed ? 'Expand panel' : 'Collapse panel'}
                </Tooltip>
            }
        >
            <Button
                ghost
                size="small"
                onClick={props.toggleCollapsed}
                data-qa="collapse-result-chain"
                icon={props.isCollapsed ? <ExpandVerticalIcon /> : <CollapseVerticalIcon />}
            />
        </TooltipHandler>
    </ControlsItem>
);
