import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {isQueryDraft} from '../../utils';

import {useDraftQueriesCache} from '../../queries-sidebar/hooks/use-draft-queries';
import {useQueryInfoCache} from './use-query-info';

function createQueryDraftRequest(
    content?: string,
    globalVersionId?: number,
    templateQueryId?: number,
    name = 'Untitled query'
) {
    return apiClient.post<QueryInfo>({
        path: 'queries-service/api/queries',
        parameters: {name, globalVersionId, content, templateQueryId}
    });
}

interface UseCreateQueryDraftPayload {
    name?: string;
    content?: string;
    templateQueryId?: number;
    globalVersionId: number;
}

/** Creates a new query draft */
export function useCreateQueryDraftMutation() {
    const queryInfoCache = useQueryInfoCache();
    const draftQueriesCache = useDraftQueriesCache();

    return useMutation<QueryInfo, Error, UseCreateQueryDraftPayload>(
        (payload) =>
            createQueryDraftRequest(
                payload.content,
                payload.globalVersionId,
                payload.templateQueryId,
                payload.name
            ),
        {
            onSuccess: (createdQuery) => {
                queryInfoCache.addQuery(createdQuery);

                if (isQueryDraft(createdQuery)) {
                    draftQueriesCache.addQuery(createdQuery);
                }
            }
        }
    );
}
