import React from 'react';

import {DocumentNodes} from '../../../../../../components/document-nodes';
import {useRemainingNodesInfiniteQuery} from '../../../hooks/requests/use-remaining-nodes-infinite-query';
import {extractInfiniteQueryItems} from '../../../../../../utils/infinite-query-items';
import {NodeSearchData} from '../../../../common/sidebar-search-field/interface';
import {GlobalVersionData} from '../../../../../../hooks/global-version/use-global-version-query';

interface Props {
    usageAccession: string;
    searchData: NodeSearchData;

    initialNodes: DocumentNode[];
    remaining: number;
    cursor: number;
    selectedGlobalVersion: GlobalVersionData;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export function ViewModeDocumentNodes({
    usageAccession,
    searchData,
    initialNodes,
    remaining,
    cursor,
    selectedGlobalVersion,
    getDocumentLink
}: Props) {
    const [isLoading, setIsLoading] = React.useState(false);

    const {documentAccession, nodeId} = searchData;

    const remainingUsagesNodesQuery = useRemainingNodesInfiniteQuery(
        {
            accession: documentAccession,
            declarationNodeId: nodeId,
            usageAccession,
            globalVersionId: selectedGlobalVersion.id,
            cursor
        },
        {
            initialData: {
                pages: [{parsedNodes: initialNodes, remaining, nextCursor: cursor}],
                pageParams: [{cursor}]
            },
            onSettled: () => {
                setIsLoading(false);
            }
        }
    );

    const handleMoreBtn = React.useCallback(() => {
        if (documentAccession && nodeId) {
            setIsLoading(true);
            remainingUsagesNodesQuery.fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentAccession, nodeId]);

    const hasNextPage = remainingUsagesNodesQuery.hasNextPage ?? Boolean(remaining);
    const nodes = extractInfiniteQueryItems(remainingUsagesNodesQuery, (res) => res.parsedNodes);

    return (
        <DocumentNodes
            isLoading={isLoading}
            docAccession={usageAccession}
            nodes={nodes ?? []}
            hasNextPage={hasNextPage}
            onLoadNextPage={handleMoreBtn}
            getDocumentLink={getDocumentLink}
        />
    );
}
