/** Category ID for uncategorized documents */
export const UNCATEGORIZED_CATEGORY_ID = 'uncategorized';

/** Structure representing CategoriesSearch */
export interface CategoriesSearch {
    categories: string[];
    includeUncategorized: boolean;
}

/** Create CategoriesSearch parameter from array of categories */
export function getCategoriesSearch(categories?: string[]): CategoriesSearch | undefined {
    if (categories?.length) {
        return {
            categories: categories.filter((f) => f !== UNCATEGORIZED_CATEGORY_ID),
            includeUncategorized: categories.includes(UNCATEGORIZED_CATEGORY_ID)
        };
    }

    return undefined;
}
