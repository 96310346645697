import {DocumentStatus} from '../api';

import {
    useEditModeDocsInfiniteQuery,
    useEditModeDocsInfiniteQueryCache
} from './use-edit-mode-docs-infinite-query';

/** Hook for Unpublished documents */
export function useUnpublishedDocsQuery() {
    return useEditModeDocsInfiniteQuery({
        queryKey: 'unpublished',
        statuses: [DocumentStatus.DRAFT, DocumentStatus.EDITABLE],
        onlyUserDocuments: false
    });
}

/** QueryCache for Unpublished documents */
export function useUnpublishedDocsQueryCache() {
    return useEditModeDocsInfiniteQueryCache('unpublished');
}
