import React from 'react';
import {useHistory} from 'react-router-dom';

const RelativePath = React.createContext({
    path: ''
});

/** A hook to use RelativePath context */
export const useRelativePath = () => React.useContext(RelativePath);

/** A wrapper over Context.Provider to pass `path` easier */
export const RelativePathProvider = ({
    path,
    children
}: {
    path: string;
    children?: React.ReactNode;
}) => (
    <RelativePath.Provider value={React.useMemo(() => ({path}), [path])}>
        {children}
    </RelativePath.Provider>
);

/** A hook returning a `push` function */
export const usePushRelativeLocation = () => {
    const {push} = useHistory();
    const {path} = useRelativePath();

    /** Creates new, relative to base path, location in History */
    return React.useCallback(
        (to: string) => {
            push(`${path}${to}`);
        },
        [push, path]
    );
};
