import {InfiniteData, UseInfiniteQueryResult} from 'react-query';

function flatten<T>(array: T[][]): T[] {
    return ([] as T[]).concat(...array);
}

/** Returns flattened array of the items of Infinite query */
export function extractInfiniteQueryItems<T, I = T>(
    query: UseInfiniteQueryResult<T>,
    mapper: (response: T) => I[]
): I[] | undefined {
    return query.data ? flatten(query.data.pages.map(mapper)) : undefined;
}

/** Updates document metadata in PageResponses */
export function updateDocumentInCache<T extends {accession: string}>(
    queryData: InfiniteData<PageResponse<DocInfo>>,
    updatedData: T
) {
    return {
        ...queryData,
        pages: queryData.pages.map((page) => ({
            ...page,
            data: page.data.map<DocInfo>((docInfo) =>
                docInfo.accession === updatedData.accession ? {...docInfo, ...updatedData} : docInfo
            )
        }))
    };
}

/** Adds document metadata to PageResponses and remove all previous copies of this document */
export function addDocumentToCache(
    queryData: InfiniteData<PageResponse<DocInfo>>,
    docMetadata: DocInfo,
    addToTheEnd?: boolean
) {
    const pagesNumber = queryData.pages.length;

    const getPageWithAddedDoc = (pageData: DocInfo[]) => {
        const withPrevVersionsExcluded = pageData.filter((data) => data.id !== docMetadata.id);

        return addToTheEnd
            ? [...withPrevVersionsExcluded, docMetadata]
            : [docMetadata, ...withPrevVersionsExcluded];
    };

    return {
        ...queryData,
        pages: queryData.pages.map((page, index) => {
            const isLastPage = index === pagesNumber - 1;
            const isFirstPage = index === 0;
            const shouldAddDocToThisPage =
                (addToTheEnd && isLastPage) || (!addToTheEnd && isFirstPage);

            const pageData = shouldAddDocToThisPage ? getPageWithAddedDoc(page.data) : page.data;

            return {
                ...page,
                data: pageData,
                total: page.total + 1
            };
        })
    };
}

/** Removes document metadata from PageResponses */
export function removeDocumentFromCache(
    queryData: InfiniteData<PageResponse<DocInfo>>,
    accession: string
) {
    return {
        ...queryData,
        pages: queryData.pages.map((page) => ({
            ...page,
            total: page.total > 0 ? page.total - 1 : page.total,
            data: page.data.filter((metadata) => metadata.accession !== accession)
        }))
    };
}
