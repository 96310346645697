import {useQueryClient, QueryKey} from 'react-query';

import {uniqueBy} from '../../../../utils/array';

export function useQueryListCache<Query extends Partial<QueryInfo>>(queryKey: QueryKey) {
    const queryClient = useQueryClient();

    function getQueryById(queryId: number) {
        const queryList = queryClient.getQueryData<Query[]>(queryKey);

        return queryList?.find((q) => q.id === queryId);
    }

    function addQuery(queryInfo: Query, addToStart?: boolean) {
        queryClient.setQueriesData<Query[] | undefined>(
            queryKey,
            (currentState) =>
                currentState &&
                uniqueBy(
                    'id',
                    addToStart ? [queryInfo, ...currentState] : [...currentState, queryInfo]
                )
        );
    }

    function updateExistingQuery(updatedQuery: {id: number} & Partial<Query>) {
        queryClient.setQueriesData<Query[] | undefined>(queryKey, (currentState) =>
            currentState?.map((q) => (q.id === updatedQuery.id ? {...q, ...updatedQuery} : q))
        );
    }

    function removeQuery(queryId: number) {
        queryClient.setQueriesData<Query[] | undefined>(queryKey, (currentState) =>
            currentState?.filter((q) => q.id !== queryId)
        );
    }

    function updateQueryResultsCount(queryId: number, resultsCount: number) {
        queryClient.setQueriesData<RecentQuery[] | undefined>(queryKey, (currentState) =>
            currentState?.map((q) => (q.id === queryId ? {...q, resultsCount} : q))
        );
    }

    function moveQueryUpInList(queryId: number) {
        queryClient.setQueriesData<RecentQuery[] | undefined>(queryKey, (currentState) => {
            if (!currentState) {
                return currentState;
            }

            const queryIndex = currentState.findIndex((query) => query.id === queryId);
            if (queryIndex === currentState.length - 1) {
                return currentState;
            }

            const newState = [...currentState];
            const temp = newState[queryIndex];
            newState[queryIndex] = newState[queryIndex + 1];
            newState[queryIndex + 1] = temp;

            return newState;
        });
    }

    function invalidateCache() {
        return queryClient.invalidateQueries(queryKey);
    }

    return {
        getQueryById,
        addQuery,
        updateExistingQuery,
        removeQuery,
        invalidateCache,
        updateQueryResultsCount,
        moveQueryUpInList
    };
}
