import {useMutation} from 'react-query';

import {UserIdentity} from '../../../typings/user';
import {apiClient} from '../../utils/api-client';

import {useUserListQueryCache} from './use-user-list-query';

function addUserRequest(user: Partial<UserIdentity>) {
    return apiClient.post<UserIdentity>({
        path: 'users-service/api/users',
        parameters: user
    });
}

/** Adds a user */
export function useAddUserMutation() {
    const userListQueryCache = useUserListQueryCache();

    return useMutation(addUserRequest, {
        onSuccess: (user) => {
            if (user) {
                userListQueryCache.addUser(user);
            }
        }
    });
}
