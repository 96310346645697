import {Identity, Session} from '@ory/kratos-client';
import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';

/** Kratos form message */
export interface FormMessage {
    id: number;
    text: string;
    type: string;
}

/** Element of the Kratos form */
export interface FormNode {
    type: string;
    group: 'default' | 'profile' | 'password';
    attributes: React.InputHTMLAttributes<HTMLInputElement>;
    messages: FormMessage[];
    meta: unknown;
}

/** Kratos Form for displaying in UI */
export interface FormQueryResponse {
    id: string;
    type: string;
    expires_at: string;
    issued_at: string;
    request_url: string;
    ui: {
        action: string;
        method: string;
        nodes: FormNode[];
        messages?: FormMessage[];
    };
    created_at: string;
    updated_at: string;
    forced: boolean;
    state?: string;
}

/** Kratos error response */
export interface ErrorResponse {
    error: {code: number; status: string; reason: string; message: string};
}

/** Kratos response after changing the password or user data */
export interface ChangeUserDataResponse {
    id: string;
    type: 'browser';
    expires_at: string;
    issued_at: string;
    request_url: string;
    ui: {
        action: string;
        method: string;
        nodes: FormNode[];
        messages: FormMessage[];
    };
    identity: Identity;
    state: string;
}

/** Kratos Response with user session */
export interface SessionResponse {
    session: Session;
}

/** Hook for fetching an Identity Kratos form */
export function useIdentityFormQuery(props: {path: string}) {
    return useQuery(
        [props.path],
        () => axios.get<FormQueryResponse>(props.path).then((res) => res.data),
        // after implementing default onErr on every useQuery, it became apparent that this request
        // fails after successful login (see JBKB-3326). I will suppress this error for now bc it
        // looks like we don't need to handle an err here, but this whole thing must be refactored
        // and fixed later
        {onError: () => null}
    );
}

/** Type guard of session response */
export function isResultSession(
    result: SessionResponse | FormQueryResponse | ErrorResponse
): result is SessionResponse {
    return !!(result as SessionResponse).session;
}

/** Type guard of error response */
export function isResultErrorResponse(
    result: SessionResponse | FormQueryResponse | ErrorResponse | ChangeUserDataResponse | undefined
): result is ErrorResponse {
    return !!(result as ErrorResponse)?.error;
}

/** Type guard of user data response */
export function isChangeUserDataResponse(
    result: SessionResponse | FormQueryResponse | ErrorResponse | ChangeUserDataResponse | undefined
): result is ChangeUserDataResponse {
    return !!(result as ChangeUserDataResponse)?.ui;
}

/** Type guard of Form Query response */
export function isFormQueryResponse(
    result: ChangeUserDataResponse | FormQueryResponse | ErrorResponse | SessionResponse | undefined
): result is FormQueryResponse {
    return !!(result as FormQueryResponse)?.ui;
}
