import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

function runQueryRequest(queryId: number) {
    return apiClient.post<QueryInfo>({
        path: `queries-service/api/queries/${queryId}/run`
    });
}

/** Hook for run query */
export function useRunQueryMutation() {
    return useMutation<QueryInfo, Error, number>(runQueryRequest);
}
