import {
    BulletIcon,
    Typography,
    ListItem,
    ListItemProps,
    ArrowBottomThinIcon,
    Controls,
    ControlsItem
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {CategoryTreeElement} from './adapt-tree';
import styles from './category-tree-item.module.css';

const OFFSET_WIDTH = 20;

interface Props extends ListItemProps {
    item: CategoryTreeElement;
    checked: boolean;
    onCheckedChange: (item: CategoryTreeElement, checked: boolean) => void;
    highlighted?: boolean;
    order: number;
    disabled?: boolean;
    className?: string;
    onHighlight?: (item: CategoryTreeElement | null) => void;
}

// eslint-disable-next-line react/display-name
export const CategoryTreeItem = React.memo((props: Props) => {
    const {
        item,
        onCheckedChange,
        checked,
        disabled,
        highlighted,
        order,
        className,
        onHighlight,
        ...rest
    } = props;
    const isLastChild = !item.childIds.length;
    const Icon = isLastChild ? BulletIcon : ArrowBottomThinIcon;

    const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onCheckedChange(item, e.target.checked);
    };

    const width = order * OFFSET_WIDTH;

    const handleMouseEnter = () => {
        if (onHighlight) {
            onHighlight(item);
        }
    };
    const handleMouseLeave = () => {
        if (onHighlight) {
            onHighlight(null);
        }
    };

    return (
        <ListItem
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            prepend={<div style={{width}} />}
            className={classNames(styles.item, className, {
                [styles.highlighted]: highlighted
            })}
            contentProps={{className: styles.content}}
            append={
                <div className={styles.aside}>
                    <Controls>
                        <ControlsItem>
                            <Typography
                                className={classNames({[styles.quiet]: !props.item.assigned})}
                            >
                                {item.documentsCount}
                            </Typography>
                        </ControlsItem>
                        <ControlsItem className={styles.checkbox}>
                            <input
                                type="checkbox"
                                onChange={handleCheckedChange}
                                checked={checked}
                                disabled={disabled}
                                data-qa="category-checkbox-input"
                            />
                        </ControlsItem>
                    </Controls>
                </div>
            }
        >
            <div className={styles.itemTitle}>
                <Icon className={styles.arrow} />
                <Typography
                    ellipsis
                    title={props.item.name}
                    className={props.titleContentProps?.className}
                >
                    {props.item.name}
                </Typography>
            </div>
        </ListItem>
    );
});
