import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {isDocumentDraft} from '../../../../utils/document';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useCategoriesInfiniteQueryCache} from './categories/use-categories-infinite-query-cache';
import {useDocMetadataQueryCache} from './use-doc-metadata-query';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';
import {useUnpublishedDocsQueryCache} from './use-unpublished-docs-query';
import {useFetchWorkingCopyQueryCache} from './use-fetch-working-copy-query';

function discardWorkingCopyRequest(accession: string) {
    return apiClient.callApi({
        path: `editor-documents-service/api/documents/${accession}/wc`,
        method: 'DELETE'
    });
}

function useDiscardDraftInCache() {
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const searchDocumentsCache = useSearchDocsCache();
    const ontologyDocumentsQueryCache = useOntologyDocsQueryCache();
    const categoriesInfiniteQueryCache = useCategoriesInfiniteQueryCache();

    return (docMetadata: DraftDocument) => {
        docMetadataQueryCache.remove(docMetadata.accession);

        searchDocumentsCache.deleteDocument(docMetadata.accession);
        ontologyDocumentsQueryCache.deleteDocument(docMetadata);

        if (docMetadata.type === 'BTB') {
            categoriesInfiniteQueryCache.updateDocumentsCount('root', -1);
        }
    };
}

function useDiscardWorkingCopyInCache() {
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const searchDocumentsCache = useSearchDocsCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const categoriesInfiniteQueryCache = useCategoriesInfiniteQueryCache();

    return async (accession: string) => {
        searchDocumentsCache.clearCache();

        const nextMetadata = await docMetadataQueryCache.get(accession);

        if (nextMetadata) {
            await categoriesInfiniteQueryCache.invalidate();
            await ontologyDocsQueryCache.invalidate(nextMetadata.type);
        }
    };
}

/** Discard working copy */
export function useDiscardWorkingCopyMutation() {
    const workingCopyQueryCache = useFetchWorkingCopyQueryCache();
    const discardDraftInCache = useDiscardDraftInCache();
    const discardWorkingCopyInCache = useDiscardWorkingCopyInCache();

    const unpublishedDocsQueryCache = useUnpublishedDocsQueryCache();

    return useMutation((docMetadata: DocInfo) => discardWorkingCopyRequest(docMetadata.accession), {
        onSuccess: async (_, docMetadata) => {
            if (isDocumentDraft(docMetadata)) {
                discardDraftInCache(docMetadata);
            } else {
                await discardWorkingCopyInCache(docMetadata.accession);
            }

            workingCopyQueryCache.remove(docMetadata.accession);
            unpublishedDocsQueryCache.deleteDocument(docMetadata.accession);
        }
    });
}
