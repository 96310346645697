import {Typography} from '@genestack/ui';
import React from 'react';

import {useUserPermittedEditingDoc} from '../../../../../../hooks/user/use-has-permission';
import {CommitDto} from '../../../../../../providers/version';
import {isDocumentDraft, isDocumentBeingEdited} from '../../../../../../utils/document';
import {LastActionCaption} from '../../../../common/last-action-caption';

interface Props {
    docInfo: DocInfo;
    lastAction?: CommitDto;
}

/** Caption for edit view menu */
export function EditViewMenuCaption(props: Props) {
    const userCanEditDocument = useUserPermittedEditingDoc(props.docInfo);

    if (props.docInfo.readOnly) {
        return (
            <Typography box="paragraph" variant="caption">
                Read-only document
            </Typography>
        );
    }

    if (isDocumentBeingEdited(props.docInfo)) {
        if (!userCanEditDocument) {
            return (
                <Typography box="paragraph" variant="caption" intent="quiet">
                    Draft (displayed in the read-only state)
                </Typography>
            );
        }

        return (
            <Typography box="paragraph" variant="caption">
                (
                <strong>
                    {isDocumentDraft(props.docInfo)
                        ? 'Not published yet'
                        : 'Changes are not published'}
                </strong>
                )
            </Typography>
        );
    }

    if (props.lastAction) {
        return <LastActionCaption lastAction={props.lastAction} />;
    }

    return null;
}
