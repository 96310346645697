import {chain} from '@genestack/ui';
import React from 'react';

import {Group, GroupProps} from '../../../../../../components/group';
import {OntologyTreeState} from '../../../../../../providers/global-state';
import {UNCATEGORIZED_CATEGORY_ID} from '../../../../../../utils/get-categories-search';
import {useOntologyDocsQuery} from '../../../hooks/use-ontology-docs-query';

import {DocumentList} from './document-list';

export type OnChangeOntologyBranchStateHandler = (
    isOpen: boolean,
    targetCategoryTreePath: string[]
) => void;

interface Props extends GroupProps {
    docType: DocType;

    branchOpenState?: OntologyTreeState;
    changeIsCategoryOpenState?: OnChangeOntologyBranchStateHandler;
    categoryTreePath: string[];
}

// eslint-disable-next-line react/display-name
export const DocumentGroup = React.memo(
    ({
        docType,
        title = 'Uncategorized',

        branchOpenState,
        changeIsCategoryOpenState,

        categoryTreePath,
        ...restProps
    }: Props) => {
        const isOpen = branchOpenState !== undefined;

        const docsQuery = useOntologyDocsQuery({
            docType,
            // We use pageSize equal 0 to get the counter for the closed group of documents.
            pageSize: 0,
            categories: [UNCATEGORIZED_CATEGORY_ID]
        });

        const onTitleClick = () => {
            changeIsCategoryOpenState?.(!isOpen, categoryTreePath);
        };

        return (
            <Group
                {...restProps}
                isOpen={isOpen}
                title={title}
                onTitleClick={chain(onTitleClick, restProps.onTitleClick)}
                count={docsQuery.data?.pages[0]?.total}
                depth={1}
            >
                <DocumentList docType={docType} depth={0} />
            </Group>
        );
    }
);
