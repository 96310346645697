import {WithClasses, mergeClassesProps, OverridableProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './base-resizable-panel.module.css';

interface Props extends WithClasses<keyof typeof styles> {
    isDragging: boolean;
}

interface TypeMap {
    props: Props;
    defaultType: 'div';
}

/** Requisites for base resizable panel */
export type BaseResizablePanelProps = OverridableProps<TypeMap>;

/** Base resizable panel component */
export const BaseResizablePanel = React.forwardRef<HTMLDivElement, BaseResizablePanelProps>(
    function BaseResizablePanelComponent(props, ref) {
        const {
            component: Component = 'div',
            classes,
            isDragging,
            children,
            ...restProps
        } = mergeClassesProps(props, styles);

        return (
            <Component
                {...restProps}
                ref={ref}
                className={classNames(
                    classes.root,
                    {
                        [classes.dragging]: isDragging
                    },
                    restProps.className
                )}
            >
                {children}
            </Component>
        );
    }
);
