import {
    Button,
    Typography,
    Controls,
    ControlsItem,
    PageFullWidth,
    CrossIcon,
    PageContent
} from '@genestack/ui';
import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
    closePanelLink: string;
}

// eslint-disable-next-line react/display-name
export const RevisionsSidebarHeader = React.memo((props: Props) => {
    return (
        <PageContent endDividerProps={{startGap: 1, endGap: 1}}>
            <Controls justify="space-between" gap={0}>
                <ControlsItem>
                    <Typography variant="title">Revision History</Typography>
                </ControlsItem>

                <ControlsItem>
                    <PageFullWidth>
                        <Button
                            ghost
                            size="normal"
                            icon={<CrossIcon />}
                            title="Close Revision History sidebar"
                            data-qa="close-revisions-button"
                            tabIndex={-1}
                            to={props.closePanelLink}
                            component={Link}
                        />
                    </PageFullWidth>
                </ControlsItem>
            </Controls>
        </PageContent>
    );
});
