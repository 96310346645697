import {FadeProps, Fade, mergeClassesProps, chain} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './local-backdrop.module.css';

type TargetProps = React.HTMLAttributes<HTMLDivElement>;

type BackdropVariant = 'standard' | 'zerostate' | 'accent' | 'transparent';

/** Public Backdrop properties */
export interface Props extends TargetProps {
    open?: boolean;
    variant?: BackdropVariant;
    /** Properties of the `<Fade />` element */
    fadeProps?: Omit<FadeProps, 'in' | 'appear' | 'children' | 'unmountOnExit'>;
    /** Calls when <Fade /> transition exited. */
    onExited?: () => void;
}

/**
 * Backdrop component is used for blocking page content when a modal is shown.
 * Container that uses Backdrop should have its own z-index and focus state,
 * because popovers or menus have greater `z-index` than dialog has.
 *
 * Can be transparent when rendering a popover or a menu.
 */
export function LocalBackdrop(props: Props) {
    const {
        open,
        variant = 'standard',
        className,
        fadeProps = {},
        classes,
        onExited,
        ...rest
    } = mergeClassesProps(props, styles);

    return (
        <Fade
            {...fadeProps}
            in={open}
            appear
            unmountOnExit
            onExited={chain(fadeProps.onExited, onExited)}
        >
            <div
                {...rest}
                className={classNames(className, classes.root, {
                    [classes.zerostate]: variant === 'zerostate',
                    [classes.accent]: variant === 'accent',
                    [classes.transparent]: variant === 'transparent'
                })}
            />
        </Fade>
    );
}
