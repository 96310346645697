import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';

import {UserIdentity, UserIdentityBasic} from '../../../../../../typings/user';
import {apiClient} from '../../../../../utils/api-client';

/** Info about a user who was permitted to browse query and the user who shared it */
export interface QueryPermission {
    sharedWith: UserIdentityBasic;
    sharedBy: UserIdentityBasic;
}

/** Info about every user who has access to this query */
export interface PermissionsQueryResponse {
    owner: UserIdentityBasic;
    shared: QueryPermission[];
}

function getQueryPermissionsRequest(queryId: string) {
    return apiClient.get<PermissionsQueryResponse>({
        path: `queries-service/api/queries/${queryId}/permissions`
    });
}

/** Returns the list of emails of the users who were given permission to view this query */
export function useGetQueryPermittedUsers(
    queryId: string,
    options?: Omit<UseQueryOptions<PermissionsQueryResponse>, 'queryFn' | 'queryKey'>
) {
    return useQuery(
        ['getQueryPermittedUsers', queryId],
        () => getQueryPermissionsRequest(queryId),
        options
    );
}

/** Provides methods to managing the cache of permitted users query */
export function useGetQueryPermissionsCache(queryId: string) {
    const queryClient = useQueryClient();
    const queryKey = ['getQueryPermittedUsers', queryId];

    function setPermissions(newPermissions: QueryPermission[]) {
        queryClient.setQueryData(queryKey, () => newPermissions);
    }

    async function onChangeUserPermission(
        sharedWith: UserIdentityBasic,
        sharedBy: UserIdentity,
        mode: 'share' | 'unshare'
    ) {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const previousPermissions = queryClient.getQueryData<PermissionsQueryResponse | undefined>(
            queryKey
        );

        queryClient.setQueriesData<PermissionsQueryResponse | undefined>(
            queryKey,
            (currentState) => {
                if (!currentState) {
                    return undefined;
                }

                if (mode === 'share') {
                    const newPermission: QueryPermission = {
                        sharedBy: {
                            name: sharedBy.name,
                            email: sharedBy.email
                        },
                        sharedWith
                    };

                    return {
                        owner: currentState.owner,
                        shared: [newPermission, ...currentState.shared]
                    };
                }

                return {
                    owner: currentState.owner,
                    shared: currentState.shared.filter(
                        (permission) => permission.sharedWith.email !== sharedWith.email
                    )
                };
            }
        );

        // Return a context object with the snapshotted value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return previousPermissions!.shared;
    }

    return {onChangeUserPermission, setPermissions};
}
