import {Button, Divider, PageContent, Paper, Typography} from '@genestack/ui';
import React from 'react';

import {RelativeLink} from '../../../../../../components/relative-link';

import styles from './not-viewable-stub.module.css';

interface Props {
    globalVersionLabel?: string;
    variant: DocViewableStatus;
    docId: string;
    latestRevisionId: string | null;
    earliestRevisionId: string | null;
}

/** A stub to render instead of actual editor for large documents */
export const NotViewableStub = (props: Props) => {
    const {variant, globalVersionLabel, docId, latestRevisionId, earliestRevisionId} = props;

    return (
        <PageContent as={Paper} className={styles.root}>
            <Typography variant="section">Not available</Typography>
            <Typography box="paragraph">
                {variant === 'DELETED'
                    ? 'This document is not present in the selected global version'
                    : 'The document is not yet created in the global version'}
                {Boolean(globalVersionLabel) && (
                    <>
                        {' '}
                        <strong>{globalVersionLabel}</strong>
                    </>
                )}
            </Typography>

            {latestRevisionId && earliestRevisionId && (
                <>
                    <Divider variant="transparent" gap={2} />
                    <Button
                        component={RelativeLink}
                        to={`/${docId}/revisions/${
                            variant === 'DELETED' ? latestRevisionId : earliestRevisionId
                        }`}
                    >
                        See revision history
                    </Button>
                </>
            )}
        </PageContent>
    );
};
