import {
    ControlsItem,
    CrossSmallIcon,
    Button,
    Typography,
    TooltipHandler,
    Tooltip,
    ArrowBottomThinIcon,
    WithSeparator
} from '@genestack/ui';
import React from 'react';

import {CategoryTreeElement} from '../../explore-view/sidebar/search-results/categories/adapt-tree';

import styles from './pill.module.css';

interface Props {
    element: CategoryTreeElement;
    rootName: string;
    onRemove: (element: CategoryTreeElement) => void;
}

/** Component with chosen value and button to remove */
// eslint-disable-next-line react/display-name
export const Pill = React.memo((props: Props) => {
    const handleButtonClick = () => {
        props.onRemove(props.element);
    };

    const renderContent = () => {
        if (props.element.path.length === 0) {
            return <Typography data-qa="category-pill-title">{props.element.name}</Typography>;
        }

        return (
            <div className={styles.valueContainer}>
                <WithSeparator separator={<ArrowBottomThinIcon className={styles.icon} />}>
                    {[props.rootName, '...', props.element.name].map((name, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TooltipHandler key={i} tooltip={<Tooltip>{name}</Tooltip>}>
                            <Typography ellipsis className={styles.title}>
                                {name}
                            </Typography>
                        </TooltipHandler>
                    ))}
                </WithSeparator>
            </div>
        );
    };

    return (
        <ControlsItem className={styles.root}>
            {renderContent()}
            <Button
                ghost
                data-qa={`remove-pill-${props.element.name}-button`}
                size="tiny"
                className={styles.close}
                type="button"
                onClick={handleButtonClick}
            >
                <CrossSmallIcon />
            </Button>
        </ControlsItem>
    );
});
