import {ControlsProps, Controls, ControlsItem, Spinner} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './ontology-tree-preloader-item.module.css';

/** Loader component for ontology documents */
export function OntologyTreePreloaderItem({children, ...restProps}: ControlsProps) {
    return (
        <Controls {...restProps} className={classNames(styles.preloaderItem, restProps.className)}>
            <ControlsItem>
                <Spinner />
            </ControlsItem>
            <ControlsItem grow>{children}</ControlsItem>
        </Controls>
    );
}
