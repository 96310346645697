import {PageContent, PageContentProps, Preloader, PreloaderProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './sidebar-results-header.module.css';

export interface Props extends PageContentProps {
    isLoading?: boolean;
    children: React.ReactNode;
    preloaderProps?: PreloaderProps;
}

export function SidebarResultsHeader({
    isLoading,
    preloaderProps = {},
    children,
    ...restProps
}: Props) {
    return (
        <PageContent
            noStartDivider
            noEndDivider
            {...restProps}
            className={classNames(styles.root, restProps.className)}
        >
            <Preloader
                show={isLoading}
                {...preloaderProps}
                className={classNames(styles.preloader, preloaderProps?.className)}
            >
                {children}
            </Preloader>
        </PageContent>
    );
}
