import {Button, CrossIcon} from '@genestack/ui';
import React from 'react';

import {LocalBackdrop, LocalBackdropProps} from '../local-backdrop';
import {LocalSpinner} from '../local-spinner';
import {Tetragon} from '../tetragon';

import styles from './universal-backdrop.module.css';
import {CriticalErrorView} from '../critical-error/critical-error-view';

interface Props extends LocalBackdropProps {
    isLoading?: boolean;
    message?: React.ReactNode;
    onClose?: () => void;
}

export function UniversalBackdrop(props: Props) {
    const {open, isLoading, message, onClose, ...restProps} = props;
    const hasMessage = Boolean(message);

    return (
        <LocalBackdrop
            variant={isLoading ? 'standard' : 'zerostate'}
            {...restProps}
            open={isLoading || hasMessage || open}
        >
            {onClose && (
                <Button
                    ghost
                    className={styles.closeButton}
                    data-qa="close-document-button"
                    onClick={onClose}
                    title="Close"
                    icon={<CrossIcon />}
                />
            )}

            {(function () {
                if (isLoading) {
                    return <LocalSpinner />;
                }

                if (hasMessage) {
                    return (
                        <div className={styles.stubWrapper}>
                            <Tetragon className={styles.stubBackground} />

                            <div className={styles.messageContainer}>{message}</div>
                        </div>
                    );
                }

                return <CriticalErrorView />;
            })()}
        </LocalBackdrop>
    );
}
