// eslint-disable-next-line max-classes-per-file
import type {CallApiOptions} from './call-api-options';
import type {CallApiResult} from './parse-fetch-response';

/** Custom CallApi Error */
export class CallApiError extends Error {
    public options: CallApiOptions;
    public result: CallApiResult;
    public code: number;

    constructor(message: string, options: CallApiOptions, result: CallApiResult) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.options = options;
        this.result = result;
        this.code = result.json?.error.code;
    }
}

/** Custom CallApi Unauthorised Error */
export class CallApiUnauthorisedError extends CallApiError {
    constructor(options: CallApiOptions, result: CallApiResult) {
        super('CallApiUnauthorizedError', options, result);
    }
}

/** Is thrown on unexpected server error */
export class CallApiUnexpectedError extends CallApiError {
    constructor(options: CallApiOptions, result: CallApiResult) {
        super('CallApiUnexpectedError', options, result);
    }
}

/** Request error codes */
export enum RequestErrorCodes {
    /** Bad request error code */
    BAD_REQUEST_ERROR_CODE = 400,
    /** Forbidden error code */
    FORBIDDEN_ERROR_CODE = 403,
    /** Not found error code */
    NOT_FOUND_ERROR_CODE = 404
}
