import {Typography} from '@genestack/ui';
import * as React from 'react';

import {UserBadgeInline} from '../../../../components/user-badge-inline/user-badge-inline';

import styles from './user-info.module.css';

interface Props {
    fullName: string;
    email: string;
    children?: React.ReactNode;
}

export const UserInfo: React.FC<Props> = ({fullName, email, children}) => (
    <div className={styles.userInfo}>
        <UserBadgeInline fullName={fullName} className={styles.avatar} />
        <Typography box="inline" ellipsis className={styles.name} title={fullName}>
            {fullName}
        </Typography>
        <Typography intent="quiet" box="inline" ellipsis className={styles.email} title={email}>
            {email}
        </Typography>

        <div className={styles.rightCornerContent}>{children}</div>
    </div>
);
