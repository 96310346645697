import React from 'react';
import {FoldingItem, FoldingItemProps} from '../folding-item';
import {ContinuantStatRecord} from '../interface';

interface Props extends Omit<FoldingItemProps, 'count'> {
    continuants: ContinuantStatRecord[];
}

export function ContinuantsGroupFoldingItem({continuants, children, ...restProps}: Props) {
    const count = React.useMemo(() => {
        let result = 0;

        continuants.forEach((continuant) => {
            result += continuant.occurrencesCount;
        });
        return result;
    }, [continuants]);

    return (
        <FoldingItem {...restProps} count={count}>
            {children}
        </FoldingItem>
    );
}
