import classNames from 'classnames';
import React, {HTMLAttributes} from 'react';

import styles from './resizable-layout-overlay.module.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
    isDragging: boolean;
    alignment: 'vertical' | 'horizontal';
}

/** Component for displaying cursors of the resizable layout */
export function ResizableLayoutOverlay(props: Props) {
    const {isDragging, alignment, ...restProps} = props;

    return (
        <div
            {...restProps}
            className={classNames(
                styles.root,
                {
                    [styles.draggable]: isDragging,
                    [styles.draggableVertical]: alignment === 'vertical',
                    [styles.draggableHorizontal]: alignment === 'horizontal'
                },
                restProps.className
            )}
        />
    );
}
