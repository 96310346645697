import React from 'react';
import {ListItem, Typography} from '@genestack/ui';
import styles from './results-list.module.css';

interface Props {
    connection: ConnectionDto;
    index: number;
    onSelect: () => void;
    active: boolean;
}

export function QueryResultConnectionItem(props: Props) {
    return (
        <ListItem interactive onClick={props.onSelect} active={props.active}>
            <div className={styles.resultsListRow}>
                <Typography>{props.index}</Typography>

                <Typography>{props.connection.pathLength}</Typography>

                <Typography>{props.connection.score * 100}%</Typography>
            </div>
        </ListItem>
    );
}
