import {Controls, ControlsItem, Typography} from '@genestack/ui';
import {DateTime} from 'luxon';
import React from 'react';

import {EditorFooter} from '../editor-footer';

interface Props {
    timestamp: number;
}

/** Footer for ontology editor */
export function OntologyViewFooter(props: Props) {
    return (
        <EditorFooter>
            <Controls gap={1}>
                <ControlsItem>
                    <Typography>Imported on </Typography>
                </ControlsItem>
                <ControlsItem>
                    {/* eslint-disable-next-line no-use-before-define */}
                    <Typography intent="quiet">{formatDate(props.timestamp)}</Typography>
                </ControlsItem>
            </Controls>
        </EditorFooter>
    );
}

function formatDate(timestamp: number) {
    return DateTime.fromMillis(timestamp).toFormat('LLL d, y  HH:mm');
}
