export enum ViewModeTab {
    SEARCH = 'search',
    RECENT = 'recent',
    FAVORITES = 'favorites'
}

export enum EditModeTab {
    SEARCH = 'search',
    RECENT = 'recent',
    UNPUBLISHED = 'unpublished',
    DOCUMENTS = 'documents',
    BIN = 'bin'
}

export enum QueriesTab {
    DRAFTS = 'drafts',
    RUNNING = 'running',
    FAVORITES = 'favorites',
    RECENT = 'recent',
    SHARED = 'shared',
    BIN = 'bin'
}

export enum QueryRightPanelTab {
    STATISTICS = 'STATISTICS',
    RESULTS_LIST = 'RESULTS_LIST',
    START_END_NODES = 'START_END_NODES'
}

export type LayoutType = 'explore' | 'edit' | 'queries';

export type ActivityBarTabs = ViewModeTab | EditModeTab | QueriesTab | QueryRightPanelTab;

export interface LayoutSizeParams {
    layoutWidth: number;
    leftWidth: number;
    rightWidth: number;
}
