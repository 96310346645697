import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';

import {apiClient} from '../../utils/api-client';

interface UserData {
    email: string;
    name?: string;
}

/** Possible actions over the document */
export type CommitAction = 'TRASH_BIN' | 'PUBLISH' | 'RESTORE' | 'DELETE';

/** Commit to the document history as it comes from backend */
export interface CommitDto {
    revision: string;
    documentName: string;
    createdAt: number;
    author: UserData;
    action: CommitAction;
    renamedInCommit: boolean;
    commitMessage?: string;
}

/** Commit to the document history */
export interface CommitData extends CommitDto {
    type: 'commit-data';
}

function getDocumentHistory(accession: string) {
    return apiClient
        .get<CommitDto[]>({
            path: `documents-revisions-service/api/viewer/documents/${accession}/history`,
            query: {limit: 100}
        })
        .then<CommitData[]>((data) => data.map((item) => ({type: 'commit-data', ...item})));
}

/** Hook for getting document history */
export function useDocumentHistoryQuery(
    accession?: string,
    options?: Omit<UseQueryOptions<CommitData[] | undefined>, 'queryKey' | 'queryFn'>
) {
    return useQuery(
        ['docHistory', accession],
        () => {
            return accession ? getDocumentHistory(accession) : undefined;
        },
        options
    );
}

/** QueryCache for the history of the documents */
export function useDocumentHistoryQueryCache() {
    const queryClient = useQueryClient();

    function invalidate(documentId: string) {
        queryClient.invalidateQueries(['docHistory', documentId], {refetchInactive: true});
    }

    return {invalidate};
}
