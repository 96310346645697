import React from 'react';

import {useSizeParamsStore} from './use-size-params-store';
import {FlexibleLayoutSettings, PermittedStorageKeys} from './interface';
import {getContainerSize} from './utils';

interface StartSeparatorData {
    point: number;
    firstPanelSize: number;
    secondPanelSize: number;
}

const DEFAULT_LAYOUT_SETTINGS: FlexibleLayoutSettings = {
    minPermittedWidth: 300,
    initialFirstPanelShare: 0.3
};

export const useFlexibleLayout = <
    SeparatorElement extends HTMLElement,
    WrapperElement extends HTMLElement
>(
    storageKey: PermittedStorageKeys,
    layoutSettings?: Partial<FlexibleLayoutSettings>,
    isVertical?: boolean
) => {
    const containerRef = React.useRef<WrapperElement>(null);

    const separatorRef = React.useRef<SeparatorElement>(null);

    const settings: FlexibleLayoutSettings = React.useMemo(
        () => ({
            ...DEFAULT_LAYOUT_SETTINGS,
            ...layoutSettings
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [storageKey, layoutSettings]
    );

    const {firstPanelShare, setFirstPanelShare, changeFirstPanelSizeInPx} = useSizeParamsStore({
        settings,
        storageKey,
        containerRef,
        isVertical
    });

    const [startSeparatorData, setStartSeparatorData] = React.useState<StartSeparatorData | null>(
        null
    );
    const isDragging = Boolean(startSeparatorData);

    const handleStartResizing = React.useCallback(
        (e: MouseEvent) => {
            const path = e.composedPath();

            if (!separatorRef.current || !path.includes(separatorRef.current)) {
                return;
            }

            const firstPanelSize =
                getContainerSize(containerRef, isVertical ? 'height' : 'width') * firstPanelShare;

            setStartSeparatorData({
                point: e[isVertical ? 'screenY' : 'screenX'],
                firstPanelSize,
                secondPanelSize:
                    getContainerSize(containerRef, isVertical ? 'height' : 'width') - firstPanelSize
            });
        },
        [firstPanelShare, isVertical]
    );

    const handleResizing = React.useCallback(
        (e: MouseEvent) => {
            if (!startSeparatorData || e.which === 0) {
                return;
            }

            const {point, firstPanelSize: lw} = startSeparatorData;
            const diff = e[isVertical ? 'screenY' : 'screenX'] - point;

            setFirstPanelShare(
                lw / getContainerSize(containerRef, isVertical ? 'height' : 'width') +
                    diff / getContainerSize(containerRef, isVertical ? 'height' : 'width')
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startSeparatorData, firstPanelShare]
    );

    const handleEndResizing = React.useCallback(() => {
        setStartSeparatorData(null);
    }, []);

    React.useEffect(() => {
        window.addEventListener('mousedown', handleStartResizing);
        window.addEventListener('mousemove', handleResizing);
        window.addEventListener('mouseup', handleEndResizing);

        return () => {
            window.removeEventListener('mousedown', handleStartResizing);
            window.removeEventListener('mousemove', handleResizing);
            window.removeEventListener('mouseup', handleEndResizing);
        };
    }, [handleStartResizing, handleResizing, handleEndResizing]);

    return {
        wrapperRef: containerRef,
        separatorRef,
        isDragging,
        firstPanelShare,
        changeFirstPanelSizeInPx,
        minPermittedWidth: settings.minPermittedWidth,
        minPermittedHeight: settings.minPermittedHeight
    };
};
