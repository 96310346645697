import {Notification} from '@genestack/ui';
import * as React from 'react';
import {useQueryClient} from 'react-query';

import {showNotification} from '../../../components/notifications-center';
import {FormQueryResponse} from '../../../hooks/user/auth-form-query';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {useIdentityFormMutation} from '../../../hooks/user/use-identity-form-mutation';
import {JsonFormData} from '../../../utils/build-form-data';
import {OK_STATUS_CODE} from '../utils';

import {RecoveryForm} from './recovery-form';

/** Component for Recovering a password */
export function RecoveryPerformer(props: FormQueryResponse) {
    const queryClient = useQueryClient();
    const currentUserQuery = useCurrentUserQuery();
    const formMutation = useIdentityFormMutation<FormQueryResponse | undefined>();

    const handleSubmit = async (jsonFormData: JsonFormData) => {
        try {
            const response = await formMutation.mutateAsync({
                jsonFormData,
                action: props.ui.action
            });

            const result = response.data;

            if (!result?.ui) {
                /** Kratos returns Status code 200 and no
                 * response json when a user is already logged in */
                if (response.status === OK_STATUS_CODE) {
                    await currentUserQuery.refetch();
                }

                return;
            }

            queryClient.setQueryData(['/self-service/recovery/browser'], result);
        } catch (error) {
            if (error instanceof Error) {
                showNotification(<Notification>{error.message}</Notification>);
            }
        }
    };

    return (
        <RecoveryForm
            data={props}
            onSubmit={handleSubmit}
            result={formMutation.data?.data}
            isLoading={formMutation.isLoading}
        />
    );
}
