import React from 'react';
import {RouteComponentProps, Switch, Route} from 'react-router-dom';

import {DefaultRoute} from '../../default-route';
import {useUserEditingPermissions} from '../../hooks/user/use-has-permission';

import {EditRoute} from './edit-view';
import {ExploreRoute} from './explore-view';
import {TitleSlotView} from './explore-view/title-slot-view';
import {useGlobalVersion} from '../../providers/version/use-global-version';

interface Props extends RouteComponentProps {
    globalVersionProps: ReturnType<typeof useGlobalVersion>;
}

export const DocumentsRoute = ({match, globalVersionProps}: Props) => {
    const {editingPermitted} = useUserEditingPermissions();

    return (
        <TitleSlotView.Provider>
            <Switch>
                <Route
                    path={`${match.path}/explore`}
                    render={(routeProps) => (
                        <ExploreRoute globalVersionProps={globalVersionProps} {...routeProps} />
                    )}
                />
                {editingPermitted && (
                    <Route
                        path={`${match.path}/edit`}
                        render={(routeProps) => (
                            <EditRoute
                                {...routeProps}
                                globalVersions={globalVersionProps.globalVersions}
                            />
                        )}
                    />
                )}

                <DefaultRoute />
            </Switch>
        </TitleSlotView.Provider>
    );
};
