import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogBody,
    DialogHeader,
    DialogProps,
    Divider,
    Typography
} from '@genestack/ui';
import React from 'react';

import styles from './socket-offline-dialog.module.css';

interface Props extends Omit<DialogProps, 'onClose'> {
    onClose: () => void;
}

/** Socket error dialog component */
export function SocketOfflineDialog({onClose, ...restProps}: Props) {
    const handleReloadPageButtonClick = React.useCallback(() => {
        document.location.reload();
    }, []);

    return (
        <Dialog {...restProps} classes={{overlay: styles.overlay}} onClose={onClose}>
            <DialogHeader>
                <Typography variant="title">Connection timed out</Typography>
            </DialogHeader>
            <DialogBody>
                <Typography>Couldn't establish a stable connection with server</Typography>
                <Divider variant="transparent" gap={2} />
                <Controls>
                    <ControlsItem>
                        <Button onClick={handleReloadPageButtonClick}>Reload application</Button>
                    </ControlsItem>
                    <ControlsItem>
                        <Button ghost onClick={onClose}>
                            Dismiss
                        </Button>
                    </ControlsItem>
                </Controls>
            </DialogBody>
        </Dialog>
    );
}
