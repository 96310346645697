import React from 'react';

import {PageContent} from '@genestack/ui';
import {FindUsages} from './find-usages';
import {SidebarSearchResults} from './sidebar-search-results';
import {SidebarSearchField} from '../../../common/sidebar-search-field';
import {Sidebar} from '../../../../../components/sidebar';
import {useSearchSidebarContext} from '../../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useSuggestions} from '../../../common/sidebar-search-field/hooks/use-suggestions';
import {useHandleFindUsages} from '../../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {CategorySearchResult} from './category-search-result';

interface Props {
    revisionId?: string;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export const SearchLayout = (props: Props) => {
    const suggestionsProps = useSuggestions({
        inputValue: props.searchSidebarContext.parsedInputValue
    });

    return (
        <Sidebar
            component="div"
            header={
                <PageContent
                    startDividerProps={{startGap: 3, endGap: 2}}
                    endDividerProps={{startGap: 1, endGap: 1}}
                >
                    <SidebarSearchField
                        disabled={props.findUsagesProps.loadingNodeType || !!props.revisionId}
                        busy={suggestionsProps.isLoading || props.findUsagesProps.loadingNodeType}
                        suggestionsProps={suggestionsProps}
                        searchSidebarContext={props.searchSidebarContext}
                    />
                </PageContent>
            }
        >
            {(function () {
                const {searchData} = props.searchSidebarContext;

                if (!searchData) {
                    return null;
                }

                if (searchData.mode === 'category') {
                    return <CategorySearchResult searchData={searchData} />;
                }

                if (searchData.mode === 'node') {
                    return (
                        <FindUsages
                            searchData={searchData}
                            getDocumentLink={props.getDocumentLink}
                        />
                    );
                }

                if (searchData.mode === 'doc') {
                    return <SidebarSearchResults searchData={searchData} />;
                }
            })()}
        </Sidebar>
    );
};
