import {Typography} from '@genestack/ui';
import React from 'react';

import {Sidebar} from '../../../../components/sidebar';
import {SidebarHeader} from '../../../../components/sidebar-header';
import {useRecentDocsQuery} from '../hooks/use-recent-docs-query';

import {PortionedDocsGroupContent} from './portioned-docs-group-content';
import {DocumentStatusIconWithDeleted} from '../../../../components/doc-status-icon/document-status-icon';

export const RecentDocuments = () => {
    const recentDocs = useRecentDocsQuery();

    const sidebarHeader = React.useMemo(
        () => (
            <SidebarHeader>
                <Typography variant="section">Recent documents</Typography>
            </SidebarHeader>
        ),
        []
    );

    return (
        <Sidebar header={sidebarHeader}>
            <PortionedDocsGroupContent
                isInitialLoading={recentDocs.isLoading}
                docs={recentDocs.data}
                onClickLoadMoreButton={recentDocs.refetch}
                DocStatusIconComponent={DocumentStatusIconWithDeleted}
            />
        </Sidebar>
    );
};
