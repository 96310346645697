import React from 'react';

import {CommitData} from '../../../../../providers/version';
import {DocumentMetadataSkeleton, DocumentMetadata} from '../../../common/document-metadata';
import {EditorFooter} from '../../../common/editor-footer';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';

interface Props {
    isLoading: boolean;
    documentId: string;
    documentRevision?: string;
    revisionInfo?: CommitData;
    selectedGlobalVersion: GlobalVersionData;
}

export function EditorFooterViewMode(props: Props) {
    if (props.isLoading) {
        return (
            <EditorFooter>
                <DocumentMetadataSkeleton />
            </EditorFooter>
        );
    }

    if (props.revisionInfo) {
        return (
            <EditorFooter>
                <DocumentMetadata
                    documentId={props.documentId}
                    documentRevision={props.documentRevision}
                    revisionInfo={props.revisionInfo}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                />
            </EditorFooter>
        );
    }

    return <EditorFooter />;
}
