import React from 'react';
import {ListItem, Typography} from '@genestack/ui';

interface Props {
    message: string;
}

export function EmptyList(props: Props) {
    return (
        <ListItem>
            <Typography>{props.message}</Typography>
        </ListItem>
    );
}
