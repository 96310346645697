import React from 'react';
import {useFoldingItemsState} from '../statistics/use-folding-items-state';
import {RightPanelListItem} from '../right-panel-list-item';
import {FoldingItem} from '../folding-item';
import {GraphNode} from '../../../../../../components/graph/interface';

export interface NodesGroupProps {
    nodes: GraphNode[];
    label: string;
    foldingStateKey: string;
    foldingStateProps: ReturnType<typeof useFoldingItemsState>;
}

interface InteractiveProps extends NodesGroupProps {
    selectedGraphNodes: number[];
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
}

function isInteractiveProps(props: NodesGroupProps | InteractiveProps): props is InteractiveProps {
    return !!(props as InteractiveProps).selectGraphNodes;
}

export function NodesGroup<P extends NodesGroupProps = NodesGroupProps>(props: P) {
    const isItemsInteractive = isInteractiveProps(props);

    return (
        <FoldingItem
            foldingStateKey={props.foldingStateKey}
            label={props.label}
            foldingStateProps={props.foldingStateProps}
            count={props.nodes.length}
            padding={2}
        >
            {props.nodes.map((node) => (
                <RightPanelListItem
                    key={node.id}
                    padding={3}
                    label={node.label}
                    graphViewNodeIds={[node.id]}
                    active={isItemsInteractive && props.selectedGraphNodes.includes(node.id)}
                    selectGraphNodes={isItemsInteractive ? props.selectGraphNodes : undefined}
                    setHoveredNodes={isItemsInteractive ? props.setHoveredNodes : undefined}
                />
            ))}
        </FoldingItem>
    );
}
