import {ListItem, RootRef} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {DocumentTypeLabel} from '../../../../components/document-type-label';
import {removeElementRef, saveElementRef} from '../../../../components/scroll-to-element';

import styles from './document-link.module.css';
import {DocumentStatusIconProps} from '../../../../components/doc-status-icon/document-status-icon';

function isModifiedEvent(event: MouseEvent | KeyboardEvent) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface Props {
    doc: DocInfo;
    disabled?: boolean;
    active?: boolean;
    prepend?: React.ReactNode;
    /** Determines whether current link can be scrolled into view */
    canScrollIntoView?: boolean;
    offset?: number;
    marginLeft?: number;
    DocStatusIconComponent: React.FunctionComponent<DocumentStatusIconProps>;
}

/**
 * Document item in the Sidebar.
 * It is prepended with the label reflecting the type of the document.
 * If the document has one of the DocStatus (read-only, working-copy or own-working-copy),
 * the proper icon with a tooltip is appended to the item.
 */
// eslint-disable-next-line react/display-name
export const DocumentLink = React.memo(
    ({
        doc,
        active,
        DocStatusIconComponent,
        disabled = false,
        canScrollIntoView = false,
        prepend,
        offset,
        marginLeft
    }: Props) => {
        const basename = window.location.origin;
        const {push} = useHistory();
        const documentPath = `/documents/edit/${doc.accession}`;

        const liElement = React.useRef<HTMLElement>(null);

        React.useEffect(() => {
            if (canScrollIntoView && active && liElement.current) {
                saveElementRef(liElement, doc.accession);
            }

            // teardown
            return () => {
                removeElementRef(doc.accession);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [active]);

        const label = (
            <span className={styles.labelContainer}>
                <DocumentTypeLabel type={doc.type} selected={active} readOnly={doc.readOnly} />
            </span>
        );

        const handleClick = React.useCallback(
            ({nativeEvent}: {nativeEvent: MouseEvent | KeyboardEvent}) => {
                if (
                    (nativeEvent instanceof MouseEvent || nativeEvent instanceof KeyboardEvent) &&
                    !nativeEvent.defaultPrevented && // onClick prevented default
                    !isModifiedEvent(nativeEvent) // ignore clicks with modifier keys
                ) {
                    nativeEvent.preventDefault();
                    push(documentPath);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [documentPath]
        );

        return (
            <RootRef rootRef={liElement}>
                <ListItem
                    data-qa-id="document"
                    href={`${basename}${documentPath}`}
                    disabled={disabled}
                    onClick={handleClick}
                    prepend={
                        <>
                            {prepend}
                            {label}
                        </>
                    }
                    append={<DocStatusIconComponent document={doc} />}
                    interactive
                    className={classNames(styles.root, {
                        [styles.active]: active
                    })}
                    titleContentProps={
                        {
                            'data-qa-name': 'document name'
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                    }
                    style={{
                        paddingLeft: offset,
                        marginLeft
                    }}
                >
                    {doc.name}
                </ListItem>
            </RootRef>
        );
    }
);
