import React from 'react';

import {extractInfiniteQueryItems} from '../../../../../utils/infinite-query-items';

import {DocumentResultsHeader, DocumentResultsLayout} from './document-results';
import {useSearchDocumentsInfiniteQuery} from './search-docs-infinite-query';
import {SearchResultComponentProps} from './interface';
import {CategorySearchData} from '../../../common/sidebar-search-field/interface';
import {CategoryTreeElement} from './categories/adapt-tree';

export function CategoryResults({
    searchData,
    categoriesFilters,
    versionId,
    expanded,
    onToggleExpand,
    loadingState,
    setLoadingState,
    selectedGlobalVersion,
    getDocumentLink
}: SearchResultComponentProps<CategorySearchData>) {
    const docsQuery = useSearchDocumentsInfiniteQuery({
        categories: categoriesFilters.appliedFilters.length
            ? categoriesFilters.appliedFilters
            : [searchData.categoryId],
        versionId
    });

    const items = extractInfiniteQueryItems(
        docsQuery,
        (result: PageResponse<ViewModeDocInfo>) => result.data
    );

    const handleChangeFilters = React.useCallback(
        (
            toAddVisual: CategoryTreeElement[],
            toRemoveVisual: CategoryTreeElement[],
            toAddApplied: string[],
            toRemoveApplied: string[]
        ) => {
            categoriesFilters.onChangeAppliedFilters(toAddApplied, toRemoveApplied);
            categoriesFilters.onChangeVisualFilters(toAddVisual, toRemoveVisual);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <DocumentResultsHeader
                isLoading={docsQuery.isFetching}
                hasItems={Boolean(items)}
                total={docsQuery.data?.pages[0]?.total ?? 0}
                expanded={expanded}
                onToggleExpand={onToggleExpand}
                searchData={searchData}
                loadingState={loadingState}
                selectedGlobalVersion={selectedGlobalVersion}
            />

            <DocumentResultsLayout
                paginationState={{
                    items,
                    total: docsQuery.data?.pages[0]?.total,
                    isLoading: docsQuery.isFetching,
                    hasNextPage: docsQuery.hasNextPage,
                    loadNextPage: docsQuery.fetchNextPage,
                    isLoadingNextPage: docsQuery.isFetchingNextPage,
                    reset: docsQuery.remove
                }}
                appliedFilters={categoriesFilters.appliedFilters}
                visualFilters={categoriesFilters.visualFilters}
                onChangeFilters={handleChangeFilters}
                onResetFilters={categoriesFilters.onResetFilters}
                versionId={versionId}
                expanded={expanded}
                categories={[searchData.categoryId]}
                onLoadNextPage={docsQuery.fetchNextPage}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                getDocumentLink={getDocumentLink}
            />
        </>
    );
}
