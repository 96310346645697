import {
    Dialog,
    DialogBody,
    DialogHeader,
    DialogProps,
    Typography,
    DialogFooter,
    Controls,
    ControlsItem,
    Button,
    DialogFullWidth
} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader';
import {useUserListQuery} from '../../../../hooks/manage-users/use-user-list-query';
import {useGetQueryPermittedUsers} from './hooks/use-get-query-permitted-users';
import {useCurrentUserQuery} from '../../../../hooks/user/use-current-user-query';

import {ShareQueryDialogBody} from './share-query-dialog-body';
import styles from './share-query-dialog.module.css';
import {showErrorNotification} from '../../../../components/notifications-center/notifications-store';

interface Props extends DialogProps {
    queryId: string;
    onClose: () => void;
    onlyOwnerCanShare: boolean;
}

export const ShareQueryDialog = ({queryId, open, onClose, onlyOwnerCanShare}: Props) => {
    const onQueryError = (error: unknown) => {
        onClose();
        showErrorNotification('Request error, try again later')(error);
    };

    const userListQuery = useUserListQuery({enabled: open, onError: onQueryError});
    const permissionsQuery = useGetQueryPermittedUsers(queryId, {
        enabled: open,
        onError: onQueryError
    });
    const currentUserQuery = useCurrentUserQuery({enabled: open});
    const hasLoadingQueries =
        userListQuery.isLoading || permissionsQuery.isLoading || currentUserQuery.isLoading;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="medium"
            style={{verticalAlign: 'top'}}
            overlayProps={{
                disableEscListener: true,
                disableClickListener: true
            }}
        >
            <DialogHeader>
                <Typography variant="title">Share query</Typography>
            </DialogHeader>

            <DialogBody className={styles.body}>
                <Preloader
                    count={2}
                    show={hasLoadingQueries}
                    wrapAll={DialogFullWidth}
                    iterateClassnames={[styles.preloaderInputCaption, styles.preloaderInput]}
                >
                    {permissionsQuery.data &&
                        userListQuery.data &&
                        currentUserQuery.data &&
                        open && (
                            <ShareQueryDialogBody
                                queryId={queryId}
                                permissions={permissionsQuery.data.shared}
                                userList={userListQuery.data}
                                currentUser={currentUserQuery.data}
                                queryOwner={permissionsQuery.data.owner}
                                onlyOwnerCanShare={onlyOwnerCanShare}
                            />
                        )}
                </Preloader>
            </DialogBody>

            <DialogFooter>
                <Preloader
                    show={hasLoadingQueries}
                    count={1}
                    wrapAll={DialogFullWidth}
                    iterateClassnames={[styles.preloaderFooter]}
                >
                    <Controls>
                        <ControlsItem>
                            <Button intent="accent" type="submit" onClick={onClose}>
                                OK
                            </Button>
                        </ControlsItem>

                        <ControlsItem>
                            <Typography intent="quiet" variant="caption">
                                The query is shared altogether with results
                            </Typography>
                            <Typography intent="quiet" variant="caption">
                                (if and when they are present)
                            </Typography>
                        </ControlsItem>
                    </Controls>
                </Preloader>
            </DialogFooter>
        </Dialog>
    );
};
