import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useOntologyDocsQueryCache} from './use-ontology-docs-query';
import {useFetchWorkingCopyQueryCache} from './use-fetch-working-copy-query';

function renameWorkingCopyRequest(accession: string, name: string) {
    return apiClient.callApi({
        path: `editor-documents-service/api/documents/${accession}/wc`,
        method: 'PATCH',
        query: {name}
    });
}

/** Rename working copy of a document */
export function useRenameWorkingCopyMutation() {
    const workingCopyQueryCache = useFetchWorkingCopyQueryCache();
    const searchDocumentsCache = useSearchDocsCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();

    return useMutation(
        (payload: Pick<DocInfo, 'accession' | 'type' | 'name'>) => {
            workingCopyQueryCache.updateName(payload.accession, payload.name);
            searchDocumentsCache.updateDocument(payload);
            ontologyDocsQueryCache.updateDocument(payload);

            return renameWorkingCopyRequest(payload.accession, payload.name);
        },
        {
            onError: (_, payload) => {
                workingCopyQueryCache.invalidate(payload.accession);
                searchDocumentsCache.invalidate();
                ontologyDocsQueryCache.invalidate(payload.type);
            }
        }
    );
}
