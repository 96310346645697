import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {RelativePathProvider} from '../../../components/relative-link';
import {useLastLocationEffect} from '../../../hooks/use-last-location-effect';
import {DocInfoStoreProvider} from '../../../providers/doc-info-store';

import {
    GlobalVersionProps,
    GlobalVersionsLoadedChecker
} from '../../../components/global-versions/global-versions-loaded-checker';
import {getLineSeparator} from '../../../utils/get-client-os';
import {ExploreViewLayout} from './explore-view-layout';
import {useGlobalVersion} from '../../../providers/version/use-global-version';

interface Props extends RouteComponentProps {
    globalVersionProps: ReturnType<typeof useGlobalVersion>;
}

export const ExploreRoute = ({match, globalVersionProps}: Props) => {
    useLastLocationEffect({
        path: '/documents/explore',
        lsKey: 'documents.explore.lastLocation'
    });

    return (
        <RelativePathProvider path={match.path}>
            <DocInfoStoreProvider>
                <GlobalVersionsLoadedChecker
                    errorText={`At least one Knowledge Base global version ${getLineSeparator()} is required to explore documents`}
                    globalVersionProps={globalVersionProps}
                >
                    {({currentGlobalVersionData, globalVersions}: GlobalVersionProps) => (
                        <ExploreViewLayout
                            path={match.path}
                            globalVersions={globalVersions}
                            selectedGlobalVersion={currentGlobalVersionData}
                        />
                    )}
                </GlobalVersionsLoadedChecker>
            </DocInfoStoreProvider>
        </RelativePathProvider>
    );
};
