import React from 'react';

export function useFoldingItemsState() {
    const [foldingState, setFoldingState] = React.useState<{[key: string]: true}>({});

    const toggleFoldingState = React.useCallback(
        (key: string) => {
            if (foldingState[key]) {
                // I didn't find a way to remove the unimportant var ¯\_(ツ)_/¯
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const {[key]: unimportant, ...restFoldingState} = foldingState;
                setFoldingState(restFoldingState);
                return;
            }

            setFoldingState({...foldingState, [key]: true});
        },
        [foldingState]
    );

    return {
        foldingState,
        toggleFoldingState
    };
}
