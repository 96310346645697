import React from 'react';
import {useRequestNodesStructure} from './hooks/use-request-nodes-structure';
import {useNeighborhoodGraphSettings} from './hooks/use-neighborhood-graph-settings';
import {GraphResultPanelView} from '../../../../../components/graph/result-chains-panel/graph-results-panel/graph-result-panel-view';

interface Props {
    documentId: string;
    selectedFacts: string[];
    globalVersionId: number;
    selectedNodeIds: number[];
    closePanel: () => void;
    graphSettings: ReturnType<typeof useNeighborhoodGraphSettings>;
    isCollapsed: boolean;
    toggleIsCollapsed: () => void;
}

export function FactsetPanel(props: Props) {
    const nodesStructureRequest = useRequestNodesStructure({
        documentId: props.documentId,
        viewType: props.graphSettings.selectedViewType,
        selectedFacts: props.selectedFacts,
        maxDistance: props.graphSettings.maxDistance,
        globalVersionId: props.globalVersionId,
        direction: props.graphSettings.direction,
        selectedNodeIds: props.selectedNodeIds
    });

    return (
        <GraphResultPanelView
            selectedNodes={props.selectedNodeIds}
            contentRequest={nodesStructureRequest}
            isPanelCollapsed={props.isCollapsed}
            globalVersionId={props.globalVersionId}
            toggleIsPanelCollapsed={props.toggleIsCollapsed}
            closePanel={props.closePanel}
            maxPathLength={props.graphSettings.maxDistance}
        />
    );
}
