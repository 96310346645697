import {Menu, Divider, MenuCaption} from '@genestack/ui';
import React from 'react';

import {DocumentTypeLabel} from '../../../../../../components/document-type-label';
import {CommitDto} from '../../../../../../providers/version';

import {EditViewMenuCaption} from './edit-view-menu-caption';
import {EditViewMenuItems} from './edit-view-menu-items';

interface Props {
    documentMetadata: DocInfo;
    lastAction?: CommitDto;
    onClickPublish: () => void;
    onClickRename: () => void;
}

/**
 * Menu which shows some basic info about a BioKB document
 * and provides controls to do some actions over it
 */
export const EditViewEditorMenu = ({
    lastAction,
    documentMetadata,
    onClickPublish,
    onClickRename,
    ...restProps
}: Props) => {
    return (
        <Menu {...restProps}>
            <MenuCaption>
                <DocumentTypeLabel
                    variant="full"
                    type={documentMetadata.type}
                    readOnly={documentMetadata.readOnly}
                />
                <Divider variant="transparent" />
                <EditViewMenuCaption docInfo={documentMetadata} lastAction={lastAction} />
            </MenuCaption>

            <EditViewMenuItems
                docInfo={documentMetadata}
                onClickPublish={onClickPublish}
                onClickRename={onClickRename}
            />
        </Menu>
    );
};
