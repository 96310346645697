import * as React from 'react';

let idCounter = 0;

function useElementId(): string {
    // eslint-disable-next-line no-return-assign
    const id = React.useMemo(() => (idCounter += 1), []);

    return `${id}`;
}

interface SlotElement {
    children: React.ReactNode;
    id: string;
}

type SlotHandler = (el: SlotElement) => () => void;

interface SlotProviderProps {
    children?: React.ReactNode;
}

/** Provider to create a Plug and Slot for the custom children elements outside parent component */
export function createSlotView() {
    const SlotElementsContext = React.createContext<SlotElement[] | null>(null);
    const SlotHandlerContext = React.createContext<SlotHandler | null>(null);

    function SlotProvider(props: SlotProviderProps) {
        const [elements, setElements] = React.useState<SlotElement[]>([]);

        const registerElement = React.useCallback((element: SlotElement) => {
            setElements((current) => {
                return [...current, element];
            });

            return () => {
                setElements((current) => {
                    return current.filter((e) => e !== element);
                });
            };
        }, []);

        return (
            <SlotElementsContext.Provider value={elements}>
                <SlotHandlerContext.Provider value={registerElement}>
                    {props.children}
                </SlotHandlerContext.Provider>
            </SlotElementsContext.Provider>
        );
    }

    function Slot() {
        const elements = React.useContext(SlotElementsContext);

        if (!elements) {
            throw new Error('Should be inside Provider');
        }

        return (
            <>
                {elements.map((element) => (
                    <React.Fragment key={element.id}>{element.children}</React.Fragment>
                ))}
            </>
        );
    }

    interface PlugProps {
        children: React.ReactNode;
    }

    function Plug(props: PlugProps) {
        const registerElement = React.useContext(SlotHandlerContext);
        const id = useElementId();

        if (!registerElement) {
            throw new Error('Should be inside Provider');
        }

        React.useLayoutEffect(() => {
            return registerElement({
                children: props.children,
                id
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.children]);

        return null;
    }

    return {
        Slot,
        Plug,
        Provider: SlotProvider
    };
}
