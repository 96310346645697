import {useUserPermittedEditingDoc} from '../../../../../hooks/user/use-has-permission';
import {useDocMetadataQueryCache} from '../../hooks/use-doc-metadata-query';
import {useOntologyDocsQueryCache} from '../../hooks/use-ontology-docs-query';
import {useUnpublishedDocsQueryCache} from '../../hooks/use-unpublished-docs-query';
import {useSearchDocsCache} from '../../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';
import {useCreateWorkingCopy} from '../../hooks/use-create-working-copy-mutation';
import {useFetchWorkingCopyQueryCache} from '../../hooks/use-fetch-working-copy-query';

interface Props {
    documentMetadata: DocInfo;
    revisionId?: string;
}

export function useStartEditingDocument(props: Props) {
    const documentQueryCache = useSearchDocsCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const unpublishedDocsQueryCache = useUnpublishedDocsQueryCache();
    const wcQueryCache = useFetchWorkingCopyQueryCache();
    const isUserPermittedToEdit = useUserPermittedEditingDoc(props.documentMetadata);

    const workingCopyMutation = useCreateWorkingCopy({
        accession: props.documentMetadata.accession,
        revisionId: props.revisionId
    });

    const handleEdit = async () => {
        const wc = await workingCopyMutation.mutateAsync();

        const nextMetadata: DocInfo = {
            ...props.documentMetadata,
            readOnly: false,
            status: 'EDITABLE',
            editedByCurrentUser: true
        };

        documentQueryCache.updateDocument(nextMetadata);
        ontologyDocsQueryCache.updateDocument(nextMetadata);

        docMetadataQueryCache.set(nextMetadata);
        unpublishedDocsQueryCache.addDocument(nextMetadata);
        wcQueryCache.addDocument(nextMetadata, wc);
    };

    const editingDisabledReason = (function () {
        if (props.documentMetadata.readOnly) {
            return 'This document is read-only.';
        }

        if (!isUserPermittedToEdit) {
            return 'You are not authorised to edit this document.';
        }

        return undefined;
    })();

    return {
        startEditing: handleEdit,
        isDocEditable: !editingDisabledReason,
        editingDisabledReason
    };
}
