import React from 'react';

interface Props {
    text?: string;
    children?: React.ReactNode;
}

const baseTitle = document.title;

/** A component that dynamically updates page title */
export const WindowTitle: React.FunctionComponent<Props> = ({text, children}) => {
    const title = text ? `${text} – ${baseTitle}` : baseTitle;

    // updates document.title every time `title` changes
    React.useEffect(() => {
        document.title = title;
    }, [title]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
