import {Notification, NotificationProps, Typography} from '@genestack/ui';
import React from 'react';

interface Props extends Omit<NotificationProps, 'countdown'> {
    isError?: boolean;
    errorMessage?: string;
}

const deleteMessages = {
    alarm: 'Document can’t be moved to Recycle bin',
    success: 'Document moved to Recycle bin'
};

/** Notification about deletion of the document */
export const DeleteStatusNotification = ({
    isError = false,
    errorMessage = '',
    ...notificationProps
}: Props) => {
    const status = isError ? 'alarm' : 'success';

    return (
        <Notification {...notificationProps} countdown={status === 'alarm' ? 'none' : 'active'}>
            <Typography intent={status}>{deleteMessages[status]}</Typography>
            {Boolean(errorMessage) && <Typography>{errorMessage}</Typography>}
        </Notification>
    );
};

const deletePermanentlyMessages = {
    alarm: 'Documents are not removed from the Recycle bin',
    success: 'Recycle bin is cleared'
};

/** Notification about deletion of the document */
export const DeletePermanentlyStatusNotification = ({
    isError = false,
    errorMessage = '',
    ...notificationProps
}: Props) => {
    const status = isError ? 'alarm' : 'success';

    return (
        <Notification {...notificationProps} countdown={status === 'alarm' ? 'none' : 'active'}>
            <Typography intent={status}>{deletePermanentlyMessages[status]}</Typography>
            {Boolean(errorMessage) && <Typography>{errorMessage}</Typography>}
        </Notification>
    );
};
