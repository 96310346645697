import {Notification, NotificationProps, Typography} from '@genestack/ui';
import React from 'react';

interface Props extends Omit<NotificationProps, 'countdown'> {
    isError?: boolean;
    errorMessage?: string;
}

const publishMessages = {
    alarm: 'Document can’t be published',
    success: 'Document is published'
};

/** Notification about publishing of the document */
export const PublishStatusNotification = ({
    isError = false,
    errorMessage = '',
    ...notificationProps
}: Props) => {
    const status = isError ? 'alarm' : 'success';

    return (
        <Notification {...notificationProps} countdown={status === 'alarm' ? 'none' : 'active'}>
            <Typography intent={status}>{publishMessages[status]}</Typography>
            {Boolean(errorMessage) && <Typography>{errorMessage}</Typography>}
        </Notification>
    );
};

const publishAllMessages = {
    warning: 'Some documents can’t be published',
    success: 'Documents are published'
};

/** Notification about publishing of all unpublished documents */
export const PublishAllStatusNotification = ({
    isError = false,
    errorMessage = '',
    ...notificationProps
}: Props) => {
    const status = isError ? 'warning' : 'success';

    return (
        <Notification {...notificationProps} countdown={status === 'warning' ? 'none' : 'active'}>
            <Typography intent={status}>{publishAllMessages[status]}</Typography>
            {Boolean(errorMessage) && <Typography>{errorMessage}</Typography>}
        </Notification>
    );
};
