import {useQuery, useQueryClient} from 'react-query';
import {apiClient} from '../../../../../utils/api-client';

interface Props {
    enabled: boolean;
    globalVersionId?: number;
    limit: number;
}

function getNodeTypesRequest(props: Props) {
    return apiClient.get<string[]>({
        path: 'autocomplete-service/api/autocomplete/suggestions/node-types',
        query: {'global-version': props.globalVersionId, limit: props.limit}
    });
}

export function useNodeTypesQuery(props: Props) {
    return useQuery(
        ['autocomplete-node-types', props.globalVersionId || 'no-gv', props.limit],
        () => getNodeTypesRequest(props),
        {
            enabled: props.enabled
        }
    );
}

export function useNodeTypesQueryCache(props: Props) {
    const queryClient = useQueryClient();
    const key = ['autocomplete-node-types', props.globalVersionId || 'no-gv', props.limit];

    const invalidate = () => {
        return queryClient.invalidateQueries(key);
    };

    return {
        invalidate
    };
}
