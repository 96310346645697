import {useMutation} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

import {
    SearchHistoryRecordDto,
    useExploreViewSearchHistoryQueryCache
} from './use-explore-view-search-history-query';

function addRecordToSearchHistoryRequest(searchHistoryRecord: Partial<SearchHistoryRecordDto>) {
    return apiClient.post<unknown>({
        path: 'documents-revisions-service/api/user/documents/search-history',
        parameters: searchHistoryRecord
    });
}

/** Adds item to search history in explore view */
export function useAddRecordToExploreViewSearchHistoryMutation() {
    const exploreViewSearchHistoryQueryCache = useExploreViewSearchHistoryQueryCache();

    return useMutation<unknown, Error, SearchHistoryRecordDto>(addRecordToSearchHistoryRequest, {
        onSuccess: (_, payload) => {
            exploreViewSearchHistoryQueryCache.addItem(payload);
        }
    });
}
