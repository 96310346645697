import {DocumentStatus} from '../../../api';
import {
    useEditModeDocsInfiniteQueryCache,
    useEditModeDocsInfiniteQuery
} from '../../../hooks/use-edit-mode-docs-infinite-query';

interface Props {
    searchText: string;
    pageSize: number;
}

/** Hook for searching documents in Edit mode */
export function useSearchDocsQuery(props: Props) {
    return useEditModeDocsInfiniteQuery({
        queryKey: 'search',
        searchText: props.searchText,
        statuses: [DocumentStatus.DRAFT, DocumentStatus.EDITABLE, DocumentStatus.PUBLISHED],
        pageSize: props.pageSize,
        options: {enabled: props.searchText !== ''}
    });
}

/** Hook to update all search caches */
export function useSearchDocsCache() {
    return useEditModeDocsInfiniteQueryCache('search');
}
