import React from 'react';
import {useHistory} from 'react-router-dom';

import {useGetNodeContentQuery} from '../../../hooks/requests/use-get-node-content-structure';
import {GraphResultPanelView} from '../../../../../components/graph/result-chains-panel/graph-results-panel/graph-result-panel-view';

interface Props {
    queryInfo: QueryInfo;
    selectedNodes: number[];
    clearNodeSelection: () => void;
    isPanelCollapsed: boolean;
    toggleIsPanelCollapsed: () => void;
    maxPathLength?: number;
}

export const collapsedFactsetPanelHeight = 40;

export function GraphResultChainsPanel(props: Props) {
    const history = useHistory();
    const nodeContentQuery = useGetNodeContentQuery(
        props.queryInfo.id,
        props.maxPathLength,
        props.selectedNodes
    );

    const closePanel = () => {
        props.clearNodeSelection();
        history.push(`/queries/${props.queryInfo.id}`);
    };

    return (
        <GraphResultPanelView
            selectedNodes={props.selectedNodes}
            isPanelCollapsed={props.isPanelCollapsed}
            toggleIsPanelCollapsed={props.toggleIsPanelCollapsed}
            contentRequest={nodeContentQuery}
            closePanel={closePanel}
            globalVersionId={props.queryInfo.globalVersionId}
            maxPathLength={props.maxPathLength}
        />
    );
}
