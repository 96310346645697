import React from 'react';

import styles from './editor-header-skeleton.module.css';

interface Props {
    withoutCloseBtn?: boolean;
}

/**
 * Skeleton component for Editor Header
 */
export function EditorHeaderSkeleton(props: Props) {
    return (
        <div className={styles.root}>
            <div className={styles.title} />
            {!props.withoutCloseBtn && <div className={styles.close} />}
        </div>
    );
}
