import {Avatar, Button, Controls, ControlsItem, Divider, Paper, Typography} from '@genestack/ui';
import React from 'react';
import {useLocation} from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';

import {UiPermission, UserRole} from '../../../typings/user';
import {useIdentityFormQuery} from '../../hooks/user/auth-form-query';
import {useCurrentUserQuery} from '../../hooks/user/use-current-user-query';
import {getInitials} from '../../utils/initials';
import {BusyIndicator} from '../busy-indicator';
import {LogoutItem} from '../logout-item';

import {ChangePasswordDialog} from './change-password-dialog';
import styles from './profile-settings.module.css';
import {UserInfoDialog} from './user-info-dialog';

const GAP_3 = 3;

const PERMISSION_LABELS: Record<UiPermission, string> = {
    [UiPermission.manageUsers]: 'Can manage users',
    [UiPermission.manageQueries]: 'Can manage queries',
    [UiPermission.createGlobalVersions]: 'Can explore KB and run queries'
};

const ROLE_LABELS: Record<UserRole, string> = {
    [UserRole.EDITOR]: 'Can create and edit documents, assets and libraries',
    [UserRole.BTB_EDITOR]: 'Can create and edit BTB-documents',
    [UserRole.EXPLORER]: 'Can explore documents'
};

/** Settings of current user */
export function ProfileSettings() {
    const location = useLocation();
    const settingsForm = useIdentityFormQuery({path: '/self-service/settings/browser'});
    const currentUserQuery = useCurrentUserQuery();

    const [changePasswordDialogOpen, togglePasswordDialogOpen] = useToggle(false);
    const [editUserInfoDialogOpen, toggleEditUserInfoDialogOpen] = useToggle(false);

    const closeChangePasswordDialog = () => {
        togglePasswordDialogOpen(false);
        /** Clear messages from backend for previous password value */
        settingsForm.refetch();
    };

    const closeEditUserInfoDialog = () => {
        toggleEditUserInfoDialogOpen(false);
    };

    React.useEffect(() => {
        if (location.search) {
            togglePasswordDialogOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!settingsForm.data || !currentUserQuery.data) {
        return <BusyIndicator />;
    }

    const userName = currentUserQuery.data.name;
    const initials = getInitials(userName);

    const {role} = currentUserQuery.data;

    return (
        <>
            <Paper className={styles.root}>
                <Controls>
                    <ControlsItem>
                        <Avatar initials={initials} />
                    </ControlsItem>
                    <ControlsItem shrink title={userName}>
                        <Typography variant="header" intent="quiet" ellipsis>
                            {userName}
                        </Typography>
                    </ControlsItem>
                </Controls>

                <Divider gap={1} variant="transparent" />

                <div className={styles.content}>
                    <Typography>{currentUserQuery.data.email}</Typography>
                    <Divider gap={GAP_3} variant="transparent" />
                    <Controls>
                        <ControlsItem>
                            <Button onClick={togglePasswordDialogOpen}>Change password</Button>
                        </ControlsItem>
                        <ControlsItem>
                            <Button onClick={toggleEditUserInfoDialogOpen}>
                                Edit personal information
                            </Button>
                        </ControlsItem>
                        <ControlsItem>
                            <LogoutItem>
                                <Button ghost intent="alarm" onClick={toggleEditUserInfoDialogOpen}>
                                    Sign out
                                </Button>
                            </LogoutItem>
                        </ControlsItem>
                    </Controls>

                    {currentUserQuery.data.permissions.length > 0 && (
                        <>
                            <Divider gap={GAP_3} variant="transparent" />
                            <Typography variant="section">Account permissions:</Typography>

                            <ul className={styles.permissions}>
                                {currentUserQuery.data.permissions.map((permission) => (
                                    <li key={permission}>{PERMISSION_LABELS[permission]}</li>
                                ))}

                                <li key={role}>{ROLE_LABELS[role]}</li>
                            </ul>
                        </>
                    )}
                </div>
            </Paper>

            <ChangePasswordDialog
                open={changePasswordDialogOpen}
                onClose={closeChangePasswordDialog}
                data={settingsForm.data}
            />

            <UserInfoDialog open={editUserInfoDialogOpen} onClose={closeEditUserInfoDialog} />
        </>
    );
}
