import {useMutation} from 'react-query';

import {useOntologyDocsQueryCache} from '../use-ontology-docs-query';

import {useCategoriesInfiniteQueryCache} from './use-categories-infinite-query-cache';

interface AddDocumentToCategoryPayload {
    categoryId: string;
    document: DocInfo;
}

/** Remove document from categories of BTB documents */
export function useRemoveDocumentFromCategoryCache() {
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const categoriesInfiniteQueryCache = useCategoriesInfiniteQueryCache();

    return useMutation<unknown, Error, AddDocumentToCategoryPayload>(async (payload) => {
        ontologyDocsQueryCache.deleteDocument(payload.document, payload.categoryId);
        categoriesInfiniteQueryCache.updateDocumentsCount(payload.categoryId, -1);

        // We must invalidate the cache to update the root category counter
        await ontologyDocsQueryCache.invalidate(payload.document.type);
        await categoriesInfiniteQueryCache.invalidate();
    });
}
