import React from 'react';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {GraphPresentation} from './interface';

interface Props {
    queryId: number;
    presentation: GraphPresentation;
}

export function usePresentationEditedProps(props: Props) {
    const {queryId, presentation} = props;

    const [isPresentationEdited, setIsPresentationEdited, clearIsPresentationEdited] = useLsValue<
        'true' | 'false',
        [number, number],
        []
    >({
        changeEntityParams: [queryId, presentation.id],
        rewriteKeyParams: [],
        getKey: ([qId, pId]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.isPresentationEdited`,
        defaultValue: 'false',
        validateValue: (value) => value === 'true' || value === 'false'
    });

    const handlePresentationEdited = React.useCallback(
        () => setIsPresentationEdited('true'),
        [setIsPresentationEdited]
    );

    return {
        isPresentationEdited: isPresentationEdited === 'true',
        handlePresentationEdited,
        clearIsPresentationEdited
    };
}
