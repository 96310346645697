import React from 'react';

import {LocalBackdrop} from '../local-backdrop';
import {LocalSpinner} from '../local-spinner';

/**
 * The component which shows a busy indicator
 */
export function BusyIndicator() {
    return (
        <LocalBackdrop open variant="accent">
            <LocalSpinner />
        </LocalBackdrop>
    );
}
