import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {generateNewTitle} from '../../../../utils/document';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useCategoriesInfiniteQueryCache} from './categories/use-categories-infinite-query-cache';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';
import {useUnpublishedDocsQueryCache} from './use-unpublished-docs-query';

function createDocumentRequest(name: string, type: DocType, categoryNodeId?: string) {
    return apiClient.post<DraftDocument>({
        path: 'editor-documents-service/api/documents/draft',
        parameters: {name, type, categoryNodeId}
    });
}

/** Hook to create a document */
export function useCreateDocumentMutation() {
    const unpublishedDocsQueryCache = useUnpublishedDocsQueryCache();
    const searchDocumentsCache = useSearchDocsCache();
    const ontologyDocumentsQueryCache = useOntologyDocsQueryCache();
    const categoriesInfiniteQueryCache = useCategoriesInfiniteQueryCache();

    return useMutation(async ({type, categoryNodeId}: {type: DocType; categoryNodeId?: string}) => {
        const name = generateNewTitle(type);

        const draftDocument = await createDocumentRequest(name, type, categoryNodeId);

        unpublishedDocsQueryCache.addDocument(draftDocument);
        searchDocumentsCache.clearCache();
        ontologyDocumentsQueryCache.refetch(type, categoryNodeId);
        if (type === 'BTB') {
            categoriesInfiniteQueryCache.updateDocumentsCount(categoryNodeId || 'root', 1);
        }

        return draftDocument;
    });
}
