import {
    Button,
    chain,
    Controls,
    ControlsItem,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Typography
} from '@genestack/ui';
import React from 'react';

export interface ConfirmSettings {
    title: string;
    message?: string;
    confirmBtnText: string;
    cancelBtnText: string;
    onConfirm: () => void;
}

interface Props {
    // if undefined don't show confirm dialog
    settings?: ConfirmSettings;
    onClose: () => void;
}

export function ConfirmComponent(props: Props) {
    return (
        <Dialog open={!!props.settings} onClose={props.onClose}>
            <DialogHeader>
                <Typography variant="title">{props.settings?.title}</Typography>
            </DialogHeader>

            <DialogBody>
                <Typography box="paragraph">{props.settings?.message}</Typography>
            </DialogBody>

            <DialogFooter>
                <Controls>
                    <ControlsItem>
                        <Button
                            intent="accent"
                            onClick={chain(props.settings?.onConfirm, props.onClose)}
                        >
                            {props.settings?.confirmBtnText}
                        </Button>
                    </ControlsItem>
                    <ControlsItem>
                        <Button onClick={props.onClose}>{props.settings?.cancelBtnText}</Button>
                    </ControlsItem>
                </Controls>
            </DialogFooter>
        </Dialog>
    );
}
