import React from 'react';
import {ChevronDownIcon, ChevronRightIcon, ListItem, Typography} from '@genestack/ui';
import style from './graph-fact-panel-entry.module.css';
import {FactPanelEntryContentGroup} from './fact-panel-entry-content-group';
import {DocumentContent} from '../document-content/document-content';
import {MergedFactPanelEntry, SimpleFactPanelEntry} from '../../interface';
import {isSimpleFactPanelEntry} from '../../helpers';

interface Props {
    entry: SimpleFactPanelEntry | MergedFactPanelEntry;
    nodeIndex: number;
    globalVersionId: number;
}

export function GraphFactPanelEntry({entry, nodeIndex, globalVersionId}: Props) {
    const [isUnfolded, setIsUnfolded] = React.useState(false);
    const toggleIsUnfolded = React.useCallback(() => setIsUnfolded((prev) => !prev), []);

    return (
        <React.Fragment>
            <ListItem
                className={style.graphFactPanelEntry}
                prepend={isUnfolded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                onClick={toggleIsUnfolded}
            >
                <Typography box="inline" variant="section">
                    Node #{nodeIndex + 1}:{' '}
                </Typography>
                {entry.summary}
            </ListItem>

            {isUnfolded &&
                (isSimpleFactPanelEntry(entry) ? (
                    <DocumentContent
                        globalVersionId={globalVersionId}
                        entry={entry.content}
                        indentation={1}
                    />
                ) : (
                    <React.Fragment>
                        <FactPanelEntryContentGroup
                            globalVersionId={globalVersionId}
                            contents={entry.inputContents}
                            label="Inbound"
                        />
                        <FactPanelEntryContentGroup
                            globalVersionId={globalVersionId}
                            contents={entry.outputContents}
                            label="Outbound"
                        />
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
}
