import React from 'react';

import {Pill} from '../../../../common/pill/pill';

import {CategoryTreeElement} from './adapt-tree';
import styles from './categories.module.css';

interface Props {
    filters: CategoryTreeElement[];
    onChangeFilters: (
        toAddVisual: CategoryTreeElement[],
        toRemoveVisual: CategoryTreeElement[],
        toAddApplied: string[],
        toRemoveApplied: string[]
    ) => void;
}

/** Container for pills which represent applied filters */
export function Pills(props: Props) {
    const handleFilterRemove = React.useCallback(
        (el: CategoryTreeElement) => {
            props.onChangeFilters(
                [],
                props.filters.filter((f) => f.categoryId === el.categoryId),
                [],
                [el.categoryId, ...el.childIds].filter((item, i, arr) => i === arr.indexOf(item))
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.filters]
    );

    const filters = props.filters.filter(
        (filter) => props.filters.find((f) => f.categoryId === filter.categoryId)?.id === filter.id
    );

    return (
        <div className={styles.pillContainer}>
            {filters.map((el) => (
                <Pill
                    element={el}
                    key={el.id}
                    onRemove={handleFilterRemove}
                    rootName={el.rootName}
                />
            ))}
        </div>
    );
}
