import React from 'react';

import {FavoriteDocumentsSidebar} from './favorites-sidebar';
import {RecentDocumentsSidebar} from './recents-sidebar';
import {SearchDocumentsSidebar} from './search-sidebar';
import {LayoutContextValue} from '../../../../components/page-layout/use-page-layout';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';
import {useSearchSidebarContext} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: LayoutContextValue;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    globalVersions: GlobalVersionData[];
    selectedGlobalVersion: GlobalVersionData;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export const ViewModeDocumentsSidebar = (props: Props) => {
    switch (props.tabSettings.activityBarTab) {
        // eslint-disable-next-line default-case-last
        default:
        case 'recent':
            return <RecentDocumentsSidebar getDocumentLink={props.getDocumentLink} />;
        case 'search':
            return (
                <SearchDocumentsSidebar
                    layoutSettings={props.layoutSettings}
                    searchSidebarContext={props.searchSidebarContext}
                    findUsagesProps={props.findUsagesProps}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                    getDocumentLink={props.getDocumentLink}
                    globalVersions={props.globalVersions}
                />
            );
        case 'favorites':
            return <FavoriteDocumentsSidebar getDocumentLink={props.getDocumentLink} />;
    }
};
