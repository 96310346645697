import React from 'react';
import {GraphNode} from './interface';

interface Props {
    selectedNodes: number[];
    setSelectedNodes: (nodes: number[]) => void;
}

function mapNodesByNodeId(nodes: GraphNode[]) {
    const nodesMap: {[key: number]: GraphNode} = {};
    nodes.forEach((node) => {
        nodesMap[node.id] = node;
    });
    return nodesMap;
}

function mapNodesByRawNodeId(nodes: GraphNode[]) {
    const nodesMap: {[key: number]: GraphNode} = {};
    nodes.forEach((node) => {
        node.rawNodeIds.forEach((rawNodeId) => {
            nodesMap[rawNodeId] = node;
        });
    });
    return nodesMap;
}

/* this code manages saving nodes selection after loading new nodes
       it is needed when user selects larger max path length and wants their
       selection to persist or when they change view type, that's why raw node ids are used */

export function usePersistSelectionOnNodesLoaded({selectedNodes, setSelectedNodes}: Props) {
    const previousLoadedNodesRef = React.useRef<GraphNode[]>();

    return React.useCallback(
        (nodes: GraphNode[]) => {
            if (previousLoadedNodesRef.current) {
                const nodesByIdMap = mapNodesByNodeId(previousLoadedNodesRef.current);
                const previouslySelectedRawIds: number[] = [];
                selectedNodes.forEach((nodeId) => {
                    previouslySelectedRawIds.splice(
                        previouslySelectedRawIds.length,
                        0,
                        ...nodesByIdMap[nodeId].rawNodeIds
                    );
                });

                const nodesByRawNodeIdMap = mapNodesByRawNodeId(nodes);
                const matchingNewNodes = new Set<number>(
                    previouslySelectedRawIds
                        .filter((rawId) => nodesByRawNodeIdMap[rawId])
                        .map((rawId) => nodesByRawNodeIdMap[rawId].id)
                );

                setSelectedNodes(Array.from(matchingNewNodes));
            }

            previousLoadedNodesRef.current = nodes;
        },
        [setSelectedNodes, selectedNodes]
    );
}
