import React from 'react';

import {useGlobalStateVariable, Variables} from '../../providers/global-state';

import {useFlexibleLayout} from './flexible-layout';
import {PermittedStorageKeys} from './flexible-layout/interface';

interface Props {
    layoutSizeStorageKey: PermittedStorageKeys;
    isHasRightPanel?: boolean;
    layoutExpandedStorageKey?: keyof Variables;
    sidebarCollapsedStorageKey?: keyof Variables;
}

const EXPANDED_PANEL_ADDITIONAL_WIDTH = 400;

export function usePageLayout({
    isHasRightPanel,
    layoutSizeStorageKey,
    layoutExpandedStorageKey,
    sidebarCollapsedStorageKey
}: Props) {
    const [isExpandedSidebar, setIsExpandedSidebar] = useGlobalStateVariable(
        layoutExpandedStorageKey,
        false
    );
    const isShowExpandedSidebar = !!layoutExpandedStorageKey && isExpandedSidebar;

    const [isCollapsedSidebar, setIsCollapsedSidebar] = useGlobalStateVariable(
        sidebarCollapsedStorageKey,
        false
    );
    const collapseSidebar = () => setIsCollapsedSidebar(true);
    const decollapseSidebar = () => setIsCollapsedSidebar(false);

    const flexibleLayoutProps = useFlexibleLayout<HTMLDivElement, HTMLDivElement>(
        layoutSizeStorageKey
    );

    const toggleIsExpandedSidebar = () => {
        const shouldBeExpanded = !isExpandedSidebar;
        setIsExpandedSidebar(shouldBeExpanded);

        if (shouldBeExpanded) {
            flexibleLayoutProps.changeFirstPanelSizeInPx(EXPANDED_PANEL_ADDITIONAL_WIDTH);
        } else {
            flexibleLayoutProps.changeFirstPanelSizeInPx(-EXPANDED_PANEL_ADDITIONAL_WIDTH);
        }
    };

    React.useEffect(() => {
        if (isHasRightPanel && isExpandedSidebar) {
            setIsExpandedSidebar(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHasRightPanel]);

    return {
        toggleIsExpandedSidebar,
        collapseSidebar,
        decollapseSidebar,
        flexiblePanel: flexibleLayoutProps,
        isHasRightPanel,
        isExpandedSidebar: !!isShowExpandedSidebar,
        isCollapsedSidebar: !!isCollapsedSidebar
    };
}

export type LayoutContextValue = ReturnType<typeof usePageLayout>;
