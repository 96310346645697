import {Controls, ControlsItem, Link, Typography, Switch} from '@genestack/ui';
import * as React from 'react';

import {useShareQuerySettingsMutation} from './hooks/use-share-query-settings-mutation';

interface Props {
    othersCanShare: boolean;
    queryId: string;
}

export const OthersCanShareSwitch = ({othersCanShare, queryId}: Props) => {
    const {mutate} = useShareQuerySettingsMutation(queryId);

    const onCheckedChange = (checked: boolean) => {
        mutate({queryId, onlyOwnerCanShare: !checked});
    };

    return (
        <Typography as="label" intent="no-intent">
            <Controls gap={2}>
                <ControlsItem>
                    <Switch checked={othersCanShare} onCheckedChange={onCheckedChange} />
                </ControlsItem>
                <ControlsItem as={Link}>Allow others to share</ControlsItem>
            </Controls>
        </Typography>
    );
};
