import {PageContent, Typography, Divider} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader';
import {Sidebar} from '../../../../components/sidebar';
import {SidebarHeader} from '../../../../components/sidebar-header';
import {useRecentsQuery} from '../hooks/requests/use-recents-query';
import {useLocationParams} from '../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../providers/doc-info-store';

import {DocumentLink} from './document-link';

function RecentsResults(props: {
    items: ViewModeDocInfo[];
    selectedDocumentId?: string;
    getDocumentLink: (documentId: string) => string;
}) {
    useConsumeDocInfo(props.items);

    return (
        <>
            {props.items.map((docInfo) => (
                <DocumentLink
                    key={docInfo.accession}
                    documentId={docInfo.accession}
                    active={docInfo.accession === props.selectedDocumentId}
                    getDocumentLink={props.getDocumentLink}
                />
            ))}
        </>
    );
}

interface Props {
    getDocumentLink: (documentId: string) => string;
}

export const RecentDocumentsSidebar = (props: Props) => {
    const {documentId: selectedDocumentId} = useLocationParams();
    const recentDocsQuery = useRecentsQuery();

    const header = React.useMemo(
        () => (
            <SidebarHeader>
                <Typography variant="section">Recent</Typography>
            </SidebarHeader>
        ),
        []
    );

    return (
        <Sidebar header={header}>
            <Preloader show={recentDocsQuery.isFetching}>
                {!recentDocsQuery.isSuccess || !recentDocsQuery.data?.data.length ? (
                    <PageContent noStartDivider noEndDivider>
                        <Typography intent="quiet">Your browsing history appears here</Typography>
                    </PageContent>
                ) : (
                    <>
                        <RecentsResults
                            items={recentDocsQuery.data.data}
                            selectedDocumentId={selectedDocumentId}
                            getDocumentLink={props.getDocumentLink}
                        />
                        <Divider
                            variant="transparent"
                            gap={4} // tslint:disable-line: no-magic-numbers
                        />
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
};
