import * as React from 'react';

/** Async tracker hook */
export function useTracker() {
    const mostRecentId = React.useRef(0);

    return React.useMemo(
        () => ({
            generateId: () => {
                mostRecentId.current += 1;

                return mostRecentId.current;
            },

            isMostRecentId: (id: number) => mostRecentId.current === id
        }),
        []
    );
}
