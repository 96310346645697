import {
    BookmarkBorderedIcon,
    Button,
    chain,
    MenuIcon,
    PlusIcon,
    ShareIcon,
    TrashIcon,
    DraftIcon,
    InProgressIcon
} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {VerticalBar, VerticalBarItem} from '../../../components/vertical-bar';
import {useCreateQueryDraftMutation} from '../hooks/requests/use-create-query-mutation';

import styles from './activity-bar.module.css';
import {QueriesTab} from '../../../components/page-layout/layout-typings';
import {useSidebarTabs} from '../../../hooks/use-sidebar-tabs';
import {usePageLayout} from '../../../components/page-layout/use-page-layout';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Show draft queries',
        value: QueriesTab.DRAFTS,
        prepend: <DraftIcon />
    },
    {
        tooltip: 'Show running queries',
        value: QueriesTab.RUNNING,
        prepend: <InProgressIcon />
    },
    {
        tooltip: 'Show my bookmarks',
        value: QueriesTab.FAVORITES,
        prepend: <BookmarkBorderedIcon />
    },
    {
        tooltip: 'Browse queries',
        value: QueriesTab.RECENT,
        prepend: <MenuIcon />
    },
    {
        tooltip: 'Shared with me',
        value: QueriesTab.SHARED,
        prepend: <ShareIcon />
    },
    {
        className: styles.binTab,
        tooltip: 'Recycle bin',
        value: QueriesTab.BIN,
        prepend: <TrashIcon />
    }
];

interface Props {
    layoutSettings: ReturnType<typeof usePageLayout>;
    tabSettings: ReturnType<typeof useSidebarTabs>;
    currentGlobalVersion: GlobalVersionData;
}

export function QueriesActivityBar({tabSettings, layoutSettings, currentGlobalVersion}: Props) {
    const createQueryDraft = useCreateQueryDraftMutation();
    const history = useHistory();

    const handleCreateNewDocument = async () => {
        const queryInfo = await createQueryDraft.mutateAsync({
            globalVersionId: currentGlobalVersion.id
        });
        layoutSettings.decollapseSidebar();

        history.push(`/queries/${queryInfo.id}`);
    };

    return (
        <div className={styles.root}>
            <Button
                data-qa="create-query-button"
                className={styles.createButton}
                icon={<PlusIcon />}
                onClick={handleCreateNewDocument}
                disabled={createQueryDraft.isLoading}
            />

            <VerticalBar
                tabs={SIDEBAR_TABS}
                value={layoutSettings.isCollapsedSidebar ? undefined : tabSettings.activityBarTab}
                onValueChange={chain(
                    layoutSettings.decollapseSidebar,
                    tabSettings.setActivityBarTab
                )}
            />
        </div>
    );
}
