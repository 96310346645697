import React from 'react';

import {getFormattedDate} from '../../../../utils/common';

interface Props {
    timestamp: number;
    writeIfTodayOrYesterday?: boolean;
}

/** Renders date text node from provided timestamp */
export const DateText = ({timestamp, writeIfTodayOrYesterday}: Props) => (
    <>
        {React.useMemo(
            () =>
                getFormattedDate({
                    date: timestamp,
                    showTime: true,
                    writeIfTodayOrYesterday
                }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [timestamp]
        )}
    </>
);
