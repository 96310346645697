import {useQuery, useQueryClient} from 'react-query';

import {UserIdentity} from '../../../typings/user';
import {apiClient} from '../../utils/api-client';

export interface GlobalVersionDataResponseItem {
    id: number;
    label: string;
    description: string;
    createdAt: number;
    author: UserIdentity;
}

export interface GlobalVersionData extends GlobalVersionDataResponseItem {
    type: 'global-version-data';
}

interface Props {
    from?: string;
    to?: string;
}

function getGlobalVersionsRequest(from?: string, to?: string) {
    return apiClient
        .get<GlobalVersionDataResponseItem[]>({
            path: 'documents-revisions-service/api/global-versions',
            query: {from, to}
        })
        .then((data) => {
            return data.map(
                (item) =>
                    // tslint:disable-next-line no-object-literal-type-assertion
                    ({
                        ...item,
                        type: 'global-version-data'
                    }) as GlobalVersionData
            );
        });
}

export function useGlobalVersionsQuery(props: Props) {
    return useQuery(['global-versions', props.from, props.to], () =>
        getGlobalVersionsRequest(props.from, props.to)
    );
}

export function useGlobalVersionsQueryCache() {
    const queryClient = useQueryClient();

    function invalidate() {
        queryClient.invalidateQueries(['global-versions'], {refetchInactive: true});
    }

    function setQueryData(globalVersion: GlobalVersionData) {
        const data = queryClient.getQueryData<GlobalVersionData[]>('recents');

        if (!data) {
            invalidate();
        } else {
            queryClient.setQueryData<GlobalVersionData[]>(['global-versions'], (currentData) => [
                globalVersion,
                ...(currentData || [])
            ]);
        }
    }

    return {invalidate, setQueryData};
}
