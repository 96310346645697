import {MenuItem, Notification, Typography} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {showNotification} from '../../../../../../components/notifications-center';
import {isDocumentDraft} from '../../../../../../utils/document';
import {useDiscardWorkingCopyMutation} from '../../../hooks/use-discard-working-copy-mutation';

interface Props {
    docMetadata: DocInfo;
    disabled: boolean;
}

/** Component allowing to Discard working copy of the document */
export function DiscardItem(props: Props) {
    const history = useHistory();
    const discardWorkingCopy = useDiscardWorkingCopyMutation();

    const handleDiscard = () => {
        try {
            discardWorkingCopy.mutate(props.docMetadata);

            if (isDocumentDraft(props.docMetadata)) {
                history.push('/documents/edit');
            }
        } catch (error) {
            showNotification(
                <Notification>
                    <Typography intent="alarm">Error: Not possible to discard the draft</Typography>
                    {error instanceof Error ? <Typography>{error.message}</Typography> : null}
                </Notification>
            );
        }
    };

    return (
        <MenuItem key="discard" onClick={handleDiscard} disabled={props.disabled}>
            Discard {isDocumentDraft(props.docMetadata) ? 'draft' : 'working copy'}
        </MenuItem>
    );
}
