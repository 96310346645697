import {
    Typography,
    Button,
    Dialog,
    DialogProps,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Textarea
} from '@genestack/ui';
import anime from 'animejs';
import * as React from 'react';

import {getErrorReport, getErrorReportTitle} from './error-report';

interface Props extends DialogProps {
    error: Error | ErrorEvent;
    errorDateOccurred: Date;
    onReportSubmitted: () => void;
}

interface State {
    report: string;
}

const ERROR_REPORT_TEXTAREA_ROWS = 20;

const slideOut = (element: HTMLElement) => {
    anime({
        targets: element,
        translateX: ['0%', '100%'],
        easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        duration: 300
    });
};

/** Dialog for sending error report from user  */
export class ReportErrorDialog extends React.Component<Props, State> {
    private textareaElement: HTMLTextAreaElement | null = null;
    private dialogRef = React.createRef<HTMLDivElement>();

    // eslint-disable-next-line react/state-in-constructor
    public state: State = {
        report: getErrorReport(this.props)
    };

    public componentDidMount() {
        if (this.textareaElement) {
            this.textareaElement.scrollTop = 0;
        }
    }

    private handleClose = () => {
        if (this.dialogRef.current) {
            slideOut(this.dialogRef.current);
        }

        this.props.onReportSubmitted();
    };

    private handleReportChange = (report: string) => {
        this.setState({report});
    };

    private handleTextareaRef = (textareaElement: HTMLTextAreaElement | null) => {
        this.textareaElement = textareaElement;
    };

    public render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {onReportSubmitted, error, errorDateOccurred, ...rest} = this.props;

        const errorTitle = getErrorReportTitle(error);

        return (
            <Dialog {...rest} size="large" rootRef={this.dialogRef}>
                <DialogHeader>
                    <Typography variant="title" ellipsis title={errorTitle}>
                        {errorTitle}
                    </Typography>
                </DialogHeader>
                <DialogBody>
                    <Textarea
                        fullWidth
                        autoFocus
                        maxRows={ERROR_REPORT_TEXTAREA_ROWS}
                        value={this.state.report}
                        onValueChange={this.handleReportChange}
                        inputRef={this.handleTextareaRef}
                    />
                </DialogBody>
                <DialogFooter>
                    <Button onClick={this.handleClose}>OK</Button>
                </DialogFooter>
            </Dialog>
        );
    }
}
