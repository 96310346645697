import {Spinner} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './local-spinner.module.css';

/** JBKB version of standard @genestack/ui spinner */
export const LocalSpinner = ({className}: {className?: string}) => (
    <div className={classNames(styles.root, className)}>
        <Spinner
            // tslint:disable-next-line: no-magic-numbers
            size={16}
        />
    </div>
);
