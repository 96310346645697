import React from 'react';
import {usePresentationEditedProps} from './use-presentation-edited-props';
import {useGraphSettings} from './use-graph-settings';
import {useLayoutAndCameraProps} from './use-layout-and-camera-props';
import {GraphPresentationDetails} from './interface';

interface Props {
    presentationEditedProps: ReturnType<typeof usePresentationEditedProps>;
    graphSettings: ReturnType<typeof useGraphSettings>;
    layoutAndCameraProps: ReturnType<typeof useLayoutAndCameraProps>;
}

export function useUnsavedPresentationDetailsActions(props: Props) {
    const {
        presentationEditedProps: {clearIsPresentationEdited, handlePresentationEdited},
        graphSettings: {
            clearGraphType,
            setGraphType,
            clearSelectedViewType,
            setSelectedViewType,
            clearMaxPathLength,
            setMaxPathLength
        },
        layoutAndCameraProps: {
            clearIsCustomLayout,
            setIsCustomLayout,
            clearLayoutAlgorithm,
            selectLayoutAlgorithm,
            clearNodePositions,
            setNodePositions
        }
    } = props;

    const clearUnsavedDetails = React.useCallback(() => {
        clearIsPresentationEdited();
        clearGraphType();
        clearSelectedViewType();
        clearMaxPathLength();
        clearIsCustomLayout();
        clearLayoutAlgorithm();
        clearNodePositions();
    }, [
        clearIsPresentationEdited,
        clearGraphType,
        clearSelectedViewType,
        clearMaxPathLength,
        clearIsCustomLayout,
        clearLayoutAlgorithm,
        clearNodePositions
    ]);

    const setUnsavedDetails = React.useCallback(
        (presentationDetails: GraphPresentationDetails) => {
            handlePresentationEdited();
            setGraphType(presentationDetails.graphType);
            setSelectedViewType(presentationDetails.viewKind);
            setMaxPathLength(presentationDetails.maxPathLength);
            setIsCustomLayout(String(presentationDetails.isLayoutCustom) as 'true' | 'false');
            selectLayoutAlgorithm(presentationDetails.layoutAlgorithm);
            setNodePositions(presentationDetails.layout);
        },
        [
            handlePresentationEdited,
            setGraphType,
            setSelectedViewType,
            setMaxPathLength,
            setIsCustomLayout,
            selectLayoutAlgorithm,
            setNodePositions
        ]
    );

    return {clearUnsavedDetails, setUnsavedDetails};
}
