import {useQuery, useQueryClient} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphPresentationDetails} from '../interface';

function requestPresentationDetails(queryId: number, presentationId: number) {
    return apiClient.get<GraphPresentationDetails>({
        path: `queries-service/api/queries/${queryId}/graph-presentations/${presentationId}`
    });
}

const baseKey = 'request-presentation-details';

export function useGetPresentationDetails(
    queryId: number,
    presentationId: number,
    enabled: boolean
) {
    return useQuery(
        [baseKey, queryId, presentationId],
        () => requestPresentationDetails(queryId, presentationId),
        {
            enabled
        }
    );
}

export function useGetPresentationsDetailsCache() {
    const queryClient = useQueryClient();

    function setDetails(
        queryId: number,
        presentationId: number,
        details: GraphPresentationDetails
    ) {
        return queryClient.setQueryData<GraphPresentationDetails | undefined>(
            [baseKey, queryId, presentationId],
            () => details
        );
    }

    return {
        setDetails
    };
}
