import {Typography, DarkContext} from '@genestack/ui';
import React from 'react';

import {CommitData} from '../../../../providers/version';
import {getFormattedDate} from '../../../../utils/common';

import styles from './version-label.module.css';
import {VersionMenu} from './version-menu';
import {CopyToDraftBtnProps} from './version-menu-items';

interface Props {
    selectedVersion: CommitData;
    copyToDraftBtnProps?: CopyToDraftBtnProps;
}

export const VersionLabel = (props: Props) => {
    return (
        <DarkContext.Provider value>
            <div className={styles.label}>
                <Typography style={{marginRight: '8px'}}>
                    {getFormattedDate({date: props.selectedVersion.createdAt, showTime: true})}
                </Typography>
                <VersionMenu
                    selectedVersion={props.selectedVersion}
                    copyToDraftBtnProps={props.copyToDraftBtnProps}
                />
            </div>
        </DarkContext.Provider>
    );
};
