import React from 'react';
import {Button, QuestionIcon} from '@genestack/ui';
import {GraphLegendPopover} from './graph-legend-popover';

interface Props {
    disabled?: boolean;
}

export function GraphLegend(props: Props) {
    const [isLegendPopoverOpen, setIsLegendPopoverOpen] = React.useState(false);
    const toggleIsLegendPopoverOpen = () => {
        setIsLegendPopoverOpen((currentValue) => !currentValue);
    };

    return (
        <React.Fragment>
            <Button
                size="small"
                iconStart={<QuestionIcon />}
                onClick={toggleIsLegendPopoverOpen}
                // style={{display: 'flex'}}
                active={isLegendPopoverOpen}
                disabled={props.disabled}
            />

            <GraphLegendPopover
                isOpen={isLegendPopoverOpen}
                closePopover={toggleIsLegendPopoverOpen}
            />
        </React.Fragment>
    );
}
