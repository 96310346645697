import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {isQueryDraft} from '../../utils';

import {useDraftQueriesCache} from '../../queries-sidebar/hooks/use-draft-queries';
import {useFavoriteQueriesCache} from '../../queries-sidebar/hooks/use-favorite-queries';
import {useGetQueryInfoById} from './use-get-query-info-by-id';
import {useQueryInfoCache} from './use-query-info';
import {useRecentQueriesCache} from '../../queries-sidebar/hooks/use-recent-queries';
import {useSharedQueriesCache} from '../../queries-sidebar/hooks/use-shared-queries';

interface UpdateQueryPayload {
    id: number;
    name?: string;
    favorite?: boolean;
    globalVersionId?: number;
}

function updateQueryRequest({id: queryId, ...updatedData}: UpdateQueryPayload) {
    return apiClient.callApi({
        path: `queries-service/api/queries/${queryId}`,
        method: 'PATCH',
        parameters: updatedData
    });
}

/** Updates the query data across the entire cache */
export function useUpdateQueryInCache() {
    const recentQueriesCache = useRecentQueriesCache();
    const draftQueriesCache = useDraftQueriesCache();
    const queryInfoCache = useQueryInfoCache();

    return (updatedQuery: QueryInfo) => {
        isQueryDraft(updatedQuery)
            ? draftQueriesCache.updateExistingQuery(updatedQuery)
            : recentQueriesCache.updateExistingQuery(updatedQuery);

        queryInfoCache.addQuery(updatedQuery);
    };
}

export function useUpdateQueryMutation() {
    const getQueryInfoById = useGetQueryInfoById();
    const updateQueryInCache = useUpdateQueryInCache();

    const queryInfoCache = useQueryInfoCache();
    const recentQueriesCache = useRecentQueriesCache();
    const draftQueriesCache = useDraftQueriesCache();
    const sharedQueriesCache = useSharedQueriesCache();
    const favoriteQueriesCache = useFavoriteQueriesCache();

    return useMutation<QueryInfo, Error, UpdateQueryPayload, QueryInfo>(
        async (payload) => {
            const previousQueryInfo = await getQueryInfoById(payload.id);

            queryInfoCache.updateQuery(payload);
            recentQueriesCache.updateExistingQuery(payload);
            draftQueriesCache.updateExistingQuery(payload);
            sharedQueriesCache.updateExistingQuery(payload);
            favoriteQueriesCache.updateExistingQuery(payload);

            await updateQueryRequest(payload);

            return previousQueryInfo;
        },
        {
            onError: (err, payload, previousQueryInfo) => {
                if (previousQueryInfo) {
                    updateQueryInCache(previousQueryInfo);
                }
            }
        }
    );
}
