import {
    Drawer,
    Controls,
    Typography,
    Button,
    ControlsItem,
    Divider,
    CrossIcon,
    DarkContext
} from '@genestack/ui';
import * as React from 'react';

import styles from './notifications-drawer.module.css';

interface Props {
    open: boolean;
    onClose: () => void;
    onDismissAll: () => void;
    onClosed: () => void;
    children?: React.ReactNode;
}

/** Custom dark drawer with notifications */
export const NotificationsDrawer = (props: Props) => {
    return (
        <Drawer
            open={props.open}
            onClose={props.onClose}
            side="right"
            className={styles.root}
            onClosed={props.onClosed}
        >
            <DarkContext.Provider value>
                <div className={styles.content}>
                    <Controls justify="space-between">
                        <ControlsItem>
                            <Typography variant="title">Notifications</Typography>
                        </ControlsItem>
                        <ControlsItem>
                            <Controls>
                                <ControlsItem>
                                    <Button onClick={props.onDismissAll}>Dismiss all</Button>
                                </ControlsItem>
                                <ControlsItem>
                                    <Button
                                        icon={<CrossIcon />}
                                        ghost
                                        onClick={props.onClose}
                                        className={styles.closeButton}
                                        data-qa="close-notification-button"
                                    />
                                </ControlsItem>
                            </Controls>
                        </ControlsItem>
                    </Controls>
                </div>
                <Divider variant="transparent" />
                <div className={styles.content}>{props.children}</div>
            </DarkContext.Provider>
        </Drawer>
    );
};
