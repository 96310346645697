import React from 'react';
import {chain, ListIcon, StatisticsIcon, StartEndIcon} from '@genestack/ui';
import styles from './right-panel.module.css';
import {VerticalBar, VerticalBarItem} from '../../../../../components/vertical-bar';
import {QueryRightPanelTab} from '../../../../../components/page-layout/layout-typings';
import {useQueryLayoutProps} from '../../../hooks/use-query-layout-props';
import {useGlobalStateVariable} from '../../../../../providers/global-state';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'View results as list',
        value: QueryRightPanelTab.RESULTS_LIST,
        prepend: <ListIcon />
    },
    {
        tooltip: 'Open statistics',
        value: QueryRightPanelTab.STATISTICS,
        prepend: <StatisticsIcon />
    },
    {
        tooltip: 'Start and end nodes',
        value: QueryRightPanelTab.START_END_NODES,
        prepend: <StartEndIcon />
    }
];

interface Props {
    queryLayoutProps: ReturnType<typeof useQueryLayoutProps>;
}

export const RightPanelNavBar = (props: Props) => {
    const [selectedTab, setSelectedTab] = useGlobalStateVariable(
        'queries.rightPanel.selectedTab',
        QueryRightPanelTab.STATISTICS
    );

    return (
        <div className={styles.sidePanelNavBar}>
            <VerticalBar
                tabs={SIDEBAR_TABS}
                value={props.queryLayoutProps.isQueryRightPanelOpen ? selectedTab : undefined}
                onValueChange={chain(setSelectedTab, props.queryLayoutProps.openQueryRightPanel)}
                tabsProps={{
                    indicatorPlacement: 'left'
                }}
            />
        </div>
    );
};
