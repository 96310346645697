import {chain, Typography, MarginBox} from '@genestack/ui';
import React from 'react';
import {ErrorBoundary, ErrorBoundaryPropsWithRender, FallbackProps} from 'react-error-boundary';
import {useLocation} from 'react-router-dom';

import {CallApiUnauthorisedError} from '../../utils/api-client';
import {logError} from '../../utils/log-error';
import {UniversalBackdrop} from '../universal-backdrop';

interface Props extends Partial<ErrorBoundaryPropsWithRender> {
    variant?: 'text' | 'critical' | 'empty';
    message?: string;
    children?: React.ReactNode;
}

/** Component for catch errors */
export function LocalErrorBoundary({
    variant = 'critical',
    message = 'Something went wrong',
    resetKeys = [],
    onError,
    ...restProps
}: Props) {
    const {pathname} = useLocation();

    const handleFallbackRender = ({error}: FallbackProps) => {
        if (error instanceof CallApiUnauthorisedError) {
            return null;
        }

        if (variant === 'empty') {
            return null;
        }

        if (variant === 'critical') {
            return <UniversalBackdrop open />;
        }

        return (
            <Typography as={MarginBox} intent="alarm">
                {message}
            </Typography>
        );
    };

    return (
        <ErrorBoundary
            resetKeys={[pathname, ...resetKeys]}
            fallbackRender={handleFallbackRender}
            {...restProps}
            onError={chain(logError, onError)}
        />
    );
}
