import React from 'react';
import {Badge, MenuItem, StarIcon} from '@genestack/ui';
import {GraphPresentation} from '../results-graph/interface';
import {GraphPresentationPropsLoaded} from '../results-graph/use-graph-presentation-props';
import {usePresentationEditedProps} from '../results-graph/use-presentation-edited-props';

interface Props {
    queryId: number;
    presentation: GraphPresentation;
    presentationProps: GraphPresentationPropsLoaded;
}

export const unsavedChangesBadge = <Badge intent="warning">Unsaved</Badge>;

export function PresentationItem({presentation, presentationProps, queryId}: Props) {
    const {isPresentationEdited} = usePresentationEditedProps({queryId, presentation});

    return (
        <MenuItem
            prepend={presentation.isFavorite ? <StarIcon /> : undefined}
            active={presentation.id === presentationProps.selectedPresentation.id}
            value={presentation.id}
            onClick={() => presentationProps.selectPresentation(presentation.id)}
            append={isPresentationEdited ? unsavedChangesBadge : undefined}
        >
            {presentation.name}
        </MenuItem>
    );
}
