/** Insertion type */
export interface Insertion {
    prev: string;
    current: string | null;
}

/** Grouping all includes in text */
export const getAllIncludes = (value = '', text = ''): Insertion[] => {
    if (!value || !text || value === text) {
        return [];
    }

    const escapedValue = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const regExp = new RegExp(escapedValue, 'gi');
    const regExpIterator = text.matchAll(regExp);
    const lastSymbolIndex = text.length;
    const valueLength = value.length;

    const result = [];
    let firstIndex = 0;

    for (const item of regExpIterator) {
        const [current] = item;
        const starValueNdx = item.index || 0;
        const endValueNdx = starValueNdx + valueLength;

        const data = {
            prev: text.slice(firstIndex, starValueNdx),
            current
        };

        result.push(data);
        firstIndex = endValueNdx;
    }

    if (firstIndex < lastSymbolIndex) {
        result.push({
            prev: text.slice(firstIndex, lastSymbolIndex),
            current: null
        });
    }

    return result;
};
