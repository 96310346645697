import React, {useMemo} from 'react';

import {useAddToRecentsMutation} from '../hooks/requests/use-recents-query';
import {useDocumentSyntheticEvents} from '../../../../providers/document-synthetic-events';
import {useDocumentHistoryQuery} from '../../../../providers/version/document-history-query';

import {useViewableMetadataQuery} from '../hooks/requests/use-viewable-metadata-query';

interface Params {
    globalVersionId: number;
    documentId: string;
    revisionId?: string;
}

export type DocInfoError = 'network' | 'not-found';

export const useEditorModel = ({documentId, revisionId, globalVersionId}: Params) => {
    const documentInfoQuery = useViewableMetadataQuery({
        accession: documentId,
        globalVersionId,
        revisionId
    });

    const documentHistoryQuery = useDocumentHistoryQuery(documentId, {enabled: false});

    const useAddToRecentMutation = useAddToRecentsMutation();

    // todo: delet this
    const syntheticEvents = useDocumentSyntheticEvents();

    const currentRevision = useMemo(() => {
        if (revisionId) {
            return revisionId;
        }

        if (documentInfoQuery.data) {
            return documentInfoQuery.data.revision;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentInfoQuery.isSuccess, revisionId]);

    const revisionInfo = useMemo(() => {
        if (documentHistoryQuery.data && documentInfoQuery.data) {
            return documentHistoryQuery.data.find(({revision}) => revision === currentRevision);
        }
    }, [documentHistoryQuery.data, documentInfoQuery.data, currentRevision]);

    React.useEffect(() => {
        if (documentInfoQuery.data) {
            syntheticEvents.trigger({
                type: 'add-to-recent',
                payload: {documentInfo: documentInfoQuery.data}
            });

            useAddToRecentMutation.mutate(documentInfoQuery.data);

            if (documentInfoQuery.data.revision) {
                documentHistoryQuery.refetch();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentInfoQuery.data]);

    const documentInfoError = (function () {
        if (!documentInfoQuery.isError) {
            return undefined;
        }

        return (
            documentInfoQuery.error instanceof TypeError ? 'network' : 'not-found'
        ) as DocInfoError;
        // FIXME this is probably not the best way of detecting whether it's a network error
    })();

    return {
        isLoadingDocumentInfo: documentInfoQuery.isLoading,
        documentInfo: documentInfoQuery.data,
        documentInfoError,
        isLoadingLastRevision: documentHistoryQuery.isLoading,
        revisionInfo
    };
};
