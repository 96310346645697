import * as React from 'react';

import {useLogoutMutation} from '../../hooks/user/use-logout-mutation';
import {getLoginUrlWithLastLocation} from '../../utils/login';

/** UI item to perform logout */
export function LogoutItem(props: {children: React.ReactElement}) {
    const logoutMutation = useLogoutMutation();

    const handleSignOutClick = async () => {
        await logoutMutation.mutateAsync();

        window.location.href = getLoginUrlWithLastLocation();
    };

    return React.cloneElement(props.children, {
        onClick: handleSignOutClick,
        disabled: logoutMutation.isLoading
    });
}
