import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogBody,
    DialogHeader,
    DialogFooter,
    Field,
    Shake,
    Typography
} from '@genestack/ui';
import React from 'react';

import styles from './publish-dialog.module.css';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    documentMetadata: DocInfo;
    open: boolean;
    onSubmit: (commitMessage?: string) => void;
    onClose: () => void;
}

const PUBLISH_MESSAGE_MAX_LENGTH = 500;

/** Dialog to enter commit stub-message while publishing */
export const PublishDialog = (props: Props) => {
    const [commitMessage, setCommitMessage] = React.useState('');
    const [shaking, setShaking] = React.useState(false);

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > PUBLISH_MESSAGE_MAX_LENGTH) {
            setShaking(true);
            setCommitMessage(inputValue.slice(0, PUBLISH_MESSAGE_MAX_LENGTH));

            return;
        }

        setCommitMessage(inputValue);
    };

    const handleSubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        props.onSubmit(commitMessage);
        props.onClose();
    };

    const handleShakeComplete = () => {
        setShaking(false);
    };

    return (
        <Dialog as="form" open={props.open} onSubmit={handleSubmit} onClose={props.onClose}>
            <DialogHeader>
                <Typography variant="title">Publish document</Typography>
            </DialogHeader>
            <DialogBody>
                <Typography className={styles.label}>Describe what changed</Typography>
                <Shake in={shaking} onEntered={handleShakeComplete}>
                    <Field
                        autoFocus
                        component="textarea"
                        className={styles.textarea}
                        onChange={onInputChange}
                        value={commitMessage}
                    />
                </Shake>
            </DialogBody>
            <DialogFooter>
                <Controls>
                    <ControlsItem>
                        <Button intent="accent" type="submit">
                            Publish
                        </Button>
                    </ControlsItem>
                    <ControlsItem>
                        <Button onClick={props.onClose}>Cancel</Button>
                    </ControlsItem>
                </Controls>
            </DialogFooter>
        </Dialog>
    );
};
