import {Notification} from '@genestack/ui';
import * as React from 'react';
import {useQueryClient} from 'react-query';

import {showNotification} from '../../../components/notifications-center';
import {
    FormQueryResponse,
    isResultSession,
    isResultErrorResponse,
    SessionResponse,
    ErrorResponse
} from '../../../hooks/user/auth-form-query';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {useIdentityFormMutation} from '../../../hooks/user/use-identity-form-mutation';
import {JsonFormData} from '../../../utils/build-form-data';
import {OK_STATUS_CODE} from '../utils';

import {LoginForm} from './login-form';

/** Component for performing login */
export function LoginPerformer(props: FormQueryResponse) {
    const queryClient = useQueryClient();
    const currentUserQuery = useCurrentUserQuery();

    const loginMutation = useIdentityFormMutation<
        FormQueryResponse | SessionResponse | ErrorResponse
    >();

    const handleSubmit = async (jsonFormData: JsonFormData) => {
        try {
            const response = await loginMutation.mutateAsync({
                jsonFormData,
                action: props.ui.action
            });

            const result = response.data;

            if (isResultErrorResponse(result)) {
                return;
            }

            if (isResultSession(result) || (!result?.ui && response.status === OK_STATUS_CODE)) {
                queryClient.removeQueries('/self-service/login/browser');
                queryClient.removeQueries('/self-service/recovery/browser');
                await currentUserQuery.refetch();
            } else {
                queryClient.setQueryData(['/self-service/login/browser'], result);
            }
        } catch (error) {
            if (error instanceof Error) {
                showNotification(<Notification>{error.message}</Notification>);
            }
        }
    };

    return (
        <LoginForm
            onSubmit={handleSubmit}
            data={props}
            isLoading={loginMutation.isLoading}
            result={loginMutation.data?.data}
        />
    );
}
