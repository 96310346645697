import React from 'react';
import {CategoryTreeElement} from '../sidebar/search-results/categories/adapt-tree';

export const useCategoriesFilters = () => {
    const [appliedFilters, setAppliedFilters] = React.useState<string[]>([]);
    const handleChangeAppliedFilters = React.useCallback((toAdd: string[], toRemove: string[]) => {
        setAppliedFilters((currentFilters) =>
            [...currentFilters, ...toAdd].filter((f) => !toRemove.includes(f))
        );
    }, []);

    const [visualFilters, setVisualFilters] = React.useState<CategoryTreeElement[]>([]);
    const handleChangeVisualFilters = React.useCallback(
        (toAdd: CategoryTreeElement[], toRemove: CategoryTreeElement[]) => {
            setVisualFilters((currentFilters) =>
                [...currentFilters, ...toAdd].filter((f) =>
                    toRemove.every((filter) => f.id !== filter.id)
                )
            );
        },
        []
    );

    const handleResetFilters = React.useCallback(() => {
        setAppliedFilters([]);
        setVisualFilters([]);
    }, []);

    return {
        appliedFilters,
        visualFilters,
        onChangeAppliedFilters: handleChangeAppliedFilters,
        onChangeVisualFilters: handleChangeVisualFilters,
        onResetFilters: handleResetFilters
    };
};
