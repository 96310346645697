import {Typography, ScrollView, PageContent} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../../../components/preloader';
import {SidebarResultsHeader} from '../../../../../../components/sidebar-results-header';
import {
    useExploreViewSearchHistoryQuery,
    SearchHistoryRecordDto
} from '../../../hooks/requests/use-explore-view-search-history-query';
import {assert} from '../../../../../../utils/assert';
import {
    DOC_SHORTCUT_TYPE,
    CATEGORY_SHORTCUT_TYPE
} from '../../../../common/sidebar-search-field/hooks/use-suggestions';

import {SearchHistoryRecord, SearchHistoryRecordProps} from './search-history-record';
import {SearchData} from '../../../../common/sidebar-search-field/interface';

function convertSearchHistoryRecordToSearchData(record: SearchHistoryRecordDto): SearchData {
    if (record.stringSearch) {
        return {
            mode: 'doc',
            value: record.stringSearch.value
        };
    }

    if (record.referencedNode) {
        return {
            mode: 'node',
            type: record.referencedNode.type,
            name: record.referencedNode.name,
            documentAccession: record.referencedNode.accession,
            nodeId: record.referencedNode.nodeId
        };
    }

    assert(record.categoriesSearch, 'Invalid search history record');

    const [category] = record.categoriesSearch.categories;

    assert(category, 'Category not found');

    return {
        mode: 'category',
        type: 'category',
        name: category.name,
        categoryId: category.categoryId
    };
}

function getPrefixByRecord(record: SearchHistoryRecordDto) {
    if (record.referencedNode) {
        return record.referencedNode.type;
    }

    if (record.stringSearch) {
        return DOC_SHORTCUT_TYPE;
    }

    if (record.categoriesSearch) {
        return CATEGORY_SHORTCUT_TYPE;
    }

    return '';
}

function getValueByRecord(record: SearchHistoryRecordDto) {
    if (record.referencedNode) {
        return record.referencedNode.name;
    }

    if (record.stringSearch) {
        return record.stringSearch.value;
    }

    if (record.categoriesSearch && record.categoriesSearch.includeUncategorized) {
        return 'Uncategorized';
    }

    if (record.categoriesSearch?.categories.length) {
        const [category] = record.categoriesSearch.categories;

        return category.name;
    }

    return '';
}

interface Props {
    search: (searchData: SearchData) => void;
}

export function SearchHistory(props: Props) {
    const searchHistoryQuery = useExploreViewSearchHistoryQuery();

    const handleSearchHistoryRecord: SearchHistoryRecordProps['onClick'] = (payload) => {
        props.search(convertSearchHistoryRecordToSearchData(payload.data));
    };

    return (
        <>
            <SidebarResultsHeader>
                <Typography variant="section">Search history</Typography>
            </SidebarResultsHeader>

            <Preloader show={searchHistoryQuery.isLoading}>
                <ScrollView>
                    {searchHistoryQuery.data?.length === 0 && (
                        <PageContent noStartDivider noEndDivider>
                            No records found
                        </PageContent>
                    )}

                    {searchHistoryQuery.data?.map((record, index) => {
                        const prefix = getPrefixByRecord(record);
                        const value = getValueByRecord(record);

                        return (
                            <SearchHistoryRecord
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={`${prefix}_${value}_${index}`}
                                // FIXME Don't use index for key (Remove after https://github.com/JetBrains/BiologyLanguage/pull/1496 is in the master))
                                prefix={prefix}
                                value={value}
                                data={record}
                                onClick={handleSearchHistoryRecord}
                            />
                        );
                    })}
                </ScrollView>
            </Preloader>
        </>
    );
}
