import React from 'react';
import {usePersistSelectionOnNodesLoaded} from './use-persist-selection-on-nodes-loaded';

export function useGraphSelectionProps() {
    const [hoveredNodes, setHoveredNodes] = React.useState<number[]>([]);
    const [hoveredEdges, setHoveredEdges] = React.useState<number[]>([]);
    const [selectedNodes, setSelectedNodes] = React.useState<number[]>([]);

    const onNodesSelected = React.useCallback(
        (inputNodeIds: number[], isSelectMultiple: boolean) => {
            const toBeSelectedNodes = (function () {
                if (!isSelectMultiple || !selectedNodes.length) {
                    return [...inputNodeIds];
                }

                const werentPreviouslySelected = inputNodeIds.filter(
                    (nodeId) => !selectedNodes.includes(nodeId)
                );

                if (!werentPreviouslySelected.length) {
                    return selectedNodes.filter(
                        (selectedNodeId) => !inputNodeIds.includes(selectedNodeId)
                    );
                }

                return [...selectedNodes, ...werentPreviouslySelected];
            })();

            setSelectedNodes(toBeSelectedNodes);
        },
        [selectedNodes]
    );

    const clearNodeSelection = () => {
        setSelectedNodes([]);
    };

    const onNodesLoaded = usePersistSelectionOnNodesLoaded({
        selectedNodes,
        setSelectedNodes
    });

    return {
        selectedNodes,
        clearNodeSelection,
        onNodesSelected,
        setSelectedNodes,
        hoveredNodes,
        setHoveredNodes,
        hoveredEdges,
        setHoveredEdges,
        onNodesLoaded
    };
}
