import {ListItem, ListItemProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {RelativeLink} from '../relative-link';

import styles from './list-item-link.module.css';

interface Props extends ListItemProps {
    to: string;
}

/** Restyling for ListItem component from genestack-ui */
// eslint-disable-next-line react/display-name
export const ListItemLink = React.forwardRef<HTMLAnchorElement, Props>(function (props, ref) {
    const {className, to, ...listItemProps} = props;
    const illegalProps = {as: RelativeLink, to};

    return (
        <ListItem
            interactive
            ref={ref}
            className={classNames(styles.root, {[styles.active]: listItemProps.active}, className)}
            {...illegalProps}
            {...listItemProps}
        />
    );
});
