import {Notification, NotificationProps, Typography} from '@genestack/ui';
import React from 'react';

interface Props extends Omit<NotificationProps, 'countdown'> {
    isError?: boolean;
    errorMessage?: string;
}

const MESSAGES = {
    alarm: 'Not possible to restore document',
    success: 'Document restored'
};

/** Notification about non-successful restoring of the document */
export const RestoreStatusNotification = ({
    isError = false,
    errorMessage = '',
    ...notificationProps
}: Props) => {
    const status = isError ? 'alarm' : 'success';

    return (
        <Notification {...notificationProps} countdown={status === 'alarm' ? 'none' : 'active'}>
            <Typography intent={status}>{MESSAGES[status]}</Typography>
            {Boolean(errorMessage) && <Typography>{errorMessage}</Typography>}
        </Notification>
    );
};
