import {SearchHistoryRecordDto} from '../../explore-view/hooks/requests/use-explore-view-search-history-query';
import {getAllIncludes} from '../../../../utils/text';
import {
    CategorySuggestionDto,
    NodeSuggestionDto,
    InputValueType,
    SuggestionDto,
    SidebarCategorySuggestion,
    SidebarDocSuggestion,
    SidebarNodeSuggestion,
    SidebarNodeTypeSuggestion,
    SidebarSuggestion
} from './interface';

import {CATEGORY_SHORTCUT_TYPE, DOC_SHORTCUT_TYPE, TEXT_OPERATOR} from './hooks/use-suggestions';
import {SearchInputValue} from './hooks/use-search-sidebar-context';

export function parseInputValue(inputValue: string): SearchInputValue {
    if (inputValue[0] === TEXT_OPERATOR) {
        return {prefix: '', value: inputValue, type: 'searchNodeTypes'};
    }

    const dividerIndex = inputValue.indexOf(TEXT_OPERATOR);

    if (dividerIndex === -1) {
        return {
            prefix: '',
            value: inputValue,
            type: inputValue ? 'noPrefix' : 'emptyInput'
        };
    }

    const prefix = inputValue.slice(0, dividerIndex);
    const value = inputValue.slice(dividerIndex + 1, inputValue.length);
    const type =
        (prefix === DOC_SHORTCUT_TYPE && 'searchByDocName') ||
        (prefix === CATEGORY_SHORTCUT_TYPE && 'searchByCategory') ||
        'searchByNode';

    return {prefix, value, type};
}

/** Returns common suggestions with meta info (insertions) by search type */
export function getCommonSuggestions(
    searchType: InputValueType,
    suggestions: SuggestionDto[],
    value: string
): Array<SidebarNodeSuggestion | SidebarCategorySuggestion> {
    if (
        searchType === 'searchNodeTypes' ||
        searchType === 'searchByDocName' ||
        searchType === 'emptyInput'
    ) {
        return [];
    }

    return suggestions.map((suggestion) => {
        const {type, name, matchedSynonym} = suggestion as CategorySuggestionDto;

        if (suggestion.type === CATEGORY_SHORTCUT_TYPE) {
            const {categoryId} = suggestion as CategorySuggestionDto;

            return {
                mode: type,
                type,
                value: categoryId,
                label: name,
                matchedSynonym,
                insertions: {
                    type: getAllIncludes(value, type),
                    label: getAllIncludes(value, name),
                    matchedSynonym: getAllIncludes(value, matchedSynonym)
                }
            };
        }

        const {nodeId, documentAccession} = suggestion as NodeSuggestionDto;

        return {
            mode: 'node',
            type,
            value: nodeId,
            label: name,
            matchedSynonym,
            documentAccession,
            insertions: {
                type: getAllIncludes(value, type),
                label: getAllIncludes(value, name),
                matchedSynonym: getAllIncludes(value, matchedSynonym)
            }
        };
    });
}

/** Returns doc suggestions with meta info (insertions) by search type */
export function getDocSuggestions(
    searchType: InputValueType,
    value: string
): SidebarDocSuggestion[] {
    if (searchType !== 'noPrefix' && searchType !== 'searchNodeTypes') {
        return [];
    }

    const label = value.replace(TEXT_OPERATOR, '');

    return [
        {
            mode: 'doc',
            type: DOC_SHORTCUT_TYPE,
            value,
            label,
            placeholder: 'search by document name',
            insertions: {
                type: getAllIncludes(value, DOC_SHORTCUT_TYPE),
                label: getAllIncludes(value, label)
            }
        }
    ];
}

/** Returns node type suggestions with meta info (insertions) by search type */
export function getNodeTypeSuggestions(
    searchType: InputValueType,
    nodeTypes: string[],
    value = ''
): SidebarNodeTypeSuggestion[] {
    if (searchType !== 'noPrefix' && searchType !== 'searchNodeTypes') {
        return [];
    }

    const inputValue = value.split(TEXT_OPERATOR)[1]?.toLowerCase() ?? value.toLowerCase();

    return nodeTypes
        .filter((nodeType) => nodeType.toLowerCase().includes(inputValue))
        .map((nodeType) => ({
            mode: 'nodeType',
            type: nodeType,
            value: nodeType,
            label: '',
            insertions: {
                type: getAllIncludes(inputValue, nodeType),
                label: []
            }
        }));
}

export function getHistoryRecordSuggestion(record: SearchHistoryRecordDto): SidebarSuggestion {
    if (record.referencedNode) {
        return {
            mode: 'node',
            type: record.referencedNode.type,
            value: record.referencedNode.nodeId,
            label: record.referencedNode.name,
            documentAccession: record.referencedNode.accession,
            insertions: {
                type: [],
                label: []
            }
        };
    }

    if (record.categoriesSearch?.categories.length) {
        const [category] = record.categoriesSearch.categories;

        return {
            mode: 'category',
            type: 'category',
            value: category.categoryId,
            label: category.name,
            insertions: {
                type: [],
                label: []
            }
        };
    }

    return {
        mode: 'doc',
        type: DOC_SHORTCUT_TYPE,
        value: record.stringSearch?.value ?? '',
        label: record.stringSearch?.value ?? '',
        insertions: {
            type: [],
            label: []
        }
    };
}
