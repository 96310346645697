import React from 'react';
import {Switch, Route, Redirect, RouteComponentProps} from 'react-router-dom';

import {RelativePathProvider} from '../../../components/relative-link';
import {useLastLocationEffect} from '../../../hooks/use-last-location-effect';

import {EditView} from './edit-view';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';

interface Props extends RouteComponentProps {
    globalVersions: GlobalVersionData[] | null;
}

export const EditRoute = ({match, globalVersions}: Props) => {
    useLastLocationEffect({
        path: '/documents/edit',
        lsKey: 'documents.edit.lastLocation'
    });

    return (
        <RelativePathProvider path={match.path}>
            <Switch>
                <Route
                    exact
                    path={[
                        match.path,
                        `${match.path}/new/:type`,
                        `${match.path}/:documentId`,
                        `${match.path}/:documentId/@:nodeId`,
                        `${match.path}/:documentId/revisions/:revisionId?`,
                        `${match.path}/:documentId/revisions/:revisionId/@:nodeId`
                    ]}
                >
                    <EditView globalVersions={globalVersions} />
                </Route>

                <Route>
                    <Redirect to={match.path} />
                </Route>
            </Switch>
        </RelativePathProvider>
    );
};
