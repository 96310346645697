import {UseQueryOptions, useQuery, useQueryClient} from 'react-query';

import {CommitAction, CommitDto} from '../../../../providers/version';
import {apiClient} from '../../../../utils/api-client';

interface UseLastActionQueryProps {
    accession: string | undefined;
    action: CommitAction;
}

function getActionRequest(accession: string, action: CommitAction) {
    return apiClient
        .get<CommitDto[]>({
            path: `documents-revisions-service/api/viewer/documents/${accession}/history`,
            query: {limit: 1, action}
        })
        .then((result) => result[0]);
}

/** Request for the Last action in Document history */
export function useLastActionQuery(
    props: UseLastActionQueryProps,
    options?: Pick<UseQueryOptions<unknown, unknown, CommitDto>, 'enabled'>
) {
    return useQuery({
        ...options,
        queryKey: ['last-action', props.accession, props.action],
        queryFn: () => {
            if (props.accession) {
                return getActionRequest(props.accession, props.action);
            }
        }
    });
}

/** Query Cache for Document history */
export function useLastActionQueryCache() {
    const queryClient = useQueryClient();

    function invalidate(accession: string, action: CommitAction) {
        return queryClient.invalidateQueries(['last-action', accession, action], {
            refetchInactive: true
        });
    }

    return {invalidate};
}
