import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';

import {UserIdentity} from '../../../typings/user';
import {apiClient} from '../../utils/api-client';
import {stringSortComparator} from '../../utils/array';

function getUserListRequest() {
    return apiClient.get<UserIdentity[]>({path: 'users-service/api/users'});
}

/** Returns the list of users */
export function useUserListQuery(
    options?: Omit<UseQueryOptions<UserIdentity[]>, 'queryKey' | 'queryFn'>
) {
    return useQuery('user-list', getUserListRequest, options);
}

/** Query cache for user list */
export function useUserListQueryCache() {
    const queryClient = useQueryClient();
    const queryKey = 'user-list';

    function invalidate() {
        queryClient.invalidateQueries(queryKey, {refetchInactive: true});
    }

    function addUser(user: UserIdentity) {
        queryClient.setQueriesData<UserIdentity[] | undefined>(
            queryKey,
            (currentState) =>
                currentState && [user, ...currentState].sort(stringSortComparator('name'))
        );
    }

    function updateUser(updatedData: Partial<UserIdentity> & {id: string}) {
        queryClient.setQueriesData<UserIdentity[] | undefined>(
            queryKey,
            (currentState) =>
                currentState &&
                currentState.map<UserIdentity>((user) =>
                    user.id === updatedData.id ? {...user, ...updatedData} : user
                )
        );
    }

    return {invalidate, addUser, updateUser};
}
