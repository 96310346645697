import {Tabs, TabsProps, Tab, CloneProps, TooltipHandler, Tooltip, chain} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './vertical-bar.module.css';
import {ActivityBarTabs} from '../page-layout/layout-typings';

type TabProps = React.ComponentProps<typeof Tab>;

export interface VerticalBarItem extends TabProps {
    tooltip: string;
    value: ActivityBarTabs;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    tabs: VerticalBarItem[];
    value?: ActivityBarTabs;
    onValueChange: TabsProps['onValueChange'];
    tabsProps?: Partial<TabsProps>;
}

export function VerticalBar({tabs, value, onValueChange, tabsProps = {}, ...restProps}: Props) {
    return (
        <div {...restProps} className={styles.root}>
            <Tabs
                {...tabsProps}
                value={value}
                orientation="vertical"
                onValueChange={chain(onValueChange, tabsProps?.onValueChange)}
                className={classNames(styles.tabs, tabsProps.className)}
            >
                {tabs.map(({tooltip, value: tabValue, ...restTabProps}) => (
                    <CloneProps key={tabValue} value={tabValue} {...restTabProps}>
                        {(tabProps: TabProps) => (
                            <TooltipHandler
                                disableFocusListener
                                tooltip={
                                    <Tooltip placement="right" className={styles.tooltip}>
                                        {tooltip}
                                    </Tooltip>
                                }
                            >
                                <Tab
                                    data-qa={`activitybar-${tabValue}`}
                                    {...tabProps}
                                    className={classNames(styles.tab, tabProps?.className)}
                                />
                            </TooltipHandler>
                        )}
                    </CloneProps>
                ))}
            </Tabs>
        </div>
    );
}
