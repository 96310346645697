import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

/** Entity of Imported Ontology */
export interface ImportedOntology {
    name: string;
    lastImported: number;
    totalElements: number;
}

/** useQuery hook for getting Imported Ontology */
export const useOntologyQuery = (props: {
    documentId?: number;
    accession: string;
    viewer: boolean;
}) => {
    const path = props.viewer
        ? `documents-revisions-service/api/viewer/documents/ontologies?document-id=${props.documentId}`
        : `documents-revisions-service/api/viewer/documents/ontologies?accession=${props.accession}`;

    return useQuery(['getOntology', props.accession, props.documentId], async () =>
        apiClient.get<ImportedOntology>({
            path
        })
    );
};

/** Element of Ontology Tree */
export interface OntologyTreeItem {
    accession: string;
    elementId: string;
    name: string;
    children: OntologyTreeItem[];
}

interface OntologyElement {
    name: string;
    content?: string;
    tree: {
        roots: OntologyTreeItem[];
    };
}

/** useQuery hook for getting Imported Ontology Element */
export const useOntologyElementQuery = (props: {
    accession: string;
    documentId?: number;
    versionId?: number | null;
    nodeId: string;
    viewer: boolean;
}) => {
    const path = props.viewer
        ? `documents-revisions-service/api/viewer/documents/ontologies/elements/${props.nodeId}?document-id=${props.documentId}&global-version=${props.versionId}`
        : `documents-revisions-service/api/viewer/documents/ontologies/elements/${props.nodeId}?accession=${props.accession}`;

    return useQuery(
        ['getOntologyElement', props.accession, props.nodeId, props.documentId, props.versionId],
        () => apiClient.get<OntologyElement>({path})
    );
};
