// tslint:disable: max-file-line-count
import {Button, Paper, Fade, FIXED_BLOCKS_CLASS_NAME, chain} from '@genestack/ui';
import anime from 'animejs';
import classNames from 'classnames';
import * as React from 'react';
import {throttle} from 'throttle-debounce';

import styles from './notifications-center.module.css';
import {NotificationsDrawer} from './notifications-drawer';
import {NotificationsStack} from './notifications-stack';
import {NotificationItem, notificationsStore, NotificationsStore} from './notifications-store';

const DEFAULT_MAX_NOTIFICATIONS_COUNT = 3;
const NOTIFICATION_TRANSITION_DURATION = 500;
const THROTTLE_WINDOW_MOUSE_MOVE_DURATION = 100;

type TargetProps = React.HTMLAttributes<HTMLDivElement>;

const shake = (element: HTMLElement) => {
    anime({
        targets: element,
        // tslint:disable-next-line no-magic-numbers
        translateX: [-5, -5, 5, 5, -1, 1, 0],
        easing: 'linear',
        duration: 300
    });
};

/** NotificationsCenter public properties. */
export interface Props extends TargetProps {
    /** Maximum notifications count that will be shown at the time. */
    maxNotificationsCount?: number;
    /**
     * Pass `true` for valid notifications positioning when the application
     * does not have the header.
     */
    noHeader?: boolean;
    /** Custom `NotificationsStore` */
    store?: NotificationsStore;
}

interface State {
    /** Notifications that has been passed to center. */
    notifications: NotificationItem[];
    /** Notifications that are visible at the moment. */
    shownNotifications: NotificationItem[];
    /**
     * Is used to stop countdowns for all notifications
     * when user hovers to any notification.
     */
    hovered: boolean;
    /**
     * All notifications are displayed in the drawer
     * when user presses `Show More` button.
     */
    showAllNotifications: boolean;
    drawerExited: boolean;
}

/**
 * This component shows notifications that are passed to `showNotification` function.
 * Must be mounted once.
 */
export class NotificationsCenter extends React.PureComponent<Props, State> {
    private unsubscribe: (() => void) | null = null;
    private showMoreRef = React.createRef<HTMLDivElement>();
    private rootRef = React.createRef<HTMLDivElement>();

    /* eslint-disable-next-line react/state-in-constructor */
    public state: State = {
        notifications: [],
        shownNotifications: [],
        hovered: false,
        showAllNotifications: false,
        drawerExited: true
    };

    public componentDidMount() {
        const {store = notificationsStore} = this.props;
        this.unsubscribe = store.subscribe(this.handleNotificationsChange);
    }

    public componentDidUpdate(props: Props, state: State) {
        const {maxNotificationsCount = DEFAULT_MAX_NOTIFICATIONS_COUNT} = this.props;

        if (
            this.state.notifications.length > maxNotificationsCount + 1 &&
            this.state.notifications.length > state.notifications.length &&
            this.showMoreRef.current
        ) {
            shake(this.showMoreRef.current);
        }

        if (
            state.notifications !== this.state.notifications ||
            state.shownNotifications !== this.state.shownNotifications
        ) {
            this.handleUpdateShownNotificationsThrottled();
        }
    }

    public componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }

        this.handleUpdateShownNotificationsThrottled.cancel();
        this.handleWindowMouseMoveThrottled.cancel();

        window.removeEventListener('mousemove', this.handleWindowMouseMoveThrottled);
    }

    /**
     * Moves notifications one by one to `shownNotifications` state with throttling
     * to prevent user confusing while notifications hiding and showing.
     */
    private handleUpdateShownNotificationsThrottled = throttle(
        NOTIFICATION_TRANSITION_DURATION,
        () => {
            this.setState((state) => {
                const {maxNotificationsCount = DEFAULT_MAX_NOTIFICATIONS_COUNT} = this.props;

                let {shownNotifications: shownItems} = state;

                const itemToRemove = shownItems.find(
                    (item) => state.notifications.indexOf(item) === -1
                );

                shownItems = [...shownItems.filter((item) => item !== itemToRemove)];

                let itemToAdd: NotificationItem | undefined;

                if (shownItems.length < maxNotificationsCount) {
                    itemToAdd = state.notifications.find((item) => shownItems.indexOf(item) === -1);
                    if (itemToAdd) {
                        shownItems = [...shownItems, itemToAdd];
                    }
                }

                if (itemToRemove || itemToAdd) {
                    return {...state, shownNotifications: shownItems};
                }
            });
        }
    );

    private handleWindowMouseMoveThrottled = throttle(
        THROTTLE_WINDOW_MOUSE_MOVE_DURATION,
        (event: MouseEvent) => {
            if (
                this.rootRef.current &&
                event.target instanceof HTMLElement &&
                this.rootRef.current.contains(event.target)
            ) {
                this.setState({hovered: false});
                window.removeEventListener('mousemove', this.handleWindowMouseMoveThrottled);
            }
        }
    );

    private handleNotificationsChange = (newItem: NotificationItem) => {
        this.setState((state) => {
            const existsItem = state.notifications.find((i) => i.key === newItem.key);

            if (existsItem) {
                existsItem.element = newItem.element;

                return {...state, notifications: [...state.notifications]};
            }

            return {
                ...state,
                notifications: [...state.notifications, newItem]
            };
        });
    };

    private handleMouseOver = () => {
        this.setState({hovered: true});
        window.removeEventListener('mousemove', this.handleWindowMouseMoveThrottled);
        window.addEventListener('mousemove', this.handleWindowMouseMoveThrottled);
    };

    private handleDismissAllClick = () => {
        this.setState({
            showAllNotifications: false,
            notifications: [],
            shownNotifications: []
        });
    };

    private handleShowAllNotificationsClick = () => {
        this.setState({
            showAllNotifications: true,
            drawerExited: false
        });
    };

    private handleDrawerClose = () => {
        this.setState({
            showAllNotifications: false
        });
    };

    private handleDrawerClosed = () => {
        this.setState({drawerExited: true});
    };

    private createCloseHandler(item: NotificationItem) {
        return () => {
            this.setState((state) => ({
                notifications: state.notifications.filter((i) => i !== item)
            }));
        };
    }

    private renderShownNotifications() {
        const {shownNotifications, hovered, showAllNotifications} = this.state;
        const countdownStopped = hovered || showAllNotifications;

        return [...shownNotifications].reverse().map((item) => {
            const elementProps = item.element.props;

            const onClose = chain(elementProps.onClose, this.createCloseHandler(item));

            const countdown =
                elementProps.countdown !== 'none' && countdownStopped
                    ? 'stopped'
                    : elementProps.countdown;

            return (
                <div key={item.key} className={styles.item}>
                    {React.cloneElement(item.element, {
                        onClose,
                        countdown
                    })}
                </div>
            );
        });
    }

    public render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {maxNotificationsCount, store, noHeader, className, ...rest} = this.props;
        const {showAllNotifications, drawerExited} = this.state;

        const notifications =
            !showAllNotifications && drawerExited ? (
                <Fade in appear>
                    <div>
                        <NotificationsStack showMoreElement={this.renderShowMoreButton()}>
                            {this.renderShownNotifications()}
                        </NotificationsStack>
                    </div>
                </Fade>
            ) : null;

        const drawer = showAllNotifications || !drawerExited ? this.renderDrawer() : null;

        return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <div
                {...rest}
                ref={this.rootRef}
                className={classNames(styles.root, className, FIXED_BLOCKS_CLASS_NAME, {
                    [styles.noHeader]: noHeader
                })}
                onMouseOver={this.handleMouseOver}
            >
                {notifications}
                {drawer}
            </div>
        );
    }

    private renderDrawer() {
        const {notifications, showAllNotifications} = this.state;

        return (
            <NotificationsDrawer
                open={showAllNotifications}
                onClose={this.handleDrawerClose}
                onDismissAll={this.handleDismissAllClick}
                onClosed={this.handleDrawerClosed}
            >
                <NotificationsStack>
                    {[...notifications].reverse().map((item) => {
                        const elementProps = item.element.props;

                        const onClose = chain(elementProps.onClose, this.createCloseHandler(item));

                        return (
                            <div key={item.key} className={styles.item}>
                                {React.cloneElement(item.element, {
                                    onClose,
                                    countdown: 'none'
                                })}
                            </div>
                        );
                    })}
                </NotificationsStack>
            </NotificationsDrawer>
        );
    }

    private renderShowMoreButton() {
        const {maxNotificationsCount = DEFAULT_MAX_NOTIFICATIONS_COUNT} = this.props;
        const {notifications} = this.state;
        const hiddenCount = Math.max(0, notifications.length - maxNotificationsCount);

        return hiddenCount > 0 ? (
            <div className={styles.showMoreContainer}>
                <Paper className={styles.showMoreButton} rootRef={this.showMoreRef}>
                    <Button ghost inverted onClick={this.handleShowAllNotificationsClick}>
                        Show more ({hiddenCount})
                    </Button>
                </Paper>
            </div>
        ) : null;
    }
}
