import {BulletIcon, ArrowBottomThinIcon, Button} from '@genestack/ui';
import anime from 'animejs';
import classNames from 'classnames';
import React from 'react';

import {RelativeLink} from '../../../../../../components/relative-link';
import {OntologyTreeItem} from '../../ontology-query';

import {TreeItemLabel} from './tree-item-label';
import styles from './tree-item.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isInteractive: boolean;
    data: OntologyTreeItem;
    goToItem: () => void;
}

/** Groups document info and find usages */
export function TreeItem(props: Props) {
    const firstMountRef = React.useRef(true);
    const groupWrapperRef = React.useRef<HTMLDivElement>(null);
    const groupRef = React.useRef<HTMLDivElement>(null);

    const isLastChild = !props.children;
    const Icon = isLastChild ? BulletIcon : ArrowBottomThinIcon;

    React.useEffect(() => {
        if (firstMountRef.current) {
            firstMountRef.current = false;

            return undefined;
        }

        const groupWrapperElement = groupWrapperRef.current;
        const groupElement = groupRef.current;

        if (groupElement && groupWrapperElement) {
            const {height} = groupElement.getBoundingClientRect();

            anime({
                targets: groupWrapperElement,
                height: [0, height],
                easing: 'cubicBezier(.4, 0, .2, 1)',
                duration: 150,
                complete: () => {
                    groupWrapperElement.style.height = '';
                }
            });
        }
    }, []);

    return (
        <div className={styles.root}>
            <div className={classNames(styles.item, props.className)}>
                <div className={styles.itemTitle}>
                    <Icon />

                    <TreeItemLabel
                        isInteractive={props.isInteractive}
                        item={props.data}
                        goToItem={props.goToItem}
                    />
                </div>

                {!props.isInteractive && (
                    <div className={styles.aside}>
                        <Button
                            size="tiny"
                            component={RelativeLink}
                            to={`/${props.data.accession}/@${encodeURIComponent(
                                props.data.elementId
                            )}`}
                        >
                            Open in MetaOntology
                        </Button>
                    </div>
                )}
            </div>

            {!isLastChild ? (
                <div ref={groupWrapperRef} style={{overflow: 'hidden'}}>
                    <div ref={groupRef}>{props.children}</div>
                </div>
            ) : null}
        </div>
    );
}
