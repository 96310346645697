import {useMutation} from 'react-query';

import {useDocumentHistoryQueryCache} from '../../../../providers/version/document-history-query';
import {apiClient} from '../../../../utils/api-client';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useDeletedDocumentsQueryCache} from './use-deleted-documents-query';
import {useDocMetadataQueryCache} from './use-doc-metadata-query';
import {useLastActionQueryCache} from './use-last-action-query';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';

function restoreDocumentRequest(accession: string) {
    return apiClient.post({
        path: `editor-documents-service/api/documents/${accession}/trash-bin/restore`
    });
}

/** Hook restores the document in edit mode */
export function useRestoreMutation() {
    const lastActionQueryCache = useLastActionQueryCache();
    const documentHistoryQueryCache = useDocumentHistoryQueryCache();
    const searchDocsCache = useSearchDocsCache();
    const deletedQueryCache = useDeletedDocumentsQueryCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const docMetadataQueryCache = useDocMetadataQueryCache();

    return useMutation(restoreDocumentRequest, {
        onSuccess: async (_, accession) => {
            lastActionQueryCache.invalidate(accession, 'PUBLISH');
            documentHistoryQueryCache.invalidate(accession);

            deletedQueryCache.deleteDocument(accession);
            searchDocsCache.updateDocument({accession, status: 'PUBLISHED'});

            ontologyDocsQueryCache.invalidate('BTB');

            const nextMetaData = await docMetadataQueryCache.get(accession);

            if (nextMetaData) {
                docMetadataQueryCache.set({...nextMetaData, status: 'PUBLISHED'});
            }
        }
    });
}
