import {BookmarkBorderedIcon, SearchIcon, TimeReverseIcon} from '@genestack/ui';
import React from 'react';

import {VerticalBar, VerticalBarItem} from '../../../../components/vertical-bar';

import styles from './activity-bar.module.css';
import {ViewModeTab} from '../../../../components/page-layout/layout-typings';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Search',
        value: ViewModeTab.SEARCH,
        prepend: <SearchIcon />
    },
    {
        tooltip: 'Show recent document',
        value: ViewModeTab.RECENT,
        prepend: <TimeReverseIcon />
    },
    {
        tooltip: 'Show my bookmarks',
        value: ViewModeTab.FAVORITES,
        prepend: <BookmarkBorderedIcon />
    }
];

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
}

/** A component displaying vertical navigation stack by sidebar */
export function ViewModeActivityBar({tabSettings}: Props) {
    return (
        <div className={styles.root}>
            <VerticalBar
                tabs={SIDEBAR_TABS}
                value={tabSettings.activityBarTab}
                onValueChange={tabSettings.setActivityBarTab}
            />
        </div>
    );
}
