import {useMutation} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

interface GetNodeTypeMutationPayload {
    accession: string;
    nodeId: string | null;
    globalVersionId?: number;
}

function getNodeTypeRequest(accession: string, nodeId: string | null, globalVersionId?: number) {
    return apiClient
        .get<{nodeId: string; nodeName: string; nodeType: string}>({
            path: `autocomplete-service/api/autocomplete/documents/${accession}/nodes/${nodeId}`,
            query: {'global-version': globalVersionId}
        })
        .then((result) => result.nodeType);
}

/** Returns node type for imported ontologies */
export function useGetNodeTypeMutation() {
    return useMutation<string, Error, GetNodeTypeMutationPayload>((payload) =>
        getNodeTypeRequest(payload.accession, payload.nodeId, payload.globalVersionId)
    );
}
