import {Typography, Controls, ControlsItem} from '@genestack/ui';
import * as React from 'react';

import {ShortcutsLabel} from './shortcut-label';
import styles from './shortcuts-section.module.css';

/** Shortcut data type */
export interface Shortcut {
    label: string;
    keys: string[];
}

interface Props {
    title?: string;
    shortcuts: Shortcut[];
}

/** List of shortcuts */
export function ShortcutsSection(props: Props) {
    return (
        <div className={styles.root}>
            {props.title && (
                <Typography className={styles.title} variant="section">
                    {props.title}
                </Typography>
            )}

            {props.shortcuts.map((item) => (
                <Controls key={item.label} className={styles.item}>
                    <ControlsItem className={styles.shortcut}>
                        <ShortcutsLabel keys={item.keys} justify="end" />
                    </ControlsItem>
                    <ControlsItem>
                        <Typography>{item.label}</Typography>
                    </ControlsItem>
                </Controls>
            ))}
        </div>
    );
}
