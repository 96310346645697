import React from 'react';

import {UNCATEGORIZED_CATEGORY_ID} from '../../../../../../utils/get-categories-search';
import {useOntologyDocsQuery} from '../../../hooks/use-ontology-docs-query';
import {CategorySubTree, CategoryTreeProps} from '../category-tree';
import {DocumentGroup} from '../document-group';

import styles from './btb-ontology.module.css';

export function BtbDocuments({
    branchOpenState,
    changeIsCategoryOpenState,
    categoryTreePath,
    ...restProps
}: CategoryTreeProps) {
    const docsQuery = useOntologyDocsQuery({
        docType: restProps.docType,
        // We use pageSize equal 0 to get the counter for the closed group of documents.
        pageSize: 0
    });

    return (
        <CategorySubTree
            {...restProps}
            count={docsQuery.data?.pages[0]?.total}
            branchOpenState={branchOpenState}
            changeIsCategoryOpenState={changeIsCategoryOpenState}
            categoryTreePath={categoryTreePath}
        >
            <DocumentGroup
                docType={restProps.docType}
                branchOpenState={branchOpenState?.[UNCATEGORIZED_CATEGORY_ID]}
                changeIsCategoryOpenState={changeIsCategoryOpenState}
                titleProps={{className: styles.italicTitle}}
                countProps={{className: styles.count}}
                categoryTreePath={[restProps.docType, UNCATEGORIZED_CATEGORY_ID]}
            />
        </CategorySubTree>
    );
}
