import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {MergedFactPanelEntry, SimpleFactPanelEntry} from '../../../../components/graph/interface';

function getNodesContentByIds(queryId: number, maxPathLength: number, nodes: number[]) {
    return apiClient.post<Array<SimpleFactPanelEntry | MergedFactPanelEntry>>({
        path: `queries-service/api/queries/${queryId}/connection-graph/node-structures`,
        query: {
            'path-length-limit': maxPathLength
        },
        parameters: nodes
    });
}

export function useGetNodeContentQuery(
    queryId: number,
    maxPathLength: number | undefined,
    nodes: number[]
) {
    return useQuery({
        queryKey: ['use-node-content-structure', queryId, maxPathLength, ...nodes],
        queryFn: () => getNodesContentByIds(queryId, maxPathLength!, nodes),
        enabled: !!nodes.length && maxPathLength !== undefined
    });
}
