import {CellDirection} from './interface';
import {GridCoordinates} from '../interface';

const directionsDiffCoordinates: {[key in CellDirection]: GridCoordinates} = {
    top: {x: 0, y: -1},
    right: {x: 1, y: 0},
    bottom: {x: 0, y: +1},
    left: {x: -1, y: 0},
    topLeft: {x: -1, y: -1},
    topRight: {x: 1, y: -1},
    bottomRight: {x: 1, y: 1},
    bottomLeft: {x: -1, y: 1}
};

/** Takes two neighboring nodes and returns the
 * direction in which you have to go from first to get to the second */
export function getDirectionFromTwoNeighborCoordinates(
    sourceCoordinates: GridCoordinates,
    destinationCoordinates: GridCoordinates
) {
    const x = sourceCoordinates.x - destinationCoordinates.x;
    const y = sourceCoordinates.y - destinationCoordinates.y;

    const result = Object.keys(directionsDiffCoordinates).find((direction) => {
        return (
            directionsDiffCoordinates[direction as CellDirection].x === x &&
            directionsDiffCoordinates[direction as CellDirection].y === y
        );
    });

    if (!result) {
        throw new Error(
            'getDirectionFromTwoNeighborCoordinates function error: provided cells are not neighbors'
        );
    }

    return result as CellDirection;
}

/** Get straight distance between two points.
 * Can work with grid coordinates, as well as svg coordinates */
export function getDistanceBetweenGraphPoints(
    cellCoordinates1: GridCoordinates,
    cellCoordinates2: GridCoordinates
) {
    const distanceOnX = cellCoordinates2.x - cellCoordinates1.x;
    const distanceOnY = cellCoordinates2.y - cellCoordinates1.y;

    return Math.sqrt(distanceOnX ** 2 + distanceOnY ** 2);
}
