import * as webeditor from '../../../../webeditor/biokb-editor-adapters';
import {SearchData} from '../common/sidebar-search-field/interface';
import {SearchHistoryRecordDto} from './hooks/requests/use-explore-view-search-history-query';

function getWholeNodeText(accession: string, usage: webeditor.UsageNode) {
    return webeditor
        .getHtmlForUsageJSON(accession, usage)
        .then((e) => e.innerText.replace('\n', ''));
}

export const parseNodes = (
    accession: string,
    usages: webeditor.UsageNode[]
): Promise<DocumentNode[]> => {
    return Promise.all(
        usages.map(async (usage) => {
            return {
                id: webeditor.getNodeIdOfResolvedNode(usage.node),
                label: await getWholeNodeText(accession, usage)
            };
        })
    );
};

export function convertSearchDataToSearchHistoryRecord(
    searchData: SearchData
): SearchHistoryRecordDto | null {
    if (searchData.mode === 'category') {
        return {
            stringSearch: null,
            referencedNode: null,
            categoriesSearch: {
                categories: [{name: searchData.name, categoryId: searchData.categoryId}],
                includeUncategorized: false
            }
        };
    }

    if (searchData.mode === 'node' && searchData.documentAccession && searchData.nodeId) {
        return {
            stringSearch: null,
            categoriesSearch: null,
            referencedNode: {
                accession: searchData.documentAccession,
                nodeId: searchData.nodeId,
                type: searchData.type,
                name: searchData.name
            }
        };
    }

    return null;
}
