import React from 'react';

import {useDocRevisionsContext, CommitDto} from '../../../../../providers/version';

import {LastActionLabel} from './last-action-label';

interface Props {
    lastAction?: CommitDto;
}

/** Status of the last action with the document */
export function LastActionStatus({lastAction}: Props) {
    const {selectedRevision} = useDocRevisionsContext();

    const version = selectedRevision || lastAction;

    if (version) {
        return <LastActionLabel {...version} />;
    }

    return null;
}
