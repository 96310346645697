import {List, PreloaderProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {Preloader} from './preloader';
import styles from './preloader.module.css';

const DSL_PRELOADER_COUNT = 5;

function PreloaderItem(props: React.HTMLAttributes<HTMLLIElement>) {
    return <li {...props} className={classNames(styles.preloaderItem, props.className)} />;
}

/** DSL preloader */
export function DSLPreloader(props: PreloaderProps) {
    return (
        <Preloader count={DSL_PRELOADER_COUNT} wrapAll={List} wrapEach={PreloaderItem} {...props} />
    );
}
