import React from 'react';

import {GridCoordinates} from '../interface';
import {QueryGraph} from './query-graph';
import {CalculateEdgePathsWorkerInitData} from '../../../workers/types';
import {getStaticFileUrl} from '../../../utils/get-static-file-url';

interface EdgesGridPathInfo {
    queryGraph: QueryGraph;
    paths: {[key: string]: GridCoordinates[]};
}

export function useCalculateEdgePaths(
    queryGraph: QueryGraph,
    onCalculated?: (calculationTime: number) => void
) {
    const [edgesGridPathsInfo, setEdgesGridPathsInfo] = React.useState<EdgesGridPathInfo>();
    const [isBrowserUnsupported, setIsBrowserUnsupported] = React.useState<boolean>();

    const workerRef = React.useRef<Worker>();

    React.useEffect(() => {
        if (!window.Worker) {
            setIsBrowserUnsupported(true);

            return undefined;
        }

        workerRef.current = new window.Worker(
            getStaticFileUrl('js/calculate-edge-paths-worker.js')
        );

        const edgesSortedByLength = queryGraph.edgesSortedByLength(queryGraph.allEdges);

        const calculationStartedTimestamp = new Date().valueOf();
        workerRef.current.postMessage({
            edges: edgesSortedByLength,
            gridMap: queryGraph.getGridMap({}),
            nodesGridCoordinates: queryGraph.nodesGridCoordinates
        } as CalculateEdgePathsWorkerInitData);

        workerRef.current.onmessage = (event) => {
            setEdgesGridPathsInfo({paths: event.data, queryGraph});
            workerRef.current?.terminate();
            workerRef.current = undefined;
            if (onCalculated) {
                onCalculated(new Date().valueOf() - calculationStartedTimestamp);
            }
        };

        return () => {
            workerRef.current?.terminate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryGraph]);

    return {
        edgesGridPaths:
            queryGraph === edgesGridPathsInfo?.queryGraph ? edgesGridPathsInfo.paths : undefined,
        isBrowserUnsupported
    };
}
