import {Input, InputProps, Shake, Tooltip} from '@genestack/ui';
import * as React from 'react';

interface Props extends InputProps {
    value: string;
    maxLength: number;
    onValueChange: (value: string) => void;
    tooltipMessage?: string;
    showTooltipDuration?: number;
}

const SHOW_TOOLTIP_DURATION = 5000;

/** Input which validates its value with maximum length */
export const MaxLengthInput = ({
    value,
    onValueChange,
    maxLength,
    tooltipMessage,
    showTooltipDuration,
    ...rest
}: Props) => {
    const [valueTooLong, setValueTooLong] = React.useState(false);
    const [shaking, setShaking] = React.useState(false);

    const timerId = React.useRef<number | null>(null);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleValueChange = (inputValue: string) => {
        if (inputValue.length > maxLength) {
            setShaking(true);
            setValueTooLong(true);
            if (timerId.current) {
                clearTimeout(timerId.current);
            }

            timerId.current = window.setTimeout(() => {
                setValueTooLong(false);
            }, showTooltipDuration || SHOW_TOOLTIP_DURATION);

            onValueChange(inputValue.slice(0, maxLength));

            return;
        }

        if (valueTooLong) {
            setValueTooLong(false);
        }

        onValueChange(inputValue);
    };

    const handleShakeComplete = () => {
        setShaking(false);
    };

    return (
        <>
            <Shake in={shaking} onEntered={handleShakeComplete}>
                <Input
                    {...rest}
                    onValueChange={handleValueChange}
                    value={value}
                    inputRef={inputRef}
                />
            </Shake>
            <Tooltip
                referenceElement={inputRef.current}
                open={Boolean(tooltipMessage) && valueTooLong}
                portalContainer={document.body}
                modifiers={[
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true
                        }
                    }
                ]}
                placement="right"
            >
                {tooltipMessage}
            </Tooltip>
        </>
    );
};
