import React from 'react';

import {edgePropsByType} from '../biokb-graph/constants';
import {GraphEntityStatus} from '../cytoscape-graph/interface';
import {RelationPresentation} from '../interface';

interface Props {
    edgePropsByStatus: (typeof edgePropsByType)[RelationPresentation.ASSERTED];
    isDashed?: boolean;
}

const size = 15;

/** The example for arrow in the graph legend */
export function LegendEdgeExample(props: Props) {
    const {color, markerUrl} = props.edgePropsByStatus[GraphEntityStatus.default];

    return (
        <svg width={size} height={size}>
            <polyline
                stroke={color}
                points="15,0 0,15"
                strokeWidth={2}
                markerEnd={`url(#${markerUrl})`}
                strokeDasharray={props.isDashed ? '4 4' : undefined}
            />
        </svg>
    );
}
