import usePrevious from 'react-use/lib/usePrevious';
import * as React from 'react';
import {GraphEdge} from '../interface';

function getIsEncounteredMap(array: number[]): {[key: number]: true} {
    const isEncounteredMap: {[key: number]: true} = {};

    if (!array) {
        return isEncounteredMap;
    }

    array.forEach((nodeId) => {
        isEncounteredMap[nodeId] = true;
    });

    return isEncounteredMap;
}

interface Props {
    selectedNodes: number[];
    hoveredNodes: number[];
    edges: GraphEdge[];
}

// probably there's a better way to write this, but I've put enough time into this task already
// so I'm sorry
export function useGraphStateDiff({selectedNodes, hoveredNodes, edges}: Props) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedNodesPrevious = usePrevious(selectedNodes) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hoveredNodesPrevious = usePrevious(hoveredNodes) || [];

    const newSelectedNodes = React.useMemo(() => {
        const selectedNodesPreviousMap = getIsEncounteredMap(selectedNodesPrevious);
        return selectedNodes.filter((nodeId) => !selectedNodesPreviousMap[nodeId]);
    }, [selectedNodes, selectedNodesPrevious]);

    const selectedNodesCurrentMap = React.useMemo(
        () => getIsEncounteredMap(selectedNodes),
        [selectedNodes]
    );

    const newUnselectedNodes = React.useMemo(
        () => selectedNodesPrevious.filter((nodeId) => !selectedNodesCurrentMap[nodeId]),
        [selectedNodesPrevious, selectedNodesCurrentMap]
    );

    const newHoveredNodes = React.useMemo(() => {
        const hoveredNodesPreviousMap = getIsEncounteredMap(hoveredNodesPrevious);
        return hoveredNodes.filter((node) => !hoveredNodesPreviousMap[node]);
    }, [hoveredNodes, hoveredNodesPrevious]);

    const hoveredNodesCurrentMap = React.useMemo(
        () => getIsEncounteredMap(hoveredNodes),
        [hoveredNodes]
    );

    const newUnhoveredNodes = React.useMemo(
        () => hoveredNodesPrevious.filter((nodeId) => !hoveredNodesCurrentMap[nodeId]),
        [hoveredNodesPrevious, hoveredNodesCurrentMap]
    );

    const selectedEdges = React.useMemo(() => {
        return edges
            .filter(
                (edge) =>
                    selectedNodesCurrentMap[edge.startViewNodeId] &&
                    selectedNodesCurrentMap[edge.endViewNodeId]
            )
            .map((edge) => edge.id);
    }, [edges, selectedNodesCurrentMap]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedEdgesPrevious = usePrevious(selectedEdges) || [];

    const newSelectedEdges = React.useMemo(() => {
        const selectedEdgesPreviousMap = getIsEncounteredMap(selectedEdgesPrevious);

        return selectedEdges.filter((edgeId) => !selectedEdgesPreviousMap[edgeId]);
    }, [selectedEdges, selectedEdgesPrevious]);

    const newUnselectedEdges = React.useMemo(() => {
        const selectedEdgesMap = getIsEncounteredMap(selectedEdges);

        return selectedEdgesPrevious.filter((edgeId) => !selectedEdgesMap[edgeId]);
    }, [selectedEdges, selectedEdgesPrevious]);

    return {
        newSelectedNodes,
        newHoveredNodes,
        newUnselectedNodes,
        newUnhoveredNodes,
        newSelectedEdges,
        newUnselectedEdges
    };
}
