import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

import {useRecentDocsQueryCache} from './use-recent-docs-query';

function addToRecentRequest(accession: string) {
    return apiClient.post({
        path: `editor-documents-service/api/user/documents/${accession}/recent`
    });
}

/** Add a document to recent in Edit mode */
export function useAddToRecentDocsMutation() {
    const recentQueryCache = useRecentDocsQueryCache();

    return useMutation(
        (docMetadata: DocInfo) => {
            recentQueryCache.addDocument(docMetadata);

            return addToRecentRequest(docMetadata.accession);
        },
        {
            onError: () => {
                recentQueryCache.invalidate();
            }
        }
    );
}
