import {usePushRelativeLocation} from '../components/relative-link/relative-path';
import {useDocRevisionsContext} from '../providers/version';

import {useGetDocumentRevisionByDateMutation} from '../application/document-workflow/explore-view/hooks/requests/use-get-document-revision-by-date-mutation';
import {useLocationParams} from './use-location';

function getDocumentUrl(accession: string, revisionId?: string, nodeId?: string | null) {
    const url = [`/${accession}`];

    if (revisionId) {
        url.push(`/revisions/${revisionId}`);
    }

    if (nodeId) {
        url.push(`/@${encodeURIComponent(nodeId)}`);
    }

    return url.join('');
}

/** Returns a handler for the editor's onNavigate method */
export function useGoToDeclarationHandler() {
    const locationParams = useLocationParams();
    const pushRelativeLocation = usePushRelativeLocation();
    const versionContext = useDocRevisionsContext();
    const getDocumentRevisionByDate = useGetDocumentRevisionByDateMutation();

    return async (accession: string | null, nodeId: string | null) => {
        if (!accession) {
            return;
        }

        const revisionInfo = versionContext.revisions?.find(
            ({revision}) => revision === locationParams.revisionId
        );

        const revisionData =
            locationParams.revisionId && revisionInfo
                ? await getDocumentRevisionByDate.mutateAsync({
                      accession,
                      createdBefore: revisionInfo?.createdAt
                  })
                : null;

        const url = getDocumentUrl(accession, revisionData?.revision, nodeId);

        pushRelativeLocation(url);
    };
}
