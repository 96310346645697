const refs = new Map<string | number, React.RefObject<HTMLElement>>();

let scrollToElementUID: string | number | undefined;

const tryScrollingToNode = () => {
    if (!scrollToElementUID) {
        return;
    }

    const ref = refs.get(scrollToElementUID);
    if (!ref || !ref.current) {
        return;
    }

    // scroll to node
    ref.current.scrollIntoView({block: 'center'});

    // and clear current job
    scrollToElementUID = undefined;
};

/** Schedules scrolling task and tries to scroll immediately if possible */
export const scrollToElementOnceFound = (elementUID: string | number) => {
    scrollToElementUID = elementUID;
    tryScrollingToNode();
};

/** Cancels scrolling job */
export const cancelScrollToElementTask = () => {
    scrollToElementUID = undefined;
};

/** Saves document's component reference and tries to scroll immediately if possible */
export const saveElementRef = (ref: React.RefObject<HTMLElement>, elementUID: string | number) => {
    refs.set(elementUID, ref);
    tryScrollingToNode();
};

/** Removes document's component reference */
export const removeElementRef = (elementUID: string | number) => {
    refs.delete(elementUID);
};
