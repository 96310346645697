import React from 'react';
import {LinkProps} from 'react-router-dom';

import {RelativeLink} from './relative-link';
import styles from './relative-link-styled.module.css';

type Props = LinkProps;

/** Incapsulates some styles for relative links */
export function RelativeLinkStyled(props: Props) {
    return <RelativeLink {...props} className={styles.link} />;
}
