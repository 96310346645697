import {PageContent, PageContentProps, DividerProps} from '@genestack/ui';
import React from 'react';

import styles from './sidebar-header.module.css';

type TargetProps = PageContentProps;

const SMALL_GAP: DividerProps = {startGap: 1, endGap: 0};

export const SidebarHeader = ({children, ...restProps}: TargetProps) => {
    return (
        <PageContent className={styles.root} {...restProps} endDividerProps={SMALL_GAP}>
            <div className={styles.content}>{children}</div>
        </PageContent>
    );
};
