import type {OntologyTreeState} from '../../providers/global-state';

interface Props {
    isTargetCategoryOpen: boolean;
    initialState: OntologyTreeState;
    targetCategoryTreePath: string[];
}

export function changeCategoryTreeState({
    initialState = {},
    isTargetCategoryOpen,
    targetCategoryTreePath
}: Props): OntologyTreeState {
    const newState = {
        ...initialState
    };
    let cursor = newState;

    targetCategoryTreePath.forEach((categoryId, index) => {
        const isTargetCategory = index === targetCategoryTreePath.length - 1;
        if (isTargetCategory) {
            if (isTargetCategoryOpen) {
                cursor[categoryId] = {};
            } else {
                cursor[categoryId] = undefined;
            }

            return;
        }

        cursor[categoryId] = {
            ...cursor[categoryId]
        };
        cursor = cursor[categoryId];
    });

    return newState;
}
