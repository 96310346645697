import {useMutation} from 'react-query';

import {CommitDto} from '../../../../../providers/version';
import {apiClient} from '../../../../../utils/api-client';

interface GetDocumentRevisionByDatePayload {
    accession: string;
    createdBefore: number;
}

function getRevisionDataByDateRequest(accession: string, createdBefore: number) {
    return apiClient
        .get<CommitDto[]>({
            path: `documents-revisions-service/api/viewer/documents/${accession}/history`,
            query: {limit: 1, 'created-before': createdBefore}
        })
        .then((result) => result[0]);
}

/** Returns the revision data of a document by date */
export function useGetDocumentRevisionByDateMutation() {
    return useMutation<CommitDto, Error, GetDocumentRevisionByDatePayload>(
        'revision-data',
        (payload) => getRevisionDataByDateRequest(payload.accession, payload.createdBefore)
    );
}
