import {
    Menu,
    Divider,
    HeaderItem,
    HeaderItemText,
    MenuItem,
    useMenuHandler,
    RootRef,
    HeaderItemProps,
    InfoIcon,
    Typography,
    CheckMarkIcon
} from '@genestack/ui';
import * as React from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';

import {CreateDocumentSubmenu} from '../../application/document-workflow/edit-view/activity-bar/create-documents-submenu';
import {useHotkey} from '../../hooks/use-hotkey';
import {
    useIsCanManageUsers,
    useIsCanManageQueries,
    useUserEditingPermissions
} from '../../hooks/user/use-has-permission';
import {ShortcutsDrawer} from '../shortcuts-drawer';

import styles from './application-menu.module.css';
import {useGlobalVersionDialogsGlobalValue} from '../../providers/version/use-global-version-dialogs-global-value';

interface ApplicationMenuLinkItemProps {
    isActive: boolean;
    path: string;
    title: string;
}

/** Navigation link item in Application Menu */
export function ApplicationMenuLinkItem({isActive, path, title}: ApplicationMenuLinkItemProps) {
    return (
        <MenuItem append={isActive ? <CheckMarkIcon /> : null}>
            <Typography as={Link} to={path} className={styles.menuLink}>
                {title}
            </Typography>
        </MenuItem>
    );
}

export interface Props extends HeaderItemProps {
    navigationItems: React.ReactNode;
}

export function ApplicationMenu(props: Props) {
    const {navigationItems, ...rest} = props;

    const [shortcutsPanelVisible, toggleShortcutsPanelVisibility] = useToggle(false);
    const headerItemElementRef = React.useRef<HTMLElement>(null);

    const history = useHistory();
    const isCanManageUsers = useIsCanManageUsers();
    const isCanManageQueries = useIsCanManageQueries();
    const {editingPermitted} = useUserEditingPermissions();
    const {openShowVersionsDialog} = useGlobalVersionDialogsGlobalValue();
    const menuHandler = useMenuHandler({
        referenceElement: headerItemElementRef.current
    });

    const handleCreateQueryClick = () => {
        history.push('/queries/new');
    };

    const handleCreateNew = (docType: DocType) => {
        history.push(`/documents/edit/new/${docType}`);
    };

    const handleCreateBtbDocumentClick = () => {
        handleCreateNew('BTB');
        menuHandler.close();
    };

    const {pathname} = useLocation();

    const handleCloseShortcutsDrawer = () => {
        toggleShortcutsPanelVisibility(false);
    };

    useHotkey({
        ctrlKey: true,
        key: '/',
        handler: toggleShortcutsPanelVisibility
    });

    useHotkey({
        key: '/',
        handler: toggleShortcutsPanelVisibility,
        isDisabledOnFields: true
    });

    return (
        <>
            <RootRef rootRef={headerItemElementRef}>
                <HeaderItem
                    shrink
                    active={menuHandler.isOpen}
                    {...menuHandler.getReferenceProps({...rest})}
                >
                    <HeaderItemText variant="section" intent="quiet" ellipsis>
                        <span className={styles.applicationLink}>Biology Knowledge Base</span>
                    </HeaderItemText>
                </HeaderItem>
            </RootRef>

            <Menu {...menuHandler.getMenuProps()} className={styles.menu}>
                {editingPermitted && (
                    <MenuItem
                        onClick={handleCreateBtbDocumentClick}
                        subMenu={<CreateDocumentSubmenu createNew={handleCreateNew} />}
                    >
                        Create new document
                    </MenuItem>
                )}
                <MenuItem onClick={handleCreateQueryClick}>Create new query</MenuItem>

                <Divider />
                {navigationItems}

                <Divider />
                <MenuItem onClick={openShowVersionsDialog as () => void}>
                    Show KB global versions...
                </MenuItem>

                {isCanManageUsers && (
                    <ApplicationMenuLinkItem
                        title="Manage users"
                        path="/users"
                        isActive={pathname === '/users'}
                    />
                )}

                {isCanManageQueries && (
                    <ApplicationMenuLinkItem
                        title="Manage queries"
                        path="/manage-queries"
                        isActive={pathname === '/manage-queries'}
                    />
                )}

                <Divider />
                <MenuItem
                    prepend={<InfoIcon />}
                    append={
                        <Typography intent="quiet" as="span">
                            Ctrl + /
                        </Typography>
                    }
                    onClick={toggleShortcutsPanelVisibility}
                >
                    Keyboard shortcuts
                </MenuItem>
            </Menu>

            <ShortcutsDrawer open={shortcutsPanelVisible} onClose={handleCloseShortcutsDrawer} />
        </>
    );
}
