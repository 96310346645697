import {
    Button,
    ListItem,
    PlusIcon,
    TooltipHandler,
    Tooltip,
    ChevronRightIcon,
    ChevronDownIcon
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {formatInteger} from '../../utils/format-integer';

import styles from './group.module.css';

export const DEFAULT_OFFSET_WIDTH = 14;

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isOpen?: boolean;
    isDisabled?: boolean;

    title?: string;
    onTitleClick?: React.MouseEventHandler<HTMLSpanElement>;
    titleProps?: React.HTMLAttributes<HTMLSpanElement>;

    count?: number;
    countProps?: React.HTMLAttributes<HTMLSpanElement>;

    depth?: number;

    onButtonClick?: () => void;
    categoryNodeId?: string;
}

export const Group = React.forwardRef<HTMLDivElement, Props>(function GroupComponent(
    {
        isOpen,
        isDisabled,

        title,
        onTitleClick,
        titleProps = {},

        count,
        countProps = {},

        onButtonClick,

        depth = 0,
        children,
        ...restProps
    },
    ref
) {
    const counter = count !== undefined && (
        <span
            className={classNames(styles.count, {[styles.zeroCount]: !count}, countProps.className)}
        >
            {formatInteger(count)}
        </span>
    );

    const handleButtonClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (onButtonClick) {
            onButtonClick();
        }
    };

    const createDocumentButton = onButtonClick ? (
        <TooltipHandler tooltip={<Tooltip>Create new document with this category</Tooltip>}>
            <Button
                size="tiny"
                icon={<PlusIcon />}
                onClick={handleButtonClick}
                className={styles.createButton}
            />
        </TooltipHandler>
    ) : null;

    const groupIcon = isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />;

    const itemStyles = React.useMemo(
        () => ({
            paddingLeft: (depth + 1) * DEFAULT_OFFSET_WIDTH,
            marginLeft: -(depth * DEFAULT_OFFSET_WIDTH)
        }),
        [depth]
    );

    return (
        <div
            {...restProps}
            className={classNames(restProps.className, {
                [styles.containerDisabled]: isDisabled
            })}
            ref={ref}
        >
            <ListItem
                as="div"
                data-qa="group-title-edit-mode"
                className={classNames(styles.groupTitle, {
                    [styles.withBorder]: depth === 0
                })}
                interactive={!isDisabled}
                onClick={!isDisabled ? onTitleClick : undefined}
                prepend={groupIcon}
                append={counter}
                style={itemStyles}
            >
                <div
                    {...titleProps}
                    title={title}
                    data-qa-id={`heading ${isDisabled ? 'disabled' : 'enabled'}`}
                    className={classNames(styles.title, titleProps?.className)}
                >
                    {title}
                    {createDocumentButton}
                </div>
            </ListItem>

            {isOpen ? <div className={styles.documents}>{children}</div> : null}
        </div>
    );
});
