import {Controls, ControlsItem, Typography} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {showNotification} from '../../../../components/notifications-center';
import {DeletePermanentlyStatusNotification} from '../../../../components/notifications/delete';
import {Sidebar} from '../../../../components/sidebar';
import {SidebarHeader} from '../../../../components/sidebar-header';
import {TrashBinMenu} from '../../../../components/trash-bin-menu';
import {useLocationParams} from '../../../../hooks/use-location';
import {isDeletedDocument} from '../../../../utils/document';
import {extractInfiniteQueryItems} from '../../../../utils/infinite-query-items';
import {useDeleteFromTrashBinMutation} from '../hooks/use-delete-from-trash-bin-mutation';
import {useDeletedDocumentsQuery} from '../hooks/use-deleted-documents-query';
import {useDocMetadataQuery} from '../hooks/use-doc-metadata-query';

import {PortionedDocsGroupContent} from './portioned-docs-group-content';
import {DocumentStatusIcon} from '../../../../components/doc-status-icon';

export function DeletedDocuments() {
    const {documentId} = useLocationParams();
    const deletedQuery = useDeletedDocumentsQuery();
    const deletedDocuments = extractInfiniteQueryItems(deletedQuery, (response) => response.data);
    const deleteFromTrashBin = useDeleteFromTrashBinMutation();
    const documentQuery = useDocMetadataQuery(documentId);

    const selectedDocument = documentQuery.data || null;

    const {push} = useHistory();

    const handleEmptyTrashBin = async () => {
        try {
            await deleteFromTrashBin.mutateAsync();

            if (selectedDocument && isDeletedDocument(selectedDocument)) {
                push('/documents/edit');
            }

            showNotification(<DeletePermanentlyStatusNotification />);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            showNotification(
                <DeletePermanentlyStatusNotification isError errorMessage={error.message} />
            );
        }
    };

    const header = (
        <SidebarHeader>
            <Controls justify="space-between">
                <ControlsItem>
                    <Typography variant="section">Recycle bin</Typography>
                </ControlsItem>

                <ControlsItem>
                    <TrashBinMenu
                        isLoading={deleteFromTrashBin.isLoading}
                        onEmptyTrashBin={handleEmptyTrashBin}
                        disabled={deletedQuery.isLoading || !deletedDocuments?.length}
                    />
                </ControlsItem>
            </Controls>
        </SidebarHeader>
    );

    return (
        <Sidebar header={header}>
            <PortionedDocsGroupContent
                isInitialLoading={deletedQuery.isLoading}
                docs={deletedDocuments}
                isLoadingMore={deletedQuery.isFetchingNextPage}
                onClickLoadMoreButton={deletedQuery.fetchNextPage}
                hasNextPage={deletedQuery.hasNextPage}
                disabled={deleteFromTrashBin.isLoading}
                DocStatusIconComponent={DocumentStatusIcon}
            />
        </Sidebar>
    );
}
