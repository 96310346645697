import React, {PropsWithChildren} from 'react';
import {Button, CrossIcon, Tooltip, TooltipHandler, Typography, Controls} from '@genestack/ui';
import {SidebarHeader} from '../../../components/sidebar-header';
import styles from '../../../components/vertical-bar/vertical-bar.module.css';

interface Props {
    title: string;
    onCollapse: () => void;
}

export const QueriesSidebarHeader = (props: PropsWithChildren<Props>) => {
    return (
        <SidebarHeader>
            <Controls justify="space-between">
                <Typography variant="section">{props.title}</Typography>

                <TooltipHandler
                    tooltip={
                        <Tooltip placement="bottom" className={styles.tooltip}>
                            Close sidebar
                        </Tooltip>
                    }
                >
                    <Button iconEnd={<CrossIcon />} size="small" ghost onClick={props.onCollapse} />
                </TooltipHandler>
            </Controls>

            {props.children}
        </SidebarHeader>
    );
};
