import React from 'react';
import classNames from 'classnames';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';
import {QueryEditor} from './query-editor';
import styles from './query-layout.module.css';
import {isEditableQueryDraft} from '../utils';

interface Props {
    selectedQuery: StoppedQuery | FailedQuery | DraftQuery | PendingQuery | RunningQuery;
    globalVersions: GlobalVersionData[];
}

export function NonCompletedQueryContent(props: Props) {
    return (
        <div
            className={classNames(styles.queryLayout, {
                [styles.viewMode]: !isEditableQueryDraft(props.selectedQuery)
            })}
        >
            <QueryEditor
                isCollapsed={false}
                toggleIsCollapsed={() => null}
                selectedQuery={props.selectedQuery}
                globalVersions={props.globalVersions}
            />
        </div>
    );
}
