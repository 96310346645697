import {useDeletedQueriesCache} from '../../queries-sidebar/hooks/use-deleted-queries';
import {useDraftQueriesCache} from '../../queries-sidebar/hooks/use-draft-queries';
import {useFavoriteQueriesCache} from '../../queries-sidebar/hooks/use-favorite-queries';
import {useQueryInfoCache, getQueryInfoRequest} from './use-query-info';
import {useRecentQueriesCache} from '../../queries-sidebar/hooks/use-recent-queries';
import {useSharedQueriesCache} from '../../queries-sidebar/hooks/use-shared-queries';

/** Returns query data from all caches */
function useGetQueryInfoFromCache() {
    const queryInfoCache = useQueryInfoCache();
    const draftQueriesCache = useDraftQueriesCache();
    const recentQueriesCache = useRecentQueriesCache();
    const deletedQueriesCache = useDeletedQueriesCache();
    const sharedQueriesCache = useSharedQueriesCache();
    const favoriteQueriesCache = useFavoriteQueriesCache();

    return (queryId: number): QueryInfo | undefined =>
        queryInfoCache.getQueryById(queryId) ??
        recentQueriesCache.getQueryById(queryId) ??
        draftQueriesCache.getQueryById(queryId) ??
        deletedQueriesCache.getQueryById(queryId) ??
        favoriteQueriesCache.getQueryById(queryId) ??
        sharedQueriesCache.getQueryById(queryId);
}

/** Returns query info from cache or server */
export function useGetQueryInfoById() {
    const getQueryInfoFromCache = useGetQueryInfoFromCache();

    return async (queryId: number): Promise<QueryInfo> => {
        return getQueryInfoFromCache(queryId) ?? getQueryInfoRequest(queryId);
    };
}
