import React from 'react';
import {useQueryLayoutProps} from '../../hooks/use-query-layout-props';
import {useResultsListProps} from './result-chains-panel/use-results-list-props';
import {
    isLoadedPresentationProps,
    useGraphPresentationProps
} from './results-graph/use-graph-presentation-props';
import {useGraphSelectionProps} from '../../../../components/graph/use-graph-selection-props';
import {GraphViewType} from '../../../../components/graph/interface';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {MaxPathLengthOptionResult} from './results-graph/requests/use-query-result-max-length-options';
import {GraphPresentation} from './results-graph/interface';
import {CompletedQueryInnerContent} from './completed-query-inner-content';
import {QueryLayoutLoading} from '../query-layout-loading';

export interface CompletedQueryInnerContentProps {
    selectedQuery: CompletedQuery;
    globalVersions: GlobalVersionData[];
    queryLayoutProps: ReturnType<typeof useQueryLayoutProps>;
    resultsListProps: ReturnType<typeof useResultsListProps>;
    selectionProps: ReturnType<typeof useGraphSelectionProps>;
    maxPathLengthOptions: MaxPathLengthOptionResult;
    viewTypesOptions: GraphViewType[];
    presentations: GraphPresentation[];
    setMaxPathLength: (val: number | undefined) => void;
}

export const CompletedQueryContentWithPresentations = ({
    presentations,
    ...restProps
}: CompletedQueryInnerContentProps) => {
    const graphPresentationProps = useGraphPresentationProps(
        restProps.selectedQuery.id,
        presentations,
        restProps.maxPathLengthOptions
    );

    if (!isLoadedPresentationProps(graphPresentationProps)) {
        return <QueryLayoutLoading />;
    }

    return (
        <CompletedQueryInnerContent
            {...restProps}
            graphPresentationProps={graphPresentationProps}
        />
    );
};
