import React from 'react';

import {DEFAULT_OFFSET_WIDTH} from '../../../../../../components/group';
import {UNCATEGORIZED_CATEGORY_ID} from '../../../../../../utils/get-categories-search';
import {extractInfiniteQueryItems} from '../../../../../../utils/infinite-query-items';
import {useOntologyDocsQuery} from '../../../hooks/use-ontology-docs-query';
import {PortionedDocsGroupContent} from '../../portioned-docs-group-content';
import {OntologyTreePreloader} from '../ontology-tree-preloader';
import {DocumentStatusIcon} from '../../../../../../components/doc-status-icon/document-status-icon';

interface Props {
    docType: DocType;
    depth: number;
}

function getDocumentsOffsetWidth(offset: number) {
    return offset * DEFAULT_OFFSET_WIDTH;
}

export function DocumentList(props: Props) {
    const docsQuery = useOntologyDocsQuery({
        docType: props.docType,
        pageSize: 'variable',
        categories: [UNCATEGORIZED_CATEGORY_ID]
    });

    const documents = extractInfiniteQueryItems(
        docsQuery,
        (response: PageResponse<DocInfo>) => response.data
    );

    const offset = (props.depth + 1) * DEFAULT_OFFSET_WIDTH;

    return (
        <div style={{marginLeft: offset}}>
            <OntologyTreePreloader show={docsQuery.isLoading}>
                <PortionedDocsGroupContent
                    hasSiblings
                    docs={documents?.length ? documents : undefined}
                    hasNextPage={docsQuery.hasNextPage}
                    isLoadingMore={docsQuery.isFetchingNextPage}
                    onClickLoadMoreButton={docsQuery.fetchNextPage}
                    offset={getDocumentsOffsetWidth(offset)}
                    DocStatusIconComponent={DocumentStatusIcon}
                />
            </OntologyTreePreloader>
        </div>
    );
}
