import React from 'react';
import {useMutation, useQueryClient} from 'react-query';

import {useSearchDocsInfiniteQueryCache} from '../../application/document-workflow/explore-view/sidebar/search-results/search-docs-infinite-query';
import {apiClient} from '../../utils/api-client';
import {assert} from '../../utils/assert';
import {useDocumentSyntheticEvents} from '../document-synthetic-events';

interface DocInfoContextValue {
    getDocInfo: (documentId: string) => ViewModeDocInfo | undefined;
    consumeDocInfo: (docInfos: ViewModeDocInfo[]) => void;
}

const DocInfoContext = React.createContext<DocInfoContextValue | null>(null);

const useDocInfoStore = () => {
    const value = React.useContext(DocInfoContext);
    assert(value, 'Could not be used outside of `DocInfoStoreProvider`');

    return value;
};

/** Returns docinfo from global document info store */
export const useDocInfo = (documentId: string) => {
    const {getDocInfo} = useDocInfoStore();

    return React.useMemo(() => getDocInfo(documentId), [documentId, getDocInfo]);
};

function useFavoritesQueryCache() {
    const queryClient = useQueryClient();

    function invalidate() {
        queryClient.invalidateQueries(['favoritesViewMode'], {refetchInactive: true});
    }

    return {invalidate};
}

/** Adds batch of docinfos to global document info store */
export const useConsumeDocInfo = (docInfo: ViewModeDocInfo[]) => {
    const {consumeDocInfo} = useDocInfoStore();

    React.useEffect(() => {
        consumeDocInfo(docInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo]);
};

function useToggleFavoriteMutation() {
    const favoritesQueryCache = useFavoritesQueryCache();
    const searchQueryCache = useSearchDocsInfiniteQueryCache();

    return useMutation((docMetadata: ViewModeDocInfo) =>
        (docMetadata.userFavorite
            ? apiClient.callApi<object>({
                  path: `documents-revisions-service/api/user/documents/${docMetadata.id}/favorite`,
                  method: 'DELETE'
              })
            : apiClient.post<object>({
                  path: `documents-revisions-service/api/user/documents/${docMetadata.id}/favorite`
              })
        ).then((response) => {
            favoritesQueryCache.invalidate();
            searchQueryCache.setIsDocumentFavorite(docMetadata.id, !docMetadata.userFavorite);

            return response;
        })
    );
}

/** Runs async toggle favorite operation and applies necessary updates */
export const useToggleFavorite = (documentId: string) => {
    const docInfo = useDocInfo(documentId);
    const {consumeDocInfo} = useDocInfoStore();

    const toggleFavorite = useToggleFavoriteMutation();

    const {trigger} = useDocumentSyntheticEvents();

    return React.useCallback(async () => {
        if (!docInfo) {
            return;
        }

        const nextDocInfo = {...docInfo, userFavorite: !docInfo.userFavorite};

        // update document info in lists
        trigger({
            type: 'toggle-favorite',
            payload: {documentInfo: nextDocInfo}
        });

        consumeDocInfo([nextDocInfo]);
        toggleFavorite.mutate(docInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo]);
};

type StoreValue = Record<string, ViewModeDocInfo>;

const reducer = (
    prevState: StoreValue,
    action: {type: 'set-batch'; payload: {docInfos: ViewModeDocInfo[]}}
) =>
    action.payload.docInfos.reduce(
        (acc, docInfo) => ({...acc, [docInfo.accession]: docInfo}),
        prevState
    );

/** Global docinfo store provider */
export const DocInfoStoreProvider = ({children}: {children: React.ReactNode}) => {
    const [store, dispatch] = React.useReducer(reducer, {});

    const getDocInfo = React.useCallback((documentId: string) => store[documentId], [store]);

    const consumeDocInfo = React.useCallback((docInfos: ViewModeDocInfo[]) => {
        dispatch({type: 'set-batch', payload: {docInfos}});
    }, []);

    return (
        <DocInfoContext.Provider
            value={React.useMemo(
                () => ({getDocInfo, consumeDocInfo}),
                [getDocInfo, consumeDocInfo]
            )}
        >
            {children}
        </DocInfoContext.Provider>
    );
};
