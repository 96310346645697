import React, {PropsWithChildren} from 'react';

import {BaseResizablePanel} from './base-resizable-panel';
import {Separator} from './separator';

export interface ResizableLayoutContextProps {
    isDragging: boolean;
    separatorRef: React.Ref<HTMLDivElement>;
    firstPanelShare: number;
    panelsAlignment: 'horizontal' | 'vertical';
    minWidth: number;
    minHeight?: number;
    fixedPanelSize?: {panelIndex: 0 | 1; size: number};
}

interface Props extends ResizableLayoutContextProps {
    index: 0 | 1;
    className?: string;
}

export function ResizablePanel(props: PropsWithChildren<Props>) {
    const isFirstPanel = props.index === 0;

    const panelSize = (function () {
        if (props.fixedPanelSize) {
            if (props.fixedPanelSize.panelIndex === props.index) {
                return props.fixedPanelSize.size;
            }

            return `calc(100% - ${props.fixedPanelSize.size}px)`;
        }

        if (isFirstPanel) {
            return `${props.firstPanelShare * 100}%`;
        }

        return `${(1 - props.firstPanelShare) * 100}%`;
    })();

    return (
        <BaseResizablePanel
            className={props.className}
            isDragging={props.isDragging}
            style={{
                [props.panelsAlignment === 'vertical' ? 'maxHeight' : 'maxWidth']: panelSize,
                minWidth:
                    props.panelsAlignment === 'horizontal' &&
                    props.fixedPanelSize &&
                    props.fixedPanelSize.panelIndex === props.index
                        ? Math.min(props.minWidth, props.fixedPanelSize.size)
                        : props.minWidth,
                minHeight:
                    props.panelsAlignment === 'vertical' &&
                    props.fixedPanelSize &&
                    props.minHeight &&
                    props.fixedPanelSize.panelIndex === props.index
                        ? Math.min(props.minHeight, props.fixedPanelSize.size)
                        : props.minHeight
            }}
        >
            {props.children}

            {isFirstPanel && !props.fixedPanelSize && (
                <Separator
                    variant={props.panelsAlignment === 'horizontal' ? 'right' : 'bottom'}
                    isDragging={props.isDragging}
                    ref={props.separatorRef}
                />
            )}
        </BaseResizablePanel>
    );
}
