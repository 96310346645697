import {ListItemProps} from '@genestack/ui';
import React from 'react';

import {ListItemLink} from '../../../../components/list-item-link';

/** Props of the Document item */
export interface Props extends ListItemProps {
    queryId: number;
}

/**
 * Document item in the Sidebar.
 * It is prepended with the label reflecting the type of the document.
 * If the document has one of the DocStatus (read-only, working-copy or own-working-copy),
 * the proper icon with a tooltip is appended to the item.
 */
export const QueryLink = (props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {className, queryId, ...listItemProps} = props;

    return <ListItemLink to={`/queries/${queryId}`} data-qa-id="query" {...listItemProps} />;
};
