import {Divider, Paper, PageContent, Typography} from '@genestack/ui';
import * as React from 'react';
import {Link} from 'react-router-dom';

import {BusyIndicator} from '../../../components/busy-indicator';
import {useIdentityFormQuery} from '../../../hooks/user/auth-form-query';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import styles from '../login-page/login-page.module.css';

import {RecoveryPerformer} from './recovery-performer';

/** Page for recovering password */
export function RecoveryPage() {
    const currentUserQuery = useCurrentUserQuery();
    const recoveryFormQuery = useIdentityFormQuery({path: '/self-service/recovery/browser'});

    React.useEffect(() => {
        if (recoveryFormQuery.error) {
            currentUserQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoveryFormQuery.error]);

    if (recoveryFormQuery.data) {
        return (
            <PageContent className={styles.root}>
                <Paper className={styles.form}>
                    <Typography variant="header" className={styles.header}>
                        Biology Knowledge Base
                    </Typography>
                    <Divider variant="transparent" />
                    <Typography variant="title" intent="quiet">
                        Restore password
                    </Typography>
                    <Divider variant="transparent" />
                    <RecoveryPerformer {...recoveryFormQuery.data} />
                </Paper>
                <div>
                    <Divider variant="transparent" gap={2} />
                    <Link to="/login" className={styles.link}>
                        Back to sign in
                    </Link>
                </div>
            </PageContent>
        );
    }

    return <BusyIndicator />;
}
