import {
    Button,
    Controls,
    ControlsItem,
    InProgressIcon,
    Spinner,
    StopIcon,
    Typography
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {RelativeLinkStyled} from '../../../components/relative-link/relative-link-styled';
import {useMoveQueryInQueueMutation} from './use-move-query-in-queue-mutation';
import {useStopQueryMutation} from '../hooks/requests/use-stop-query-mutation';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {usePreselectedTab} from '../../../components/page-layout/use-preselected-tab';
import {formatInteger} from '../../../utils/format-integer';
import {useQueryDuration} from '../query-layout/completed-query/query-results-header/use-query-duration';
import {Timer} from '../common/timer';

import styles from './query-management-panel.module.css';
import {QueriesTab} from '../../../components/page-layout/layout-typings';

interface BaseProps {
    isHighlighted?: boolean;
}

interface PendingQueryProps extends BaseProps {
    query: PendingQuery;
    positionInQueue: number;
}

interface RunningQueryProps extends BaseProps {
    query: RunningQuery;
}

type Props = PendingQueryProps | RunningQueryProps;

function isPendingQueryProps(props: Props): props is PendingQueryProps {
    return props.query.status === 'PENDING';
}

export function UnresolvedQuery(props: Props) {
    const isPendingQuery = isPendingQueryProps(props);
    const stopQueryMutation = useStopQueryMutation();
    const stopQuery = () => {
        stopQueryMutation.mutate(props.query.id);
    };

    const moveQueryInQueueMutation = useMoveQueryInQueueMutation();
    const onRankUpPress = () => {
        moveQueryInQueueMutation.mutate({queryId: props.query.id});
    };

    const queryStatusInfo = (function () {
        if (!isPendingQuery) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const queryDuration = useQueryDuration({query: props.query});

            return (
                <>
                    <Typography>
                        <Timer duration={queryDuration} accented />
                    </Typography>

                    <Typography intent="quiet" className={styles.resultsCount}>
                        {formatInteger(props.query.resultsCount)}
                    </Typography>

                    <div>
                        <Spinner /> Query running
                    </div>

                    <div className={styles.visibleOnRowHover}>
                        <Button size="small" intent="alarm" onClick={stopQuery}>
                            <StopIcon /> Stop
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <>
                <div />
                <div />
                <div>
                    <Typography>
                        <InProgressIcon /> #{props.positionInQueue} in queue...
                    </Typography>
                </div>

                <div className={styles.visibleOnRowHover}>
                    <Controls>
                        <ControlsItem>
                            <Button size="small" intent="alarm" onClick={stopQuery}>
                                <StopIcon /> Stop
                            </Button>
                        </ControlsItem>

                        {props.positionInQueue !== 1 && (
                            <ControlsItem>
                                <Button size="small" intent="accent" onClick={onRankUpPress}>
                                    Rank up
                                </Button>
                            </ControlsItem>
                        )}
                    </Controls>
                </div>
            </>
        );
    })();

    const currentUser = useCurrentUserQuery();
    const isCreatedByCurrentUser = props.query.owner.email === currentUser.data?.email;
    const {getPreselectedTabURLParam} = usePreselectedTab();

    return (
        <div className={classNames(styles.queryRow, styles.rowWithHover)} key={props.query.id}>
            <div className={styles.queryName}>
                <RelativeLinkStyled
                    to={`/queries/${props.query.id}${
                        isCreatedByCurrentUser
                            ? `?${getPreselectedTabURLParam(QueriesTab.RECENT)}`
                            : ''
                    }`}
                    target="_blank"
                >
                    {props.query.name}
                </RelativeLinkStyled>
            </div>

            <Typography>{props.query.owner.name}</Typography>

            {queryStatusInfo}
        </div>
    );
}
