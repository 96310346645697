import {PageContent, Typography, Divider} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader';
import {Sidebar} from '../../../../components/sidebar';
import {SidebarHeader} from '../../../../components/sidebar-header';
import {useLocationParams} from '../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../providers/doc-info-store';
import {extractInfiniteQueryItems} from '../../../../utils/infinite-query-items';

import {DocumentLink} from './document-link';
import {useListFavoritesInfiniteQuery} from './explore-favorites-query';

function FavoritesResults(props: {
    items: ViewModeDocInfo[];
    selectedDocumentId?: string;
    getDocumentLink: (documentId: string) => string;
}) {
    useConsumeDocInfo(props.items);

    return (
        <>
            {props.items.map((docInfo) => (
                <DocumentLink
                    key={docInfo.accession}
                    documentId={docInfo.accession}
                    active={docInfo.accession === props.selectedDocumentId}
                    displayFavoriteState={false}
                    getDocumentLink={props.getDocumentLink}
                />
            ))}
        </>
    );
}

interface Props {
    getDocumentLink: (documentId: string) => string;
}

export const FavoriteDocumentsSidebar = (props: Props) => {
    const {documentId: selectedDocumentId} = useLocationParams();

    const listFavoritesQuery = useListFavoritesInfiniteQuery();
    const items = extractInfiniteQueryItems(
        listFavoritesQuery,
        (result: PageResponse<ViewModeDocInfo>) => result.data
    );

    const header = React.useMemo(
        () => (
            <SidebarHeader>
                <Typography variant="section">Bookmarks</Typography>
            </SidebarHeader>
        ),
        []
    );

    return (
        <Sidebar header={header}>
            <Preloader show={listFavoritesQuery.isFetching}>
                {!listFavoritesQuery.isSuccess || !items?.length ? (
                    <PageContent noStartDivider noEndDivider>
                        <Typography intent="quiet">
                            You haven't bookmarked any documents yet
                        </Typography>
                    </PageContent>
                ) : (
                    <>
                        <FavoritesResults
                            items={items}
                            selectedDocumentId={selectedDocumentId}
                            getDocumentLink={props.getDocumentLink}
                        />
                        <Divider
                            variant="transparent"
                            gap={4} // tslint:disable-line: no-magic-numbers
                        />
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
};
