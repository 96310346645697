import {useQuery, useQueryClient} from 'react-query';

import {
    UsageNode,
    getHtmlForUsage,
    getNodeIdOfResolvedNode,
    CurrentPage,
    FindUsagePageEntryAdapter,
    findUsages
} from '../../../../../../../webeditor/biokb-editor-adapters';
import {NodeSearchData} from '../../../../common/sidebar-search-field/interface';

export interface UsageNodesType {
    total: number;
    hasNextPage: boolean;
    data: DocumentNode[];
    nextPage: () => Promise<CurrentPage<UsageNode>>;
}

export interface UsageItemType {
    document: DocInfo;
    nodes: UsageNodesType;
}

export interface FindUsagesResponse {
    totalDocuments: number;
    totalNodes: number;
    items: UsageItemType[];
    hasNextPage: boolean;
    onLoadNextPage: () => Promise<CurrentPage<FindUsagePageEntryAdapter>>;
}

export const NODES_PAGE_SIZE = 7;
export const DOC_PAGE_SIZE = 25;

const keyFactory = {
    allQueries: ['find-usages-edit'],
    specificSearchQuery: (searchData: NodeSearchData) => [
        ...keyFactory.allQueries,
        searchData.documentAccession,
        searchData.nodeId
    ]
};

export async function convertNodes(
    docAccession: string,
    usages: UsageNode[]
): Promise<DocumentNode[]> {
    return Promise.all(
        usages.map(async (usage) => {
            const htmlElement = await getHtmlForUsage(docAccession, usage);

            return {
                id: getNodeIdOfResolvedNode(usage.node),
                label: htmlElement.innerText
            };
        })
    );
}

export async function convertFindUsagesResponse(
    usagesData: CurrentPage<FindUsagePageEntryAdapter>
): Promise<UsageItemType[]> {
    return Promise.all(
        usagesData.data.map(async ({document, nodes}) => ({
            document,
            nodes: {
                total: await nodes.total,
                hasNextPage: nodes.current.hasNextPage,
                data: await convertNodes(document.accession, nodes.current.data),
                nextPage: nodes.nextPage.bind(nodes)
            }
        }))
    );
}

export function useFindUsagesQuery(params: NodeSearchData) {
    return useQuery<FindUsagesResponse>({
        cacheTime: 0,
        queryKey: keyFactory.specificSearchQuery(params),
        queryFn: async () => {
            const usagesData = await findUsages(
                params.documentAccession!,
                params.nodeId!,
                DOC_PAGE_SIZE,
                NODES_PAGE_SIZE
            );

            return {
                totalDocuments: await usagesData.total,
                totalNodes: await usagesData.totalNodes,
                items: await convertFindUsagesResponse(usagesData.current),
                hasNextPage: usagesData.current.hasNextPage,
                onLoadNextPage: usagesData.nextPage.bind(usagesData)
                // FIXME the binding looks like an overhead
            };
        }
    });
}

export function useFindUsagesQueryCache() {
    const queryClient = useQueryClient();

    function updateDocument(document: DocInfo) {
        queryClient.setQueriesData<FindUsagesResponse | undefined>(
            keyFactory.allQueries,
            (currentState) => {
                if (currentState) {
                    return {
                        ...currentState,
                        items: currentState.items?.map((usage) =>
                            usage.document.accession === document.accession
                                ? {...usage, document}
                                : usage
                        )
                    };
                }
            }
        );
    }

    function addItems(items: UsageItemType[], searchData: NodeSearchData, hasNextPage: boolean) {
        queryClient.setQueryData<FindUsagesResponse | undefined>(
            keyFactory.specificSearchQuery(searchData),
            (currentData) =>
                currentData && {
                    ...currentData,
                    items: [...currentData.items, ...items],
                    hasNextPage
                }
        );
    }

    return {updateDocument, addItems};
}
