import {useMutation} from 'react-query';

import {useDocumentHistoryQueryCache} from '../../../../providers/version/document-history-query';
import {apiClient} from '../../../../utils/api-client';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useCategoriesInfiniteQueryCache} from './categories/use-categories-infinite-query-cache';
import {useDeletedDocumentsQueryCache} from './use-deleted-documents-query';
import {useDocMetadataQueryCache} from './use-doc-metadata-query';
import {useLastActionQueryCache} from './use-last-action-query';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';

function deleteDocumentRequest(accession: string) {
    return apiClient.post({
        path: `editor-documents-service/api/documents/${accession}/trash-bin`
    });
}

/** Discard working copy */
export function useDeleteDocumentMutation() {
    const lastActionQueryCache = useLastActionQueryCache();
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const documentHistoryQueryCache = useDocumentHistoryQueryCache();
    const documentsQueryCache = useSearchDocsCache();
    const deletedQueryCache = useDeletedDocumentsQueryCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const categoriesQueryCache = useCategoriesInfiniteQueryCache();

    return useMutation(async (accession: string) => {
        await deleteDocumentRequest(accession);

        const nextMetadata = await docMetadataQueryCache.get(accession);

        if (nextMetadata) {
            const categoryNodeIds = ontologyDocsQueryCache.getCategoriesByDocument(nextMetadata);
            categoryNodeIds.forEach((categoryNodeId) => {
                categoriesQueryCache.updateDocumentsCount(categoryNodeId, -1);
            });

            ontologyDocsQueryCache.deleteDocument(nextMetadata);
            deletedQueryCache.addDocument(nextMetadata);
        } else {
            await ontologyDocsQueryCache.invalidate();
            await deletedQueryCache.invalidate();
        }

        await categoriesQueryCache.invalidate();
        await lastActionQueryCache.invalidate(accession, 'DELETE');
        documentHistoryQueryCache.invalidate(accession);
        documentsQueryCache.deleteDocument(accession);
    });
}
