import {useMutation} from 'react-query';

import {useDocumentHistoryQueryCache} from '../../../../providers/version/document-history-query';
import {apiClient} from '../../../../utils/api-client';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useDocMetadataQueryCache} from './use-doc-metadata-query';
import {useLastActionQueryCache} from './use-last-action-query';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';
import {useUnpublishedDocsQueryCache} from './use-unpublished-docs-query';
import {useFetchWorkingCopyQueryCache} from './use-fetch-working-copy-query';

interface Payload {
    accession: string;
    commitMessage?: string;
}

function publishWorkingCopyRequest(accession: string, commitMessage?: string) {
    return apiClient.post({
        path: `editor-documents-service/api/documents/${accession}/wc/publish`,
        parameters: {commitMessage}
    });
}

/** Publish working copy mutation */
export function usePublishWorkingCopyMutation(belEditorPublished: () => void) {
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const unpublishedDocsQueryCache = useUnpublishedDocsQueryCache();
    const searchDocsCache = useSearchDocsCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();

    const wcQueryCache = useFetchWorkingCopyQueryCache();

    const lastActionQueryCache = useLastActionQueryCache();
    const documentHistoryQueryCache = useDocumentHistoryQueryCache();

    return useMutation(
        (payload: Payload) => publishWorkingCopyRequest(payload.accession, payload.commitMessage),
        {
            onSuccess: async (_, payload) => {
                lastActionQueryCache.invalidate(payload.accession, 'PUBLISH');
                documentHistoryQueryCache.invalidate(payload.accession);
                unpublishedDocsQueryCache.deleteDocument(payload.accession);

                belEditorPublished();

                const nextMetadata = await docMetadataQueryCache.get(payload.accession);

                if (nextMetadata) {
                    searchDocsCache.updateDocument(nextMetadata);
                    ontologyDocsQueryCache.updateDocument(nextMetadata);
                }

                wcQueryCache.remove(payload.accession);
            }
        }
    );
}
