interface Props {
    min?: number;
    max?: number;
}

/** Returns the calculated value */
export function sum(a: number, b: number, props: Props): number {
    const value = a + b;

    if (props.min && value < props.min) {
        return props.min;
    }

    if (props.max && value > props.max) {
        return props.max;
    }

    return value;
}
