import {ScrollView} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './sidebar.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    header?: React.ReactNode;
    component?: React.ElementType;
}

// FIXME Rename to smth like `ScrollViewWithHeader`
/** A component that creates sidebar and its contents */
export const Sidebar = ({
    className,
    header,
    children,
    component: Component = ScrollView,
    ...rest
}: Props) => (
    <div className={classNames(styles.root, className)} {...rest}>
        {header}
        <Component className={styles.content}>{children}</Component>
    </div>
);
