import {useQuery, useQueryClient} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

function getDocMetadataRequest(accession: string) {
    return apiClient.get<DocInfo>({
        path: `editor-documents-service/api/documents/${accession}/metadata`
    });
}

/** Request for the Document metadata */
export function useDocMetadataQuery(accession: string | undefined) {
    return useQuery(
        ['document-metadata', accession],
        () => {
            if (accession) {
                return getDocMetadataRequest(accession);
            }
        },
        {enabled: Boolean(accession)}
    );
}

/** Query Cache for Document metadata objects */
export function useDocMetadataQueryCache() {
    const queryClient = useQueryClient();
    const queryKey = 'document-metadata';

    function invalidate(accession: string) {
        return queryClient.invalidateQueries([queryKey, accession], {
            refetchInactive: true
        });
    }

    async function get(accession: string) {
        await invalidate(accession);

        return queryClient.getQueryData<DocInfo>([queryKey, accession]);
    }

    function set(docInfo: DocInfo) {
        queryClient.setQueryData<DocInfo>([queryKey, docInfo.accession], docInfo);
    }

    function remove(accession: string) {
        queryClient.removeQueries([queryKey, accession]);
    }

    function invalidateAll() {
        return queryClient.invalidateQueries({
            queryKey,
            exact: false,
            refetchInactive: true
        });
    }

    function clearCache() {
        queryClient.removeQueries({queryKey, exact: false});
    }

    return {set, get, remove, invalidate, invalidateAll, clearCache};
}
