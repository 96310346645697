import {Typography} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {Insertion} from '../../../../utils/text';

import styles from './custom-suggestion-item.module.css';
import {SidebarSuggestion} from './interface';

interface Props {
    suggestion: SidebarSuggestion;
}

function renderInsertions(insertions: Insertion[], label: string) {
    if (insertions.length) {
        return insertions.map(({prev, current}, labelNdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={labelNdx}>
                {prev}
                <b>{current}</b>
            </React.Fragment>
        ));
    }

    return label;
}

export function CustomSuggestionItem({suggestion}: Props) {
    const {mode, type, label, placeholder, insertions, matchedSynonym} = suggestion;

    return (
        <div className={styles.root}>
            <div className={classNames(styles.value, {[styles.withSynonym]: matchedSynonym})}>
                {type && (
                    <span className={styles.type}>
                        {mode === 'nodeType' && <span className={styles.bullet}>• </span>}
                        {renderInsertions(insertions.type, type)}:{' '}
                    </span>
                )}

                <span className={styles.label} title={label}>
                    {renderInsertions(insertions.label, label)}
                </span>
            </div>
            {matchedSynonym && insertions.matchedSynonym ? (
                <span className={styles.synonym} title={matchedSynonym}>
                    {renderInsertions(insertions.matchedSynonym, matchedSynonym)}
                </span>
            ) : null}
            {placeholder && (
                <Typography
                    className={styles.placeholder}
                    as="span"
                    intent="quiet"
                    variant="caption"
                >
                    {placeholder}
                </Typography>
            )}
        </div>
    );
}
