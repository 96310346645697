import React from 'react';

import {showNotification} from '../../../../../../components/notifications-center';
import {RestoreStatusNotification} from '../../../../../../components/notifications/restore';
import {useRestoreMutation} from '../../../hooks/use-restore-document-mutation';

interface Props {
    accession: string;
    children: React.ReactElement;
}

/**
 * Component allowing to Restore a document from the Trash bin
 */
export function RestoreItem(props: Props) {
    const restoreMutation = useRestoreMutation();

    const handleRestore = async () => {
        try {
            await restoreMutation.mutateAsync(props.accession);

            showNotification(<RestoreStatusNotification />);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            showNotification(<RestoreStatusNotification isError errorMessage={error.message} />);
        }
    };

    return React.cloneElement(props.children, {onClick: handleRestore});
}
