import React from 'react';
import {isQueryRunning} from '../../../utils';

interface Props {
    query: RecentQuery;
}

export function useQueryDuration(props: Props) {
    const timerId = React.useRef<number>();
    const [duration, setDuration] = React.useState(0);

    const runTimer = React.useCallback(
        (startedAt: number) =>
            window.setTimeout(() => {
                setDuration(new Date().getTime() - startedAt);
                clearTimeout(timerId.current);
                timerId.current = runTimer(startedAt);
            }, 1000),
        []
    );

    // keep timer running until component unmounts or query changes
    React.useEffect(() => {
        if (isQueryRunning(props.query)) {
            setDuration(new Date().getTime() - props.query.startedAt);
            timerId.current = runTimer(props.query.startedAt);

            return () => {
                clearTimeout(timerId.current);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.query.id, isQueryRunning(props.query)]);

    return duration;
}
