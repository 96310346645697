import {Controls, ControlsItem, Flex, Typography} from '@genestack/ui';
import React from 'react';

import {pluralize} from '../../../../../../utils/pluralize';
import {CopySearchButton} from '../../copy-search-button';
import {ExpandCategoriesButton} from '../categories/expand-button';
import {SearchResultsHeader} from '../search-results-header';
import {SearchData} from '../../../../common/sidebar-search-field/interface';
import {SidebarLoadingState} from '../../../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {GlobalVersionData} from '../../../../../../hooks/global-version/use-global-version-query';

interface Props {
    isLoading?: boolean;
    hasItems: boolean;
    total: number;

    expanded: boolean;
    onToggleExpand: () => void;
    searchData: SearchData;
    loadingState: SidebarLoadingState;
    selectedGlobalVersion: GlobalVersionData;
}

function getSearchLabel(count: number, hasNoResults: boolean, hasItems: boolean): string {
    if (!hasItems) {
        return '';
    }

    return hasNoResults
        ? 'No documents matched your search'
        : `Found ${count} ${pluralize('document', count)}`;
}

export function DocumentResultsHeader({
    isLoading,
    hasItems,
    total,
    expanded,
    onToggleExpand,
    searchData,
    loadingState,
    selectedGlobalVersion
}: Props) {
    const hasNoResults = !isLoading && total === 0;
    const searchLabel = getSearchLabel(total, hasNoResults, hasItems);

    return (
        <SearchResultsHeader loadingState={loadingState}>
            <Controls justify="space-between">
                <ControlsItem>
                    <Controls>
                        <Flex align="center">
                            <ControlsItem>
                                <ExpandCategoriesButton
                                    expanded={expanded}
                                    onToggleExpand={onToggleExpand}
                                />
                            </ControlsItem>
                        </Flex>
                        <ControlsItem>
                            <Typography variant="section">{searchLabel}</Typography>
                        </ControlsItem>
                    </Controls>
                </ControlsItem>
            </Controls>
            <ControlsItem>
                <CopySearchButton
                    searchData={searchData}
                    selectedGlobalVersion={selectedGlobalVersion}
                />
            </ControlsItem>
        </SearchResultsHeader>
    );
}
