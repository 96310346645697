import {MenuHandlerProps, Controls, ControlsItem, ControlsItemProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {EditableTitle, EditableTitleApi} from '../editable-title';

import {EditorHeaderSkeleton} from './editor-header-skeleton';
import styles from './editor-header.module.css';

interface TitleProps {
    'data-qa': string;
    append?: JSX.Element;
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isLoading?: boolean;

    disableEditingTitle?: boolean;
    menu?: MenuHandlerProps['menu'] | null;

    name: string;
    onRename?: (name: string) => void;

    prepend?: React.ReactNode;
    append?: React.ReactNode;

    titleProps: TitleProps;
    contentProps?: ControlsItemProps;
}

export const editorHeaderHeight = 56;

export const EditorHeader = React.forwardRef<EditableTitleApi, Props>(
    function EditorHeaderComponent(props, ref) {
        const {
            className,
            isLoading,
            disableEditingTitle,
            menu,
            name,
            onRename,
            prepend,
            append,
            titleProps,
            contentProps,
            children,
            ...restProps
        } = props;

        const [titleFocused, setTitleFocused] = React.useState(false);

        const handleTitleFocus = () => {
            setTitleFocused(true);
        };
        const handleTitleBlur = () => {
            setTitleFocused(false);
        };

        if (isLoading) {
            return <EditorHeaderSkeleton />;
        }

        return (
            <div className={classNames(styles.root, className)} {...restProps}>
                <Controls justify="space-between">
                    <ControlsItem shrink grow={titleFocused}>
                        <Controls gap={1}>
                            <ControlsItem shrink grow>
                                <EditableTitle
                                    {...titleProps}
                                    ref={ref}
                                    disableEditing={disableEditingTitle}
                                    menu={menu}
                                    value={name}
                                    onSubmitValue={onRename}
                                    prepend={prepend}
                                    onFocus={handleTitleFocus}
                                    onBlur={handleTitleBlur}
                                />
                            </ControlsItem>

                            {append && (
                                <ControlsItem style={{display: 'flex'}}>{append}</ControlsItem>
                            )}
                        </Controls>
                    </ControlsItem>

                    {children && (
                        <ControlsItem
                            grow={!titleFocused}
                            shrink
                            className={styles.additionalControlsItem}
                            {...contentProps}
                        >
                            {children}
                        </ControlsItem>
                    )}
                </Controls>
            </div>
        );
    }
);
