import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {LoginPage} from './login-page';
import {RecoveryPage} from './recovery-page';

/** Application component which is available for Unauthenticated users */
export function UnauthenticatedApp() {
    return (
        <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/recovery" component={RecoveryPage} />
            <Redirect to="/login" />
        </Switch>
    );
}
