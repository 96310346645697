import React from 'react';
import {Typography} from '@genestack/ui';
import styles from './graph-display-err.module.css';

interface Props {
    label: string;
}

export function GraphDisplayErr(props: Props) {
    return (
        <div className={styles.graphDisplayErr}>
            <Typography>{props.label}</Typography>
        </div>
    );
}
