import classNames from 'classnames';
import React from 'react';

import {LocalErrorBoundary} from '../../../../components/error-boundary';
import {CustomResizableLayout, ResizablePanel} from '../../../../components/resizable-layout';
import {isEditableQueryDraft} from '../../utils';

import styles from '../query-layout.module.css';
import {CompletedQueryContentWithPresentations} from './completed-query-content-with-presentations';
import {useQueryLayoutProps} from '../../hooks/use-query-layout-props';
import {useResultsListProps} from './result-chains-panel/use-results-list-props';
import {useGetPresentations} from './results-graph/requests/use-get-presentations';
import {useGraphSelectionProps} from '../../../../components/graph/use-graph-selection-props';
import {QueryLayoutLoading} from '../query-layout-loading';
import {useQueryResultMaxLengthOptions} from './results-graph/requests/use-query-result-max-length-options';
import {useGetQueryViewTypes} from './results-graph/requests/use-get-query-view-types';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {RightPanelNavBar} from './right-panel/right-panel-nav-bar';
import {
    collapsedFactsetPanelHeight,
    GraphResultChainsPanel
} from './result-chains-panel/graph-result-chains-panel';
import {ResultChainsPanel} from '../../../../components/graph/result-chains-panel/path-result-panel/result-chains-panel';

interface Props {
    selectedQuery: CompletedQuery;
    globalVersions: GlobalVersionData[];
}

export function CompletedQueryContent(props: Props) {
    const [maxPathLength, setMaxPathLength] = React.useState<number>();
    const presentationsRequest = useGetPresentations(props.selectedQuery.id);
    const maxLengthOptionsRequest = useQueryResultMaxLengthOptions(props.selectedQuery.id);
    const viewTypesOptionsRequest = useGetQueryViewTypes(props.selectedQuery.id);

    const graphSelectionProps = useGraphSelectionProps();
    const queryLayoutProps = useQueryLayoutProps({
        selectedQuery: props.selectedQuery,
        selectedNodes: graphSelectionProps.selectedNodes
    });
    const resultsListProps = useResultsListProps({selectedQuery: props.selectedQuery});

    React.useEffect(() => {
        if (graphSelectionProps.selectedNodes.length) {
            resultsListProps.setSelectedResultItem(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphSelectionProps.selectedNodes]);

    React.useEffect(() => {
        if (resultsListProps.selectedResultItem) {
            graphSelectionProps.onNodesSelected([], false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsListProps.selectedResultItem]);

    const hasResultsPanel =
        Boolean(resultsListProps.selectedResultItem) ||
        graphSelectionProps.selectedNodes.length > 0;

    const factsetPanelFixedSize = (function () {
        if (!hasResultsPanel) {
            return {panelIndex: 1 as const, size: 0};
        }

        if (queryLayoutProps.isFactsetCollapsed) {
            return {panelIndex: 1 as const, size: collapsedFactsetPanelHeight};
        }

        return undefined;
    })();

    if (
        !presentationsRequest.data ||
        !maxLengthOptionsRequest.data ||
        !viewTypesOptionsRequest.data
    ) {
        return <QueryLayoutLoading />;
    }

    return (
        <React.Fragment>
            <CustomResizableLayout
                localStorageKey="queries.results.chain.layoutSize"
                settings={{initialFirstPanelShare: 0.6, minPermittedHeight: 200}}
                fixedPanelSize={factsetPanelFixedSize}
                alignment="vertical"
            >
                {(resizableLayoutProps) => (
                    <React.Fragment>
                        <ResizablePanel
                            index={0}
                            {...resizableLayoutProps}
                            className={classNames(styles.queryLayout, {
                                [styles.viewMode]: !isEditableQueryDraft(props.selectedQuery)
                            })}
                        >
                            <CompletedQueryContentWithPresentations
                                selectedQuery={props.selectedQuery}
                                globalVersions={props.globalVersions}
                                queryLayoutProps={queryLayoutProps}
                                resultsListProps={resultsListProps}
                                presentations={presentationsRequest.data}
                                selectionProps={graphSelectionProps}
                                maxPathLengthOptions={maxLengthOptionsRequest.data}
                                viewTypesOptions={viewTypesOptionsRequest.data}
                                setMaxPathLength={setMaxPathLength}
                            />
                        </ResizablePanel>

                        {!!resultsListProps.selectedResultItem && (
                            <LocalErrorBoundary variant="empty">
                                <ResizablePanel
                                    className={styles.factsetPanelBottom}
                                    index={1}
                                    {...resizableLayoutProps}
                                >
                                    <ResultChainsPanel
                                        queryInfo={props.selectedQuery}
                                        connectionId={resultsListProps.selectedResultItem}
                                        closePanel={resultsListProps.closeResultChainsPanel}
                                        selectedConnectionIndex={
                                            resultsListProps.selectedConnectionIndex!
                                        }
                                        isPanelCollapsed={queryLayoutProps.isFactsetCollapsed}
                                        toggleIsPanelCollapsed={
                                            queryLayoutProps.toggleIsFactsetCollapsed
                                        }
                                    />
                                </ResizablePanel>
                            </LocalErrorBoundary>
                        )}

                        {graphSelectionProps.selectedNodes.length > 0 && (
                            <LocalErrorBoundary variant="empty">
                                <ResizablePanel
                                    className={styles.factsetPanelBottom}
                                    index={1}
                                    {...resizableLayoutProps}
                                >
                                    <GraphResultChainsPanel
                                        queryInfo={props.selectedQuery}
                                        selectedNodes={graphSelectionProps.selectedNodes}
                                        clearNodeSelection={graphSelectionProps.clearNodeSelection}
                                        isPanelCollapsed={queryLayoutProps.isFactsetCollapsed}
                                        toggleIsPanelCollapsed={
                                            queryLayoutProps.toggleIsFactsetCollapsed
                                        }
                                        maxPathLength={maxPathLength}
                                    />
                                </ResizablePanel>
                            </LocalErrorBoundary>
                        )}
                    </React.Fragment>
                )}
            </CustomResizableLayout>

            <RightPanelNavBar queryLayoutProps={queryLayoutProps} />
        </React.Fragment>
    );
}
