import {useInfiniteQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

const PAGE_SIZE = 100;

/** useInfiniteQuery for favorite documents */
export function useListFavoritesInfiniteQuery() {
    return useInfiniteQuery(
        ['favoritesViewMode'],
        () =>
            apiClient.callApi<PageResponse<ViewModeDocInfo>>({
                path: `documents-revisions-service/api/user/documents/favorite?limit=${PAGE_SIZE}`,
                method: 'GET'
            }),

        {
            getNextPageParam: ({nextCursor, remaining}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: PAGE_SIZE
                    };
                }
            },
            keepPreviousData: true
        }
    );
}
