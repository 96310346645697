import {RootElement} from '@genestack/ui';
import React from 'react';
import {QueryCache, QueryClient, QueryClientProvider} from 'react-query';

import styles from './application.module.css';
import {GlobalErrorBoundary} from './components/error-boundary';
import {showErrorNotification} from './components/notifications-center/notifications-store';
import {Routes} from './routes';
import {CallApiResult} from './utils/api-client/parse-fetch-response';

const onRequestErr = (err: unknown) => {
    if ((err as CallApiResult).response && (err as CallApiResult).response.status === 401) {
        return;
    }

    showErrorNotification('Request error, try again later')(err);
};

const queryCache = new QueryCache();
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            useErrorBoundary: false,
            onError: onRequestErr
        },
        mutations: {
            useErrorBoundary: false,
            onError: onRequestErr
        }
    },
    queryCache
});

/** AppPage routes AuthenticatedApp and UnauthenticatedApp */
export const Application = function ApplicationComponent() {
    return (
        <RootElement className={styles.root}>
            <QueryClientProvider client={queryClient}>
                <GlobalErrorBoundary>
                    <Routes />
                </GlobalErrorBoundary>
            </QueryClientProvider>
        </RootElement>
    );
};
