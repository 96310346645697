import {InputProps, MenuHandlerProps} from '@genestack/ui';
import React from 'react';

import {EditModeInput} from './edit-mode-input';
import {TitleViewMode} from './title-view-mode';
import {TitleWithMenu, TitleWithMenuProps} from './title-with-menu';

type TargetProps = Omit<TitleWithMenuProps, 'menu'>;

export interface EditableTitleProps extends TargetProps {
    'data-qa'?: string;
    disableEditing?: boolean;
    value: string;
    onSubmitValue?: (name: string) => void;

    onFocus?: () => void;
    onBlur?: () => void;

    inputProps?: InputProps;
    menu?: MenuHandlerProps['menu'] | null;
}

export interface EditableTitleApi {
    rename: () => void;
}

export const EditableTitle = React.forwardRef<EditableTitleApi, EditableTitleProps>(
    function DocTitleComponent(props, ref) {
        const {
            disableEditing,
            value,
            onSubmitValue,
            onBlur,
            onFocus,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            inputProps = {},
            menu,
            prepend,
            ...restTitleProps
        } = props;

        const [isEditMode, setIsEditMode] = React.useState(false);

        const valueRef = React.useRef(value);
        const inputRef = React.useRef<HTMLInputElement>(null);

        React.useImperativeHandle(ref, () => ({
            rename: () => {
                setIsEditMode(true);
            }
        }));

        React.useEffect(() => {
            const inputNode = inputRef.current;

            if (isEditMode && inputNode) {
                inputNode.setSelectionRange(0, inputNode.value.length);
            }

            if (isEditMode) {
                onFocus?.();
            }

            if (!isEditMode) {
                onBlur?.();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isEditMode]);

        React.useEffect(() => {
            if (value !== valueRef.current && isEditMode) {
                setIsEditMode(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        const handleStopEditMode = () => {
            setIsEditMode(false);
        };

        const handleClickTitle = () => {
            if (!disableEditing) {
                setIsEditMode(true);
            }
        };

        if (isEditMode && onSubmitValue) {
            return (
                <EditModeInput
                    ref={inputRef}
                    value={value}
                    onSubmitValue={onSubmitValue}
                    onCancel={handleStopEditMode}
                />
            );
        }

        if (menu) {
            return (
                <TitleWithMenu
                    onDoubleClick={handleClickTitle}
                    menu={menu}
                    prepend={prepend}
                    {...restTitleProps}
                >
                    {value}
                </TitleWithMenu>
            );
        }

        return <TitleViewMode prepend={prepend}>{value}</TitleViewMode>;
    }
);
