import React from 'react';
import {Timer} from './timer';

const ONE_SECOND = 1000;

interface Props {
    query: FinishedQuery;
}

export function QuerySearchDuration(props: Props) {
    const duration = (function () {
        if (!props.query.startedAt) {
            return 0;
        }

        return Math.max(props.query.finishedAt - props.query.startedAt, ONE_SECOND);
    })();

    return <Timer duration={duration} />;
}
