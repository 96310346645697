/** Element of CategoryTree, adapted for UI needs */
import {RawCategoryTreeElement, CategoriesTree} from '../../../hooks/requests/use-categories-query';

/** Structure representing the category tree element */
export interface CategoryTreeElement extends RawCategoryTreeElement {
    id: string;
    path: string[];
    parents: string[];
    rootName: string;
    childIds: string[];
    childCategoryIds: string[];
}

/** Returns all child ids of the RawCategoryTreeElement */
export function getChildCategoryIds(element: RawCategoryTreeElement) {
    const result: string[] = [];

    function getCategoryId(el: RawCategoryTreeElement) {
        result.push(el.categoryId);

        if (el.children.length) {
            el.children.forEach(getCategoryId);
        }
    }

    element.children.forEach(getCategoryId);

    return result;
}

/** Returns all child ids of the RawCategoryTreeElement */
export function getChildIds(element: RawCategoryTreeElement) {
    const result: string[] = [];

    function getId(el: RawCategoryTreeElement) {
        result.push(el.categoryId);

        if (el.children.length) {
            el.children.forEach(getId);
        }
    }

    element.children.forEach(getId);

    return result;
}

/** Adapt Category tree for UI needs */
export function adaptTree(tree: CategoriesTree): CategoryTreeElement[] {
    let id = 0;
    const rootName = tree.root.name;
    const rootId = `${tree.root.categoryId}${id}`;
    const result: CategoryTreeElement[] = [
        {
            ...tree.root,
            id: rootId,
            path: [],
            parents: [],
            rootName,
            children: [],
            childCategoryIds: getChildCategoryIds(tree.root),
            childIds: getChildIds(tree.root)
        }
    ];

    function getAdaptedElement(
        el: RawCategoryTreeElement,
        pathParam: string[],
        parentsParam: string[]
    ) {
        let path = [...pathParam];
        let parents = [...parentsParam];

        id += 1;

        result.push({
            ...el,
            id: `${tree.root.categoryId}${id}`,
            path,
            parents,
            rootName,
            children: [],
            childCategoryIds: getChildCategoryIds(el),
            childIds: getChildIds(el)
        });

        if (el.children.length) {
            path = [...pathParam, `${tree.root.categoryId}${id}`];
            parents = [...parentsParam, el.categoryId];
        }

        el.children.forEach((e) => {
            getAdaptedElement(e, path, parents);
        });
    }

    tree.root.children.forEach((ch) => {
        getAdaptedElement(ch, [rootId], [tree.root.categoryId]);
    });

    return result;
}
