import {Button, CrossIcon, Typography} from '@genestack/ui';
import * as React from 'react';

import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {edgePropsByType} from '../biokb-graph/constants';
import {RelationPresentation} from '../interface';
import styles from './graph-legend.module.css';

import {LegendEdgeExample} from './legend-edge-example';
import {useGlobalStateVariable} from '../../../providers/global-state';

interface Props {
    isOpen: boolean;
    closePopover: () => void;
}

interface Coordinates {
    top: number;
    left: number;
}

const defaultCoordinates: Coordinates = {
    top: 400,
    left: 500
};

const legendWidth = 150;
const legendHeight = 94;

export function GraphLegendPopover(props: Props) {
    const ref = React.useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = React.useState(false);
    const [coordinates, setCoordinates] = useGlobalStateVariable(
        'queries.legendCoordinates',
        defaultCoordinates
    );
    // set on start dragging, then reset to undefined
    const offset = React.useRef<Coordinates | null>(null);

    const onDraggingMouseMove = React.useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            setCoordinates({top: e.y - offset.current!.top, left: e.x - offset.current!.left});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [offset]
    );

    const onDraggingMouseUp = React.useCallback(() => {
        setIsDragging(false);
        window.removeEventListener('mouseup', onDraggingMouseUp);
        window.removeEventListener('mousemove', onDraggingMouseMove);
        offset.current = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            setIsDragging(true);
            offset.current = {
                top: e.clientY - ref.current!.getBoundingClientRect().top,
                left: e.clientX - ref.current!.getBoundingClientRect().left
            };
            window.addEventListener('mouseup', onDraggingMouseUp);
            window.addEventListener('mousemove', onDraggingMouseMove);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDragging, onDraggingMouseMove, onDraggingMouseUp]
    );

    if (!props.isOpen) {
        return null;
    }

    const top = (function () {
        if (coordinates.top <= 0) {
            return 0;
        }

        if (coordinates.top >= window.innerHeight - legendHeight) {
            return window.innerHeight - legendHeight;
        }

        return coordinates.top;
    })();

    const left = (function () {
        if (coordinates.left <= 0) {
            return 0;
        }

        if (coordinates.left > window.innerWidth - legendWidth) {
            return window.innerWidth - legendWidth;
        }

        return coordinates.left;
    })();

    return createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={classNames(styles.body, {
                [styles.isDragging]: isDragging
            })}
            ref={ref}
            onMouseDown={onMouseDown}
            style={{top, left, width: legendWidth, height: legendHeight}}
        >
            <div className={styles.closeBtn}>
                <Button iconEnd={<CrossIcon />} ghost size="small" onClick={props.closePopover} />
            </div>
            <Typography box="paragraph">
                <LegendEdgeExample
                    edgePropsByStatus={edgePropsByType[RelationPresentation.ASSERTED]}
                />
                - asserted
            </Typography>
            <Typography box="paragraph">
                <LegendEdgeExample
                    edgePropsByStatus={edgePropsByType[RelationPresentation.REACTION]}
                />
                - reaction
            </Typography>
            <Typography box="paragraph">
                <LegendEdgeExample
                    edgePropsByStatus={edgePropsByType[RelationPresentation.MATCHED]}
                    isDashed
                />
                - matched
            </Typography>
        </div>,
        window.document.body
    );
}
