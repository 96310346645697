import {useQuery} from 'react-query';

import {useQueryListCache} from '../queries-sidebar/hooks/update-query-list-cache';
import {apiClient} from '../../../utils/api-client';

const PAGE_LIMIT = 100;

function getMPRecentQueriesRequest() {
    return apiClient.get<FinishedQuery[]>({
        path: 'queries-service/api/queries/admin/finished',
        query: {limit: PAGE_LIMIT}
    });
}

export function useMPRecentQueries() {
    return useQuery('recent-queries', getMPRecentQueriesRequest);
}

export function useMPRecentQueriesCache() {
    return useQueryListCache<FinishedQuery>('recent-queries');
}
