import {useMutation} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

import {useShareQuerySettingsCache} from './use-share-query-settings';

interface MutationParams {
    queryId: string;
    onlyOwnerCanShare: boolean;
}

interface MutationContext {
    onlyOwnerCanShare: boolean;
}

function changeShareQuerySettingsRequest({queryId, onlyOwnerCanShare}: MutationParams) {
    return apiClient.put({
        path: `queries-service/api/queries/${queryId}/share-settings`,
        parameters: {
            onlyOwnerCanShare
        }
    });
}

/** Mutation that allows you to change share query settings */
export function useShareQuerySettingsMutation(queryId: string) {
    const {setOnlyOwnerCanShare} = useShareQuerySettingsCache(queryId);

    return useMutation<unknown, Error, MutationParams, MutationContext>(
        changeShareQuerySettingsRequest,
        {
            onMutate: (params: MutationParams) => {
                return setOnlyOwnerCanShare(params.onlyOwnerCanShare);
            },
            onError: (error, params, context) => {
                if (context) {
                    setOnlyOwnerCanShare(context.onlyOwnerCanShare);
                }
            }
        }
    );
}
