import {Spinner} from '@genestack/ui';
import React from 'react';
import styles from './graph-loading.module.css';

export function GraphLoadingComponent() {
    return (
        <div className={styles.calculatingLayoutSpinnerContainer}>
            <div className={styles.calculatingLayoutSpinnerContent}>
                <Spinner size={48} />
                <div className={styles.calculatingLayoutSpinnerLabel}>
                    Preparing the graph, please wait.
                </div>
                <div>
                    {/* eslint-disable-next-line max-len */}
                    Be warned that changing any graph settings or leaving this page will interrupt
                    the process.
                </div>
            </div>
        </div>
    );
}
