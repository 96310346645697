import {DateTime} from 'luxon';

interface FormattedDateOptions {
    date: number;
    /** whether to show hours and minutes */
    showTime?: boolean;
    /** shows a year in the date if the year is not current */
    skipCurrentYear?: boolean;
    /** if the date is today writes word 'today' instead of date, same for yesterday */
    writeIfTodayOrYesterday?: boolean;
}

/**
 * Returns date formatted for UI
 */
export const getFormattedDate = ({
    date,
    showTime,
    skipCurrentYear = true,
    writeIfTodayOrYesterday = false
}: FormattedDateOptions) => {
    const dateTime = DateTime.fromJSDate(new Date(date));

    const {isToday, isYesterday} = (function () {
        const now = DateTime.fromJSDate(new Date());
        const isCurrentMonth = dateTime.year === now.year && dateTime.month === now.month;

        return {
            isToday: isCurrentMonth && dateTime.day === now.day,
            isYesterday: isCurrentMonth && dateTime.day === now.day - 1
        };
    })();

    let format = (function () {
        if (writeIfTodayOrYesterday && (isToday || isYesterday)) {
            return isToday ? "'Today'" : "'Yesterday'";
        }

        return 'LLLL d';
    })();

    const shouldDisplayYear = !skipCurrentYear || DateTime.local().year !== dateTime.year;

    if (shouldDisplayYear || showTime) {
        format += ',';
    }

    if (shouldDisplayYear) {
        format += ' y';
    }

    if (showTime) {
        format += ' HH:mm';
    }

    return dateTime.toFormat(format);
};

/** Date time in format 01/01/22, 08:17 */
export const getFormattedDateTimeShort = (date: number) => {
    const dateTime = DateTime.fromJSDate(new Date(date));

    return dateTime.toFormat('dd/MM/yyyy, HH:mm');
};
