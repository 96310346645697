import axios from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';

interface UserSession {
    authenticatedAt: number;
    expiresAt: number;
    active: boolean;
}

function getUserSessionRequest(userId: string) {
    return axios
        .get<UserSession>(`/users-service/api/users/${userId}/session`)
        .then((res) => res.data);
}

/** Returns user session info by user id */
export function useSessionQuery(userId: string, options?: UseQueryOptions<UserSession>) {
    return useQuery<UserSession>(
        ['getUserSessionRequest', userId],
        () => getUserSessionRequest(userId),
        options
    );
}
