import {Typography} from '@genestack/ui';
import React from 'react';

import {CommitDto} from '../../../../providers/version';
import {getFormattedDate} from '../../../../utils/common';
import {CommitText} from '../commit-text';

interface Props {
    lastAction: CommitDto;
}

/** Shows info about the last action in the menu caption */
export const LastActionCaption = ({lastAction}: Props) => (
    <>
        <Typography variant="caption" ellipsis>
            <CommitText action={lastAction.action} renamedInCommit={lastAction.renamedInCommit} />
            {` by ${lastAction.author.name || lastAction.author.email}`}
        </Typography>
        <Typography intent="quiet" variant="caption">
            {getFormattedDate({date: lastAction.createdAt, showTime: true})}
        </Typography>
    </>
);
