import React from 'react';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import usePrevious from 'react-use/lib/usePrevious';
import {Button, chain, Controls, ControlsItem, Notification, Typography} from '@genestack/ui';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {
    GraphLayout,
    GridCoordinates,
    GraphNode,
    GraphViewType,
    GraphEdge
} from '../../../../../components/graph/interface';
import {QueryGraphLayoutAlgorithm} from '../../../../../components/graph/cytoscape-graph/interface';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {defaultPan, defaultZoom, useCameraProps} from './use-camera-props';
import {showNotification} from '../../../../../components/notifications-center';
import {useCalculateLayout} from '../../../../../components/graph/cytoscape-graph/use-calculate-layout';
import {NotificationElement} from '../../../../../components/notifications-center/notifications-store';
import {GraphPresentation, GraphPresentationDetails} from './interface';

interface Props {
    maxPathLength: number;
    selectedViewType: GraphViewType;
    queryId: number;
    nodes?: GraphNode[];
    edges?: GraphEdge[];
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    handlePresentationEdited: () => void;
    isPresentationEdited: boolean;
}

interface SavedLayoutNotificationProps {
    onClick?: () => void;
    onClose?: () => void;
    isDestroyed?: boolean;
}

function getSavedLayoutNotification(props: SavedLayoutNotificationProps) {
    return (
        <Notification
            countdown={props.isDestroyed ? 'active' : 'none'}
            countdownDuration={0}
            onClose={props.onClose}
        >
            <Typography intent="warning">The previous graph layout was left unchanged</Typography>
            <Controls justify="end">
                <ControlsItem>
                    <Button size="small" onClick={props.onClick}>
                        Rebuild layout
                    </Button>
                </ControlsItem>
            </Controls>
        </Notification>
    ) as NotificationElement;
}

export function useLayoutAndCameraProps(props: Props) {
    const {
        queryId,
        maxPathLength,
        selectedViewType,
        nodes,
        edges,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited,
        isPresentationEdited
    } = props;

    const [isBrowserUnsupported, setIsBrowserUnsupported] = React.useState<boolean>();

    const [layoutAlgorithm, setLayoutAlgorithm, clearLayoutAlgorithm] = useLsValue<
        QueryGraphLayoutAlgorithm,
        [number, number],
        []
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        getKey: ([qId, pId]) => `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.layoutAlgorithm`,
        rewriteKeyParams: [],
        defaultValue: selectedPresentationDetails.layoutAlgorithm,
        validateValue: (value) =>
            Object.values(QueryGraphLayoutAlgorithm).includes(value as QueryGraphLayoutAlgorithm)
    });
    const [isCustomLayout, setIsCustomLayout, clearIsCustomLayout] = useLsValue<
        'true' | 'false',
        [number, number],
        [number, GraphViewType, QueryGraphLayoutAlgorithm]
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        rewriteKeyParams: [maxPathLength, selectedViewType, layoutAlgorithm],
        getKey: ([qId, pId], [mPLength, viewType, lAlgo]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${mPLength}.${viewType}.${lAlgo}.isCustomLayout`,
        defaultValue: isPresentationEdited
            ? 'false'
            : (String(selectedPresentationDetails.isLayoutCustom) as 'true' | 'false'),
        validateValue: (value) => value === 'true' || value === 'false'
    });
    const [nodePositions, setNodePositions, clearNodePositions] = useLsValue<
        string,
        [number, number],
        [number, GraphViewType, QueryGraphLayoutAlgorithm]
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        rewriteKeyParams: [maxPathLength, selectedViewType, layoutAlgorithm],
        getKey: ([qId, pId], [mPLength, viewType, lAlgo]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${mPLength}.${viewType}.${lAlgo}.layout`,
        defaultValue: (function () {
            if (isPresentationEdited || !Object.keys(selectedPresentationDetails.layout).length) {
                return '';
            }

            return selectedPresentationDetails.layout;
        })(),
        validateValue: (value) =>
            !value ||
            Object.values(JSON.parse(value) as GraphLayout).every(
                (coordinates: GridCoordinates) =>
                    !Number.isNaN(coordinates.x) && !Number.isNaN(coordinates.y)
            )
    });

    const {setZoom, setPan, ...restCameraProps} = useCameraProps({
        queryId,
        layoutAlgorithm,
        maxPathLength,
        selectedViewType,
        selectedPresentation,
        selectedPresentationDetails
    });

    const calculateLayout = useCalculateLayout({
        nodes,
        edges,
        setNodePositions,
        setIsBrowserUnsupported,
        layoutAlgorithm,
        nodePositions
    });

    const previousLoadedNodes = usePreviousDistinct(nodes, (prev, next) => !next);
    const previousNodePositions = usePreviousDistinct(nodePositions, (prev, next) => !next);

    const previousZoom = usePrevious(restCameraProps.zoom);
    const previousPan = usePreviousDistinct(
        restCameraProps.pan,
        (prev, next) => JSON.stringify(next) === JSON.stringify(defaultPan)
    );

    const nodePositionsRef = React.useRef(nodePositions);
    nodePositionsRef.current = nodePositions;
    const prevNodePositionsRef = React.useRef(previousNodePositions);
    prevNodePositionsRef.current = previousNodePositions;
    const previousLoadedNodesRef = React.useRef(previousLoadedNodes);
    previousLoadedNodesRef.current = previousLoadedNodes;
    const previousZoomRef = React.useRef(previousZoom);
    previousZoomRef.current = previousZoom;
    const previousPanRef = React.useRef(previousPan);
    previousPanRef.current = previousPan;
    // hack for manually closing the notification, see task JBKB-3237
    const isShowingNotificationRef = React.useRef(false);

    React.useEffect(() => {
        if (!nodes || nodePositionsRef.current) {
            return;
        }

        if (
            prevNodePositionsRef.current &&
            previousLoadedNodesRef.current &&
            previousLoadedNodesRef.current.length > nodes.length
        ) {
            // if new nodes arr has fewer nodes then previous nodes arr,
            // then try to save the layout using rawNodeIds...
            const previousNodesPositionsParsed: GraphLayout = JSON.parse(
                prevNodePositionsRef.current
            );
            const newPositions: GraphLayout = {};
            const positionsByRawNodeId: GraphLayout = {};

            previousLoadedNodesRef.current.forEach((node) =>
                node.rawNodeIds.forEach((rawNodeId) => {
                    positionsByRawNodeId[rawNodeId] = previousNodesPositionsParsed[node.id];
                })
            );

            nodes.forEach((node) => {
                newPositions[node.id] = positionsByRawNodeId[node.rawNodeIds[0]];
            });

            setNodePositions(JSON.stringify(newPositions));
            setPan(previousPanRef.current || {x: 0, y: 0});
            setZoom(previousZoomRef.current || 0);

            let closeNotificationFun: () => void;
            const notification = getSavedLayoutNotification({
                onClick: () => {
                    setNodePositions('');
                    calculateLayout();
                    closeNotificationFun!();
                    setPan(defaultPan);
                    setZoom(Number(defaultZoom));
                },
                onClose: () => {
                    isShowingNotificationRef.current = false;
                }
            });

            const replaceNotification = showNotification(notification);
            isShowingNotificationRef.current = true;
            closeNotificationFun = () => {
                if (isShowingNotificationRef.current) {
                    isShowingNotificationRef.current = false;
                    replaceNotification(getSavedLayoutNotification({isDestroyed: true}));
                }
            };

            return closeNotificationFun;
        }

        // ...else just recalculate layout
        calculateLayout();
    }, [nodes, setNodePositions, setZoom, setPan, calculateLayout, isPresentationEdited]);

    const handleNodeMoved = React.useCallback(
        (newLayout: GraphLayout) => {
            setIsCustomLayout('true');
            setNodePositions(JSON.stringify(newLayout));
            handlePresentationEdited();
        },
        [setIsCustomLayout, setNodePositions, handlePresentationEdited]
    );

    return {
        layoutAlgorithm: layoutAlgorithm as QueryGraphLayoutAlgorithm,
        clearLayoutAlgorithm,
        nodePositions: nodePositions && (JSON.parse(nodePositions) as GraphLayout),
        clearNodePositions,
        handleNodeMoved,
        isCustomLayout: isCustomLayout === 'true',
        clearIsCustomLayout,
        selectLayoutAlgorithm: chain(setLayoutAlgorithm, handlePresentationEdited)!,
        isBrowserUnsupported,
        setZoom,
        setPan,
        setNodePositions,
        setIsCustomLayout,
        ...restCameraProps
    };
}
