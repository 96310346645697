import {RootRef, Typography} from '@genestack/ui';
import React from 'react';

import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';
import {getFormattedDate} from '../../../utils/common';
import {removeElementRef, saveElementRef} from '../../scroll-to-element';

import styles from './dialog-version-item.module.css';

interface Props {
    version: GlobalVersionData;
    isLatest?: boolean;
    isActive?: boolean;
}

/** Element of version history displaying the global version */
export const DialogVersionItem = ({version, isLatest, isActive}: Props) => {
    const divElement = React.useRef(null);

    React.useEffect(() => {
        saveElementRef(divElement, version.id);

        return () => {
            removeElementRef(version.id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RootRef rootRef={divElement}>
            <div className={styles.container}>
                <Typography variant="section" className={styles.title}>
                    {(isLatest || isActive) && (
                        <Typography as="span" className={styles.label} intent="quiet">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isLatest ? 'Latest version' : isActive ? 'Active version' : null}
                        </Typography>
                    )}
                    <strong>{version.label}</strong>
                </Typography>

                <Typography>
                    {getFormattedDate({
                        date: version.createdAt,
                        skipCurrentYear: true,
                        showTime: true
                    })}{' '}
                    by <i>{version.author.name || version.author.email}</i>
                </Typography>
                <Typography variant="caption" intent="quiet" className={styles.description}>
                    {version.description}
                </Typography>
            </div>
        </RootRef>
    );
};
