import React from 'react';

import {OntologyTreeItem} from '../ontology-query';

import {TreeItem} from './tree-item';

interface Props {
    docAccession: string;
    data: OntologyTreeItem;
    onNavigate: (nodeId: string) => void;
}

/** Node view item component for render element of tree */
export function NodeViewTree(props: Props) {
    const handleGoToDeclaration = () => {
        props.onNavigate(props.data.elementId);
    };

    const hasChildren = props.data.children.length > 0;

    return (
        <TreeItem
            isInteractive={props.docAccession === props.data.accession}
            data={props.data}
            goToItem={handleGoToDeclaration}
        >
            {hasChildren &&
                props.data.children.map((item) => (
                    <NodeViewTree {...props} key={item.elementId} data={item} />
                ))}
        </TreeItem>
    );
}
