import {Avatar, Badge, Controls, ControlsItem, ListItemProps, Typography} from '@genestack/ui';
import classNames from 'classnames';
import * as React from 'react';

import {UiPermission, UserIdentity, UserRole} from '../../../typings/user';
import {getInitials} from '../../utils/initials';
import {HighlightedText} from '../highlighted-text';
import {ListItemLink} from '../list-item-link';
import {UserDeactivatedBadge} from '../user-deactivated-badge/user-deactivated-badge';

import styles from './user-item.module.css';

/** Requisites for UserItem component */
export interface UserItemProps extends ListItemProps {
    user: UserIdentity;
    highlight?: boolean;
    highLightedText: string;
    isCurrentUser: boolean;
}

const HEADER_HEIGHT = 40;

const ROLE_BADGE = {
    [UserRole.EXPLORER]: 'EXPLORER',
    [UserRole.BTB_EDITOR]: 'BTB EDITOR',
    [UserRole.EDITOR]: 'EDITOR'
};

function getRoleLabels(user: UserIdentity) {
    const isAdmin = user.permissions.indexOf(UiPermission.manageUsers) >= 0;

    return [...(isAdmin ? ['USER ADMIN'] : []), ROLE_BADGE[user.role]];
}

// eslint-disable-next-line react/display-name
export const UserItem = React.memo(
    ({user, highlight, highLightedText = '', isCurrentUser, ...restProps}: UserItemProps) => {
        const elementRef = React.useRef<HTMLAnchorElement>(null);

        React.useEffect(() => {
            if (highlight && elementRef.current) {
                elementRef.current.scrollIntoView();

                const scrolledY = window.scrollY;

                if (scrolledY) {
                    window.scroll(0, scrolledY - HEADER_HEIGHT);
                }
            }
        }, [highlight]);

        const initials = getInitials(user.name);

        return (
            <ListItemLink
                {...restProps}
                ref={elementRef}
                to={`/users/${user.id}`}
                className={classNames(styles.root, {
                    [styles.highlight]: highlight
                })}
            >
                <Controls>
                    <ControlsItem>
                        <Avatar
                            initials={initials}
                            classes={{
                                root: user.active ? null : styles.avatar,
                                letter: user.active ? null : styles.avatarLetter
                            }}
                        />
                    </ControlsItem>

                    <ControlsItem shrink grow>
                        <Controls justify="space-between">
                            <ControlsItem shrink>
                                <Controls>
                                    <ControlsItem shrink>
                                        <Typography
                                            ellipsis
                                            intent={user.active ? 'no-intent' : 'quiet'}
                                            title={user.name}
                                        >
                                            <HighlightedText
                                                value={highLightedText}
                                                text={user.name}
                                            />
                                        </Typography>
                                    </ControlsItem>

                                    <ControlsItem>
                                        {isCurrentUser && <Badge disableTextTransform>You</Badge>}

                                        {!user.active && <UserDeactivatedBadge />}
                                    </ControlsItem>
                                </Controls>
                            </ControlsItem>

                            <ControlsItem>
                                <Controls>
                                    {getRoleLabels(user).map((label, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <ControlsItem key={index}>
                                            <Badge ghost>{label}</Badge>
                                        </ControlsItem>
                                    ))}
                                </Controls>
                            </ControlsItem>
                        </Controls>

                        <Typography intent="quiet" variant="caption">
                            <HighlightedText value={highLightedText} text={user.email} />
                        </Typography>
                    </ControlsItem>
                </Controls>
            </ListItemLink>
        );
    }
);
