import {Typography, Link} from '@genestack/ui';
import React from 'react';

import {OntologyTreeItem} from '../../ontology-query';

interface Props {
    isInteractive: boolean;
    item: OntologyTreeItem;
    goToItem: () => void;
}

/** Component for displaying of the node label */
export function TreeItemLabel({isInteractive, item, goToItem}: Props) {
    if (!item.children.length) {
        return (
            <Typography title={item.name} intent="quiet" variant="section">
                {item.name}
            </Typography>
        );
    }

    if (isInteractive) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link title={item.name} onClick={goToItem} as="div">
                {item.name}
            </Link>
        );
    }

    return <Typography title={item.name}>{item.name}</Typography>;
}
