/** Parsed fetch response */
export interface CallApiResult {
    /** Original fetch Response */
    response: Response;
    /** Response string content */
    text: string | undefined;
    /**
     * Parsed JSON from response.
     * It could be `undefined` in case of ParseError
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json: any | undefined;
    /** Error that could be thrown on paring original response */
    parseError: Error | undefined;
}

/** Parses fetch response */
export async function parseFetchResponse(response: Response): Promise<CallApiResult> {
    let text: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let json: any | undefined;
    let parseError: Error | undefined;

    try {
        text = await response.text();
        json = JSON.parse(text || '{}');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        parseError = e;
    }

    return {response, text, json, parseError};
}
