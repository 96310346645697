import React from 'react';
import {useHistory} from 'react-router-dom';

import {Group, GroupProps} from '../../../../../../components/group';
import {CategoryId} from '../../../hooks/categories/use-categories-infinite-query';
import {useCreateDocumentMutation} from '../../../hooks/use-create-document-mutation';

import {CategorySubTreeContent, CategoryTreeContentProps} from './category-sub-tree-content';
import styles from './category-tree.module.css';

export interface Props extends GroupProps, Omit<CategoryTreeContentProps, 'categoryId'> {
    categoryId?: CategoryId;
    categoryTreePath: string[];
}

export function CategorySubTree({
    docType,
    categoryId = 'root',
    count,

    branchOpenState,
    changeIsCategoryOpenState,
    categoryTreePath,

    offset = 0,
    depth = 0,
    children,
    ...restProps
}: Props) {
    const isOpen = Boolean(branchOpenState);
    const {push} = useHistory();

    const createDocumentMutation = useCreateDocumentMutation();

    const onTitleClick = () => {
        changeIsCategoryOpenState(!isOpen, categoryTreePath);
    };

    const documentsCount = count;

    const handleButtonClick = async () => {
        const draftDocument = await createDocumentMutation.mutateAsync({
            type: docType,
            categoryNodeId: categoryId
        });
        push(`/documents/edit/${draftDocument.accession}`);
    };

    return (
        <Group
            {...restProps}
            isOpen={isOpen}
            onButtonClick={categoryId !== 'root' ? handleButtonClick : undefined}
            titleProps={categoryId === 'root' ? undefined : {className: styles.title}}
            onTitleClick={onTitleClick}
            count={documentsCount}
            countProps={categoryId === 'root' ? undefined : {className: styles.count}}
            depth={depth + 1}
        >
            {isOpen && (
                <CategorySubTreeContent
                    docType={docType}
                    categoryId={categoryId}
                    branchOpenState={branchOpenState}
                    changeIsCategoryOpenState={changeIsCategoryOpenState}
                    offset={offset}
                    categoryTreePath={categoryTreePath}
                >
                    {children}
                </CategorySubTreeContent>
            )}
        </Group>
    );
}
