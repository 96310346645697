import {Button, ListItemProps} from '@genestack/ui';
import React from 'react';

import {DocumentNodeItem} from './document-node-item';
import styles from './document-nodes.module.css';

interface Props {
    isLoading: boolean;

    docAccession: string;
    nodes: DocumentNode[];

    hasNextPage: boolean;
    onLoadNextPage: () => void;

    nodeProps?: ListItemProps;
    getDocumentLink: (docId: string, nodeId?: string) => string;
}

export function DocumentNodes({
    isLoading,

    docAccession,
    nodes,

    hasNextPage,
    onLoadNextPage,
    getDocumentLink,

    nodeProps = {}
}: Props) {
    return (
        <>
            {nodes.map((node) => {
                return (
                    <DocumentNodeItem
                        key={node.id}
                        documentNode={node}
                        nodeProps={nodeProps}
                        docAccession={docAccession}
                        getDocumentLink={getDocumentLink}
                    />
                );
            })}

            {hasNextPage && (
                <Button
                    size="small"
                    className={styles.nodesModeBtn}
                    onClick={onLoadNextPage}
                    disabled={isLoading}
                >
                    {isLoading ? 'Loading...' : 'Load more nodes'}
                </Button>
            )}
        </>
    );
}
