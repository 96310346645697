import {ListItem, Preloader as UIPreloader, PreloaderProps} from '@genestack/ui';
import React from 'react';

import styles from './preloader.module.css';

const PRELOADER_COUNT_ITEMS = 6;

/** Styled preloader */
export function Preloader({show, children, ...restProps}: PreloaderProps) {
    const Wrapper = show ? 'div' : React.Fragment;
    const wrapperProps = show ? {className: styles.root} : {};

    return (
        <Wrapper {...wrapperProps}>
            <UIPreloader
                show={show}
                count={PRELOADER_COUNT_ITEMS}
                wrapEach={ListItem}
                {...restProps}
            >
                {children}
            </UIPreloader>
        </Wrapper>
    );
}
