import {Typography} from '@genestack/ui';
import {DateTime} from 'luxon';
import memoizeOne from 'memoize-one';
import React from 'react';

import styles from './query-history.module.css';

type QueriesByDay = Map<string, QueryInfo[]>;

const getQueriesByDay = (queryInfos: QueryInfo[]) =>
    queryInfos
        .sort(({createdAt: aCreatedAt}, {createdAt: bCreatedAt}) =>
            aCreatedAt === bCreatedAt ? -1 : bCreatedAt - aCreatedAt
        )
        .reduce<QueriesByDay>((map, queryInfo) => {
            const isoDate = DateTime.fromMillis(queryInfo.createdAt).toISODate()!;
            map.set(isoDate, [...(map.get(isoDate) || []), queryInfo]);

            return map;
        }, new Map());

const memoizedGetQueriesByDay = memoizeOne(getQueriesByDay);

const isToday = (dateTime: DateTime) => DateTime.utc().hasSame(dateTime, 'day');
const isYesterday = (dateTime: DateTime) => DateTime.utc().hasSame(dateTime.plus({days: 1}), 'day');

const DateTimeLabel = ({value}: {value: DateTime}) => (
    <Typography className={styles.datetime} intent="quiet" variant="caption">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isToday(value)
            ? 'Today'
            : isYesterday(value)
              ? 'Yesterday'
              : value.toFormat('LLLL dd, yyyy')}
    </Typography>
);

interface Props {
    children: (queryInfos: QueryInfo[]) => React.ReactNode;
    queryInfos: QueryInfo[];
}

/** A component rendering a date-ordered history of queries */
export const QueryHistory = ({children: renderQueries, queryInfos}: Props) => {
    const queriesByDay = memoizedGetQueriesByDay(queryInfos);

    return (
        <>
            {Array.from(queriesByDay.keys()).map((day) => {
                return (
                    <div key={day}>
                        <DateTimeLabel value={DateTime.fromISO(day, {zone: 'utc'})} />
                        {renderQueries(queriesByDay.get(day) || [])}
                    </div>
                );
            })}
        </>
    );
};
