import * as React from 'react';
import {logError} from '../utils/log-error';

interface Props<
    Value extends string,
    ChangeEntityParams extends Array<unknown>,
    RewriteKeyParams extends Array<unknown>
> {
    // these params remove previous key in storage when they change
    rewriteKeyParams: RewriteKeyParams;
    // these params keep previous key in storage when they change
    changeEntityParams: ChangeEntityParams;
    // make sure this function doesn't change ever
    getKey: (
        changeEntityParams: ChangeEntityParams,
        rewriteKeyParams: RewriteKeyParams
    ) => string | undefined;
    defaultValue: Value;
    validateValue?: (value: Value) => boolean;
}

export function useLsValue<
    Value extends string,
    ChangeEntityParams extends Array<unknown>,
    RewriteKeyParams extends Array<unknown>
>(
    props: Props<Value, ChangeEntityParams, RewriteKeyParams>
): [Value, (arg: Value) => void, () => void] {
    const {getKey, changeEntityParams, rewriteKeyParams, defaultValue, validateValue} = props;
    const [valueUpdatedTimes, setValueUpdatedTimes] = React.useState<number>(0);
    const previousRewriteKeyParamsRef = React.useRef(rewriteKeyParams);
    const previousChangeEntityParamsRef = React.useRef(changeEntityParams);

    const value: Value | undefined = React.useMemo(() => {
        const key = getKey(changeEntityParams, rewriteKeyParams);

        if (!key) {
            return undefined;
        }

        const val = localStorage.getItem(key) as Value;
        const isInvalidValue = val && validateValue && !validateValue(val);
        if (val && !isInvalidValue) {
            return val;
        }

        if (isInvalidValue) {
            logError(
                new Error(`Invalid localstorage value for key ${key}, provided value: ${val}`)
            );
        }

        return defaultValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...changeEntityParams, ...rewriteKeyParams, valueUpdatedTimes, defaultValue]);

    React.useEffect(() => {
        const rewriteKeyParamsChanged =
            rewriteKeyParams.toString() !== previousRewriteKeyParamsRef.current.toString();
        const changeEntityParamsChanged =
            changeEntityParams.toString() !== previousChangeEntityParamsRef.current.toString();
        const previousKey = getKey(
            previousChangeEntityParamsRef.current,
            previousRewriteKeyParamsRef.current
        );

        if (rewriteKeyParamsChanged && !changeEntityParamsChanged && previousKey) {
            localStorage.removeItem(previousKey);
        }

        previousRewriteKeyParamsRef.current = rewriteKeyParams;
        previousChangeEntityParamsRef.current = changeEntityParams;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...rewriteKeyParams, ...changeEntityParams]);

    const setValue = React.useCallback(
        (val: Value) => {
            const key = getKey(changeEntityParams, rewriteKeyParams);

            if (!key) {
                return;
            }

            localStorage.setItem(key, val);
            setValueUpdatedTimes((prevState) => prevState + 1);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...changeEntityParams, ...rewriteKeyParams]
    );

    const clearValue = React.useCallback(() => {
        const key = getKey(changeEntityParams, rewriteKeyParams);

        if (!key) {
            return;
        }

        localStorage.removeItem(key);
        setValueUpdatedTimes((prevState) => prevState + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...changeEntityParams, ...rewriteKeyParams]);

    return [value || defaultValue, setValue, clearValue];
}
