import {Divider, ScrollView, Typography} from '@genestack/ui';
import React from 'react';

import {WindowTitle} from '../../../components/window-title';

import styles from './query-management-panel.module.css';
import {RecentQueries} from './recent-queries';
import {UnresolvedQueries} from './unresolved-queries';
import {useMPRecentQueries} from './use-mp-recent-queries';
import {useMPUnresolvedQueries} from './use-mp-unresolved-queries';

export function QueryManagementPanel() {
    const MPUnresolvedQueries = useMPUnresolvedQueries();
    const MPRecentQueries = useMPRecentQueries();

    const getRecentQueryById = (id: number) => {
        return MPRecentQueries.data?.find((query) => query.id === id);
    };

    return (
        <WindowTitle text="Queries">
            <div className={styles.container}>
                <ScrollView>
                    <Typography intent="quiet" variant="title" className={styles.pageHeader}>
                        Query management panel
                    </Typography>

                    {/* tslint:disable-next-line: no-magic-numbers */}
                    <Divider variant="transparent" gap={4} />

                    <UnresolvedQueries
                        unresolvedQueriesRequest={MPUnresolvedQueries}
                        getRecentQueryById={getRecentQueryById}
                    />

                    {/* tslint:disable-next-line: no-magic-numbers */}
                    <Divider variant="transparent" gap={3} />

                    <RecentQueries recentQueriesRequest={MPRecentQueries} />
                </ScrollView>
            </div>
        </WindowTitle>
    );
}
