import {useInfiniteQuery} from 'react-query';
import {UseInfiniteQueryOptions} from 'react-query/types/react/types';

import {apiClient} from '../../../../../utils/api-client';

export type CategoryId = string | 'root';

export interface CategoryTreeElement {
    categoryNodeId: CategoryId;
    // todo: can be null, write correct types
    // eslint-disable-next-line no-use-before-define
    children: CategoriesPageResponse;
    documentsCount: number;
    name: string;
}

export type CategoriesPageResponse = PageResponse<CategoryTreeElement, string>;

interface Props {
    /** Used to update the count of documents for the root category */
    categoryId: CategoryId;
    pageSize: 'variable' | number;
    options?: UseInfiniteQueryOptions<CategoryTreeElement>;
}

const CATEGORIES_PAGE_LIMITS = {
    firstPage: 11,
    secondPage: 89,
    anyPage: 100
};

export function getPageSize(offset = 0, pageSize: Props['pageSize'] = 'variable') {
    if (pageSize !== 'variable') {
        return pageSize;
    }

    if (offset >= CATEGORIES_PAGE_LIMITS.anyPage) {
        return CATEGORIES_PAGE_LIMITS.anyPage;
    }

    if (offset >= CATEGORIES_PAGE_LIMITS.firstPage) {
        return CATEGORIES_PAGE_LIMITS.secondPage;
    }

    return CATEGORIES_PAGE_LIMITS.firstPage;
}

export function useCategoriesInfiniteQuery(props: Props) {
    return useInfiniteQuery<CategoryTreeElement>(
        ['categories', props.categoryId, props.pageSize],
        ({pageParam = {cursor: null, limit: getPageSize(0, props.pageSize)}}) =>
            apiClient.get<CategoryTreeElement>({
                path: 'editor-documents-service/api/documents/categories',
                query: {
                    cursor: pageParam.cursor,
                    limit: pageParam.limit,
                    'parent-category-node-id': props.categoryId === 'root' ? null : props.categoryId
                }
            }),
        {
            ...props.options,
            getNextPageParam: ({children: {remaining, total, nextCursor}}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: getPageSize(total - remaining, props.pageSize)
                    };
                }
            },
            keepPreviousData: true
        }
    );
}
