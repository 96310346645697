import {Typography} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './stub-message.module.css';

type TargetProps = React.HTMLAttributes<HTMLDivElement>;

interface Props extends TargetProps {
    caption?: React.ReactNode;
}

/** Message component */
export function StubMessage({className, caption, children, ...restProps}: Props) {
    return (
        <div className={classNames(styles.root, className)} {...restProps}>
            {caption && (
                <Typography box="paragraph" variant="header" intent="alarm">
                    {caption}
                </Typography>
            )}
            <Typography inverted>{children}</Typography>
        </div>
    );
}
