import classNames from 'classnames';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {
    draftQuerySource,
    publishedQuerySource
} from '../../../../../webeditor/biokb-editor-adapters';
import {BusyIndicator} from '../../../../components/busy-indicator';
import {BelEditorWrapper} from '../../../../components/editor';
import {generateNavigationPath} from '../../../../utils/create-navigation-path';
import {isQueryDraft} from '../../utils';

import {QueryDraftFooter} from './query-draft-footer';
import {QueryEditorHeader} from './query-editor-header';
import styles from './query-editor.module.css';
import {NonDraftQueryFooter} from './non-draft-query-footer';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';

interface Props {
    selectedQuery: QueryInfo;
    isCollapsed: boolean;
    toggleIsCollapsed: () => void;
    globalVersions: GlobalVersionData[];
}

function queryDataSource(selectedQuery: QueryInfo) {
    if (isQueryDraft(selectedQuery)) {
        return draftQuerySource(
            selectedQuery.id,
            selectedQuery.name,
            selectedQuery.coordinates.entityId,
            selectedQuery.globalVersionId
        );
    }

    return publishedQuerySource(selectedQuery.id);
}

export function QueryEditor(props: Props) {
    const {selectedQuery, isCollapsed, toggleIsCollapsed} = props;
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false);

    const handleNavigate = (accession: string | null, nodeId: string | null) => {
        history.push(
            `/documents/explore/${generateNavigationPath(
                accession,
                nodeId,
                selectedQuery.globalVersionId
            )}`
        );
    };

    return (
        <div
            className={classNames(styles.editorLayout, {
                [styles.draft]: isQueryDraft(selectedQuery)
            })}
        >
            <QueryEditorHeader
                selectedQuery={selectedQuery}
                toggleIsBodyCollapsed={toggleIsCollapsed}
                isBodyCollapsed={isCollapsed}
            />

            <div
                className={classNames(styles.editorContainer, {
                    [styles.draft]: isQueryDraft(selectedQuery),
                    [styles.collapsed]: isCollapsed
                })}
            >
                <BelEditorWrapper
                    className={styles.editor}
                    documentSource={queryDataSource(selectedQuery)}
                    onLoadingStateChange={setIsLoading}
                    onNavigate={handleNavigate}
                />

                {isLoading && <BusyIndicator />}
            </div>

            {(function () {
                if (isCollapsed) {
                    return null;
                }

                if (isQueryDraft(selectedQuery)) {
                    return (
                        <QueryDraftFooter
                            queryDraft={selectedQuery}
                            globalVersions={props.globalVersions}
                        />
                    );
                }

                return (
                    <NonDraftQueryFooter
                        selectedQuery={selectedQuery}
                        globalVersions={props.globalVersions}
                    />
                );
            })()}
        </div>
    );
}
