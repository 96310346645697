import {DocumentStatus} from '../api';

import {
    useEditModeDocsInfiniteQuery,
    useEditModeDocsInfiniteQueryCache
} from './use-edit-mode-docs-infinite-query';

/** Hook for loading Deleted documents */
export function useDeletedDocumentsQuery() {
    return useEditModeDocsInfiniteQuery({
        queryKey: 'deleted',
        statuses: [DocumentStatus.DELETED]
    });
}

/** Query Cache for Deleted documents */
export function useDeletedDocumentsQueryCache() {
    return useEditModeDocsInfiniteQueryCache('deleted', true);
}
