import {
    Badge,
    Header,
    HeaderBlock,
    HeaderItemCell,
    Tooltip,
    TooltipHandler,
    Tabs,
    Tab
} from '@genestack/ui';
import React from 'react';
import {Link} from 'react-router-dom';

import {useNavigationInfo} from '../../hooks/use-navigation-info';
import {useJbkbSocketReadyState} from '../../providers/jbkb-socket';
import {CreateVersionDialog, ShowVersionsDialog} from '../global-versions';
import {UserMenu} from '../user-menu';

import styles from './application-header.module.css';
import {ApplicationMenu, ApplicationMenuLinkItem} from './application-menu';
import {GlobalVersionData} from '../../hooks/global-version/use-global-version-query';
import {useGlobalVersionDialogsGlobalValue} from '../../providers/version/use-global-version-dialogs-global-value';

interface Props {
    currentGlobalVersion?: GlobalVersionData;
    globalVersions: GlobalVersionData[] | null;
}

export const AppHeader = (props: Props) => {
    const {createVersionDialogOpen, closeCreateVersionDialog} =
        useGlobalVersionDialogsGlobalValue();

    const socketReadyState = useJbkbSocketReadyState();
    const navigationInfo = useNavigationInfo();

    const navigationItems = navigationInfo.list.map((item) => (
        <ApplicationMenuLinkItem
            key={item.value}
            isActive={navigationInfo.activeItem === item.value}
            title={item.title}
            path={item.path}
        />
    ));

    return (
        <>
            <Header className={styles.root}>
                <ApplicationMenu navigationItems={navigationItems} />

                <HeaderBlock shrink>
                    <Tabs className={styles.tabs} value={navigationInfo.activeItem} variant="ghost">
                        {navigationInfo.list.map((item) => (
                            <Tab
                                key={item.value}
                                value={item.value}
                                component={Link}
                                to={item.path}
                                title={item.title}
                                className={styles.tab}
                            >
                                {item.label}
                            </Tab>
                        ))}
                    </Tabs>
                </HeaderBlock>

                <HeaderBlock shrink>
                    <HeaderItemCell>
                        {socketReadyState !== 'open' && (
                            <TooltipHandler
                                tooltip={
                                    <Tooltip style={{width: 'max-content'}}>
                                        Status updates can't be received at the moment
                                    </Tooltip>
                                }
                            >
                                <Badge ghost disableTextTransform>
                                    {socketReadyState === 'offline' ? 'offline' : 'connecting…'}
                                </Badge>
                            </TooltipHandler>
                        )}
                    </HeaderItemCell>
                </HeaderBlock>
                <HeaderBlock grow />
                <UserMenu />
            </Header>

            <ShowVersionsDialog
                globalVersions={props.globalVersions}
                currentGlobalVersion={props.currentGlobalVersion}
            />

            <CreateVersionDialog
                open={createVersionDialogOpen}
                onClose={closeCreateVersionDialog}
            />
        </>
    );
};
