import {hasGapInPoint, hasGapInSegment} from './has-intersection-in-point';
import {GridCoordinates} from '../interface';
import {CellDirection, GridMap} from './interface';
import {getDirectionFromTwoNeighborCoordinates, getDistanceBetweenGraphPoints} from './helpers';

const cellSize = 20;
const margin = 10;

function getCellCenterSVGCoordinate(cellNumber: number) {
    return cellNumber * cellSize + cellSize / 2;
}

function getEdgeEndSVGCoordinates(direction: CellDirection, coordinates: GridCoordinates) {
    const x = (function () {
        if (direction === 'top' || direction === 'bottom') {
            return getCellCenterSVGCoordinate(coordinates.x);
        }

        if (direction === 'left') {
            return coordinates.x * cellSize;
        }

        if (direction === 'right') {
            return coordinates.x * cellSize + cellSize;
        }

        if (direction === 'topLeft' || direction === 'bottomLeft') {
            // tslint:disable-next-line: no-magic-numbers
            return coordinates.x * cellSize + cellSize * 0.2;
        }

        if (direction === 'topRight' || direction === 'bottomRight') {
            // tslint:disable-next-line: no-magic-numbers
            return coordinates.x * cellSize + cellSize * 0.8;
        }
    })();

    const y = (function () {
        if (direction === 'left' || direction === 'right') {
            return getCellCenterSVGCoordinate(coordinates.y);
        }

        if (direction === 'bottom') {
            return coordinates.y * cellSize + cellSize;
        }

        if (direction === 'top') {
            return coordinates.y * cellSize;
        }

        if (direction === 'topRight' || direction === 'topLeft') {
            // tslint:disable-next-line: no-magic-numbers
            return coordinates.y * cellSize + cellSize * 0.1;
        }

        if (direction === 'bottomRight' || direction === 'bottomLeft') {
            // tslint:disable-next-line: no-magic-numbers
            return coordinates.y * cellSize + cellSize * 0.9;
        }
    })();

    return `${x},${y} `;
}

export function getEdgeSVGPath(gridPath: GridCoordinates[]) {
    let result = '';

    gridPath.forEach((coordinates, index) => {
        if (index === 0) {
            const nextCoordinates = gridPath[1];
            const direction = getDirectionFromTwoNeighborCoordinates(nextCoordinates, coordinates);

            result += getEdgeEndSVGCoordinates(direction, coordinates);

            return;
        }

        if (index === gridPath.length - 1) {
            const previousCoordinates = gridPath[index - 1];
            const direction = getDirectionFromTwoNeighborCoordinates(
                previousCoordinates,
                coordinates
            );

            result += getEdgeEndSVGCoordinates(direction, coordinates);

            return;
        }

        result += `${getCellCenterSVGCoordinate(coordinates.x)},${getCellCenterSVGCoordinate(
            coordinates.y
        )} `;
    });

    return result;
}

interface LineSection {
    length: number;
    type: 'gap' | 'solid';
}

const gapSize = 9;

/** This function calculates the stroke-dasharray property for the edge polyline,
 * which is responsible for dotted segments */
export function getEdgeStrokeDasharray(
    edgeSvgPathString: string,
    edgeId: number,
    edgesGridPaths: {[key: string]: GridCoordinates[]},
    gridMap: GridMap
) {
    const edgeSvgPath = edgeSvgPathString
        .split(' ')
        .filter((el) => el !== '')
        .map((coordinatesString) => {
            const [x, y] = coordinatesString.split(',');

            return {x: Number(x), y: Number(y)};
        });

    const gridPath = edgesGridPaths[edgeId];
    const lineAsSections: LineSection[] = [];

    gridPath.forEach((segmentStartPointCoords, index) => {
        const segmentEndPointCoords = gridPath[index + 1];
        const isLastPointInEdge = !segmentEndPointCoords;

        if (isLastPointInEdge) {
            return;
        }

        const segmentHasMiddleGap = hasGapInSegment(
            edgeId,
            segmentStartPointCoords,
            segmentEndPointCoords,
            gridMap,
            edgesGridPaths
        );
        const segmentStartPointHasGap = hasGapInPoint(edgeId, gridMap, edgesGridPaths, index);
        const segmentEndPointHasGap = hasGapInPoint(edgeId, gridMap, edgesGridPaths, index + 1);

        // start of segment
        lineAsSections.push({length: gapSize / 2, type: segmentStartPointHasGap ? 'gap' : 'solid'});

        const currentSegmentSvgLength = getDistanceBetweenGraphPoints(
            edgeSvgPath[index],
            edgeSvgPath[index + 1]
        );

        // middle of segment
        if (segmentHasMiddleGap) {
            lineAsSections.push({
                length: (currentSegmentSvgLength - gapSize * 2) / 2,
                type: 'solid'
            });
            lineAsSections.push({length: gapSize, type: 'gap'});
            lineAsSections.push({
                length: (currentSegmentSvgLength - gapSize * 2) / 2,
                type: 'solid'
            });
        } else {
            lineAsSections.push({length: currentSegmentSvgLength - gapSize, type: 'solid'});
        }

        // end of segment
        lineAsSections.push({length: gapSize / 2, type: segmentEndPointHasGap ? 'gap' : 'solid'});
    });

    let result = '';
    let lineOfCurrentTypeLength = 0;

    lineAsSections.forEach((section, index) => {
        const previousSection = lineAsSections[index - 1];

        if (!previousSection || previousSection.type === section.type) {
            lineOfCurrentTypeLength += section.length;
        } else {
            result += `${lineOfCurrentTypeLength}, `;
            lineOfCurrentTypeLength = section.length;
        }
    });

    result += `${lineOfCurrentTypeLength}`;

    return result;
}

export function getNodeSVGCoordinates(nodeCoordinates: GridCoordinates) {
    return {
        x: nodeCoordinates.x * cellSize,
        y: nodeCoordinates.y * cellSize
    };
}

export function getGraphSVGSize(graphSize: GridCoordinates) {
    return {
        width: graphSize.x * cellSize + margin * 2,
        height: graphSize.y * cellSize + margin * 2,
        margin
    };
}
