import {useInfiniteQuery} from 'react-query';
import {apiClient} from '../../../../../utils/api-client';

export const QUERY_RESULTS_LIST_PAGE_SIZE = 100;

const getFindConnectionQueryResults = (queryId: number, cursor?: number | null) => {
    return apiClient.get<PageResponse<ConnectionDto, number>>({
        path: `queries-service/api/queries/${queryId}/connections`,
        query: {limit: QUERY_RESULTS_LIST_PAGE_SIZE, cursor}
    });
};

interface Props {
    queryId?: number;
    enabled: boolean;
}

export function useQueryResultsListInfiniteQuery(props: Props) {
    return useInfiniteQuery<PageResponse<ConnectionDto, number>>(
        ['query-results-list', props.queryId],
        ({pageParam = {cursor: null}}) =>
            getFindConnectionQueryResults(props.queryId!, pageParam.cursor),
        {
            getNextPageParam: ({remaining, nextCursor}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {
                        cursor: nextCursor
                    };
                }
            },
            keepPreviousData: true,
            enabled: !!props.queryId && props.enabled
        }
    );
}
