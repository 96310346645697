import {useQuery} from 'react-query';

import {apiClient} from '../utils/api-client';

import {useLocationParams, DocumentViewMode} from './use-location';

function checkConnectionRequest(mode: DocumentViewMode) {
    return apiClient.get({
        path:
            mode === 'explore'
                ? 'documents-revisions-service/api/user/documents/recent'
                : 'editor-documents-service/api/user/documents/recent',
        query: {limit: 1}
    });
}

/** Checks liveness status of backend */
export function useCheckConnectionQuery() {
    const {documentsViewMode = 'explore'} = useLocationParams();

    return useQuery(['connect', documentsViewMode], () =>
        checkConnectionRequest(documentsViewMode)
    );
}
