import {UrlSearchParam} from '../hooks/use-search-params';

/** Login path */
export const LOGIN_ROUTE = '/login';

/** Returns the login page with the last location of the user */
export function getLoginUrlWithLastLocation() {
    const originUrl = `${window.location.pathname}${window.location.search}`;

    return `${LOGIN_ROUTE}?${UrlSearchParam.originUrl}=${encodeURIComponent(originUrl)}`;
}
