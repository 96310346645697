import '@genestack/ui/dist/genestack-ui.css';
import React from 'react';
import {createRoot} from 'react-dom/client';

import {Application} from './application';
import './axios';
import './base.css';

document.addEventListener('DOMContentLoaded', function () {
    const appRoot = document.createElement('div');
    document.body.appendChild(appRoot);
    const root = createRoot(appRoot);

    root.render(<Application />);
});
