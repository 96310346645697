import {useQuery, useQueryClient} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {uniqueBy} from '../../../../utils/array';

const RECENT_DOCS_LIMIT = 20;
const POLLING_INTERVAL = 3000;

/** Docs model value */
export interface DocsValue {
    recentDocs?: DocInfo[];
    isRecentLoading: boolean;
    loadRecent: () => void;
}

function getRecentDocsRequest() {
    return apiClient
        .get<PageResponse<DocInfo>>({
            path: `editor-documents-service/api/user/documents/recent?limit=${RECENT_DOCS_LIMIT}`
        })
        .then((result) => result.data);
}

/** Hook for recent docs */
export function useRecentDocsQuery() {
    return useQuery('editModeRecent', getRecentDocsRequest, {refetchInterval: POLLING_INTERVAL});
}

/** Query Cache for recent documents in Edit Mode */
export function useRecentDocsQueryCache() {
    const queryClient = useQueryClient();
    const queryKey = 'editModeRecent';

    function addDocument(docMetadata: DocInfo) {
        queryClient.setQueriesData<DocInfo[] | undefined>(
            queryKey,
            (currentState) =>
                currentState &&
                uniqueBy('id', [docMetadata, ...currentState]).slice(0, RECENT_DOCS_LIMIT)
        );
    }

    function invalidate() {
        queryClient.invalidateQueries(queryKey, {refetchInactive: true});
    }

    return {addDocument, invalidate};
}
