import {chain, InputProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './editable-title.module.css';

interface Props extends InputProps {
    value: string;
    onSubmitValue: (name: string) => void;
    onCancel: () => void;
}

/** Input for editable title */
export const EditModeInput = React.forwardRef<HTMLInputElement, Props>(
    function EditModeInputComponent(props, ref) {
        const {value, onSubmitValue, onCancel, ...inputProps} = props;

        const [inputValue, setInputValue] = React.useState(value);

        const handleSave = (nextName: string) => {
            if (nextName !== value) {
                onSubmitValue(nextName);
            } else {
                onCancel();
            }
        };

        const handleResetInputValue = () => {
            setInputValue(value);
            onCancel();
        };

        const handleBlur: React.FocusEventHandler<HTMLInputElement> = () => {
            if (inputValue.trim().length === 0) {
                handleResetInputValue();

                return;
            }

            handleSave(inputValue);
        };

        const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
            switch (event.key) {
                case 'Escape':
                    handleResetInputValue();
                    break;
                case 'Enter':
                    inputValue.trim().length ? handleSave(inputValue) : handleResetInputValue();
                    break;
                default:
            }
        };

        const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
            setInputValue(event.target.value);
        };

        return (
            <input
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus
                ref={ref}
                data-qa="title-input"
                {...inputProps}
                className={classNames(styles.input, inputProps.className)}
                value={inputValue}
                onBlur={handleBlur}
                onKeyUp={chain(handleKeyUp, inputProps.onKeyUp)}
                onChange={chain(handleChangeInput, inputProps.onChange)}
            />
        );
    }
);
