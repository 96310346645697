import {ListItem} from '@genestack/ui';
import React from 'react';

import {DEFAULT_OFFSET_WIDTH} from '../../../../components/group';
import {Preloader} from '../../../../components/preloader';

import {DocumentsList} from './documents-list';
import {LoadMoreControl} from './ontology-layout/load-more-control';
import {DocumentStatusIconProps} from '../../../../components/doc-status-icon/document-status-icon';

interface Props {
    isInitialLoading?: boolean;
    isLoadingMore?: boolean;
    hasSiblings?: boolean;
    docs?: DocInfo[];
    hasNextPage?: boolean | null;
    onClickLoadMoreButton: () => void;
    offset?: number; // Offset in pixels
    disabled?: boolean;
    DocStatusIconComponent: React.FunctionComponent<DocumentStatusIconProps>;
}

export const SIBLINGS_OFFSET_WIDTH = 10;

function getDocumentsOffset(offset: number, hasSiblings?: boolean) {
    return hasSiblings
        ? offset + DEFAULT_OFFSET_WIDTH + SIBLINGS_OFFSET_WIDTH
        : offset + DEFAULT_OFFSET_WIDTH;
}

// eslint-disable-next-line react/display-name
export const PortionedDocsGroupContent = React.memo(
    ({
        isInitialLoading,
        isLoadingMore,
        hasSiblings,
        docs,
        hasNextPage,
        onClickLoadMoreButton,
        offset,
        disabled,
        DocStatusIconComponent
    }: Props) => {
        const itemOffset = React.useMemo(() => {
            if (offset) {
                return getDocumentsOffset(offset, hasSiblings);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [offset]);

        return (
            <Preloader show={isInitialLoading}>
                {docs && docs.length > 0 && (
                    <>
                        <DocumentsList
                            documents={docs}
                            itemOffset={itemOffset}
                            itemMarginLeft={offset ? -offset : 0}
                            DocStatusIconComponent={DocStatusIconComponent}
                        />

                        {(hasNextPage || isLoadingMore) && (
                            <ListItem as="div" style={{paddingLeft: 16}}>
                                <LoadMoreControl
                                    isLoading={isLoadingMore}
                                    onClick={onClickLoadMoreButton}
                                    disabled={disabled}
                                >
                                    ...Load more documents
                                </LoadMoreControl>
                            </ListItem>
                        )}
                    </>
                )}

                {docs && docs.length === 0 && <ListItem>No documents found</ListItem>}
            </Preloader>
        );
    }
);
