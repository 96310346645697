import React from 'react';

import {EditableDocumentStatus} from './editable-document-status';
import {DeletedTooltipIcon, ReadOnlyTooltipIcon} from './tooltip-icons';

export interface DocumentStatusIconProps {
    document: DocInfo;
}

export function DocumentStatusIcon({document}: DocumentStatusIconProps) {
    if (document.readOnly) {
        return <ReadOnlyTooltipIcon />;
    }

    return <EditableDocumentStatus document={document} />;
}

export function DocumentStatusIconWithDeleted({document}: DocumentStatusIconProps) {
    if (document.status === 'DELETED') {
        return <DeletedTooltipIcon />;
    }

    return <DocumentStatusIcon document={document} />;
}
