/** Returns the first letters of the first and last name */
export function getInitials(name?: string): string {
    return (
        name
            ?.split(' ')
            // eslint-disable-next-line no-magic-numbers
            .slice(0, 2)
            .map((word) => word.slice(0, 1))
            .join('') ?? ''
    );
}
