import axios from 'axios';

import {LOGIN_ROUTE, getLoginUrlWithLastLocation} from './utils/login';

const UNAUTHORIZED_HTTP_STATUS = 401;
const unauthorizedRoutes = [LOGIN_ROUTE, '/recovery'];

function redirectToLoginIfNeeded(status: number) {
    if (
        status === UNAUTHORIZED_HTTP_STATUS &&
        !unauthorizedRoutes.includes(window.location.pathname)
    ) {
        window.location.href = getLoginUrlWithLastLocation();
    }
}

axios.interceptors.response.use(
    (response) => {
        redirectToLoginIfNeeded(response.status);

        return Promise.resolve(response);
    },
    (error) => {
        redirectToLoginIfNeeded(error.response.status);

        return Promise.reject(error);
    }
);

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
