import {Input, InputProps, Typography, TypographyProps} from '@genestack/ui';
import React from 'react';

interface Props extends InputProps {
    validationText?: string;
    messageProps?: TypographyProps;
}

/** Input with validation Tooltip */
export function ValidatedInput({validationText, messageProps = {}, ...restProps}: Props) {
    return (
        <>
            <Input {...restProps} invalid={!!validationText} />
            <Typography {...messageProps} intent="alarm" variant="caption">
                {validationText}
            </Typography>
        </>
    );
}
