import React from 'react';

import {
    PaginationPresentation,
    PaginationPresentationProps
} from '../../../../../components/pagination-presentation';
import {SidebarLoadingState} from '../../../common/sidebar-search-field/hooks/use-search-sidebar-context';

interface Props<TItem extends object, TArgs extends unknown[] = []>
    extends PaginationPresentationProps<TItem, TArgs> {
    isLoading?: boolean;
    loadingState: SidebarLoadingState;
    setLoadingState: (state: SidebarLoadingState) => void;
}

export const SearchResultsBody = <TItem extends object, TArgs extends unknown[] = []>(
    props: Props<TItem, TArgs>
) => {
    const {isLoading, paginationState, setLoadingState, loadingState, ...restProps} = props;

    React.useEffect(() => {
        setLoadingState({
            isLoading: isLoading || paginationState.isLoading,
            isLoadingNextPage: !!paginationState.isLoadingNextPage
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, paginationState.isLoading, paginationState.isLoadingNextPage]);

    return (
        <PaginationPresentation
            {...restProps}
            paginationState={{...paginationState, ...loadingState}}
        />
    );
};
