import React from 'react';

import {roundPathCorners} from '../../utils/svg-round-corners';

const MIN_WIDTH = 350;
const MAX_WIDTH = 450;

const MIN_HEIGHT = 120;
const MAX_HEIGHT = 200;

// tslint:disable-next-line: no-magic-numbers
const MIN_OFFSET = MAX_WIDTH / 10;

function getRandomInt(min: number, max: number) {
    // The maximum is exclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min)) + min;
}

function* generateFigure(): IterableIterator<[number, number]> {
    const offsetRight = getRandomInt(MIN_OFFSET, MAX_WIDTH - MIN_WIDTH);
    const width = getRandomInt(MIN_WIDTH, MAX_WIDTH - offsetRight);

    const x1 = 0;
    const x2 = MAX_WIDTH;

    const xt1 = MAX_WIDTH - (width + offsetRight);
    const xt2 = MIN_WIDTH + offsetRight;

    // tslint:disable-next-line: no-magic-numbers
    const flip = Math.random() > 0.5;

    yield [flip ? xt1 : x1, 0];

    yield [flip ? xt2 : x2, 0];

    yield [flip ? x2 : xt2, getRandomInt(MIN_HEIGHT, MAX_HEIGHT)];

    yield [flip ? x1 : xt1, getRandomInt(MIN_HEIGHT, MAX_HEIGHT)];
}

interface Props extends React.BaseHTMLAttributes<HTMLOrSVGElement> {
    fill?: string;
}

/** A component that renders a randomly shaped quadrangle */
// eslint-disable-next-line react/display-name
export const Tetragon = React.memo((props: Props) => {
    const figureGenerator = generateFigure();

    // start
    let d = `M ${figureGenerator.next().value.join(' ')}`;
    // top
    d += ` L ${figureGenerator.next().value.join(' ')}`;
    // right
    d += ` L ${figureGenerator.next().value.join(' ')}`;
    // bottom
    d += ` L ${figureGenerator.next().value.join(' ')}`;
    // left
    d += ' Z';

    return (
        <svg
            {...props}
            width={MAX_WIDTH}
            height={MAX_HEIGHT}
            viewBox={`0 0 ${MAX_WIDTH} ${MAX_HEIGHT}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={props.fill ?? 'rgba(11, 21, 40, 0.7)'}
                d={roundPathCorners(d, 3)} // tslint:disable-line: no-magic-numbers
            />
        </svg>
    );
});
