import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';

type SetValue<ParamValue> = (paramValue: ParamValue) => void;

/** URL search parameters used in the app */
export enum UrlSearchParam {
    /** Represents a version of documents */
    GV = 'GV',
    /** Contains search data for explore view in base64 */
    search = 'search',
    /** Used to redirect user after login */
    originUrl = 'originUrl'
}

interface UseUrlSearchValue<ParamValue> {
    value: string | null;
    setValue: SetValue<ParamValue>;
    replaceValue: SetValue<ParamValue>;
    removeValue: () => void;
}

export const addGlobalVersionToUrl = (basePath: string, GV: number | null) => {
    return GV ? `${basePath}?${UrlSearchParam.GV}=${GV}` : basePath;
};

export const useUrlSearchValue = <P extends UrlSearchParam, V extends string>(
    param: P
): UseUrlSearchValue<V> => {
    const history = useHistory();
    const {search} = useLocation();
    const urlSearchParams = React.useMemo(() => new URLSearchParams(search), [search]);

    const setValue = React.useCallback(
        (paramValue: V) => {
            urlSearchParams.set(param, String(paramValue));

            history.push({
                search: urlSearchParams.toString()
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [search, param]
    );

    const replaceValue = React.useCallback(
        (paramValue: V) => {
            urlSearchParams.set(param, String(paramValue));

            history.replace({
                search: urlSearchParams.toString()
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [search, param]
    );

    const removeValue = React.useCallback(() => {
        urlSearchParams.delete(param);

        history.push({
            search: urlSearchParams.toString()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, param]);

    return {
        value: urlSearchParams.get(param),
        setValue,
        replaceValue,
        removeValue
    };
};
