import {GridCoordinates, GraphResponse, QueryNodeType} from '../interface';
import {GridCellContent, GridMap, BiokbGraphEdge, BiokbGraphNode} from './interface';
import {getDistanceBetweenGraphPoints} from './helpers';

/** The class that constructs the graph,
 *  and provides helper functions */
export class QueryGraph {
    public readonly startNodes: BiokbGraphNode[];
    public readonly allNodes: BiokbGraphNode[];
    public readonly allEdges: BiokbGraphEdge[];
    public nodesGridCoordinates: {[key: string]: GridCoordinates};

    public static getAllEdgesFromCell(gridCellContent: GridCellContent): BiokbGraphEdge[] {
        if (!gridCellContent) {
            return [];
        }

        if (QueryGraph.isCellHasNode(gridCellContent)) {
            return [...gridCellContent.incomingEdges, ...gridCellContent.outgoingEdges];
        }

        return gridCellContent;
    }

    public static isCellHasNode(
        gridCellContent: GridCellContent
    ): gridCellContent is BiokbGraphNode {
        return !!(gridCellContent && (gridCellContent as BiokbGraphNode).id);
    }

    constructor(graphData: GraphResponse) {
        const {startNodes, allNodes, allEdges} = QueryGraph.constructGraph(graphData);
        this.startNodes = startNodes;
        this.allEdges = allEdges;
        this.allNodes = allNodes;
        this.nodesGridCoordinates = QueryGraph.createNodesGridCoordinates(this.startNodes);
    }

    public get size() {
        let maxX = 0;
        let maxY = 0;

        Object.values(this.nodesGridCoordinates).forEach(({x, y}) => {
            if (x > maxX) {
                maxX = x;
            }

            if (y > maxY) {
                maxY = y;
            }
        });

        return {
            // tslint:disable-next-line: no-magic-numbers
            x: maxX + 3,
            y: maxY + 1
        };
    }

    private static createNodesGridCoordinates(startNodes: BiokbGraphNode[]) {
        let biggestX = -1;
        const allCoordinates: {[key: string]: GridCoordinates} = {};

        const assignCoordinates = (
            node: BiokbGraphNode,
            parentNodeCoordinates?: GridCoordinates,
            shouldAddHorizontalMargin?: boolean
        ) => {
            const coordinates = (function () {
                if (!parentNodeCoordinates) {
                    // eslint-disable-next-line no-return-assign
                    return {
                        x: (biggestX += 2),
                        y: 0
                    };
                }

                // eslint-disable-next-line no-return-assign
                return {
                    x: shouldAddHorizontalMargin ? (biggestX += 2) : parentNodeCoordinates.x,
                    y: parentNodeCoordinates.y + 2
                };
            })();

            allCoordinates[node.id] = coordinates;

            let shouldMoveChildrenHorizontally = false;
            node.outgoingEdges.forEach((edge) => {
                if (!allCoordinates[edge.targetNode.id]) {
                    assignCoordinates(edge.targetNode, coordinates, shouldMoveChildrenHorizontally);
                    shouldMoveChildrenHorizontally = true;
                }
            });
        };

        // tslint:disable-next-line: no-unnecessary-callback-wrapper
        startNodes.forEach((node) => {
            assignCoordinates(node);
        });

        return allCoordinates;
    }

    private static constructGraph = (graphData: GraphResponse) => {
        const nodes: BiokbGraphNode[] = graphData.nodes.map((node) => ({
            id: node.id,
            // we use biokb graph only in queries
            nodeType: node.nodeType as QueryNodeType,
            incomingEdges: [],
            outgoingEdges: []
        }));
        const edges: BiokbGraphEdge[] = graphData.edges.map((edge) => ({
            id: edge.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sourceNode: nodes.find((node) => node.id === edge.startViewNodeId)!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            targetNode: nodes.find((node) => node.id === edge.endViewNodeId)!,
            relationInfo: edge.relationInfo,
            presentation: edge.presentation
        }));

        nodes.forEach((node) => {
            edges.forEach((edge) => {
                if (edge.targetNode.id === node.id) {
                    node.incomingEdges.push(edge);
                }

                if (edge.sourceNode.id === node.id) {
                    node.outgoingEdges.push(edge);
                }
            });
        });

        return {
            startNodes: nodes.filter((node) => node.nodeType === QueryNodeType.START),
            allNodes: nodes,
            allEdges: edges
        };
    };

    public edgesSortedByLength(edges: BiokbGraphEdge[]) {
        return edges.sort((edge1, edge2) => {
            const distance1 = getDistanceBetweenGraphPoints(
                this.nodesGridCoordinates[edge1.sourceNode.id],
                this.nodesGridCoordinates[edge1.targetNode.id]
            );
            const distance2 = getDistanceBetweenGraphPoints(
                this.nodesGridCoordinates[edge2.sourceNode.id],
                this.nodesGridCoordinates[edge2.targetNode.id]
            );

            return distance1 - distance2;
        });
    }

    public getNodeById = (nodeId: number) => {
        return this.allNodes.find((node) => nodeId === node.id);
    };

    public getGridMap(edgesGridPaths: {[key: string]: GridCoordinates[]}) {
        const {size} = this;

        const map: GridMap = [];
        for (let i = 0; i < size.x; i += 1) {
            map[i] = [];

            for (let j = 0; j < size.y; j += 1) {
                map[i][j] = null;
            }
        }

        this.allNodes.forEach((node) => {
            const coordinates = this.nodesGridCoordinates[node.id];
            map[coordinates.x][coordinates.y] = node;
        });

        this.allEdges.forEach((edge) => {
            const path = edgesGridPaths[edge.id];
            if (!path) {
                return;
            }

            path.slice(1, path.length - 1).forEach((step) => {
                const cellContent = map[step.x][step.y];
                if (Array.isArray(cellContent)) {
                    cellContent.push(edge);
                } else {
                    map[step.x][step.y] = [edge];
                }
            });
        });

        return map;
    }
}
