import {MutationFunction, useMutation} from 'react-query';
import {GraphPresentation, GraphPresentationDetails} from '../interface';
import {apiClient} from '../../../../../../utils/api-client';
import {useGetPresentationsCache} from './use-get-presentations';
import {useGetPresentationsDetailsCache} from './use-get-presentation-details';

interface MutationParams {
    queryId: number;
    name: string;
    details: GraphPresentationDetails;
}

export interface PresentationCreatedResponse {
    presentation: GraphPresentation;
    details: GraphPresentationDetails;
}

function createPresentationRequest({queryId, name, details}: MutationParams) {
    return apiClient.post<PresentationCreatedResponse>({
        path: `queries-service/api/queries/${queryId}/graph-presentations`,
        parameters: {
            name,
            details
        }
    });
}

export function useCreatePresentation(onCreated: (response: PresentationCreatedResponse) => void) {
    const getPresentationsCache = useGetPresentationsCache();
    const getDetailsCache = useGetPresentationsDetailsCache();

    return useMutation<PresentationCreatedResponse, Error, MutationParams>({
        mutationFn: createPresentationRequest as MutationFunction<PresentationCreatedResponse>,
        onSuccess: (response, params) => {
            getPresentationsCache.presentationAdded(params.queryId, response.presentation);
            getDetailsCache.setDetails(params.queryId, response.presentation.id, response.details);
            onCreated(response);
        }
    });
}
