import React from 'react';
import {getHtmlForResolvedNodeJSON} from '../../../../../webeditor/biokb-editor-adapters';

interface Props {
    pathEntry: PathEntryDto;
}

export function useGetEntriesHtmlContent({pathEntry}: Props) {
    const [htmls, setHtmls] = React.useState<string>();

    React.useEffect(() => {
        let interrupted = false;
        setHtmls(undefined);
        const {document, expandedEditorContent, highlightedNodeIds} = pathEntry;

        getHtmlForResolvedNodeJSON(
            document.accession,
            expandedEditorContent,
            highlightedNodeIds
        ).then((el) => {
            if (interrupted) {
                return;
            }

            setHtmls(el.innerHTML);
        });

        return () => {
            interrupted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(pathEntry)]);

    return htmls;
}
