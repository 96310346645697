import React from 'react';
import {useHistory} from 'react-router-dom';

import {useCreateQueryDraftMutation} from '../hooks/requests/use-create-query-mutation';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';

interface Props {
    currentGlobalVersion: GlobalVersionData;
}

export function NewQuery({currentGlobalVersion}: Props) {
    const history = useHistory();
    const createQueryDraft = useCreateQueryDraftMutation();

    React.useEffect(() => {
        createQueryDraft
            .mutateAsync({
                globalVersionId: currentGlobalVersion.id
            })
            .then((queryInfo) => {
                history.push(`/queries/${queryInfo.id}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGlobalVersion]);

    // TODO Add loading state
    return null;
}
