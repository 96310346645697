import React from 'react';
import {GraphViewType} from '../../../../../../components/graph/interface';
import {NeighborhoodGraphDirection} from '../interface';

export function useNeighborhoodGraphSettings() {
    const [selectedViewType, setSelectedViewType] = React.useState(GraphViewType.MERGED);
    const toggleSelectedViewType = React.useCallback(() => {
        setSelectedViewType((prevState) =>
            prevState === GraphViewType.IDENTICAL ? GraphViewType.MERGED : GraphViewType.IDENTICAL
        );
    }, []);
    const [maxDistance, setMaxDistance] = React.useState(3);
    const [direction, setDirection] = React.useState(NeighborhoodGraphDirection.OUTGOING);

    return {
        selectedViewType,
        toggleSelectedViewType,
        maxDistance,
        setMaxDistance,
        direction,
        setDirection
    };
}
