import {
    Typography,
    Button,
    ControlsItem,
    Controls,
    StopIcon,
    Spinner,
    FailedIcon,
    CompletedIcon
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {useStopQueryMutation} from '../../hooks/requests/use-stop-query-mutation';
import {useCurrentUserQuery} from '../../../../hooks/user/use-current-user-query';
import {pluralize} from '../../../../utils/pluralize';
import {isQueryRunning, isQueryFailed, isQueryPending, isQueryRunningOrPending} from '../../utils';
import {Timer} from '../../common/timer';

import styles from './non-draft-query-footer.module.css';
import {useQueryDuration} from '../completed-query/query-results-header/use-query-duration';
import {QuerySearchDuration} from '../../common/query-search-duration';
import {useIsCanManageQueries} from '../../../../hooks/user/use-has-permission';

const ONE_SECOND = 1000;

const STATUS_TEXT: Record<QueryInfo['status'], string | null> = {
    RUNNING: 'Search in progress',
    PENDING: 'Pending',
    COMPLETED: null,
    STOPPED: null,
    FAILED: 'Search interrupted',
    DRAFT: null
};

const STATUS_ICON: Record<QueryInfo['status'], React.ElementType | null> = {
    RUNNING: Spinner,
    PENDING: Spinner,
    COMPLETED: CompletedIcon,
    STOPPED: StopIcon,
    FAILED: FailedIcon,
    DRAFT: null
};

interface Props {
    selectedQuery: RecentQuery;
    globalVersions: GlobalVersionData[];
}

function getQueryGlobalVersion(
    globalVersions: GlobalVersionData[] | null,
    queryGlobalVersionId?: number
) {
    return globalVersions?.find(({id}) => id === queryGlobalVersionId);
}

export function NonDraftQueryFooter(props: React.PropsWithChildren<Props>) {
    const {selectedQuery, globalVersions} = props;
    const stopQuery = useStopQueryMutation();
    const currentUserQuery = useCurrentUserQuery();
    const isCanManageQueries = useIsCanManageQueries();

    const queryDuration = useQueryDuration({query: selectedQuery});

    const queryGlobalVersion = React.useMemo(
        () => getQueryGlobalVersion(globalVersions, selectedQuery.globalVersionId),
        [globalVersions, selectedQuery.globalVersionId]
    );

    const handleStopQuery = () => {
        stopQuery.mutate(selectedQuery.id);
    };

    const count = selectedQuery.resultsCount;
    const statusText = STATUS_TEXT[selectedQuery.status];
    const StatusIcon = STATUS_ICON[selectedQuery.status];
    const inactiveCounter =
        isQueryFailed(selectedQuery) ||
        isQueryPending(selectedQuery) ||
        (isQueryRunning(selectedQuery) && count === 0);

    const isOwnedByCurrentUser =
        currentUserQuery.data && selectedQuery.owner.email === currentUserQuery.data.email;

    return (
        <div className={styles.root}>
            <Controls justify="space-between">
                <ControlsItem>
                    <Controls>
                        {StatusIcon && (
                            <ControlsItem>
                                <StatusIcon
                                    className={classNames(styles.icon, {
                                        [styles.failed]: isQueryFailed(selectedQuery)
                                    })}
                                />
                            </ControlsItem>
                        )}

                        {!inactiveCounter && (
                            <ControlsItem shrink>
                                <Typography ellipsis>
                                    {`${count > 0 ? count : 'No'}`} {pluralize('result', count)}{' '}
                                    found
                                </Typography>
                            </ControlsItem>
                        )}

                        {statusText && (
                            <ControlsItem>
                                <Typography>
                                    <strong>{statusText}</strong>
                                </Typography>
                            </ControlsItem>
                        )}

                        {isQueryRunningOrPending(selectedQuery) && (
                            <ControlsItem>
                                <Button
                                    ghost
                                    className={styles.button}
                                    size="small"
                                    icon={<StopIcon />}
                                    onClick={handleStopQuery}
                                    disabled={!isOwnedByCurrentUser && !isCanManageQueries}
                                >
                                    Stop
                                </Button>
                            </ControlsItem>
                        )}

                        {!isQueryPending(selectedQuery) && (
                            <ControlsItem shrink>
                                <Typography intent="quiet" ellipsis>
                                    {isQueryRunning(selectedQuery) ? (
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        <Timer duration={Math.max(queryDuration, ONE_SECOND)} />
                                    ) : (
                                        <>
                                            {Intl.DateTimeFormat('en-EN', {
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: false
                                            }).format(selectedQuery.finishedAt)}{' '}
                                            <QuerySearchDuration query={selectedQuery} />
                                        </>
                                    )}
                                </Typography>
                            </ControlsItem>
                        )}
                    </Controls>
                </ControlsItem>

                <ControlsItem>
                    {queryGlobalVersion && (
                        <ControlsItem className={styles.version} shrink>
                            <Typography intent="quiet" ellipsis>
                                KB Version: {queryGlobalVersion.label}
                            </Typography>
                        </ControlsItem>
                    )}
                </ControlsItem>
            </Controls>
        </div>
    );
}
