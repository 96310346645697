import {Button, Notification, Typography} from '@genestack/ui';
import React from 'react';

import {CallApiError} from '../../utils/api-client';

/** Returns unexpectedErrorNotification error notification component */
export function getUnexpectedErrorNotification(onClick: () => void) {
    return (
        <Notification countdown="none">
            <Typography intent="alarm" box="paragraph">
                Run-time error
            </Typography>
            <Button onClick={onClick} size="small">
                Show more info...
            </Button>
        </Notification>
    );
}

/** Returns Call API error notification component */
export function getCallApiErrorNotification(
    error: CallApiError,
    onClick: () => void,
    customHeader?: string
) {
    const errorContent = error.result.json;

    return (
        <Notification countdown="none">
            <Typography intent="alarm" box="paragraph">
                {customHeader || 'A server error occurred'}
            </Typography>
            {errorContent.error ? (
                <Typography box="paragraph" ellipsis>
                    {errorContent.error.message}
                </Typography>
            ) : null}

            <Button onClick={onClick}>Show more info...</Button>
        </Notification>
    );
}
