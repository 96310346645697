import {Divider, Paper, PageContent, Typography} from '@genestack/ui';
import * as React from 'react';

import {BusyIndicator} from '../../../components/busy-indicator';
import {useIdentityFormQuery} from '../../../hooks/user/auth-form-query';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';

import styles from './login-page.module.css';
import {LoginPerformer} from './login-performer';

/** Page for user login */
export function LoginPage() {
    const loginFormQuery = useIdentityFormQuery({path: '/self-service/login/browser'});
    const currentUserQuery = useCurrentUserQuery();

    React.useEffect(() => {
        if (loginFormQuery.error) {
            currentUserQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginFormQuery.error]);

    if (loginFormQuery.data) {
        return (
            <PageContent className={styles.root}>
                <Paper className={styles.form}>
                    <Typography variant="header" className={styles.header}>
                        Biology Knowledge Base
                    </Typography>
                    <Divider variant="transparent" gap={1} />
                    <LoginPerformer {...loginFormQuery.data} />
                </Paper>
            </PageContent>
        );
    }

    return <BusyIndicator />;
}
