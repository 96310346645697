import React from 'react';

import {Preloader} from '../../../components/preloader';
import {Sidebar} from '../../../components/sidebar';
import {useSharedQueries} from './hooks/use-shared-queries';
import {useQueryId} from '../../../hooks/use-location';

import {QueryInfoItem} from './query-info-item';
import {QueriesSidebarProps} from './interface';
import {QueriesSidebarHeader} from './queries-sidebar-header';
import {EmptyList} from '../../../components/empty-list/empty-list';

/** Sidebar for shared queries */
export const SharedQueriesSidebar = (props: QueriesSidebarProps) => {
    const sharedQueries = useSharedQueries({refetchOnMount: 'always'});
    const queryId = useQueryId();

    return (
        <Sidebar
            header={
                <QueriesSidebarHeader
                    title="Queries shared with me"
                    onCollapse={props.onCollapse}
                />
            }
        >
            <Preloader show={sharedQueries.isLoading}>
                {sharedQueries.data && (
                    <React.Fragment>
                        {sharedQueries.data.length === 0 && (
                            <EmptyList message="No queries found" />
                        )}

                        {sharedQueries.data.map((queryInfo) => (
                            <QueryInfoItem
                                key={queryInfo.id}
                                queryInfo={queryInfo}
                                isSelected={queryInfo.id === queryId}
                                showOwnerName
                            />
                        ))}
                    </React.Fragment>
                )}
            </Preloader>
        </Sidebar>
    );
};
