import * as React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {UnauthenticatedApp} from './application/unauthenticated-app';
import {AuthenticatedApp} from './authenticated-app';
import {BusyIndicator} from './components/busy-indicator';
import {useCurrentUserQuery} from './hooks/user/use-current-user-query';
import {GlobalStateProvider} from './providers/global-state';
import {DocRevisionsContextProvider} from './providers/version';
import {JbkbSocketContextProvider} from './providers/jbkb-socket';
import {DocumentSyntheticEventsContextProvider} from './providers/document-synthetic-events';
import {ConfirmContextProvider} from './components/confirm/confirm-context';

export function Routes() {
    const currentUserQuery = useCurrentUserQuery();

    if (!currentUserQuery.isFetched && currentUserQuery.isLoading) {
        return <BusyIndicator />;
    }

    return (
        <BrowserRouter>
            {!currentUserQuery.data ? (
                <UnauthenticatedApp />
            ) : (
                <GlobalStateProvider>
                    <DocRevisionsContextProvider>
                        <JbkbSocketContextProvider>
                            <ConfirmContextProvider>
                                <DocumentSyntheticEventsContextProvider>
                                    <AuthenticatedApp />
                                </DocumentSyntheticEventsContextProvider>
                            </ConfirmContextProvider>
                        </JbkbSocketContextProvider>
                    </DocRevisionsContextProvider>
                </GlobalStateProvider>
            )}
        </BrowserRouter>
    );
}
