import {GraphType} from '../../../../../components/graph/interface';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';

const problematicBiokbGraphSize = 200;
const unmanageableBiokbGraphSize = 500;
const problematicCytoscapeGraphSize = 500;
const unmanageableSizeCytoscapeGraphSize = 4000;

/** Determines if number of nodes is big enough to cause some problems in application */
export function isGraphProblematic(nodesNumber: number, graphType: GraphType) {
    const problematicGraphSize =
        graphType === GraphType.cytoscape
            ? problematicCytoscapeGraphSize
            : problematicBiokbGraphSize;
    return nodesNumber > problematicGraphSize;
}

/** Determines if number of nodes is big enough to cause major problems and break the application */
export function isGraphUnmanageable(nodesNumber: number, graphType: GraphType) {
    const unmanageableGraphSize =
        graphType === GraphType.cytoscape
            ? unmanageableSizeCytoscapeGraphSize
            : unmanageableBiokbGraphSize;
    return nodesNumber > unmanageableGraphSize;
}

export function isQueryResultUnmanageable(
    graphType: GraphType,
    maxPathLengthOptions: MaxPathLengthOptionResult
) {
    return (
        maxPathLengthOptions.merged[0] &&
        isGraphUnmanageable(maxPathLengthOptions.merged[0].amountOfNodes, graphType)
    );
}

export function isQueryResultIncomplete(maxPathLengthOptions: MaxPathLengthOptionResult) {
    return !maxPathLengthOptions.merged.length;
}
