import {Controls, ControlsItem, Typography} from '@genestack/ui';
import React, {FC} from 'react';

import {RelativeLinkStyled} from '../../../../components/relative-link/relative-link-styled';
import {CommitDto} from '../../../../providers/version';
import {DateText} from '../date-text/date-text';
import {UserUnit} from '../user-unit';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';

interface Props {
    documentRevision?: string;
    documentId: string;
    revisionInfo: CommitDto;
    selectedGlobalVersion: GlobalVersionData;
}

export const DocumentMetadata: FC<Props> = ({
    revisionInfo,
    documentId,
    documentRevision,
    selectedGlobalVersion
}) => {
    const {revision, author, createdAt} = revisionInfo;
    const username = author.name ?? author.email;
    const globalVersionVisibility = documentRevision === revision;

    return (
        <Controls
            gap={4} // tslint:disable-line: no-magic-numbers
        >
            <ControlsItem>
                <UserUnit username={username} />
            </ControlsItem>
            <ControlsItem>
                <RelativeLinkStyled to={`/${documentId}/revisions/${revision}`}>
                    <DateText timestamp={createdAt} />
                </RelativeLinkStyled>
                {globalVersionVisibility && (
                    <Typography as="span" intent="quiet">
                        {' / '}
                        {selectedGlobalVersion.label}
                    </Typography>
                )}
            </ControlsItem>
        </Controls>
    );
};
