import React from 'react';

import {useDeletedQueriesCache} from '../queries-sidebar/hooks/use-deleted-queries';
import {useDraftQueriesCache} from '../queries-sidebar/hooks/use-draft-queries';
import {useFavoriteQueriesCache} from '../queries-sidebar/hooks/use-favorite-queries';
import {useQueryInfoCache} from '../hooks/requests/use-query-info';
import {useRecentQueriesCache} from '../queries-sidebar/hooks/use-recent-queries';
import {useSharedQueriesCache} from '../queries-sidebar/hooks/use-shared-queries';
import {
    useJbkbSocketEvent,
    SocketEvent,
    useQueryEventsNotifications
} from '../../../providers/jbkb-socket';
import {useMPRecentQueriesCache} from '../query-management-panel/use-mp-recent-queries';
import {useMPUnresolvedQueriesCache} from '../query-management-panel/use-mp-unresolved-queries';
import {
    useDeleteQueryInCache,
    useRestoreQueryInCache,
    useIsQueryOwnedByCurrentUser
} from '../hooks/cache-control-hooks';

interface Props {
    notifyOnlyOnMyQueries: boolean;
}

const isQueryInfoEvent = (event: SocketEvent) => event.type.indexOf('query:') === 0;

export function QueryWebsocketEventsManager(props: Props) {
    const recentQueriesCache = useRecentQueriesCache();
    const draftQueriesCache = useDraftQueriesCache();
    const queryInfoCache = useQueryInfoCache();
    const deletedQueriesCache = useDeletedQueriesCache();
    const favoriteQueriesCache = useFavoriteQueriesCache();
    const sharedQueryCache = useSharedQueriesCache();

    const MPUnresolvedQueriesCache = useMPUnresolvedQueriesCache();
    const MPRecentQueriesCache = useMPRecentQueriesCache();
    const isQueryOwnedByCurrentUser = useIsQueryOwnedByCurrentUser();

    const deleteQueryInCache = useDeleteQueryInCache();
    const restoreQueryInCache = useRestoreQueryInCache();

    useQueryEventsNotifications(props.notifyOnlyOnMyQueries);

    // tslint:disable-next-line: cyclomatic-complexity
    const handleQuerySocketEvent = React.useCallback(async (event: SocketEvent) => {
        if (!isQueryInfoEvent(event)) {
            return;
        }

        switch (event.type) {
            case 'query:new-result':
                recentQueriesCache.updateQueryResultsCount(
                    event.payload.id,
                    event.payload.totalConnectionsCount
                );
                queryInfoCache.updateQueryResultCount(
                    event.payload.id,
                    event.payload.totalConnectionsCount
                );
                MPUnresolvedQueriesCache.updateQueryResultsCount(
                    event.payload.id,
                    event.payload.totalConnectionsCount
                );
                favoriteQueriesCache.updateQueryResultsCount(
                    event.payload.id,
                    event.payload.totalConnectionsCount
                );
                break;
            case 'query:start':
                MPUnresolvedQueriesCache.addQuery(event.payload, true);
                queryInfoCache.updateQuery(event.payload);

                if (isQueryOwnedByCurrentUser(event.payload)) {
                    draftQueriesCache.removeQuery(event.payload.id);
                    recentQueriesCache.addQuery(event.payload);
                }
                break;
            case 'query:rank-up':
                MPUnresolvedQueriesCache.moveQueryUpInList(event.payload.id);
                break;
            case 'query:run':
                queryInfoCache.updateQuery(event.payload);
                MPUnresolvedQueriesCache.updateExistingQuery(event.payload);

                if (isQueryOwnedByCurrentUser(event.payload)) {
                    recentQueriesCache.updateExistingQuery(event.payload);
                    favoriteQueriesCache.updateExistingQuery(event.payload);
                }
                break;
            case 'query:fail':
            case 'query:stop':
            case 'query:complete':
                recentQueriesCache.updateExistingQuery(event.payload);
                favoriteQueriesCache.updateExistingQuery(event.payload);
                queryInfoCache.updateQuery(event.payload);
                sharedQueryCache.updateExistingQuery(event.payload);
                MPUnresolvedQueriesCache.removeQuery(event.payload.id);
                MPRecentQueriesCache.addQuery(event.payload, true);
                break;
            case 'query:to-trash':
                deleteQueryInCache(event.payload);
                break;
            case 'query:from-trash':
                restoreQueryInCache(event.payload);
                break;
            case 'query:favorite':
                queryInfoCache.updateQuery(event.payload);
                recentQueriesCache.updateExistingQuery(event.payload);
                favoriteQueriesCache.addQuery(event.payload);
                sharedQueryCache.updateExistingQuery(event.payload);
                break;
            case 'query:remove-from-favorite':
                queryInfoCache.updateQuery(event.payload);
                recentQueriesCache.updateExistingQuery(event.payload);
                favoriteQueriesCache.removeQuery(event.payload.id);
                sharedQueryCache.updateExistingQuery(event.payload);
                break;
            case 'query:remove':
                deletedQueriesCache.removeQuery(event.payload.id);
                MPRecentQueriesCache.removeQuery(event.payload.id);
                break;
            case 'query:rename':
                draftQueriesCache.updateExistingQuery(event.payload as DraftQuery);
                recentQueriesCache.updateExistingQuery(event.payload as RecentQuery);
                favoriteQueriesCache.updateExistingQuery(event.payload as RecentQuery);
                sharedQueryCache.updateExistingQuery(event.payload as RecentQuery);
                queryInfoCache.updateQuery(event.payload);
                MPUnresolvedQueriesCache.updateExistingQuery(event.payload as RunningQuery);
                MPRecentQueriesCache.updateExistingQuery(event.payload as FinishedQuery);
                break;
            case 'query:change-gv':
                draftQueriesCache.updateExistingQuery(event.payload as DraftQuery);
                recentQueriesCache.updateExistingQuery(event.payload as RecentQuery);
                favoriteQueriesCache.updateExistingQuery(event.payload as RecentQuery);
                sharedQueryCache.updateExistingQuery(event.payload as RecentQuery);
                queryInfoCache.updateQuery(event.payload);
                break;
            case 'query:share':
                sharedQueryCache.addQuery(event.payload, true);
                queryInfoCache.addQuery(event.payload);

                if (event.payload.favorite) {
                    favoriteQueriesCache.addQuery(event.payload);
                }
                break;
            case 'query:unshare':
                sharedQueryCache.removeQuery(event.payload.id);
                favoriteQueriesCache.removeQuery(event.payload.id);
                queryInfoCache.invalidateQuery(event.payload.id);
                break;
            default:
                /* eslint-disable-next-line no-alert */
                alert('Unknown event type, see use-listen-websocket-events');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useJbkbSocketEvent(handleQuerySocketEvent);

    return null;
}
