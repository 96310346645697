import {debounce} from '@genestack/ui';
import React from 'react';

import {useAsync} from '../../../../../hooks/use-async';
import {apiClient} from '../../../../../utils/api-client';
import {SuggestionDto} from '../interface';
import {SearchInputValue} from './use-search-sidebar-context';
import {useNodeTypesQuery, useNodeTypesQueryCache} from './use-node-types-query';

type OnLoadNodesHandler = (value: string, type: string | null, globalVersionId?: number) => void;

interface Props {
    debounceDelay: number;
    inputValue: SearchInputValue;
    globalVersionId?: number;
}

export const useNodeSuggestions = (props: Props) => {
    const nodeTypesProps = {
        enabled: props.inputValue.type !== 'emptyInput',
        globalVersionId: props.globalVersionId,
        limit: 100
    };

    const nodeTypesQuery = useNodeTypesQuery(nodeTypesProps);
    const nodeTypesQueryCache = useNodeTypesQueryCache(nodeTypesProps);

    const nodeSuggestionsRequest = useAsync(
        // eslint-disable-next-line default-param-last
        (input: string, type: string | null = null, globalVersionId?: number, limit = 30) => {
            return apiClient.get<SuggestionDto[]>({
                path: 'autocomplete-service/api/autocomplete/suggestions',
                query: {
                    input,
                    type,
                    'global-version': globalVersionId,
                    limit
                }
            });
        }
    );
    const [nodes, setNodes] = React.useState<SuggestionDto[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadNodes = React.useCallback<OnLoadNodesHandler>(
        debounce((value, type, globalVersionId?) => {
            if (value) {
                nodeSuggestionsRequest.run(value, type, globalVersionId);
            }
        }, props.debounceDelay),
        [props.debounceDelay]
    );

    const resetNodes = React.useCallback(() => {
        setNodes([]);
    }, []);

    React.useEffect(() => {
        if (nodeSuggestionsRequest.rejected) {
            resetNodes();
        }

        if (nodeSuggestionsRequest.fulfilled) {
            setNodes(nodeSuggestionsRequest.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeSuggestionsRequest.fulfilled, nodeSuggestionsRequest.rejected]);

    return {
        isLoadingNodes: nodeSuggestionsRequest.pending,
        nodes,
        loadNodes,
        resetNodes,

        isLoadingNodeTypes: nodeTypesQuery.isLoading,
        nodeTypes: nodeTypesQuery.data,
        invalidateNodeTypesQuery: nodeTypesQueryCache.invalidate
    };
};
