import {Badge, Divider} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader';
import {Sidebar} from '../../../../components/sidebar';
import {useDeletedQueries} from '../hooks/use-deleted-queries';
import {useQueryId} from '../../../../hooks/use-location';
import {QueryInfoItem} from '../query-info-item';

import styles from './deleted-queries-sidebar.module.css';
import {QueriesSidebarProps} from '../interface';
import {QueriesSidebarHeader} from '../queries-sidebar-header';
import {EmptyList} from '../../../../components/empty-list/empty-list';

export function DeletedQueriesSidebar(props: QueriesSidebarProps) {
    const deletedQueries = useDeletedQueries();
    const queryId = useQueryId();

    const sidebarHeader = React.useMemo(
        () => (
            <QueriesSidebarHeader onCollapse={props.onCollapse} title="Recycle Bin">
                <Divider variant="transparent" />

                <Badge
                    ghost
                    className={styles.label}
                    intent="warning"
                    variant="caption"
                    disableTextTransform
                    contentProps={{className: styles.labelContent}}
                >
                    Queries in the Recycle Bin are deleted forever after&nbsp;30&nbsp;days
                </Badge>
            </QueriesSidebarHeader>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Sidebar header={sidebarHeader}>
            <Preloader show={deletedQueries.isLoading}>
                {deletedQueries.data?.length === 0 && <EmptyList message="No queries found" />}

                {deletedQueries.data?.map((queryInfo) => (
                    <QueryInfoItem
                        key={queryInfo.id}
                        queryInfo={queryInfo}
                        isSelected={queryInfo.id === queryId}
                        isBookmarkedIcon={queryInfo.shared}
                    />
                ))}
            </Preloader>
        </Sidebar>
    );
}
