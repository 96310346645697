import {Button, ButtonProps, chain} from '@genestack/ui';
import React from 'react';

import {isDeletedDocument, isDocumentBeingEdited} from '../../../../../utils/document';

import {useStartEditingDocument} from './use-start-editing-document';

interface Props extends ButtonProps {
    documentMetadata: DocInfo;
    onClickPublish: () => void;
}

/**
 * Component display control in header
 */
export function DocumentControl({documentMetadata, onClickPublish, ...restProps}: Props) {
    const {startEditing, isDocEditable} = useStartEditingDocument({
        documentMetadata
    });

    if (!isDocEditable || isDeletedDocument(documentMetadata)) {
        return null;
    }

    if (isDocumentBeingEdited(documentMetadata)) {
        return (
            <Button
                data-qa="doc-header-publish-button"
                {...restProps}
                onClick={chain(onClickPublish, restProps.onClick)}
            >
                Publish
            </Button>
        );
    }

    return (
        <Button data-qa="doc-header-edit-button" {...restProps} onClick={startEditing}>
            Edit
        </Button>
    );
}
