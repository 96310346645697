import React from 'react';

import classNames from 'classnames';
import {DocumentUnit} from './document-unit/document-unit';
import {DSLView} from './dsl-view';

import styles from './documents-content.module.css';
import {useGetEntriesHtmlContent} from './use-get-entries-html-content';
import {getNodeIdOfResolvedNode} from '../../../../../webeditor/biokb-editor-adapters';

interface Props {
    entry: PathEntryDto;
    globalVersionId: number;
    indentation: 0 | 1 | 2;
    includeCounter?: boolean;
}

export const DocumentContent = ({globalVersionId, entry, includeCounter, indentation}: Props) => {
    const nodeId = getNodeIdOfResolvedNode(entry.expandedEditorContent);
    const html = useGetEntriesHtmlContent({pathEntry: entry});

    return (
        <li
            className={classNames(styles.item, {
                [styles.itemWithCounter]: includeCounter,
                [styles.itemIndentation1]: indentation === 1,
                [styles.itemIndentation2]: indentation === 2
            })}
        >
            <div className={styles.content}>
                <DocumentUnit
                    globalVersionId={globalVersionId}
                    nodeId={nodeId}
                    document={entry.document}
                />

                <DSLView html={html} />
            </div>
        </li>
    );
};
