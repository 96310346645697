import {Link, Controls, ControlsItem} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';
import styles from './document-unit.module.css';
import {addGlobalVersionToUrl} from '../../../../../hooks/use-search-params';
import {DocumentTypeLabel} from '../../../../document-type-label';

interface Props {
    globalVersionId: number;
    nodeId: string;
    document: ViewModeDocInfo;
}

function isModifiedEvent(event: MouseEvent | KeyboardEvent) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export const DocumentUnit = ({globalVersionId, nodeId, document}: Props) => {
    // FIXME move this to generic link component (from react router)
    const {push} = useHistory();
    const basename = window.location.origin;
    const nodePath = addGlobalVersionToUrl(
        `/documents/explore/${document.accession}/@${nodeId}`,
        globalVersionId
    );

    const handleClick = React.useCallback(
        ({nativeEvent}: React.MouseEvent | React.KeyboardEvent) => {
            if (
                (nativeEvent instanceof MouseEvent || nativeEvent instanceof KeyboardEvent) &&
                !nativeEvent.defaultPrevented && // onClick prevented default
                !isModifiedEvent(nativeEvent) // ignore clicks with modifier keys
            ) {
                nativeEvent.preventDefault();
                push(nodePath);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodePath]
    );

    return (
        <Controls>
            <ControlsItem className={styles.docType}>
                <DocumentTypeLabel type={document.type} readOnly={document.readOnly} />
            </ControlsItem>
            <ControlsItem>
                <Link href={`${basename}${nodePath}`} onClick={handleClick}>
                    {document.name}
                </Link>
            </ControlsItem>
        </Controls>
    );
};
