import {UiPermission, UserRole} from '../../../typings/user';

import {useCurrentUserQuery} from './use-current-user-query';

export function useHasPermission(permission: UiPermission) {
    const currentUserQuery = useCurrentUserQuery();
    const userPermissions = currentUserQuery.data?.permissions ?? [];

    return userPermissions.includes(permission);
}

export function useIsCanManageUsers() {
    return useHasPermission(UiPermission.manageUsers);
}

export function useIsCanManageQueries() {
    return useHasPermission(UiPermission.manageQueries);
}

export function useCanCreateGV() {
    return useHasPermission(UiPermission.createGlobalVersions);
}

export function useUserEditingPermissions() {
    const currentUserQuery = useCurrentUserQuery();

    return {
        editingPermitted: currentUserQuery.data?.role !== UserRole.EXPLORER,
        editingOnlyBTBRelatedPermitted: currentUserQuery.data?.role === UserRole.BTB_EDITOR,
        fullEditingAccess: currentUserQuery.data?.role === UserRole.EDITOR
    };
}

export function useUserPermittedEditingDoc(docInfo: DocInfo) {
    const {editingOnlyBTBRelatedPermitted, fullEditingAccess} = useUserEditingPermissions();

    return (
        fullEditingAccess ||
        (editingOnlyBTBRelatedPermitted && (docInfo.type === 'BTB' || docInfo.type === 'SOURCES'))
    );
}
