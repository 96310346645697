import React from 'react';

import {SocketEvent} from './socket-events';

interface WebSocketOptions {
    onMessage: (message: SocketEvent) => void;
    onOpen?: () => void;
    onClose?: (e: CloseEvent) => void;
    onError?: (e: Event) => void;
}

/** A type describing websocket sendMessage function */
export type SendMessage = (message: string | ArrayBuffer | Blob | ArrayBufferView) => void;

const PING_INTERVAL = 1e4;
const PONG_TIMEOUT = 9e4;

// tslint:disable: completed-docs
export const CLOSE_CODE_PINGPONG_TIMEOUT = 4900;
export const CLOSE_CODE_CLIENT_LEFT = 4910;
// tslint:enable: completed-docs

/** A hook for handling a websocket connection */
export const useWebSocket = (
    url?: string,
    options?: WebSocketOptions
): [SendMessage, () => void] => {
    const socket = React.useRef<WebSocket>();
    /** indicates whether socket is closing under will (`true`) or due to an error (`false`) */
    const willClose = React.useRef(false);

    const pingTimer = React.useRef<number>();
    const lastPongTs = React.useRef<number>();

    const getWebSocketReadyState = React.useCallback(() => socket.current?.readyState, []);

    const close = React.useCallback((code: number, reason?: string) => {
        window.clearInterval(pingTimer.current);

        if (socket.current?.readyState === WebSocket.OPEN) {
            socket.current?.close(code, reason);
        }
    }, []);

    const sendMessage: SendMessage = React.useCallback((message) => {
        // FIXME should return a promise:
        // each stub-message we send should have its own unique ID
        // which should be used to track responses (e.g. reply-to).
        // Once response is received, a promise should either be fulfilled or rejected.
        if (socket.current?.readyState === WebSocket.OPEN) {
            socket.current?.send(message);
        }
    }, []);

    React.useEffect(() => {
        if (!url || !options) {
            return undefined;
        }

        willClose.current = false;

        lastPongTs.current = undefined;

        socket.current = new WebSocket(url);

        socket.current.onopen = () => {
            options.onOpen?.();

            lastPongTs.current = Date.now();
            pingTimer.current = window.setInterval(() => {
                if (lastPongTs.current && Date.now() - lastPongTs.current > PONG_TIMEOUT) {
                    // eslint-disable-next-line no-console
                    console.warn('Too long since we received a pong');
                    close(CLOSE_CODE_PINGPONG_TIMEOUT, 'Too long since we received a pong');

                    return;
                }

                sendMessage(JSON.stringify({type: 'ping', payload: {id: Date.now()}}));
            }, PING_INTERVAL);
        };

        socket.current.onclose = (e: CloseEvent) => {
            options.onClose?.(e);
        };

        socket.current.onerror = (e) => {
            options.onError?.(e);
        };

        socket.current.onmessage = (message) => {
            if (!willClose.current) {
                try {
                    const messageData = JSON.parse(message.data);
                    if (messageData.type === 'pong') {
                        lastPongTs.current = Date.now();

                        return;
                    }

                    options.onMessage(messageData);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("Couldn't parse JSON", e);
                }
            }
        };

        return () => {
            willClose.current = true;
            close(CLOSE_CODE_CLIENT_LEFT);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return [sendMessage, getWebSocketReadyState];
};
