import classNames from 'classnames';
import React from 'react';

import {LocalBackdrop, LocalBackdropProps} from '../local-backdrop';

import styles from './zero-state.module.css';

const appVersion = document.head.querySelector('meta[name="version"]')?.getAttribute('content');

/** Component to show when no entity is chosen */
export function ZeroState({className, ...backdropProps}: LocalBackdropProps) {
    return (
        <div className={classNames(styles.root, className)}>
            <LocalBackdrop open variant="zerostate" {...backdropProps}>
                <div className={styles.appName}>
                    Biology
                    <br />
                    Knowledge
                    <br />
                    Base
                    {appVersion && <sup className={styles.appVersion}>{appVersion}</sup>}
                </div>
            </LocalBackdrop>
        </div>
    );
}
