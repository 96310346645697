import {Controls, ControlsItem, Typography} from '@genestack/ui';
import React from 'react';

import {EditorHeaderSkeleton} from '../../../../components/editor-header';
import {LocalBackdrop} from '../../../../components/local-backdrop';
import {LocalSpinner} from '../../../../components/local-spinner';
import {useLocationParams} from '../../../../hooks/use-location';
import {useDocRevisionsContext} from '../../../../providers/version';
import {useAddToRecentDocsMutation} from '../hooks/use-add-to-recent-docs-mutation';
import {useCreateDocumentMutation} from '../hooks/use-create-document-mutation';
import {useDocMetadataQuery, useDocMetadataQueryCache} from '../hooks/use-doc-metadata-query';

import {EditorBackdrop} from './editor-backdrop';
import {EditorLayout, EditorWrapper} from './editor-layout';
import {useUnpublishedDocsQueryCache} from '../hooks/use-unpublished-docs-query';
import {isDocumentBeingEdited} from '../../../../utils/document';
import {useFindUsagesQueryCache} from '../sidebar/search-layout/hooks/use-find-usages-query';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {FindUsagesEditorEvent} from '../../../../../webeditor/biokb-editor-adapters';

interface Props {
    accession: string;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
}

export function Editor(props: Props) {
    const documentQuery = useDocMetadataQuery(props.accession);
    const createDocMutation = useCreateDocumentMutation();
    const versionContext = useDocRevisionsContext();
    const locationParams = useLocationParams();
    const addToRecentMutation = useAddToRecentDocsMutation();
    const unpublishedDocsCache = useUnpublishedDocsQueryCache();
    const docMetadataCache = useDocMetadataQueryCache();
    const findUsagesQueryCache = useFindUsagesQueryCache();
    const searchDocsQueryCache = useSearchDocsCache();

    const [isEditorLoading, setEditorLoadingStatus] = React.useState(false);

    const findUsages = React.useCallback(
        (event: FindUsagesEditorEvent) =>
            props.findUsagesProps.findUsages(event, props.accession, locationParams.revisionId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.findUsagesProps.findUsages, locationParams.revisionId, props.accession]
    );

    const isVersionNotFound = Boolean(
        locationParams.revisionId && versionContext.revisions && !versionContext.selectedRevision
    );

    React.useEffect(() => {
        if (!documentQuery.data?.accession) {
            return;
        }

        // adding doc to recently viewed bc currently it is performed on FE
        addToRecentMutation.mutate(documentQuery.data);

        // once the user opens the doc that is edited by someone else, he also starts editing it
        if (isDocumentBeingEdited(documentQuery.data) && !documentQuery.data.editedByCurrentUser) {
            unpublishedDocsCache.updateDocument({...documentQuery.data, editedByCurrentUser: true});
            docMetadataCache.set({...documentQuery.data, editedByCurrentUser: true});
            findUsagesQueryCache.updateDocument({...documentQuery.data, editedByCurrentUser: true});
            searchDocsQueryCache.updateDocument({...documentQuery.data, editedByCurrentUser: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentQuery.data?.accession]);

    if (!documentQuery.data || isVersionNotFound) {
        return (
            <EditorWrapper>
                <EditorHeaderSkeleton />
                <EditorBackdrop
                    accession={locationParams.documentId}
                    isDocumentLoading={documentQuery.isLoading || isEditorLoading}
                    isVersionNotFound={isVersionNotFound}
                    isDocumentNotFound={!documentQuery.isLoading && !documentQuery.data}
                />
            </EditorWrapper>
        );
    }

    if (createDocMutation.isLoading) {
        return (
            <EditorWrapper>
                <LocalBackdrop open variant="accent">
                    <Controls justify="center">
                        <ControlsItem>
                            <LocalSpinner />
                        </ControlsItem>
                        <ControlsItem>
                            <Typography variant="section" inverted>
                                Creating new document…
                            </Typography>
                        </ControlsItem>
                    </Controls>
                </LocalBackdrop>
            </EditorWrapper>
        );
    }

    return (
        <EditorLayout
            accession={props.accession}
            onLoadingStateChange={setEditorLoadingStatus}
            documentMetadata={documentQuery.data}
            findUsages={findUsages}
        />
    );
}
