import {useMutation, useQuery, useQueryClient} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

const PAGE_SIZE = 100;

/** Request of recent documents */
export function useRecentsQuery() {
    return useQuery(['recents'], async () =>
        apiClient.get<PageResponse<ViewModeDocInfo>>({
            path: `documents-revisions-service/api/user/documents/recent?limit=${PAGE_SIZE}`
        })
    );
}

/** Query Cache for Recents */
function useRecentsQueryCache() {
    const queryClient = useQueryClient();

    function invalidate() {
        queryClient.invalidateQueries(['recents'], {refetchInactive: true});
    }

    function addDocument(docMetadata: ViewModeDocInfo) {
        queryClient.setQueryData<PageResponse<ViewModeDocInfo> | undefined>(
            'recents',
            (currentState) => {
                if (!currentState) {
                    return currentState;
                }

                const currentStateData = currentState.data.filter(
                    ({accession}) => accession !== docMetadata.accession
                );

                return {
                    ...currentState,
                    data: [docMetadata, ...currentStateData]
                };
            }
        );
    }

    return {invalidate, addDocument};
}

/** Add a document to Recents */
export function useAddToRecentsMutation() {
    const recentsQueryCache = useRecentsQueryCache();

    return useMutation(
        (docInfo: ViewModeDocInfo) => {
            return apiClient
                .post({
                    path: `documents-revisions-service/api/user/documents/${docInfo.id}/recent`
                })
                .then(() => docInfo);
        },
        {
            onSuccess: recentsQueryCache.addDocument
        }
    );
}
