import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {PageLayout} from '../../components/page-layout';
import {useLastLocationEffect} from '../../hooks/use-last-location-effect';

import {QueriesActivityBar} from './activity-bar';
import {QueriesSidebar} from './queries-sidebar';
import {QueryLayout} from './query-layout';
import {usePageLayout} from '../../components/page-layout/use-page-layout';
import {useSidebarTabs} from '../../hooks/use-sidebar-tabs';
import {QueriesTab} from '../../components/page-layout/layout-typings';
import {useLocationParams} from '../../hooks/use-location';
import {getLineSeparator} from '../../utils/get-client-os';
import {
    GlobalVersionProps,
    GlobalVersionsLoadedChecker
} from '../../components/global-versions/global-versions-loaded-checker';
import {useGlobalVersion} from '../../providers/version/use-global-version';
import {NewQuery} from './new-query/new-query';

interface Props {
    globalVersionProps: ReturnType<typeof useGlobalVersion>;
}

export function QueryWorkflow(props: Props) {
    const {queryId} = useLocationParams();
    const tabSettings = useSidebarTabs({
        layout: 'queries',
        activityTabStorageKey: 'queries.lastTab',
        defaultActivityBarTab: QueriesTab.DRAFTS
    });

    const layoutSettings = usePageLayout({
        layoutSizeStorageKey: 'queries.layoutSize',
        sidebarCollapsedStorageKey: 'queries.sidebarCollapsed'
    });

    useLastLocationEffect({
        path: '/queries',
        lsKey: 'queries.lastLocation'
    });

    return (
        <GlobalVersionsLoadedChecker
            errorText={`Knowledge Base global versions is required ${getLineSeparator()} to write and run queries`}
            globalVersionProps={props.globalVersionProps}
        >
            {({currentGlobalVersionData, globalVersions}: GlobalVersionProps) => (
                <PageLayout
                    sidebar={
                        <QueriesSidebar layoutSettings={layoutSettings} tabSettings={tabSettings} />
                    }
                    activityBar={
                        <QueriesActivityBar
                            layoutSettings={layoutSettings}
                            tabSettings={tabSettings}
                            currentGlobalVersion={currentGlobalVersionData}
                        />
                    }
                    layoutSettings={layoutSettings}
                >
                    <Switch>
                        <Route
                            path="/queries/new"
                            render={() => (
                                <NewQuery currentGlobalVersion={currentGlobalVersionData} />
                            )}
                        />
                        <Route
                            path="/queries/:queryId"
                            render={() => (
                                <QueryLayout
                                    queryId={Number(queryId)}
                                    globalVersions={globalVersions}
                                />
                            )}
                        />
                    </Switch>
                </PageLayout>
            )}
        </GlobalVersionsLoadedChecker>
    );
}
