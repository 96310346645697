import {
    BookmarkBorderedIcon,
    Button,
    Controls,
    ControlsItem,
    ScrollView,
    Typography
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';
import {InfiniteData} from 'react-query';

import {RelativeLink} from '../../../../../../components/relative-link';
import {UsageItemsGroup} from '../../../../../../components/usage-items-group';
import {
    useFindUsagesQuery,
    PAGE_SIZE,
    useFindUsagesQueryCache,
    FindUsagesWithDocNodes,
    FindUsagesResponse
} from '../../../hooks/requests/use-find-usages-query';
import {
    DocumentSyntheticEvent,
    useDocumentSyntheticEvents
} from '../../../../../../providers/document-synthetic-events';
import {extractInfiniteQueryItems} from '../../../../../../utils/infinite-query-items';
import {pluralize} from '../../../../../../utils/pluralize';
import {CopySearchButton} from '../../copy-search-button';
import {AppliedFiltersSummary} from '../categories/applied-filters-summary';
import {Categories} from '../categories/categories';
import {ExpandCategoriesButton} from '../categories/expand-button';
import {SearchResultsBody} from '../search-results-body';
import {SearchResultsHeader} from '../search-results-header';

import {ViewModeDocumentNodes} from './document-nodes';
import styles from './node-results.module.css';
import {SearchResultComponentProps} from '../interface';
import {NodeSearchData} from '../../../../common/sidebar-search-field/interface';
import {CategoryTreeElement} from '../categories/adapt-tree';

export function NodeResults({
    searchData,
    categoriesFilters,
    versionId,
    expanded,
    onToggleExpand,
    loadingState,
    setLoadingState,
    selectedGlobalVersion,
    getDocumentLink
}: SearchResultComponentProps<NodeSearchData>) {
    const syntheticEvents = useDocumentSyntheticEvents();

    const {nodeId, documentAccession} = searchData;

    const findUsagesQuery = useFindUsagesQuery({
        globalVersionId: versionId,
        accession: documentAccession,
        declarationNodeId: nodeId,
        categories: categoriesFilters.appliedFilters,
        enabled: false
    });

    React.useEffect(() => {
        if (documentAccession && nodeId) {
            findUsagesQuery.remove();
            findUsagesQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData, categoriesFilters.appliedFilters, versionId]);

    const findUsagesQueryCache = useFindUsagesQueryCache();

    const hasFindUsages = Number(findUsagesQuery?.data?.pages[0]?.total) > 0;

    const handleSyntheticEvent = React.useCallback(
        (event: DocumentSyntheticEvent) => {
            if (event.type === 'toggle-favorite') {
                const {accession} = event.payload.documentInfo;
                findUsagesQueryCache.invalidate({
                    accession,
                    globalVersionId: versionId,
                    declarationNodeId: nodeId,
                    categories: categoriesFilters.appliedFilters
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [findUsagesQuery?.data]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => syntheticEvents.subscribe(handleSyntheticEvent), [handleSyntheticEvent]);

    const searchLabel = React.useMemo(
        // eslint-disable-next-line no-use-before-define
        () => getSearchLabel(nodeId, hasFindUsages, findUsagesQuery?.data),
        [nodeId, findUsagesQuery?.data, hasFindUsages]
    );

    const items = findUsagesQuery
        ? extractInfiniteQueryItems(findUsagesQuery, (result: FindUsagesResponse) => result.data)
        : [];

    const renderFindUsage = React.useCallback(
        (findUsage: FindUsagesWithDocNodes) => {
            const {usages, remaining, nextCursor, document, total} = findUsage;

            const element = (
                <UsageItemsGroup
                    className={styles.usages}
                    type={document.type}
                    label={document.name}
                    readOnly={document.readOnly}
                    count={total}
                    append={
                        document.userFavorite && (
                            <div title="In your Bookmarks">
                                <BookmarkBorderedIcon />
                            </div>
                        )
                    }
                >
                    <ViewModeDocumentNodes
                        usageAccession={document.accession}
                        searchData={searchData}
                        initialNodes={usages}
                        remaining={remaining}
                        cursor={nextCursor}
                        selectedGlobalVersion={selectedGlobalVersion}
                        getDocumentLink={getDocumentLink}
                    />
                </UsageItemsGroup>
            );

            return [findUsage.document.accession, element] as [React.Key, React.ReactElement];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [findUsagesQuery?.data, searchData]
    );

    const handleChangeFilters = React.useCallback(
        (
            toAddVisual: CategoryTreeElement[],
            toRemoveVisual: CategoryTreeElement[],
            toAddApplied: string[],
            toRemoveApplied: string[]
        ) => {
            categoriesFilters.onChangeAppliedFilters(toAddApplied, toRemoveApplied);
            categoriesFilters.onChangeVisualFilters(toAddVisual, toRemoveVisual);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        categoriesFilters.onResetFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchData]);

    return (
        <>
            <SearchResultsHeader loadingState={loadingState}>
                <Controls justify="space-between">
                    <ControlsItem shrink>
                        <Controls>
                            <ControlsItem>
                                <ExpandCategoriesButton
                                    expanded={expanded}
                                    onToggleExpand={onToggleExpand}
                                />
                            </ControlsItem>
                            <ControlsItem className={styles.label} shrink title={searchLabel}>
                                <Typography variant="section" ellipsis>
                                    {searchLabel}
                                </Typography>
                            </ControlsItem>
                        </Controls>
                    </ControlsItem>

                    {nodeId && (
                        <ControlsItem>
                            <Controls>
                                <ControlsItem>
                                    <Button
                                        ghost
                                        size="small"
                                        component={RelativeLink}
                                        to={getDocumentLink(documentAccession!, nodeId)}
                                    >
                                        Go to reference
                                    </Button>
                                </ControlsItem>

                                <ControlsItem>
                                    <CopySearchButton
                                        searchData={searchData}
                                        selectedGlobalVersion={selectedGlobalVersion}
                                    />
                                </ControlsItem>
                            </Controls>
                        </ControlsItem>
                    )}
                </Controls>
            </SearchResultsHeader>

            <div className={styles.main}>
                {expanded && documentAccession && nodeId ? (
                    <ScrollView className={styles.left}>
                        <Categories
                            versionId={versionId}
                            referenceNode={{accession: documentAccession, nodeId}}
                            visualFilters={categoriesFilters.visualFilters}
                            appliedFilters={categoriesFilters.appliedFilters}
                            onChangeFilters={handleChangeFilters}
                        />
                    </ScrollView>
                ) : null}
                <ScrollView className={classNames(styles.right, {[styles.expanded]: expanded})}>
                    {categoriesFilters.visualFilters.length ? (
                        <AppliedFiltersSummary
                            visualFilters={categoriesFilters.visualFilters}
                            onChangeFilters={handleChangeFilters}
                            isLoading={loadingState.isLoading}
                            count={findUsagesQuery?.data?.pages[0]?.total || 0}
                            onResetFilters={categoriesFilters.onResetFilters}
                        />
                    ) : null}
                    <SearchResultsBody
                        isLoading={!versionId}
                        pageSize={
                            findUsagesQuery?.data?.pages.map((page) => page.data.length) ||
                            PAGE_SIZE
                        }
                        items={items}
                        paginationState={{
                            items,
                            total: findUsagesQuery?.data?.pages[0]?.total,
                            isLoading: findUsagesQuery?.isLoading || false,
                            loadNextPage: findUsagesQuery?.fetchNextPage,
                            isLoadingNextPage: findUsagesQuery?.isFetchingNextPage,
                            hasNextPage: findUsagesQuery?.hasNextPage,
                            reset: findUsagesQuery?.remove
                        }}
                        renderItem={renderFindUsage}
                        onLoadNextPage={findUsagesQuery?.fetchNextPage}
                        loadingState={loadingState}
                        setLoadingState={setLoadingState}
                    />
                </ScrollView>
            </div>
        </>
    );
}

function getSearchLabel(
    nodeId: string | null,
    hasFindUsages: boolean,
    response?: InfiniteData<FindUsagesResponse>
) {
    if (!nodeId || !hasFindUsages) {
        return 'No results found';
    }

    if (response && response.pages) {
        const {totalNodes, total} = response.pages[0];
        const usagesLabel = `${totalNodes} ${pluralize('usage', totalNodes)}`;
        const documentLabel = `${total} ${pluralize('document', total)}`;

        return `${usagesLabel} in ${documentLabel}`;
    }

    return 'No usages found';
}
