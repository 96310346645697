import {
    Button,
    MenuIcon,
    PlusIcon,
    SearchIcon,
    TimeReverseIcon,
    TrashIcon,
    WorkingCopyIcon
} from '@genestack/ui';
import React from 'react';

import {VerticalBar, VerticalBarItem} from '../../../../components/vertical-bar';

import styles from './activity-bar.module.css';
import {LayoutMenu} from './layout-menu';
import {EditModeTab} from '../../../../components/page-layout/layout-typings';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Search',
        value: EditModeTab.SEARCH,
        prepend: <SearchIcon />
    },
    {
        tooltip: 'Show recent document',
        value: EditModeTab.RECENT,
        prepend: <TimeReverseIcon />
    },
    {
        tooltip: 'To be published',
        value: EditModeTab.UNPUBLISHED,
        prepend: <WorkingCopyIcon />
    },
    {
        tooltip: 'Browse documents',
        value: EditModeTab.DOCUMENTS,
        prepend: <MenuIcon />
    },
    {
        className: styles.binTab,
        tooltip: 'Recycle bin',
        value: EditModeTab.BIN,
        prepend: <TrashIcon />
    }
];

interface Props {
    createNew: (docType: DocType) => void;
    isDocCreating: boolean;
    tabSettings: ReturnType<typeof useSidebarTabs>;
}

export function EditModeActivityBar({createNew, isDocCreating, tabSettings}: Props) {
    const handleCreateNewDocument = () => {
        createNew('BTB');
    };

    return (
        <div className={styles.root}>
            <Button
                data-qa="add-new-btb-document"
                icon={<PlusIcon />}
                onClick={handleCreateNewDocument}
                disabled={isDocCreating}
            />

            <LayoutMenu tabSettings={tabSettings} className={styles.menu} createNew={createNew} />

            <VerticalBar
                tabs={SIDEBAR_TABS}
                value={tabSettings.activityBarTab}
                onValueChange={tabSettings.setActivityBarTab}
            />
        </div>
    );
}
