import {Button, Divider, Typography} from '@genestack/ui';
import * as React from 'react';

import {ValidatedInput} from '../../../components/validated-input';
import {FormQueryResponse, isFormQueryResponse} from '../../../hooks/user/auth-form-query';
import {buildFormData} from '../../../utils/build-form-data';
import {IdentityFormMessage} from '../identity-form-message';
import styles from '../login-page/login-page.module.css';
import {getSubmitNode} from '../utils';

const SUCCESS_RECOVERY_STATE = 'sent_email';

interface Props {
    data: FormQueryResponse;
    onSubmit: (jsonFormData: {[key: string]: FormDataEntryValue}) => void;
    result?: FormQueryResponse;
    isLoading?: boolean;
}

/** Form for Recovering a password */
export function RecoveryForm(props: Props) {
    const [emailValue, setEmailValue] = React.useState('');
    const [submitNotActive, setSubmitNotActive] = React.useState(false);

    const formRef = React.createRef<HTMLFormElement>();

    const submitNode = getSubmitNode(props.data.ui?.nodes);

    React.useEffect(() => {
        if (isFormQueryResponse(props.result) && props.result.state === SUCCESS_RECOVERY_STATE) {
            setSubmitNotActive(true);
        }
    }, [props.result]);

    const handleInputFocus = () => {
        if (submitNotActive) {
            setSubmitNotActive(false);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (formRef.current) {
            const jsonFormData = buildFormData(formRef.current);
            if (submitNode?.attributes.name && submitNode?.attributes.value) {
                jsonFormData[String(submitNode.attributes.name)] = String(
                    submitNode.attributes.value
                );
            }

            props.onSubmit(jsonFormData);
        }
    };

    const hiddenNodes = props.data.ui?.nodes.filter((node) => node.attributes.type === 'hidden');
    const emailNode = props.data.ui?.nodes.find((node) => node.attributes.name === 'email');

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const {value, defaultValue, ...restAttributes} = emailNode?.attributes || {};

    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            {hiddenNodes?.map((node) => {
                return <input key={node.attributes.name} {...node.attributes} />;
            })}

            <Typography variant="section" box="paragraph" as="label" htmlFor="email" intent="quiet">
                Email to receive password reset link
            </Typography>

            <ValidatedInput
                className={styles.input}
                value={emailValue}
                onValueChange={setEmailValue}
                onFocus={handleInputFocus}
                validationText={emailNode?.messages?.[0]?.text}
                {...restAttributes}
            />

            <IdentityFormMessage response={props.result} />

            <Divider variant="transparent" gap={2} />
            <Button type="submit" intent="accent" disabled={props.isLoading || submitNotActive}>
                Get link
            </Button>
        </form>
    );
}
