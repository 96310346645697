import {useMutation, UseMutationOptions} from 'react-query';

import {apiClient} from '../../../utils/api-client';

interface Payload {
    queryId: number;
}

function moveQueryInQueueRequest(queryId: number) {
    return apiClient.callApi({
        path: `queries-service/api/queries/${queryId}`,
        method: 'PATCH',
        parameters: {
            rankUp: true
        }
    });
}

/** Move query up in pending queue mutation */
export function useMoveQueryInQueueMutation() {
    // tslint:disable-next-line: no-object-literal-type-assertion
    return useMutation<number, Error, Payload>({
        mutationFn: (payload: Payload) => {
            moveQueryInQueueRequest(payload.queryId);
        }
    } as UseMutationOptions<number, Error, Payload>);
}
