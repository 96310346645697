import {Badge, ChevronRightIcon, ChevronDownIcon} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {DocumentTypeLabel} from '../document-type-label';

import styles from './usage-items-group.module.css';

interface Props {
    className?: string;
    label: string;
    type: DocType;
    count: number;
    readOnly?: boolean;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    children?: React.ReactNode;
}

/** Groups document info and find usages */
export const UsageItemsGroup: React.FunctionComponent<Props> = ({
    className,
    label,
    type,
    count,
    prepend,
    append,
    children,
    readOnly
}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const toggleCollapsedState = () => {
        setIsCollapsed(!isCollapsed);
    };

    const Icon = isCollapsed ? ChevronRightIcon : ChevronDownIcon;

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */}
            <div
                className={classNames(styles.usageGroupHeader, className)}
                onClick={toggleCollapsedState}
            >
                <div className={styles.usageGroupTitle}>
                    <Icon className={styles.usageGroupArrow} />
                    {prepend}
                    <DocumentTypeLabel
                        className={styles.documentTypeLabel}
                        readOnly={readOnly}
                        type={type}
                    />
                    <span className={styles.usageGroupLabel} title={label}>
                        {label}
                    </span>
                </div>

                <div className={styles.aside}>
                    {append}

                    <Badge
                        ghost
                        className={styles.count}
                        classes={{
                            content: styles.countContent
                        }}
                    >
                        {count}
                    </Badge>
                </div>
            </div>

            {!isCollapsed && children}
        </>
    );
};
