import {Badge} from '@genestack/ui';
import React from 'react';

import styles from './user-deactivated-badge.module.css';

interface Props {
    className?: string;
}

/** Just a simple badge that shows a user was deactivated */
export const UserDeactivatedBadge = ({className}: Props) => (
    <Badge
        ghost
        disableTextTransform
        classes={{
            intentDefault: styles.intentDefault,
            content: styles.badge
        }}
        className={className}
    >
        Deactivated
    </Badge>
);
