import {useQueryClient, InfiniteData} from 'react-query';
import {DataUpdateFunction} from 'react-query/types/core/utils';

import {sum} from '../../../../../utils/sum';

import {
    CategoriesPageResponse,
    CategoryId,
    CategoryTreeElement
} from './use-categories-infinite-query';

type CategoryInfinityQuery = InfiniteData<CategoryTreeElement> | undefined;

function getCategoryCounts(documentsDiff: number, data?: CategoryTreeElement) {
    const childrenCategoryCount = data?.children.total ?? 0;
    const currentDocumentsCount = data?.documentsCount ?? 0;
    const updatedDocumentsCount = currentDocumentsCount + documentsDiff;

    return {
        childrenCategoryCount,
        documentsCount: updatedDocumentsCount,
        hasSiblings: childrenCategoryCount > 0 || updatedDocumentsCount > 0
    };
}

function removeCategoryFromPage(
    categoryId: CategoryId,
    categoriesData: CategoriesPageResponse
): CategoriesPageResponse {
    return {
        ...categoriesData,
        total: sum(categoriesData.total, -1, {min: 0}),
        data: categoriesData.data.filter(({categoryNodeId}) => categoryId !== categoryNodeId)
    };
}

/** Hook for manage the cache of categories in edit view */
export function useCategoriesInfiniteQueryCache() {
    const queryClient = useQueryClient();
    const getQueryFilters = (categoryId?: CategoryId | null) => ({
        queryKey: categoryId ? ['categories', categoryId] : 'categories',
        exact: false
    });

    function getParentCategoryData(categoryId: CategoryId) {
        const queryFilters = getQueryFilters();

        const queriesData = queryClient.getQueriesData<CategoryInfinityQuery>(queryFilters);

        for (const [, data] of queriesData) {
            const categoryData = data?.pages.find(({children}) =>
                children.data.some(({categoryNodeId}) => categoryNodeId === categoryId)
            );

            if (categoryData) {
                return categoryData;
            }
        }
    }

    function invalidate() {
        const queryFilters = getQueryFilters();

        return queryClient.invalidateQueries(queryFilters);
    }

    function updateCategories(
        // eslint-disable-next-line default-param-last
        categoryId: CategoryId | null = null,
        updater: DataUpdateFunction<InfiniteData<CategoryTreeElement>, CategoryInfinityQuery>
    ) {
        const queryFilters = getQueryFilters(categoryId);
        queryClient.setQueriesData<CategoryInfinityQuery>(
            queryFilters.queryKey,
            (currentState) => currentState && updater(currentState)
        );
    }

    function removeCategory(categoryId: CategoryId) {
        const queryFilters = getQueryFilters(categoryId);

        queryClient.removeQueries(queryFilters);
    }

    function removeChildCategory(categoryId: CategoryId) {
        const emptyCategoryIds: string[] = [];

        updateCategories(null, (currentState) => ({
            ...currentState,
            pages: currentState.pages.map((page) => {
                const isEmptyCategory =
                    page.children.total <= 1 && page.children.nextCursor === categoryId;

                if (isEmptyCategory) {
                    emptyCategoryIds.push(page.categoryNodeId);
                }

                return {
                    ...page,
                    children: removeCategoryFromPage(categoryId, page.children)
                };
            })
        }));

        emptyCategoryIds.forEach((parentCategoryId) => {
            removeChildCategory(parentCategoryId);
            removeCategory(parentCategoryId);
        });
    }

    function updateDocumentsCount(categoryId: CategoryId, diff: number) {
        updateCategories(categoryId, (currentState) => {
            const categoryCounts = getCategoryCounts(diff, currentState?.pages[0]);
            if (categoryCounts.hasSiblings) {
                return {
                    ...currentState,
                    pages: currentState.pages.map((page) => ({
                        ...page,
                        documentsCount: categoryCounts.documentsCount
                    }))
                };
            }

            if (diff < 0) {
                removeChildCategory(categoryId);
            }
        });
    }

    return {invalidate, updateDocumentsCount, getParentCategoryData};
}
