import React from 'react';
import {neighborhoodGraphHeaderHeight} from '../document-view/neighborhood-graph/neighborhood-graph-header';
import {collapsedFactsetPanelHeight} from '../../../query-workflow/query-layout/completed-query/result-chains-panel/graph-result-chains-panel';
import {editorHeaderHeight} from '../../../../components/editor-header/editor-header';

interface Props {
    selectedFacts: string[];
    selectedNodes: number[];
}

export function useDocLayoutSettings({selectedFacts, selectedNodes}: Props) {
    const [isDocBodyCollapsed, setIsDocBodyCollapsed] = React.useState<boolean>(false);

    const [isGraphPanelCollapsed, setIsGraphPanelCollapsed] = React.useState<boolean>(false);
    const toggleIsGraphPanelCollapsed = React.useCallback(() => {
        if (isDocBodyCollapsed && !isGraphPanelCollapsed) {
            setIsDocBodyCollapsed(false);
        }

        setIsGraphPanelCollapsed(!isGraphPanelCollapsed);
    }, [isGraphPanelCollapsed, isDocBodyCollapsed]);

    const toggleIsDocBodyCollapsed = React.useCallback(() => {
        if (isGraphPanelCollapsed && !isDocBodyCollapsed) {
            setIsGraphPanelCollapsed(false);
        }
        setIsDocBodyCollapsed(!isDocBodyCollapsed);
    }, [isGraphPanelCollapsed, isDocBodyCollapsed]);

    const [isFactsetPanelCollapsed, setIsFactsetPanelCollapsed] = React.useState(false);
    const toggleFactsetPanelCollapsed = React.useCallback(
        () => setIsFactsetPanelCollapsed((prev) => !prev),
        []
    );

    React.useEffect(() => {
        if (!selectedFacts.length) {
            setIsGraphPanelCollapsed(false);
            setIsDocBodyCollapsed(false);
        }
    }, [selectedFacts, isGraphPanelCollapsed]);

    React.useEffect(() => {
        if (!selectedNodes.length) {
            setIsFactsetPanelCollapsed(false);
        }
    }, [selectedNodes]);

    const graphPanelFixedSize = (function () {
        if (isDocBodyCollapsed) {
            return {panelIndex: 0 as const, size: editorHeaderHeight};
        }

        if (!selectedFacts.length) {
            return {panelIndex: 1 as const, size: 0};
        }

        if (isGraphPanelCollapsed) {
            return {panelIndex: 1 as const, size: neighborhoodGraphHeaderHeight};
        }

        return undefined;
    })();

    const factsetPanelFixedSize = (function () {
        if (!selectedNodes.length) {
            return {panelIndex: 1 as const, size: 0};
        }

        if (isFactsetPanelCollapsed) {
            return {panelIndex: 1 as const, size: collapsedFactsetPanelHeight};
        }

        return undefined;
    })();

    return {
        isDocBodyCollapsed,
        toggleIsDocBodyCollapsed,
        isGraphPanelCollapsed,
        toggleIsGraphPanelCollapsed,
        graphPanelFixedSize,
        isFactsetPanelCollapsed,
        toggleFactsetPanelCollapsed,
        factsetPanelFixedSize
    };
}
