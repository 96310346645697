import {useInfiniteQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

const PAGE_SIZE = 60;

interface Props {
    queryId: number;
    connectionId: number;
}

/** useInfiniteQuery for specific result (connection path) for given query by its Connection ID */
export function useConnectionsInfiniteQuery(props: Props) {
    return useInfiniteQuery(
        ['connection', props.queryId, props.connectionId],
        async ({pageParam = {cursor: null, limit: PAGE_SIZE}}) =>
            apiClient.get<PageResponse<PathEntryDto, number>>({
                path: `queries-service/api/queries/${props.queryId}/connection-path`,
                query: {
                    'connection-id': props.connectionId,
                    cursor: pageParam.cursor,
                    limit: pageParam.limit
                }
            }),
        {
            getNextPageParam: ({nextCursor, remaining}) => {
                const hasNextPage = remaining > 0;
                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: PAGE_SIZE
                    };
                }
            },
            keepPreviousData: true
        }
    );
}
