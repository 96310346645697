import {NotificationProps, Notification, Typography, Divider, Button} from '@genestack/ui';
import * as React from 'react';

export type NotificationElement = React.ReactElement<NotificationProps>;

export interface NotificationItem {
    key: number;
    element: NotificationElement;
}

type NotificationsStoreChangeHandler = (notification: NotificationItem) => void;

// looks like all this logic should be moved to notification-center component, see JBKB-3237
export class NotificationsStore {
    private lastNotificationIndex = -1;
    private changeHandlers: NotificationsStoreChangeHandler[] = [];

    /** Subscribe to adding and updating notifications on the page. */
    public subscribe(changeHandler: NotificationsStoreChangeHandler) {
        this.changeHandlers.push(changeHandler);

        // eslint-disable-next-line no-return-assign
        return () =>
            (this.changeHandlers = this.changeHandlers.filter(
                (handler) => handler !== changeHandler
            ));
    }

    /** Create new notification descriptor and returns its updater. */
    public addNotification(element: NotificationElement) {
        this.lastNotificationIndex += 1;

        const key = this.lastNotificationIndex;

        this.changeHandlers.forEach((handler) => {
            handler({key, element});
        });

        return (updatedElement: NotificationElement) => {
            this.changeHandlers.forEach((handler) => {
                handler({key, element: updatedElement});
            });
        };
    }
}

export const notificationsStore = new NotificationsStore();

export function showNotification(notification: NotificationElement) {
    return notificationsStore.addNotification(notification);
}

export function showErrorNotification(title = 'Request error, try again later') {
    return (error: unknown) => {
        showNotification(
            <Notification countdown="none">
                <Typography intent="alarm">{title}</Typography>
                {error instanceof Error && <Typography>{error.message}</Typography>}
            </Notification>
        );
    };
}

interface UndoBtnProps {
    onClick: () => void;
    disabled?: boolean;
}

interface SuccessNotificationProps {
    title: string;
    label?: string;
    undoBtnProps?: UndoBtnProps;
    onClose?: () => void;
}

export function NotificationWithBtn(props: SuccessNotificationProps) {
    return (
        <Notification onClose={props.onClose}>
            <Typography intent="success" variant="section">
                {props.title}
            </Typography>
            {props.label && <Typography>{props.label}</Typography>}

            {props.undoBtnProps && (
                <React.Fragment>
                    <Divider variant="transparent" />

                    <Button
                        intent="accent"
                        size="small"
                        onClick={props.undoBtnProps.onClick}
                        disabled={props.undoBtnProps.disabled}
                    >
                        Undo
                    </Button>
                    <Divider variant="transparent" />
                </React.Fragment>
            )}
        </Notification>
    );
}
