import {
    Typography,
    Controls,
    ControlsItem,
    StopIcon,
    BookmarkBorderedIcon,
    ShareIcon,
    PlusUserIcon,
    InProgressIcon,
    FailedIcon
} from '@genestack/ui';
import React from 'react';

import {QueryLink} from './query-link';

interface Props {
    queryInfo: QueryInfo;
    isSelected: boolean;
    showOwnerName?: boolean;
    isSharedIcon?: boolean;
    isNotMyQueryIcon?: boolean;
    isBookmarkedIcon?: boolean;
}

const STATUS_ICON: Record<QueryInfo['status'], React.ElementType | null> = {
    RUNNING: InProgressIcon,
    PENDING: InProgressIcon,
    COMPLETED: null,
    STOPPED: StopIcon,
    FAILED: FailedIcon,
    DRAFT: null
};

// eslint-disable-next-line react/display-name
export const QueryInfoItem = React.memo(
    ({
        queryInfo,
        isSelected,
        isSharedIcon,
        isNotMyQueryIcon,
        isBookmarkedIcon,
        showOwnerName
    }: Props) => {
        const StatusIcon = STATUS_ICON[queryInfo.status];

        return (
            <QueryLink
                queryId={queryInfo.id}
                active={isSelected}
                append={
                    <Controls gap={1} align="center">
                        {isBookmarkedIcon && (
                            <ControlsItem title="In your Bookmarks">
                                <BookmarkBorderedIcon />
                            </ControlsItem>
                        )}

                        {isSharedIcon && (
                            <ControlsItem title="Shared query">
                                <PlusUserIcon />
                            </ControlsItem>
                        )}

                        {isNotMyQueryIcon && (
                            <ControlsItem title="Created by other user">
                                <ShareIcon />
                            </ControlsItem>
                        )}

                        {StatusIcon && (
                            <ControlsItem>
                                <StatusIcon />
                            </ControlsItem>
                        )}

                        <ControlsItem>
                            <Typography
                                intent="quiet"
                                variant="body"
                                title="Number of results found"
                            >
                                {queryInfo.resultsCount}
                            </Typography>
                        </ControlsItem>
                    </Controls>
                }
            >
                {queryInfo.name}

                {showOwnerName && (
                    <Typography intent="quiet" variant="caption">
                        {queryInfo.owner.name}
                    </Typography>
                )}
            </QueryLink>
        );
    }
);
