import React, {HTMLAttributes} from 'react';

import {getAllIncludes} from '../../utils/text';

import styles from './highlighted-text.module.css';

interface Props {
    value: string;
    text: string;
    highlightedElementProps?: HTMLAttributes<HTMLElement>;
}

/** Highlights all inclusions in the text */
export function HighlightedText({value, text, highlightedElementProps}: Props) {
    const insertions = getAllIncludes(value, text);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {insertions.length
                ? insertions.map(({prev, current}, insertionIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={insertionIndex}>
                          {prev}
                          <mark className={styles.mark} {...highlightedElementProps}>
                              {current}
                          </mark>
                      </React.Fragment>
                  ))
                : text}
        </>
    );
}
