import React from 'react';
import {PageContent, Preloader, ScrollView, Typography} from '@genestack/ui';
import {RightPanelHeader} from '../right-panel-header';
import {useGetStartEndNodes} from './use-get-start-end-nodes';
import {FoldingItem} from '../folding-item';
import {useFoldingItemsState} from '../statistics/use-folding-items-state';
import {useFilterNodesByType} from './use-filter-nodes-by-type';
import {NodesGroup} from './nodes-group';
import {showErrorNotification} from '../../../../../../components/notifications-center/notifications-store';
import {GraphViewType, QueryNodeType} from '../../../../../../components/graph/interface';

interface Props {
    queryId: number;
    viewType: GraphViewType;
    maxPathLength: number;
    closePanel: () => void;
    selectedGraphNodes: number[];
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
}

export function StartEndNodesPanel(props: Props) {
    const startEndNodes = useGetStartEndNodes(
        props.queryId,
        props.viewType,
        props.maxPathLength,
        showErrorNotification('Request error, try again later')
    );
    const foldingItemsProps = useFoldingItemsState();

    const {displayed, hidden} = startEndNodes.data || {};
    const displayedStartNodes = useFilterNodesByType(QueryNodeType.START, displayed);
    const displayedEndNodes = useFilterNodesByType(QueryNodeType.END, displayed);
    const hiddenStartNodes = useFilterNodesByType(QueryNodeType.START, hidden);
    const hiddenEndNodes = useFilterNodesByType(QueryNodeType.END, hidden);

    const interactiveNodesGroupProps = {
        selectGraphNodes: props.selectGraphNodes,
        selectedGraphNodes: props.selectedGraphNodes,
        setHoveredNodes: props.setHoveredNodes
    };

    return (
        <React.Fragment>
            <RightPanelHeader closePanel={props.closePanel}>
                <Typography variant="section">Start and end nodes</Typography>
            </RightPanelHeader>

            {(function () {
                if (
                    !startEndNodes.data ||
                    !displayedEndNodes ||
                    !displayedStartNodes ||
                    !hiddenStartNodes ||
                    !hiddenEndNodes
                ) {
                    return (
                        <PageContent>
                            <Preloader show count={5} box="paragraph" />
                        </PageContent>
                    );
                }

                return (
                    <ScrollView>
                        <FoldingItem
                            label="Within the current subgraph"
                            count={startEndNodes.data.displayed.length}
                            padding={1}
                            isAlwaysUnfolded
                        >
                            <NodesGroup
                                foldingStateKey="displayed.startNodes"
                                label="Start nodes"
                                nodes={displayedStartNodes}
                                foldingStateProps={foldingItemsProps}
                                {...interactiveNodesGroupProps}
                            />

                            <NodesGroup
                                foldingStateKey="displayed.endNodes"
                                label="End nodes"
                                nodes={displayedEndNodes}
                                foldingStateProps={foldingItemsProps}
                                {...interactiveNodesGroupProps}
                            />
                        </FoldingItem>

                        {!!(hiddenStartNodes.length + hiddenEndNodes.length) && (
                            <FoldingItem
                                label="Other nodes from the complete graph"
                                count={startEndNodes.data.hidden.length}
                                padding={1}
                                isAlwaysUnfolded
                            >
                                {hiddenStartNodes.length > 0 && (
                                    <NodesGroup
                                        foldingStateKey="hidden.startNodes"
                                        label="Start nodes"
                                        nodes={hiddenStartNodes}
                                        foldingStateProps={foldingItemsProps}
                                    />
                                )}

                                {hiddenEndNodes.length > 0 && (
                                    <NodesGroup
                                        foldingStateKey="hidden.endNodes"
                                        label="End nodes"
                                        nodes={hiddenEndNodes}
                                        foldingStateProps={foldingItemsProps}
                                    />
                                )}
                            </FoldingItem>
                        )}

                        {startEndNodes.data.otherMatchesCount && (
                            <FoldingItem
                                label="Outside the graph"
                                count={
                                    startEndNodes.data.otherMatchesCount.start +
                                    startEndNodes.data.otherMatchesCount.end
                                }
                                padding={1}
                                isAlwaysUnfolded
                            >
                                <FoldingItem
                                    label="Facts that meet the criteria of <from> statement"
                                    count={startEndNodes.data.otherMatchesCount.start}
                                    padding={2}
                                    isAlwaysUnfolded
                                />
                                <FoldingItem
                                    label="Facts that meet the criteria of <to> statement"
                                    count={startEndNodes.data.otherMatchesCount.end}
                                    padding={2}
                                    isAlwaysUnfolded
                                />
                            </FoldingItem>
                        )}
                    </ScrollView>
                );
            })()}
        </React.Fragment>
    );
}
