import {
    ScrollView,
    Controls,
    ControlsItem,
    Button,
    Tooltip,
    TooltipHandler,
    CrossIcon
} from '@genestack/ui';
import React from 'react';

import {DocumentContentsList} from '../document-content';
import styles from './result-chains-panel.module.css';
import {useConnectionQuery} from '../../../../hooks/use-connection-query';
import {ConnectionLabel} from '../connection-label/connection-label';
import {ResultChainLabel} from '../result-chain-label';
import {CollapseButton} from '../collapse-button';
import {BusyIndicator} from '../../../busy-indicator';
import {LocalErrorBoundary} from '../../../error-boundary';

interface Props {
    connectionId: number;
    queryInfo: QueryInfo;
    closePanel: () => void;
    selectedConnectionIndex: number;
    isPanelCollapsed: boolean;
    toggleIsPanelCollapsed: () => void;
}

export function ResultChainsPanel(props: Props) {
    const connectionQuery = useConnectionQuery(props.queryInfo.id, props.connectionId);

    return (
        <React.Fragment>
            <div className={styles.header}>
                <Controls justify="space-between">
                    <ControlsItem shrink>
                        <Controls gap={1}>
                            {props.selectedConnectionIndex !== -1 && (
                                <ControlsItem style={{display: 'flex'}}>
                                    <ConnectionLabel>
                                        {props.selectedConnectionIndex + 1}
                                    </ConnectionLabel>
                                </ControlsItem>
                            )}
                            {connectionQuery.data && (
                                <ControlsItem shrink>
                                    <ResultChainLabel
                                        variant="section"
                                        pathLength={connectionQuery.data.pathLength}
                                        score={connectionQuery.data.score}
                                    />
                                </ControlsItem>
                            )}
                        </Controls>
                    </ControlsItem>

                    <ControlsItem>
                        <Controls>
                            <CollapseButton
                                isCollapsed={props.isPanelCollapsed}
                                toggleCollapsed={props.toggleIsPanelCollapsed}
                            />
                            <ControlsItem>
                                <TooltipHandler
                                    tooltip={<Tooltip placement="left">Close panel</Tooltip>}
                                >
                                    <Button
                                        ghost
                                        size="small"
                                        onClick={props.closePanel}
                                        data-qa="close-result-chain"
                                        icon={<CrossIcon />}
                                    />
                                </TooltipHandler>
                            </ControlsItem>
                        </Controls>
                    </ControlsItem>
                </Controls>
            </div>

            {(function () {
                if (connectionQuery.isError) {
                    return (
                        <div className={styles.root} style={{padding: 16}}>
                            Something went wrong...
                        </div>
                    );
                }

                if (connectionQuery.isLoading || !connectionQuery.data) {
                    return (
                        <div className={styles.root}>
                            <BusyIndicator />
                        </div>
                    );
                }

                return (
                    <ScrollView className={styles.root}>
                        <LocalErrorBoundary
                            variant="text"
                            message="Error! It is not possible to load the document"
                        >
                            <DocumentContentsList
                                globalVersionId={props.queryInfo.globalVersionId}
                                queryId={props.queryInfo.id}
                                connectionData={connectionQuery.data}
                            />
                        </LocalErrorBoundary>
                    </ScrollView>
                );
            })()}
        </React.Fragment>
    );
}
