import {chain} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {
    BTBCategoryInfo,
    editedDocumentSource,
    editedWorkingCopySource,
    FindUsagesEditorEvent,
    historyPanelDocumentSource,
    NodeEditor
} from '../../../../../../webeditor/biokb-editor-adapters';
import {BelEditorWrapper} from '../../../../../components/editor';
import {showNotification} from '../../../../../components/notifications-center';
import {PublishStatusNotification} from '../../../../../components/notifications/publish';
import {useUserPermittedEditingDoc} from '../../../../../hooks/user/use-has-permission';
import {EditorFooter} from '../../../common/editor-footer';
import {useAddDocumentToCategoryCache} from '../../hooks/categories/use-add-document-to-category-cache';
import {useRemoveDocumentFromCategoryCache} from '../../hooks/categories/use-remove-document-from-category-cache';
import {useLastActionQuery} from '../../hooks/use-last-action-query';
import {usePublishWorkingCopyMutation} from '../../hooks/use-publish-working-copy-mutation';
import {useFetchWorkingCopyQuery} from '../../hooks/use-fetch-working-copy-query';
import {LastActionStatus, Status} from '../bottom-bar';
import {DocumentEditorHeader} from '../document-editor-header';
import {useDocumentTitle} from '../document-editor-header/document-editor-header';
import {EditorBackdrop} from '../editor-backdrop';

import styles from './editor.module.css';

interface Props {
    isEditMode: boolean;
    documentMetadata: DocInfo;
    nodeId?: string;
    revisionId?: string;
    onNavigate: (accession: string | null, nodeId: string | null) => void;
    onFindUsages: (event: FindUsagesEditorEvent, editorInstance?: NodeEditor) => void;
    onLoadingStateChange: (loading: boolean) => void;
}

/** Editor which shows Document content in View mode */
export function Editor(props: Props) {
    const [editorLoading, setEditorLoading] = React.useState(false);

    const editorRef = React.useRef<BelEditorWrapper>(null);
    const belEditorPublished = () => {
        editorRef.current?.onPublished(props.documentMetadata.accession);
    };

    const publishWorkingCopyMutation = usePublishWorkingCopyMutation(belEditorPublished);
    const addDocumentToCategory = useAddDocumentToCategoryCache();
    const removeDocumentFromCategory = useRemoveDocumentFromCategoryCache();

    const lastActionQuery = useLastActionQuery(
        {
            accession: props.documentMetadata.accession,
            action: 'PUBLISH'
        },
        {
            enabled: !props.isEditMode
        }
    );

    const wcQuery = useFetchWorkingCopyQuery(
        {
            accession: props.documentMetadata.accession
        },
        props.isEditMode
    );

    const handleAddCategory = ({accession, nodeId}: BTBCategoryInfo) => {
        const categoryId = `${accession}/${nodeId}`;

        addDocumentToCategory.mutate({
            categoryId,
            document: props.documentMetadata
        });
    };

    const handleRemoveCategory = ({accession, nodeId}: BTBCategoryInfo) => {
        const categoryId = `${accession}/${nodeId}`;

        removeDocumentFromCategory.mutate({
            categoryId,
            document: props.documentMetadata
        });
    };

    const handlePublish = async (commitMessage?: string) => {
        try {
            await publishWorkingCopyMutation.mutateAsync({
                accession: props.documentMetadata.accession,
                commitMessage
            });

            showNotification(<PublishStatusNotification />);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : undefined;

            showNotification(<PublishStatusNotification isError errorMessage={errorMessage} />);
        }
    };

    const isLoading = editorLoading || wcQuery.isLoading;
    const documentTitle = useDocumentTitle(props.documentMetadata, wcQuery.data?.workingCopyName);
    const userCanEditDocument = useUserPermittedEditingDoc(props.documentMetadata);

    return (
        <div
            className={classNames(styles.editorContainer, {
                [styles.editorContainerViewMode]: !props.isEditMode
            })}
        >
            <DocumentEditorHeader
                documentMetadata={props.documentMetadata}
                workingCopyName={wcQuery.data?.workingCopyName}
                lastAction={lastActionQuery.data}
                onPublishSubmit={handlePublish}
            />

            <BelEditorWrapper
                /* eslint-disable-next-line no-use-before-define */
                documentSource={editViewDocumentSource(
                    props.documentMetadata.accession,
                    documentTitle,
                    props.isEditMode,
                    userCanEditDocument,
                    props.revisionId,
                    wcQuery.data?.entityId
                )}
                onLoadingStateChange={chain(props.onLoadingStateChange, setEditorLoading)}
                nodeId={props.nodeId}
                onNavigate={props.onNavigate}
                onFindUsages={props.onFindUsages}
                onAddCategory={handleAddCategory}
                onRemoveCategory={handleRemoveCategory}
                ref={editorRef}
            />

            {isLoading && <EditorBackdrop isDocumentLoading />}

            <EditorFooter>
                <Status>
                    {props.isEditMode ? (
                        'Draft'
                    ) : (
                        <LastActionStatus lastAction={lastActionQuery.data} />
                    )}
                </Status>
            </EditorFooter>
        </div>
    );
}

function editViewDocumentSource(
    accession: string,
    title: string,
    editMode: boolean,
    userCanEditDocument: boolean,
    revisionId?: string,
    entityId?: string
) {
    if (revisionId) {
        return historyPanelDocumentSource(accession, revisionId);
    }
    if (editMode && entityId) {
        return editedWorkingCopySource(accession, title, entityId, !userCanEditDocument);
    }
    if (!editMode) {
        return editedDocumentSource(accession, title);
    }
}
