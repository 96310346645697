import {Preloader, Typography} from '@genestack/ui';
import React from 'react';

import {formatInteger} from '../../../../utils/format-integer';
import {pluralize} from '../../../../utils/pluralize';

import styles from './ontology-view.module.css';

interface Props {
    total: number | undefined;
}

/** Component for displaying the total of elements in an ontology */
export function OntologyViewCaption(props: Props) {
    if (props.total === undefined) {
        return <Preloader count={1} show className={styles.preloader} />;
    }

    return (
        <Typography intent="quiet">
            Contains {formatInteger(props.total)} {pluralize('element', props.total)}
        </Typography>
    );
}
