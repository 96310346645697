import {useQuery, useQueryClient} from 'react-query';

import {apiClient} from '../../../../../utils/api-client';

interface ShareQuerySettings {
    onlyOwnerCanShare: boolean;
}

function requestShareQuerySettings(queryId: string) {
    return apiClient.get<ShareQuerySettings>({
        path: `queries-service/api/queries/${queryId}/share-settings`
    });
}

export function useShareQuerySettings(queryId: string) {
    return useQuery({
        queryKey: ['get-share-query-settings', queryId],
        queryFn: () => requestShareQuerySettings(queryId)
    });
}

export function useShareQuerySettingsCache(queryId: string) {
    const queryClient = useQueryClient();
    const queryKey = ['get-share-query-settings', queryId];

    async function setOnlyOwnerCanShare(onlyOwnerCanShare: boolean) {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        const previousValue = queryClient.getQueryData<ShareQuerySettings | undefined>(queryKey);

        queryClient.setQueryData<ShareQuerySettings | undefined>(queryKey, () => {
            return {onlyOwnerCanShare};
        });

        return previousValue;
    }

    return {setOnlyOwnerCanShare};
}
