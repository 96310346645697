import classNames from 'classnames';
import React from 'react';

import {UseQueryResult} from 'react-query';
import {WindowTitle} from '../../../components/window-title';
import {useSelectedQuery} from '../hooks/requests/use-query-info';
import {isQueryCompleted, isQueryDraft} from '../utils';

import {CompletedQueryContent} from './completed-query/completed-query-content';
import styles from './query-layout.module.css';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';
import {StubMessage} from '../../../components/stub-message';
import {UniversalBackdrop} from '../../../components/universal-backdrop';
import {CallApiError, RequestErrorCodes} from '../../../utils/api-client';
import {NonCompletedQueryContent} from './non-completed-query-content';
import {QueryLayoutLoading} from './query-layout-loading';

function getQueryPageTitle(queryInfo?: QueryInfo) {
    if (!queryInfo) {
        return 'Queries';
    }

    const queryTypeTitle = isQueryDraft(queryInfo) ? 'Query Draft' : 'Query';

    return `${queryInfo.name} (${queryTypeTitle})`;
}

function isQueryNotFoundError(selectedQuery: UseQueryResult<QueryInfo>) {
    return (
        selectedQuery.error &&
        ((selectedQuery.error as CallApiError).code === RequestErrorCodes.NOT_FOUND_ERROR_CODE ||
            (selectedQuery.error as CallApiError).code === RequestErrorCodes.FORBIDDEN_ERROR_CODE ||
            (selectedQuery.error as CallApiError).code === RequestErrorCodes.BAD_REQUEST_ERROR_CODE)
    );
}

interface Props {
    queryId?: number;
    globalVersions: GlobalVersionData[];
}

export function QueryLayout(props: Props) {
    const selectedQuery = useSelectedQuery(props.queryId);

    return (
        <WindowTitle text={getQueryPageTitle(selectedQuery.data)}>
            <div
                className={classNames(styles.container, {
                    [styles.viewMode]: selectedQuery.isLoading
                })}
            >
                {(function () {
                    if (selectedQuery.isLoading) {
                        return <QueryLayoutLoading />;
                    }

                    if (isQueryNotFoundError(selectedQuery)) {
                        const stubMessage = (
                            <StubMessage caption="Query not found">
                                Requested query {props.queryId} is not shared with you or does not
                                exist
                            </StubMessage>
                        );

                        return <UniversalBackdrop open variant="zerostate" message={stubMessage} />;
                    }

                    if (!selectedQuery.data) {
                        return null;
                    }

                    if (isQueryCompleted(selectedQuery.data)) {
                        return (
                            <CompletedQueryContent
                                key={props.queryId}
                                globalVersions={props.globalVersions}
                                selectedQuery={selectedQuery.data}
                            />
                        );
                    }

                    return (
                        <NonCompletedQueryContent
                            globalVersions={props.globalVersions}
                            selectedQuery={selectedQuery.data}
                        />
                    );
                })()}
            </div>
        </WindowTitle>
    );
}
