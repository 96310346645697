import React from 'react';

import styles from './styles.module.css';

interface Props {
    text: string;
}

/** A component for displaying stylized text in circles */
export const CircledLabel = ({text}: Props) => {
    return <span className={styles.label}>{text}</span>;
};
