import {useQuery} from 'react-query';

import {apiClient} from '../utils/api-client';

function getConnectionById(queryId: number, connectionId: number) {
    return apiClient.get<ConnectionDto | null>({
        path: `queries-service/api/queries/${queryId}/connections/${connectionId}`
    });
}

/** Hook returns connection data  */
export function useConnectionQuery(queryId: number, connectionId: number) {
    return useQuery({
        queryKey: [useConnectionQuery.name, queryId, connectionId],
        queryFn: () => getConnectionById(queryId, connectionId)
    });
}
