import classNames from 'classnames';
import React from 'react';

import styles from './separator.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isDragging: boolean;
    variant: 'right' | 'bottom';
}

/** Separator for resizable panels */
export const Separator = React.forwardRef<HTMLDivElement, Props>(
    function SeparatorComponent(props, ref) {
        const {isDragging, variant = 'left', ...restProps} = props;

        return (
            <div
                {...restProps}
                ref={ref}
                className={classNames(
                    styles.root,
                    {
                        [styles.right]: variant === 'right',
                        [styles.bottom]: variant === 'bottom',
                        [styles.dragging]: isDragging
                    },
                    restProps.className
                )}
            />
        );
    }
);
