import React from 'react';

/** Hooks provides an element resize observer */
export function useResizeObserver<Element extends HTMLElement>(
    element: Element | null,
    callback: ResizeObserverCallback
) {
    const observer = React.useRef<ResizeObserver | null>(null);

    const observe = () => {
        if (element) {
            observer.current?.observe(element);
        }
    };

    React.useEffect(() => {
        // if we are already observing old element
        if (element) {
            observer.current?.unobserve(element);
        }

        observer.current = new ResizeObserver(callback);
        observe();

        return () => {
            if (element) {
                observer.current?.unobserve(element);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);
}

/** Hook returns the width of an element */
export function useObserveElementWidth<T extends HTMLElement>(element: T | null) {
    const [elementWidth, setElementWidth] = React.useState(element?.offsetWidth ?? 0);

    useResizeObserver(element, (e) => {
        const containerResizeObserverEntry = e.find((entry) => entry.target === element);

        if (containerResizeObserverEntry) {
            setElementWidth(containerResizeObserverEntry.contentRect.width);
        }
    });

    return elementWidth;
}
