import {
    MenuHandler,
    MenuHandlerApi,
    MenuHandlerProps,
    OptionLabel,
    SelectEmitter
} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {useClickPreventionOnDoubleClick} from '../../hooks/use-click-prevention-on-double-click';

import styles from './editable-title.module.css';

export interface TitleWithMenuProps extends React.HTMLAttributes<HTMLDivElement> {
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    menu: MenuHandlerProps['menu'];
}

export function TitleWithMenu(props: TitleWithMenuProps) {
    const {menu, prepend, append, onClick, onDoubleClick, children, ...restProps} = props;
    const menuRef = React.useRef<MenuHandlerApi>(null);

    const handleEmitterClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        onClick?.(e);
        menuRef.current?.open();
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick({
        onClick: handleEmitterClick,
        onDoubleClick
    });

    return (
        <MenuHandler ref={menuRef} menu={menu} disableListeners>
            <SelectEmitter
                ghost
                isButton
                {...restProps}
                className={classNames(styles.emitterTitle, restProps.className)}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                arrowProps={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    'data-qa': 'title-arrow-icon'
                }}
                label={
                    <OptionLabel
                        prepend={prepend}
                        append={append}
                        className={styles.label}
                        classes={{info: styles.emitterTitleInfo}}
                    >
                        {children}
                    </OptionLabel>
                }
            />
        </MenuHandler>
    );
}
