import {Divider, Typography} from '@genestack/ui';
import React from 'react';
// tslint:disable-next-line: no-implicit-dependencies
import {Transition, TransitionGroup} from 'react-transition-group';

import {animationDuration} from './constants';
import styles from './query-management-panel.module.css';
import {RecentQuery} from './recent-query';
import {useMPRecentQueries} from './use-mp-recent-queries';

interface Props {
    recentQueriesRequest: ReturnType<typeof useMPRecentQueries>;
}

/** Table of recent queries */
export function RecentQueries(props: Props) {
    const {data, isLoading} = props.recentQueriesRequest;

    return (
        <>
            <div className={styles.queryRow}>
                <Typography variant="section">Recently completed queries</Typography>

                <Typography intent="quiet" variant="body">
                    Author
                </Typography>

                <Typography intent="quiet" variant="body">
                    Calculation time / Found results / Status
                </Typography>

                <Typography intent="quiet" variant="body">
                    Completion timestamp
                </Typography>
            </div>

            <Divider gap={0} />

            {(function () {
                if (data?.length) {
                    return (
                        <TransitionGroup>
                            {data.map((query) => (
                                <Transition
                                    key={query.id}
                                    timeout={{appear: animationDuration, enter: animationDuration}}
                                >
                                    {(transitionStatus) => (
                                        <RecentQuery
                                            query={query}
                                            isHighlighted={transitionStatus === 'entering'}
                                        />
                                    )}
                                </Transition>
                            ))}
                        </TransitionGroup>
                    );
                }

                if (isLoading) {
                    return null;
                }

                return (
                    <div className={styles.emptyTable}>
                        <Typography intent="quiet">No queries executing at the moment</Typography>
                    </div>
                );
            })()}
        </>
    );
}
