import React from 'react';
import {useQueryResultsListInfiniteQuery} from './use-query-results-list';
import {extractInfiniteQueryItems} from '../../../../../utils/infinite-query-items';
import {isQueryCompleted} from '../../../utils';

interface Props {
    selectedQuery?: QueryInfo;
}

export function useResultsListProps(props: Props) {
    const [selectedResultItem, setSelectedResultItem] = React.useState<number>();

    const queryResultsList = useQueryResultsListInfiniteQuery({
        queryId: props.selectedQuery?.id,
        enabled: !!props.selectedQuery && isQueryCompleted(props.selectedQuery)
    });
    const queryResultsListItems = extractInfiniteQueryItems(
        queryResultsList,
        (response: PageResponse<ConnectionDto, number>) => response.data
    );

    const closeResultChainsPanel = React.useCallback(() => setSelectedResultItem(undefined), []);

    return {
        queryResultsList,
        queryResultsListItems,
        selectedResultItem,
        setSelectedResultItem,
        selectedConnectionIndex: queryResultsListItems?.findIndex(
            (item) => item.id === selectedResultItem
        ),
        closeResultChainsPanel
    };
}
