import React from 'react';

import {BusyIndicator} from '../../../../../components/busy-indicator';

import styles from './query-editor-skeleton.module.css';

/** Query editor skeleton component */
export function QueryEditorSkeleton() {
    return (
        <div className={styles.root}>
            <BusyIndicator />
        </div>
    );
}
