import {useQuery, UseQueryOptions, useQueryClient} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

const baseQueryKey = 'getQueryInfoRequest';

export function getQueryInfoRequest(queryId: number) {
    return apiClient.get<QueryInfo>({
        path: `queries-service/api/queries/${queryId}`
    });
}

function useQueryInfo(queryId: number, options?: UseQueryOptions<QueryInfo>) {
    return useQuery<QueryInfo>({
        ...options,
        queryKey: [baseQueryKey, queryId],
        queryFn: () => getQueryInfoRequest(queryId)
    });
}

export function useSelectedQuery(queryId?: number) {
    return useQueryInfo(Number(queryId), {
        enabled: queryId !== undefined
    });
}

export function useQueryInfoCache() {
    const queryClient = useQueryClient();

    function getQueryById(queryId: number) {
        return queryClient.getQueryData<QueryInfo>([baseQueryKey, queryId]);
    }

    function addQuery(queryInfo: QueryInfo) {
        queryClient.setQueryData([baseQueryKey, queryInfo.id], queryInfo);
    }

    function updateQuery(updatedQuery: {id: number} & Partial<QueryInfo>) {
        getQueryById(updatedQuery.id) &&
            queryClient.setQueryData<QueryInfo | undefined>(
                [baseQueryKey, updatedQuery.id],
                (query) => {
                    return query && {...query, ...(updatedQuery as QueryInfo)};
                }
            );
    }

    function removeQuery(queryId: number) {
        queryClient.removeQueries([baseQueryKey, queryId]);
    }

    function invalidateQuery(queryId: number) {
        return queryClient.invalidateQueries([baseQueryKey, queryId]);
    }

    function updateQueryResultCount(queryId: number, resultsCount: number) {
        return (
            getQueryById(queryId) &&
            queryClient.setQueryData<QueryInfo | undefined>([baseQueryKey, queryId], (query) => {
                return query && {...query, resultsCount};
            })
        );
    }

    function handleQueryFinished(
        queryId: number,
        status: FinishedQueryStatus,
        finishedAt: number,
        errMsg?: string
    ) {
        return (
            getQueryById(queryId) &&
            queryClient.setQueryData<QueryInfo | undefined>([baseQueryKey, queryId], (query) => {
                // tslint:disable-next-line: no-object-literal-type-assertion
                return query && ({...query, status, finishedAt, errorMessage: errMsg} as QueryInfo);
            })
        );
    }

    return {
        getQueryById,
        addQuery,
        updateQuery,
        removeQuery,
        invalidateQuery,
        updateQueryResultCount,
        handleQueryFinished
    };
}
