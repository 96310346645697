import {Button, Controls, ControlsItem, Preloader, Typography} from '@genestack/ui';
import React from 'react';

import {pluralize} from '../../../../../../utils/pluralize';

import {CategoryTreeElement} from './adapt-tree';
import styles from './applied-filters-summary.module.css';
import {Pills} from './pills';

interface Props {
    visualFilters: CategoryTreeElement[];
    isLoading: boolean;
    count: number;
    onChangeFilters: (
        toAddVisual: CategoryTreeElement[],
        toRemoveVisual: CategoryTreeElement[],
        toAddApplied: string[],
        toRemoveApplied: string[]
    ) => void;
    onResetFilters: () => void;
}

export const AppliedFiltersSummary = (props: Props) => {
    return (
        <>
            <Controls className={styles.filterSummary}>
                <Preloader show={props.isLoading}>
                    <ControlsItem className={styles.foundCount}>
                        <Typography variant="section" intent="quiet">
                            {props.count} {pluralize('document', props.count)} filtered
                        </Typography>
                    </ControlsItem>
                </Preloader>
                <ControlsItem>
                    <Button ghost onClick={props.onResetFilters} size="tiny">
                        Clear filters
                    </Button>
                </ControlsItem>
            </Controls>
            <Pills onChangeFilters={props.onChangeFilters} filters={props.visualFilters} />
        </>
    );
};
