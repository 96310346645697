import {DividerProps, Typography, PageContent} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {LocalErrorBoundary} from '../../../../components/error-boundary';
import {Sidebar} from '../../../../components/sidebar';
import {VersionSelect} from '../../../../components/version-select';
import {useLocationParams} from '../../../../hooks/use-location';
import {addGlobalVersionToUrl} from '../../../../hooks/use-search-params';

import {CategoryResults, DocResults, NodeResults, SearchHistory} from './search-results';
import {useSearchSidebarContext} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {SidebarSearchField} from '../../common/sidebar-search-field';
import styles from './sidebar.module.css';
import {LayoutContextValue} from '../../../../components/page-layout/use-page-layout';
import {useAddRecordToExploreViewSearchHistoryMutation} from '../hooks/requests/use-add-record-to-explore-view-search-history-mutation';
import {useSuggestions} from '../../common/sidebar-search-field/hooks/use-suggestions';
import {useExploreViewSearchHistoryQuery} from '../hooks/requests/use-explore-view-search-history-query';
import {SearchData} from '../../common/sidebar-search-field/interface';
import {SearchResultComponentProps} from './search-results/interface';
import {useCategoriesFilters} from '../hooks/use-categories-filters';
import {useSearchSidebarLoadingState} from '../hooks/use-search-sidebar-loading-state';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';

const HEADER_START_GAP: DividerProps = {startGap: 3, endGap: 2};
const HEADER_END_GAP: DividerProps = {startGap: 1, endGap: 1};

interface Props {
    layoutSettings: LayoutContextValue;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    selectedGlobalVersion: GlobalVersionData;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
    globalVersions: GlobalVersionData[];
}

export const SearchDocumentsSidebar = (props: Props) => {
    const {
        layoutSettings,
        searchSidebarContext,
        findUsagesProps,
        selectedGlobalVersion,
        getDocumentLink,
        globalVersions
    } = props;
    const {push} = useHistory();
    const {revisionId, documentId} = useLocationParams();
    const addItemToSearchHistoryMutation = useAddRecordToExploreViewSearchHistoryMutation();
    const {isExpandedSidebar, toggleIsExpandedSidebar} = layoutSettings;
    const {loadingState, setLoadingState} = useSearchSidebarLoadingState();

    const categoriesFilters = useCategoriesFilters();

    const searchHistory = useExploreViewSearchHistoryQuery({
        enabled: searchSidebarContext.parsedInputValue.type === 'emptyInput'
    });

    const suggestionsProps = useSuggestions({
        currentGlobalVersion: selectedGlobalVersion,
        inputValue: searchSidebarContext.parsedInputValue,
        searchHistory: searchHistory.data
    });

    const redirectToVersion = (globalVersionId: number) => {
        push(
            addGlobalVersionToUrl(
                `/documents/explore${documentId ? `/${documentId}` : ''}`,
                globalVersionId
            )
        );
    };

    const onSearchFieldBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        if (
            searchSidebarContext.parsedInputValue.type === 'searchByDocName' &&
            searchSidebarContext.parsedInputValue.value &&
            e.target instanceof HTMLInputElement
        ) {
            addItemToSearchHistoryMutation.mutate({
                categoriesSearch: null,
                referencedNode: null,
                stringSearch: {strict: false, value: searchSidebarContext.parsedInputValue.value}
            });
        }
    };

    const header = (
        <PageContent startDividerProps={HEADER_START_GAP} endDividerProps={HEADER_END_GAP}>
            <div className={styles.globalVersion}>
                <Typography className={styles.globalVersionLabel} as="span" intent="quiet">
                    KB global version:
                </Typography>

                <VersionSelect
                    value={selectedGlobalVersion.id}
                    onChangeValue={redirectToVersion}
                    globalVersions={globalVersions}
                />
            </div>

            <SidebarSearchField
                searchSidebarContext={searchSidebarContext}
                disabled={
                    Boolean(revisionId) ||
                    loadingState.isLoadingNextPage ||
                    findUsagesProps.loadingNodeType
                }
                busy={
                    suggestionsProps.isLoading ||
                    searchHistory.isLoading ||
                    findUsagesProps.loadingNodeType
                }
                onBlur={onSearchFieldBlur}
                suggestionsProps={suggestionsProps}
            />
        </PageContent>
    );

    return (
        <Sidebar component="div" header={header}>
            <LocalErrorBoundary>
                {(function () {
                    const resultsProps: Omit<
                        SearchResultComponentProps<SearchData>,
                        'searchData'
                    > = {
                        versionId: selectedGlobalVersion.id,
                        expanded: isExpandedSidebar,
                        onToggleExpand: toggleIsExpandedSidebar,
                        categoriesFilters,
                        loadingState,
                        setLoadingState,
                        selectedGlobalVersion,
                        getDocumentLink
                    };

                    if (!searchSidebarContext.searchData) {
                        return <SearchHistory search={searchSidebarContext.search} />;
                    }

                    if (searchSidebarContext.searchData.mode === 'doc') {
                        return (
                            <DocResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    if (searchSidebarContext.searchData.mode === 'node') {
                        return (
                            <NodeResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    if (searchSidebarContext.searchData.mode === 'category') {
                        return (
                            <CategoryResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    return null;
                })()}
            </LocalErrorBoundary>
        </Sidebar>
    );
};
