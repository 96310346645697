import {useQueryInfoCache} from './requests/use-query-info';
import {useSharedQueriesCache} from '../queries-sidebar/hooks/use-shared-queries';
import {useRecentQueriesCache} from '../queries-sidebar/hooks/use-recent-queries';
import {useDraftQueriesCache} from '../queries-sidebar/hooks/use-draft-queries';
import {useDeletedQueriesCache} from '../queries-sidebar/hooks/use-deleted-queries';
import {useFavoriteQueriesCache} from '../queries-sidebar/hooks/use-favorite-queries';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';

export function useIsQueryOwnedByCurrentUser() {
    const currentUserQuery = useCurrentUserQuery();

    return (query: QueryInfo) => query.owner.email === currentUserQuery.data!.email;
}

export function useDeleteQueryInCache() {
    const queryInfoCache = useQueryInfoCache();
    const sharedQueryCache = useSharedQueriesCache();
    const recentQueriesCache = useRecentQueriesCache();
    const draftQueriesCache = useDraftQueriesCache();
    const deletedQueriesCache = useDeletedQueriesCache();
    const favoriteQueriesCache = useFavoriteQueriesCache();
    const isQueryOwnedByCurrentUser = useIsQueryOwnedByCurrentUser();

    return (query: QueryInfo) => {
        queryInfoCache.updateQuery({...query, trash: true});

        if (query.sharedWithMe) {
            sharedQueryCache.removeQuery(query.id);
        }

        if (isQueryOwnedByCurrentUser(query)) {
            recentQueriesCache.removeQuery(query.id);
            draftQueriesCache.removeQuery(query.id);
            deletedQueriesCache.addQuery({...query, trash: true});
            favoriteQueriesCache.removeQuery(query.id);
        }
    };
}

export function useRestoreQueryInCache() {
    const queryInfoCache = useQueryInfoCache();
    const deletedQueriesCache = useDeletedQueriesCache();
    const draftQueriesCache = useDraftQueriesCache();
    const favoriteQueriesCache = useFavoriteQueriesCache();
    const recentQueriesCache = useRecentQueriesCache();
    const sharedQueryCache = useSharedQueriesCache();
    const isQueryOwnedByCurrentUser = useIsQueryOwnedByCurrentUser();

    return (query: QueryInfo) => {
        queryInfoCache.updateQuery({...query, trash: false});

        if (isQueryOwnedByCurrentUser(query)) {
            deletedQueriesCache.removeQuery(query.id);

            if (query.status === 'DRAFT') {
                draftQueriesCache.addQuery({...query, trash: false});
                return;
            }

            recentQueriesCache.addQuery({...query, trash: false});

            if (query.favorite) {
                favoriteQueriesCache.addQuery({...query, trash: false});
            }
        }

        if (query.sharedWithMe) {
            sharedQueryCache.addQuery({...query, trash: false} as FinishedQuery);

            if (query.favorite) {
                favoriteQueriesCache.addQuery({...query, trash: false} as FinishedQuery);
            }
        }
    };
}
