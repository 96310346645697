import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {useLocationParams} from '../../hooks/use-location';

import {useDocumentHistoryQuery} from './document-history-query';
import {useGlobalVersionDialogsGlobalValue} from './use-global-version-dialogs-global-value';
import {DocRevisionsContext} from './doc-revisions-context';

export function DocRevisionsContextProvider(props: {children: React.ReactNode}) {
    const {replace} = useHistory();
    const globalVersionDialogsModel = useGlobalVersionDialogsGlobalValue();
    const locationParams = useLocationParams();

    const isHistoryVisible = locationParams.mode === 'revisions';

    const documentHistoryQuery = useDocumentHistoryQuery(locationParams.documentId);

    React.useEffect(() => {
        if (isHistoryVisible && !locationParams.revisionId && documentHistoryQuery.data?.[0]) {
            replace(
                `/documents/${locationParams.documentsViewMode}/${locationParams.documentId}/revisions/${documentHistoryQuery.data?.[0].revision}`
            );

            documentHistoryQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHistoryVisible, locationParams.revisionId, documentHistoryQuery.data]);

    const selectedRevision = React.useMemo(() => {
        return documentHistoryQuery.data && locationParams.revisionId
            ? documentHistoryQuery.data.find((item) => item.revision === locationParams.revisionId)
            : undefined;
    }, [locationParams.revisionId, documentHistoryQuery.data]);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        selectedRevision,
        revisions: documentHistoryQuery.data || null,
        ...globalVersionDialogsModel
    };

    return (
        <DocRevisionsContext.Provider value={value}>{props.children}</DocRevisionsContext.Provider>
    );
}
