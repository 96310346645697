import {CheckMarkIcon, StopIcon, CrossIcon, TypographyProps, Typography} from '@genestack/ui';
import classNames from 'classnames';
import React, {ReactElement} from 'react';

import {RelativeLinkStyled} from '../../../components/relative-link/relative-link-styled';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {usePreselectedTab} from '../../../components/page-layout/use-preselected-tab';
import {getFormattedDateTimeShort} from '../../../utils/common';
import {formatInteger} from '../../../utils/format-integer';

import styles from './query-management-panel.module.css';
import {QueriesTab} from '../../../components/page-layout/layout-typings';
import {QuerySearchDuration} from '../common/query-search-duration';

const statusText: Record<FinishedQuery['status'], string | null> = {
    COMPLETED: 'Search complete',
    STOPPED: 'Stopped',
    FAILED: 'Failed'
};

const statusIcon: Record<FinishedQuery['status'], ReactElement> = {
    COMPLETED: <CheckMarkIcon />,
    STOPPED: <StopIcon />,
    FAILED: <CrossIcon />
};

const statusIntent: Record<FinishedQuery['status'], TypographyProps['intent']> = {
    COMPLETED: 'success',
    STOPPED: 'no-intent',
    FAILED: 'alarm'
};

interface Props {
    query: FinishedQuery;
    isHighlighted?: boolean;
}

export function RecentQuery(props: Props) {
    const currentUser = useCurrentUserQuery();
    const isCreatedByCurrentUser = props.query.owner.email === currentUser.data?.email;
    const {getPreselectedTabURLParam} = usePreselectedTab();

    return (
        <div
            className={classNames(
                styles.queryRow,
                styles.rowWithHover,
                props.isHighlighted && styles.highlightedRow
            )}
        >
            <div className={styles.queryName}>
                <RelativeLinkStyled
                    to={`/queries/${props.query.id}?${
                        isCreatedByCurrentUser ? getPreselectedTabURLParam(QueriesTab.RECENT) : ''
                    }`}
                    target="_blank"
                >
                    {props.query.name}
                </RelativeLinkStyled>
            </div>

            <Typography>{props.query.owner.name}</Typography>

            <Typography>
                <QuerySearchDuration query={props.query} />
            </Typography>

            <Typography className={styles.resultsCount}>
                {formatInteger(props.query.resultsCount)}
            </Typography>

            <Typography intent={statusIntent[props.query.status]}>
                {statusIcon[props.query.status]}
                {statusText[props.query.status]}
            </Typography>

            <Typography intent="quiet">
                {getFormattedDateTimeShort(props.query.finishedAt)}
            </Typography>
        </div>
    );
}
