import {ListItem} from '@genestack/ui';
import React from 'react';

import {DEFAULT_OFFSET_WIDTH} from '../../../../../../components/group';
import {OntologyTreeState} from '../../../../../../providers/global-state';
import {extractInfiniteQueryItems} from '../../../../../../utils/infinite-query-items';
import {
    useCategoriesInfiniteQuery,
    CategoryTreeElement,
    CategoryId
} from '../../../hooks/categories/use-categories-infinite-query';
import {useOntologyDocsQuery} from '../../../hooks/use-ontology-docs-query';
import {PortionedDocsGroupContent, SIBLINGS_OFFSET_WIDTH} from '../../portioned-docs-group-content';
import {LoadMoreControl} from '../load-more-control';
import {OntologyTreePreloader} from '../ontology-tree-preloader';

import {CategorySubTree} from './category-sub-tree';
import {DocumentStatusIcon} from '../../../../../../components/doc-status-icon/document-status-icon';

type OnChangeOntologyBranchStateHandler = (
    isOpen: boolean,
    targetCategoryTreePath: string[]
) => void;

export interface CategoryTreeContentProps {
    docType: DocType;

    categoryId: CategoryId;

    branchOpenState: OntologyTreeState;
    changeIsCategoryOpenState: OnChangeOntologyBranchStateHandler;
    categoryTreePath: string[];

    offset?: number;

    children?: React.ReactNode;
}

export function CategorySubTreeContent({
    docType,

    categoryId,

    branchOpenState,
    changeIsCategoryOpenState,
    categoryTreePath,

    offset = 0,
    children
}: CategoryTreeContentProps) {
    const treeOffset = offset + DEFAULT_OFFSET_WIDTH;

    const categoriesQuery = useCategoriesInfiniteQuery({categoryId, pageSize: 'variable'});

    const docsQuery = useOntologyDocsQuery({
        docType,
        categories: [categoryId],
        options: {enabled: categoryId !== 'root'}
    });

    const categoryItems = extractInfiniteQueryItems(
        categoriesQuery,
        (response: CategoryTreeElement) => response.children.data
    );

    const documents = extractInfiniteQueryItems(
        docsQuery,
        (response: PageResponse<DocInfo>) => response.data
    );

    const handleLoadMoreCategoriesButton = () => {
        categoriesQuery.fetchNextPage();
    };

    const isLoading = categoriesQuery.isLoading || docsQuery.isLoading;
    const hasSiblings = Boolean(categoryItems?.length);

    return (
        <div style={{marginLeft: treeOffset}}>
            <OntologyTreePreloader show={isLoading}>
                {children}

                {categoryItems?.map((category) => {
                    const categoryOntologyState = branchOpenState
                        ? branchOpenState[category.categoryNodeId]
                        : null;

                    return (
                        <CategorySubTree
                            key={category.categoryNodeId}
                            docType={docType}
                            title={category.name}
                            categoryId={category.categoryNodeId}
                            count={category.documentsCount}
                            branchOpenState={categoryOntologyState}
                            changeIsCategoryOpenState={changeIsCategoryOpenState}
                            categoryTreePath={[...categoryTreePath, category.categoryNodeId]}
                        />
                    );
                })}

                {categoriesQuery.hasNextPage && (
                    <ListItem as="div" style={{paddingLeft: 16}}>
                        <LoadMoreControl
                            isLoading={categoriesQuery.isFetchingNextPage}
                            onClick={handleLoadMoreCategoriesButton}
                        >
                            ...Load more categories
                        </LoadMoreControl>
                    </ListItem>
                )}

                {categoryId !== 'root' && (
                    // eslint-disable-next-line no-use-before-define
                    <div style={{paddingLeft: getDocumentListOffset(hasSiblings)}}>
                        <PortionedDocsGroupContent
                            hasSiblings={hasSiblings}
                            docs={documents?.length ? documents : undefined}
                            hasNextPage={docsQuery.hasNextPage}
                            isLoadingMore={docsQuery.isFetchingNextPage}
                            onClickLoadMoreButton={docsQuery.fetchNextPage}
                            /* eslint-disable-next-line no-use-before-define */
                            offset={getDocumentsOffsetWidth(categoryTreePath.length, hasSiblings)}
                            DocStatusIconComponent={DocumentStatusIcon}
                        />
                    </div>
                )}
            </OntologyTreePreloader>
        </div>
    );
}

function getDocumentsOffsetWidth(depth: number, hasSiblings: boolean) {
    const documentDepth = depth + 1;
    const offset = documentDepth * DEFAULT_OFFSET_WIDTH;

    return hasSiblings ? offset : offset + SIBLINGS_OFFSET_WIDTH;
}

function getDocumentListOffset(hasSiblings: boolean) {
    return hasSiblings ? 0 : SIBLINGS_OFFSET_WIDTH;
}
