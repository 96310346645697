/** Get unique items by key */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function uniqueBy<Item extends {[key: string]: any}>(key: string, array: Item[]): Item[] {
    const arrayMap = new Map();

    for (const item of array) {
        const id = item[key];
        arrayMap.set(id, item);
    }

    return [...arrayMap.values()];
}

/** Sorts numeric values from lowest to highest */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numericSortComparator<T extends Record<string | number, any>>(key: keyof T) {
    return (left: T, right: T) => right[key] - left[key];
}

/** Sorts string values from a to z */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringSortComparator<T extends Record<string | number, any>>(key: keyof T) {
    // eslint-disable-next-line no-magic-numbers
    return (left: T, right: T) => (right[key] > left[key] ? -1 : 1);
}
