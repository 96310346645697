import 'whatwg-fetch';

import QueryString from 'query-string';
import {getLoginUrlWithLastLocation} from '../login';

import {CallApiError, CallApiUnauthorisedError, CallApiUnexpectedError} from './api-client-errors';
import type {CallApiMethodOptions, CallApiOptions} from './call-api-options';
import {parseFetchResponse} from './parse-fetch-response';

const UNAUTHORIZED_HTTP_STATUS = 401;

/** API client that provides the API calls */
export class ApiClient {
    /**
     * Method that accepts certain options, make fetch request
     * and parse possible errors.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,class-methods-use-this
    public callApi = <Result, Request = any>(options: CallApiOptions<Request>): Promise<Result> => {
        const headers = options.headers || new Headers();
        if (options.method !== 'GET') {
            headers.append('Content-Type', 'application/json');
        }

        const request: RequestInit = {
            method: options.method,
            headers,
            body:
                typeof options.parameters !== 'string'
                    ? JSON.stringify(options.parameters)
                    : options.parameters,
            mode: 'cors',
            credentials: 'include'
        };

        let url = `${options.path[0] === '/' ? '' : '/'}${options.path}`;

        if (options.query) {
            const queryString = QueryString.stringify(options.query, {skipNull: true});
            url = queryString ? `${url}?${queryString}` : url;
        }

        return fetch(url, request)
            .then(parseFetchResponse)
            .then((result) => {
                if (result.response.status === UNAUTHORIZED_HTTP_STATUS) {
                    window.location.href = getLoginUrlWithLastLocation();
                    throw new CallApiUnauthorisedError(options, result);
                }

                if (result.json?.error) {
                    throw new CallApiError(result.json.error.message, options, result);
                }

                if (!result.response.ok || result.parseError) {
                    throw new CallApiUnexpectedError(options, result);
                }

                return result.json as Result;
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get = <Result, Request = any>(
        options: CallApiMethodOptions<Request>
    ): Promise<Result> => {
        return this.callApi<Result, Request>({...options, method: 'GET'});
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public post = <Result, Request = any>(
        options: CallApiMethodOptions<Request>
    ): Promise<Result> => {
        return this.callApi<Result, Request>({...options, method: 'POST'});
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public put = <Result, Request = any>(
        options: CallApiMethodOptions<Request>
    ): Promise<Result> => {
        return this.callApi<Result, Request>({...options, method: 'PUT'});
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public patch = <Result, Request = any>(
        options: CallApiMethodOptions<Request>
    ): Promise<Result> => {
        return this.callApi<Result, Request>({...options, method: 'PATCH'});
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public delete = <Result, Request = any>(
        options: CallApiMethodOptions<Request>
    ): Promise<Result> => {
        return this.callApi<Result, Request>({...options, method: 'DELETE'});
    };
}

/** Instance of ApiClient */
export const apiClient = new ApiClient();
