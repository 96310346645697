import React from 'react';

import {assert} from '../../utils/assert';

type Unsubscribe = () => void;

/** An interface describing DocumentSyntheticEvent */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DocumentSyntheticEvent<EventType = string, Payload = any> {
    type: EventType;
    payload: Payload;
}

type Handler = (event: DocumentSyntheticEvent) => void;

interface DocumentSyntheticEventsContextValue {
    subscribe: (handler: Handler) => Unsubscribe;
    trigger: (event: DocumentSyntheticEvent) => void;
}

const DocumentSyntheticEventsContext =
    React.createContext<DocumentSyntheticEventsContextValue | null>(null);

/** A hook to retrieve DocumentSyntheticEventsContextProvider's value */
export const useDocumentSyntheticEvents = () => {
    const value = React.useContext(DocumentSyntheticEventsContext);
    assert(value, 'Could not be used outside of DocumentSyntheticEventsContextProvider');

    return value;
};

/**
 * A context provider where value is an object consisting of methods:
 * - `subscribe`: can be used to watch on document events
 * - `trigger`: can be used to trigger document events
 *
 * This approach can be used when you want to trigger events
 * from a branch of a react component tree and consume them in another
 * (that doesn't know anything about the first one)
 */
export function DocumentSyntheticEventsContextProvider({children}: {children: React.ReactNode}) {
    const handlers = React.useRef<Handler[]>([]);

    const subscribe = React.useCallback((handler: Handler): Unsubscribe => {
        // eslint-disable-next-line no-console
        console.info('DocumentSyntheticEvents: subscribed');
        handlers.current.push(handler);

        // unsubscribe function
        return () => {
            // eslint-disable-next-line no-console
            console.info('DocumentSyntheticEvents: unsubscribed');
            handlers.current = handlers.current.filter((fn) => fn !== handler);
        };
    }, []);

    const trigger = React.useCallback((event: DocumentSyntheticEvent) => {
        handlers.current.forEach((handler) => {
            handler(event);
        });
    }, []);

    return (
        <DocumentSyntheticEventsContext.Provider
            value={React.useMemo(() => ({subscribe, trigger}), [subscribe, trigger])}
        >
            {children}
        </DocumentSyntheticEventsContext.Provider>
    );
}
