import classNames from 'classnames';
import React from 'react';

import styles from './editor.module.css';

type TargetProps = React.HTMLAttributes<HTMLDivElement>;

/** Wrapper over Editor */
export function EditorWrapper(props: TargetProps) {
    return (
        <div
            {...props}
            className={classNames(
                styles.editorContainer,
                styles.editorContainerViewMode,
                props.className
            )}
        />
    );
}
