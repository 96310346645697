import {Divider, Typography} from '@genestack/ui';
import classNames from 'classnames';
import * as React from 'react';

import {
    ChangeUserDataResponse,
    ErrorResponse,
    FormQueryResponse,
    isChangeUserDataResponse,
    isFormQueryResponse,
    isResultErrorResponse,
    SessionResponse
} from '../../../hooks/user/auth-form-query';

import styles from './identity-form-message.module.css';

function FormMessage(props: {children: React.ReactNode; type?: string}) {
    const isError = props.type === 'error';

    return (
        <Typography
            box="paragraph"
            intent={isError ? 'alarm' : 'success'}
            className={classNames(styles.root, {[styles.alarm]: isError})}
        >
            {props.children}
        </Typography>
    );
}

/** Component for rendering message from Kratos in user identity form */
export function IdentityFormMessage(props: {
    response:
        | FormQueryResponse
        | ErrorResponse
        | SessionResponse
        | ChangeUserDataResponse
        | undefined;
}) {
    const data = props.response;

    if (isResultErrorResponse(data)) {
        return (
            <>
                <Divider variant="transparent" gap={2} />
                <FormMessage type="error">
                    {data.error.message}
                    <Typography as="div">{data.error.reason}</Typography>
                </FormMessage>
            </>
        );
    }

    if (isFormQueryResponse(data)) {
        return (
            <>
                <Divider variant="transparent" gap={2} />
                {data.ui.messages?.map((m) => (
                    <FormMessage key={m.id} type={m.type}>
                        {m.text}
                    </FormMessage>
                ))}
            </>
        );
    }

    if (isChangeUserDataResponse(data)) {
        return (
            <>
                <Divider variant="transparent" gap={2} />
                {data.ui.messages?.map((m) => (
                    <FormMessage key={m.id} type={m.type}>
                        {m.text}
                    </FormMessage>
                ))}
            </>
        );
    }

    return null;
}
