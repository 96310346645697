import {useQuery} from 'react-query';

import {ResultMaxLengthOption} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';

export interface MaxPathLengthOptionResult {
    merged: ResultMaxLengthOption[];
    identical: ResultMaxLengthOption[];
}

function queryResultMaxLengthOptionsRequest(queryId: number) {
    return apiClient.get<MaxPathLengthOptionResult>({
        path: `queries-service/api/queries/${queryId}/connection-graph/path-infos`
    });
}

export function useQueryResultMaxLengthOptions(queryId: number) {
    return useQuery({
        queryKey: ['QueryResultPathInfos', queryId],
        queryFn: () => queryResultMaxLengthOptionsRequest(queryId)
    });
}
