import {BookmarkBorderedIcon} from '@genestack/ui';
import React from 'react';

import {EyeIcon} from '../../../../../components/eye-icon';
import {useUserEditingPermissions} from '../../../../../hooks/user/use-has-permission';

interface Props {
    displayFavoriteState: boolean;
    docInfo?: ViewModeDocInfo;
}

/** Document meta info */
export function DocumentMeta({displayFavoriteState, docInfo}: Props) {
    const {editingPermitted} = useUserEditingPermissions();

    if (!docInfo) {
        return null;
    }

    const {readOnly, userFavorite} = docInfo;
    const hasMeta = readOnly || (displayFavoriteState && userFavorite);

    if (!hasMeta) {
        return null;
    }

    return (
        <>
            {readOnly && editingPermitted && (
                <div title="Read-only document">
                    <EyeIcon />
                </div>
            )}

            {displayFavoriteState && userFavorite && (
                <div title="In your Bookmarks">
                    <BookmarkBorderedIcon />
                </div>
            )}
        </>
    );
}
