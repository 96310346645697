import {
    Badge,
    DialogFullWidth,
    Divider,
    Link,
    ListItem,
    PlusUserIcon,
    ScrollView,
    SuggestInput,
    SuggestInputItem,
    Typography
} from '@genestack/ui';
import React from 'react';

import {UserIdentity, UserIdentityBasic} from '../../../../../typings/user';
import {QueryPermission} from './hooks/use-get-query-permitted-users';
import {useShareQueryMutation} from './hooks/use-share-query-mutation';
import {pluralize} from '../../../../utils/pluralize';

import {OthersCanShareSwitch} from './others-can-share-switch';
import {SelectedUser} from './selected-user';
import styles from './share-query-dialog.module.css';
import {UserInfo} from './user-info';

const listLimitBeforeCollapse = 5;
const numberElementsInCollapsedList = 3;

interface Props {
    permissions: QueryPermission[];
    queryOwner: UserIdentityBasic;
    queryId: string;
    userList: UserIdentity[];
    currentUser: UserIdentity;
    onlyOwnerCanShare: boolean;
}

export const ShareQueryDialogBody = ({
    permissions,
    queryId,
    userList,
    currentUser,
    queryOwner,
    onlyOwnerCanShare
}: Props) => {
    const shareQueryMutation = useShareQueryMutation(queryId, currentUser);

    const [userSearchString, setUserSearchString] = React.useState('');
    const [shouldCollapsePermissionsList, setShouldCollapsePermissionsList] = React.useState(false);

    React.useEffect(() => {
        if (permissions.length > listLimitBeforeCollapse) {
            setShouldCollapsePermissionsList(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onShowAllClick = () => {
        setShouldCollapsePermissionsList(false);
    };

    const clearSearch = () => {
        setUserSearchString('');
    };

    const notFoundElement = (
        <ListItem>
            <Typography intent="quiet" variant="caption">
                No results
            </Typography>
        </ListItem>
    );

    const renderSuggestItem = (user: UserIdentity) => {
        const isUserSelected =
            !!permissions?.find((permission) => permission.sharedWith.email === user.email) ||
            user.email === queryOwner.email;

        return (
            <SuggestInputItem key={user.email} value={user.email} disabled={isUserSelected}>
                <UserInfo fullName={user.name} email={user.email}>
                    {isUserSelected && (
                        <Badge
                            ghost
                            disableTextTransform
                            className={styles.suggestItemAlreadySelectedBadge}
                        >
                            Already has access
                        </Badge>
                    )}
                </UserInfo>
            </SuggestInputItem>
        );
    };

    function getUserByEmail(email: string) {
        return userList.find((user) => user.email === email);
    }

    const addUserByEmail = (email: string) => {
        setUserSearchString('');
        shareQueryMutation.mutate(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {user: getUserByEmail(email)!, queryId, requestMode: 'share'}
        );
    };

    const stopSharingWithUser = (email: string) => {
        shareQueryMutation.mutate(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {user: getUserByEmail(email)!, queryId, requestMode: 'unshare'}
        );
    };

    const filteredUsers = userList.filter(
        (user) =>
            user.active &&
            user.email !== currentUser.email &&
            (user.name.toLowerCase().match(userSearchString.toLowerCase()) ||
                user.email.toLowerCase().match(userSearchString.toLowerCase()))
    );

    const suggestedContent = filteredUsers.length
        ? filteredUsers.map(renderSuggestItem)
        : notFoundElement;

    const currentUserIsQueryOwner = currentUser.email === queryOwner.email;

    const renderSelectedPermission = (permission: QueryPermission) => {
        const userFullInfo = userList.find((u) => u.email === permission.sharedWith.email);

        const canRemove =
            currentUserIsQueryOwner || permission.sharedBy.email === currentUser.email;

        const onRemoveClick = canRemove
            ? () => {
                  stopSharingWithUser(permission.sharedWith.email);
              }
            : undefined;

        return (
            <SelectedUser
                key={permission.sharedWith.email}
                fullName={permission.sharedWith.name}
                email={permission.sharedWith.email}
                isDeactivated={!userFullInfo?.active}
                onRemoveClick={onRemoveClick}
                sharedBy={permission.sharedBy.name}
            />
        );
    };

    return (
        <>
            <Typography>This query is owned by:</Typography>
            <Divider variant="transparent" gap={1} />
            <UserInfo fullName={queryOwner.name} email={queryOwner.email} />
            <Divider variant="transparent" gap={2} />

            <Typography>
                {permissions?.length
                    ? `${permissions.length} ${pluralize(
                          'user',
                          permissions.length
                      )} have access to this query:`
                    : 'Add users to give access to this query:'}
            </Typography>

            {permissions?.length ? (
                <DialogFullWidth className={styles.selectedUsersList}>
                    <ScrollView showScrollbars="always" style={{maxHeight: 256}}>
                        {(function () {
                            if (shouldCollapsePermissionsList) {
                                return permissions
                                    .slice(0, numberElementsInCollapsedList)
                                    .map(renderSelectedPermission);
                            }

                            return permissions.map(renderSelectedPermission);
                        })()}

                        {shouldCollapsePermissionsList && (
                            <ListItem>
                                <Typography className={styles.showMoreUsersControl}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link onClick={onShowAllClick}>...show all</Link>
                                </Typography>
                            </ListItem>
                        )}
                    </ScrollView>
                </DialogFullWidth>
            ) : null}

            <Divider variant="transparent" gap={1} />
            <SuggestInput
                id="share-query-user-selector"
                clearable={!!userSearchString}
                fullWidth
                autoFocus
                value={userSearchString}
                onValueChange={setUserSearchString}
                prepend={<PlusUserIcon />}
                placeholder="Start typing user name or email"
                onClearButtonClick={clearSearch}
                onComplete={addUserByEmail}
            >
                {suggestedContent}
            </SuggestInput>

            <Divider variant="transparent" gap={1} />

            {currentUserIsQueryOwner && (
                <OthersCanShareSwitch othersCanShare={!onlyOwnerCanShare} queryId={queryId} />
            )}
        </>
    );
};
