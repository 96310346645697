import {useGlobalStateVariable, Variables} from '../providers/global-state';
import {ActivityBarTabs, LayoutType} from '../components/page-layout/layout-typings';
import {usePreselectedTab} from '../components/page-layout/use-preselected-tab';

interface Props {
    activityTabStorageKey: keyof Variables;
    defaultActivityBarTab: ActivityBarTabs;
    layout: LayoutType;
}

export function useSidebarTabs(props: Props) {
    const {activityTabStorageKey, defaultActivityBarTab, layout} = props;

    const preselectedTab = usePreselectedTab().getPreselectedTabFromUrl(layout);

    const [activityBarTab, setActivityBarTab] = useGlobalStateVariable(
        activityTabStorageKey,
        defaultActivityBarTab
    );

    return {
        activityBarTab: preselectedTab || (activityBarTab as ActivityBarTabs),
        setActivityBarTab
    };
}
