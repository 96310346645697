import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

function getDocTypesRequest() {
    return apiClient.get<DocType[]>({path: 'editor-documents-service/api/documents/types'});
}

/** Request for the getting types of documents */
export function useDocTypesQuery() {
    return useQuery(['types'], getDocTypesRequest);
}
