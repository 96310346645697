import {
    Divider,
    Menu,
    MenuItem,
    MenuProps,
    BookmarkBorderedIcon,
    TrashIcon,
    ShareIcon
} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useCreateQueryDraftMutation} from '../../../hooks/requests/use-create-query-mutation';
import {useUpdateQueryMutation} from '../../../hooks/requests/use-update-query-mutation';
import {useCopyLinkToThisPage} from '../../../../../hooks/use-copy-link-to-this-page';
import {useCurrentUserQuery} from '../../../../../hooks/user/use-current-user-query';
import {isQueryDraft} from '../../../utils';

interface Props extends MenuProps {
    queryInfo: QueryInfo;
    onRename: () => void;
    onRestore: () => void;
    onDelete: () => void;
    onShare: () => void;
    onlyOwnerCanShare: boolean;
}

export function QueryEditorMenu({
    queryInfo,
    onRename,
    onRestore,
    onDelete,
    onShare,
    onlyOwnerCanShare,
    ...restProps
}: Props) {
    const handleCopyLink = useCopyLinkToThisPage();
    const history = useHistory();

    const updateQuery = useUpdateQueryMutation();
    const createQueryDraft = useCreateQueryDraftMutation();
    const currentUserQuery = useCurrentUserQuery();

    const handleToggleFavoriteQuery = () => {
        updateQuery.mutate({id: queryInfo.id, favorite: !queryInfo.favorite});
    };

    const handleMakeCopy = async () => {
        const createdQueryInfo = await createQueryDraft.mutateAsync({
            name: `Copy of ${queryInfo.name}`,
            templateQueryId: queryInfo.id,
            globalVersionId: queryInfo.globalVersionId
        });

        history.push(`/queries/${createdQueryInfo.id}`);
    };

    const isPendingQuery = queryInfo.status === 'PENDING';
    const isRunningQuery = queryInfo.status === 'RUNNING';
    const isSharedQuery = queryInfo.sharedWithMe;
    const isMyQuery =
        currentUserQuery.data && queryInfo.owner.email === currentUserQuery.data.email;

    if (queryInfo.trash) {
        return (
            <Menu {...restProps}>
                <MenuItem key="restore" onClick={onRestore}>
                    Restore
                </MenuItem>
                <MenuItem key="copyToNewDraft" onClick={handleMakeCopy}>
                    Copy to new draft
                </MenuItem>
            </Menu>
        );
    }

    return (
        <Menu {...restProps}>
            <MenuItem key="rename" onClick={onRename} disabled={isSharedQuery}>
                Rename
            </MenuItem>
            <MenuItem key="copyToNewDraft" onClick={handleMakeCopy}>
                Copy to new draft
            </MenuItem>
            <MenuItem
                key="bookmark"
                onClick={handleToggleFavoriteQuery}
                disabled={isQueryDraft(queryInfo)}
                prepend={<BookmarkBorderedIcon />}
            >
                {queryInfo.favorite ? 'Remove from bookmarks' : 'Add to Bookmarks'}
            </MenuItem>
            <MenuItem
                key="share"
                onClick={onShare}
                prepend={<ShareIcon />}
                disabled={
                    isQueryDraft(queryInfo) ||
                    (isSharedQuery && onlyOwnerCanShare) ||
                    // user can probably see this query because he is query admin
                    (!isSharedQuery && !isMyQuery)
                }
            >
                Share
            </MenuItem>
            <MenuItem key="copyLink" onClick={handleCopyLink}>
                Copy link
            </MenuItem>

            <Divider key="divider" />

            <MenuItem
                key="delete"
                prepend={<TrashIcon />}
                onClick={onDelete}
                disabled={queryInfo.trash || isRunningQuery || isSharedQuery || isPendingQuery}
            >
                Delete
            </MenuItem>
        </Menu>
    );
}
