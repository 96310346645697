import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogCloseReason,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Divider,
    Input,
    Field,
    Shake,
    TooltipHandler,
    Tooltip,
    Typography
} from '@genestack/ui';
import React from 'react';

import {useCreateGlobalVersionMutation} from '../../../hooks/global-version/use-create-global-version-mutation';
import {useGetVersionsByLabelMutation} from '../../../hooks/global-version/use-get-versions-by-label-mutation';
import {useMounted} from '../../../hooks/use-mounted';

import styles from './create-version-dialog.module.css';

const LABEL_MAX_LENGTH = 255;

interface Props {
    open: boolean;
    onClose: () => void;
}
/** Dialog to create a new version on Knowledge Base */
export const CreateVersionDialog = ({open, onClose}: Props) => {
    const createGlobalVersion = useCreateGlobalVersionMutation();
    const getVersionsByLabel = useGetVersionsByLabelMutation();

    const [labelValue, setLabelValue] = React.useState('');
    const [labelTooLong, setLabelTooLong] = React.useState(false);

    const [descriptionValue, setDescriptionValue] = React.useState('');

    const [shaking, setShaking] = React.useState(false);

    const trimmedLabel = labelValue.trim();
    const trimmedDescription = descriptionValue.trim();

    const isMounted = useMounted();

    const handleCloseDialog = (e: DialogCloseReason) => {
        if (e === 'backdrop-click') {
            return;
        }
        onClose();
    };
    const handleChangeLabelValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (getVersionsByLabel.data) {
            getVersionsByLabel.reset();
        }

        const inputValue = e.target.value;
        if (inputValue.length > LABEL_MAX_LENGTH) {
            setShaking(true);
            setLabelTooLong(true);

            setLabelValue(inputValue.slice(0, LABEL_MAX_LENGTH));

            return;
        }

        if (labelTooLong) {
            setLabelTooLong(false);
        }

        setLabelValue(inputValue);
    };

    const handleChangeDescriptionValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescriptionValue(e.target.value);
    };

    const handleSubmitForm: React.FormEventHandler = async (e) => {
        e.preventDefault();

        const globalVersions = await getVersionsByLabel.mutateAsync(trimmedLabel);

        if (!globalVersions.length) {
            createGlobalVersion.mutate({label: trimmedLabel, description: trimmedDescription});
        }
    };

    const handleShakeComplete = () => {
        setShaking(false);
    };

    React.useEffect(() => {
        if (createGlobalVersion.isSuccess && isMounted()) {
            setLabelValue('');
            setDescriptionValue('');
            onClose();
        }

        if (createGlobalVersion.isError && isMounted()) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createGlobalVersion.isSuccess, createGlobalVersion.isError]);

    const hasEmptyValues = !trimmedLabel || !trimmedDescription;
    const hasVersionLabel = Boolean(getVersionsByLabel.data?.length);
    const isLoading = createGlobalVersion.isLoading || getVersionsByLabel.isLoading;

    return (
        <Dialog
            as="form"
            open={open}
            onSubmit={handleSubmitForm}
            onClose={handleCloseDialog}
            overlayProps={{
                disableEscListener: true
            }}
        >
            <DialogHeader>
                <Typography variant="header">New Knowledge Base version</Typography>
            </DialogHeader>
            <DialogBody>
                <Typography className={styles.label}>
                    Title{' '}
                    <Typography intent="quiet" as="span">
                        must be unique
                        {labelTooLong ? ', maximum length – 255 characters' : null}
                    </Typography>
                </Typography>
                <Shake in={shaking} onEntered={handleShakeComplete}>
                    <Input
                        autoFocus
                        fullWidth
                        onChange={handleChangeLabelValue}
                        value={labelValue}
                        invalid={hasVersionLabel}
                        placeholder="v. 0.1"
                    />
                </Shake>

                {hasVersionLabel && (
                    <Typography variant="caption" intent="alarm">
                        Version with such title already exists
                    </Typography>
                )}
                <Divider gap={2} variant="transparent" />

                <Typography className={styles.label}>
                    Description
                    <Typography as="span" intent="quiet">
                        {' '}
                        required
                    </Typography>
                </Typography>
                <Field
                    component="textarea"
                    className={styles.textarea}
                    onChange={handleChangeDescriptionValue}
                    value={descriptionValue}
                />
            </DialogBody>
            <DialogFooter>
                <Controls>
                    <ControlsItem>
                        <TooltipHandler
                            tooltip={
                                hasEmptyValues ? <Tooltip>Both fields are required</Tooltip> : null
                            }
                        >
                            <Button
                                intent="accent"
                                type="submit"
                                disabled={hasEmptyValues || isLoading}
                            >
                                {isLoading ? 'Creating...' : 'Create'}
                            </Button>
                        </TooltipHandler>
                    </ControlsItem>
                    <ControlsItem>
                        <Button onClick={onClose}>Cancel</Button>
                    </ControlsItem>
                </Controls>
            </DialogFooter>
        </Dialog>
    );
};
