export const isQueryRunning = (queryInfo: QueryInfo): queryInfo is RunningQuery =>
    queryInfo.status === 'RUNNING';

export const isQueryPending = (queryInfo: QueryInfo): queryInfo is PendingQuery =>
    queryInfo.status === 'PENDING';

export const isQueryRunningOrPending = (
    queryInfo: QueryInfo
): queryInfo is PendingQuery | RunningQuery =>
    queryInfo.status === 'RUNNING' || queryInfo.status === 'PENDING';

export function isQueryDraft(query: QueryInfo): query is DraftQuery {
    return query.status === 'DRAFT';
}

export function isEditableQueryDraft(queryInfo: QueryInfo): queryInfo is DraftQuery {
    return isQueryDraft(queryInfo) && !queryInfo.trash;
}

export function isQueryFailed(query: QueryInfo): query is FailedQuery {
    return query.status === 'FAILED';
}

export function isQueryCompleted(query: QueryInfo): query is CompletedQuery {
    return query.status === 'COMPLETED';
}
