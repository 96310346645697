import React from 'react';

import {useGoToDeclarationHandler} from '../../../../../hooks/use-go-to-declaration-handler';
import {isReadOnlyOntology} from '../../../../../utils/ontology';
import {OntologyView} from '../../../common/ontology-view';

import {ExploreViewEditor} from './explore-view-editor';
import {useEditorModel} from '../model';
import {LocalBackdrop} from '../../../../../components/local-backdrop';
import {NotFoundView} from '../editor-backdrop/not-found-view';
import {useDocRevisionsContext} from '../../../../../providers/version';
import {isDocNotViewableInThisGV} from '../../../../../utils/document-viewable-status';
import {NotViewableStub} from '../editor-backdrop/not-viewable-stub';
import {BusyIndicator} from '../../../../../components/busy-indicator';
import {useDocumentStatusInThisGVQuery} from '../../hooks/requests/use-document-status-in-this-gv-query';
import {FindUsagesEditorEvent} from '../../../../../../webeditor/biokb-editor-adapters';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';
import {CriticalErrorView} from '../../../../../components/critical-error/critical-error-view';

interface Props {
    documentId: string;
    revisionId?: string;
    nodeId?: string;
    handleFindUsages: (event: FindUsagesEditorEvent) => void;

    editorState: ReturnType<typeof useEditorModel>;
    selectedGlobalVersion: GlobalVersionData;
    onFactsSelectionChanged: (factIds: string[]) => void;
    selectedFacts: string[];
}

export const Editor = ({
    documentId,
    revisionId,
    nodeId,
    editorState,
    handleFindUsages,
    selectedGlobalVersion,
    onFactsSelectionChanged,
    selectedFacts
}: Props) => {
    const {revisions, selectedRevision} = useDocRevisionsContext();

    const docViewableInThisGV = useDocumentStatusInThisGVQuery({
        accession: documentId,
        globalVersionId: selectedGlobalVersion.id,
        enabled: !isReadOnlyOntology(editorState.documentInfo)
    });

    const goToDeclarationHandler = useGoToDeclarationHandler();

    if (
        editorState.isLoadingDocumentInfo ||
        editorState.isLoadingLastRevision ||
        docViewableInThisGV.isLoading
    ) {
        return <BusyIndicator />;
    }

    if (!revisionId && isDocNotViewableInThisGV(docViewableInThisGV.data)) {
        return (
            <LocalBackdrop open variant="zerostate">
                <NotViewableStub
                    variant={docViewableInThisGV.data}
                    globalVersionLabel={selectedGlobalVersion.label}
                    docId={documentId}
                    earliestRevisionId={revisions && revisions[revisions.length - 1].revision}
                    latestRevisionId={revisions && revisions[0].revision}
                />
            </LocalBackdrop>
        );
    }

    if (!editorState.documentInfo) {
        const revisionNotFound = Boolean(revisionId) && !!revisions && !selectedRevision;

        if (editorState.documentInfoError === 'not-found' || revisionNotFound) {
            return (
                <LocalBackdrop open variant="zerostate">
                    <NotFoundView
                        variant={editorState.documentInfoError ? 'document' : 'revision'}
                        documentId={documentId}
                    />
                </LocalBackdrop>
            );
        }

        return (
            <LocalBackdrop open variant="zerostate">
                <CriticalErrorView />
            </LocalBackdrop>
        );
    }

    if (isReadOnlyOntology(editorState.documentInfo) && editorState.documentInfo.id) {
        return (
            <OntologyView
                mode="explore"
                documentMetadata={editorState.documentInfo}
                versionId={selectedGlobalVersion.id}
                nodeId={nodeId}
                onNavigate={goToDeclarationHandler}
                onFindUsages={handleFindUsages}
            />
        );
    }

    return (
        <ExploreViewEditor
            accession={documentId}
            nodeId={nodeId}
            revisionId={revisionId}
            onFindUsages={handleFindUsages}
            isLoadingLastRevision={editorState.isLoadingLastRevision}
            revisionInfo={editorState.revisionInfo}
            documentInfo={editorState.documentInfo}
            selectedGlobalVersion={selectedGlobalVersion}
            onFactsSelectionChanged={onFactsSelectionChanged}
            selectedFacts={selectedFacts}
        />
    );
};
