/** Role of a user in relation to documents */
export enum UserRole {
    /** Can only view documents */
    EXPLORER = 'EXPLORER',
    /** Can view any document and edit BTB documents */
    BTB_EDITOR = 'BTB_EDITOR',
    /** Can view and edit any document */
    EDITOR = 'EDITOR'
}

/** Other user permissions */
export enum UiPermission {
    /** Can access users page */
    manageUsers = 'MANAGE_USERS',
    /** Can manage queries */
    manageQueries = 'MANAGE_QUERIES',
    /** Can create global versions */
    createGlobalVersions = 'CREATE_GLOBAL_VERSIONS'
}

/** Object describing the user in the most basic form */
export interface UserIdentityBasic {
    name: string;
    email: string;
}

/** User */
export interface UserIdentity extends UserIdentityBasic {
    id: string;
    active: boolean;
    role: UserRole;
    permissions: UiPermission[];
}
