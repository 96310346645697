/** Generate path for nullable documentId, nullable nodeId and optional globalVersion */
export function generateNavigationPath(
    documentId: string | null,
    nodeId: string | null,
    globalVersion?: number
) {
    if (!documentId) {
        return '';
    }

    const nodeIdPath = nodeId ? `/@${encodeURIComponent(nodeId)}` : '';
    const qs = globalVersion ? `?GV=${globalVersion}` : '';

    return `${documentId}${nodeIdPath}${qs}`;
}
