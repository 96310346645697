import React, {PropsWithChildren} from 'react';

import {
    SidebarResultsHeader,
    SidebarResultsHeaderProps
} from '../../../../../components/sidebar-results-header';
import {SidebarLoadingState} from '../../../common/sidebar-search-field/hooks/use-search-sidebar-context';

interface Props extends SidebarResultsHeaderProps {
    loadingState: SidebarLoadingState;
}

export function SearchResultsHeader({
    children,
    loadingState,
    ...restProps
}: PropsWithChildren<Props>) {
    return (
        <SidebarResultsHeader
            isLoading={loadingState.isLoading && !loadingState.isLoadingNextPage}
            {...restProps}
        >
            {children}
        </SidebarResultsHeader>
    );
}
