import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {isQueryRunningOrPending} from '../../utils';

import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

function getRecentQueriesRequest() {
    return apiClient
        .get<PageResponse<RecentQuery, number>>({
            path: 'queries-service/api/queries/recent',
            query: {limit: PAGE_LIMIT}
        })
        .then(({data}) => data);
}

export function useRecentQueries() {
    return useQuery('getRecentQueriesRequest', getRecentQueriesRequest);
}

export const useRunningQueries = () => {
    const recentQueries = useRecentQueries();

    return {
        isLoading: recentQueries.isLoading,
        data: recentQueries.data?.filter(isQueryRunningOrPending) ?? []
    };
};

export function useRecentQueriesCache() {
    return useQueryListCache<RecentQuery>('getRecentQueriesRequest');
}
