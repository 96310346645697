import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

function stopQueryRequest(queryId: number) {
    return apiClient.post<QueryInfo>({
        path: `queries-service/api/queries/${queryId}/stop`
    });
}

/** Hook for deleting query */
export function useStopQueryMutation() {
    return useMutation<QueryInfo, Error, number>(stopQueryRequest);
}
