import {Notification, Typography} from '@genestack/ui';
import React from 'react';
import {useMutation} from 'react-query';

import {showNotification} from '../../components/notifications-center';
import {apiClient} from '../../utils/api-client';

import {
    useGlobalVersionsQueryCache,
    GlobalVersionDataResponseItem,
    GlobalVersionData
} from './use-global-version-query';

interface UseCreateGlobalVersionMutationPayload {
    label: string;
    description: string;
}

function createGlobalVersionRequest(label: string, description: string) {
    return apiClient
        .post<GlobalVersionDataResponseItem>({
            path: 'documents-revisions-service/api/global-versions',
            parameters: {label, description}
        })
        .then<GlobalVersionData>((nextVersion) => ({
            ...nextVersion,
            type: 'global-version-data'
        }));
}

/** Create a new Global version */
export function useCreateGlobalVersionMutation() {
    const globalVersionsQueryCache = useGlobalVersionsQueryCache();

    return useMutation<GlobalVersionData, Error, UseCreateGlobalVersionMutationPayload>(
        ({label, description}) => createGlobalVersionRequest(label, description),
        {
            onSuccess: (nextVersion) => {
                showNotification(
                    // tslint:disable-next-line no-magic-numbers
                    <Notification countdownDuration={3000}>
                        <Typography>Knowledge Base version created</Typography>
                    </Notification>
                );

                globalVersionsQueryCache.setQueryData(nextVersion);
            }
        }
    );
}
