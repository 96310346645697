import React from 'react';

import {Preloader} from '../../../components/preloader';
import {Sidebar} from '../../../components/sidebar';
import {useDraftQueries} from './hooks/use-draft-queries';
import {useQueryId} from '../../../hooks/use-location';

import {QueryLink} from './query-link';
import {QueriesSidebarProps} from './interface';
import {QueriesSidebarHeader} from './queries-sidebar-header';
import {numericSortComparator} from '../../../utils/array';
import {EmptyList} from '../../../components/empty-list/empty-list';

export function QueryDraftsSidebar(props: QueriesSidebarProps) {
    const draftQueries = useDraftQueries();
    const queryId = useQueryId();

    const draftQueriesSorted = React.useMemo(
        () => draftQueries.data?.sort(numericSortComparator('createdAt')),
        [draftQueries.data]
    );

    return (
        <Sidebar
            header={<QueriesSidebarHeader title="Draft queries" onCollapse={props.onCollapse} />}
        >
            <Preloader show={draftQueries.isLoading}>
                {draftQueriesSorted && (
                    <>
                        {draftQueriesSorted.length === 0 && <EmptyList message="No drafts found" />}

                        {draftQueriesSorted.map((queryDraft) => (
                            <QueryLink
                                key={queryDraft.id}
                                queryId={queryDraft.id}
                                active={queryDraft.id === queryId}
                            >
                                {queryDraft.name}
                            </QueryLink>
                        ))}
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
}
