import React from 'react';

import {useLocationParams} from '../../../../hooks/use-location';

import {DocumentLink} from './document-link';
import {DocumentStatusIconProps} from '../../../../components/doc-status-icon/document-status-icon';

interface Props {
    documents: DocInfo[];
    itemOffset?: number;
    itemMarginLeft?: number;
    DocStatusIconComponent: React.FunctionComponent<DocumentStatusIconProps>;
}

export const DocumentsList = ({
    documents,
    itemMarginLeft,
    itemOffset,
    DocStatusIconComponent
}: Props) => {
    const {documentId} = useLocationParams();

    return (
        <>
            {documents.map((doc) => (
                <DocumentLink
                    key={doc.accession}
                    doc={doc}
                    active={doc.accession === documentId}
                    offset={itemOffset}
                    marginLeft={itemMarginLeft}
                    DocStatusIconComponent={DocStatusIconComponent}
                />
            ))}
        </>
    );
};
