import {Controls, ControlsItem, Typography, TypographyProps} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './editable-title.module.css';

/** Props for view mode title */
export interface TitleWithMenuProps extends TypographyProps {
    prepend?: React.ReactNode;
}

/** View mode title with menu property */
export function TitleViewMode(props: TitleWithMenuProps) {
    const {prepend, children, ...restProps} = props;

    return (
        <Controls
            gap={1}
            {...restProps}
            className={classNames(styles.viewModeTitle, restProps.className)}
        >
            {prepend && <ControlsItem style={{display: 'flex'}}>{prepend}</ControlsItem>}

            <ControlsItem shrink>
                <Typography ellipsis>{children}</Typography>
            </ControlsItem>
        </Controls>
    );
}
