export function updateScrollPositionByElement(
    wrapperNode: HTMLElement | null,
    itemNode: HTMLElement | null
) {
    if (!itemNode || !wrapperNode) {
        return;
    }

    const listRect = wrapperNode.getBoundingClientRect();
    const itemRect = itemNode.getBoundingClientRect();

    // top of the list
    if (listRect.top > itemRect.top) {
        wrapperNode.scrollTo(0, wrapperNode.scrollTop + itemRect.top - listRect.top);

        return;
    }

    // bottom of the list
    const diff = itemRect.top + itemRect.height - listRect.top - listRect.height;
    if (diff > 0) {
        wrapperNode.scrollTo(0, wrapperNode.scrollTop + diff);
    }
}
