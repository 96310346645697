import React from 'react';
import {useReactQueryGlobalState} from '../../hooks/use-react-query-global-state';

export function useGlobalVersionDialogsGlobalValue() {
    const [createVersionDialogOpen, setCreateVersionDialogOpen] = useReactQueryGlobalState(
        'is-create-version-dialog-open'
    );

    const openCreateVersionDialog = React.useCallback(() => {
        setCreateVersionDialogOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeCreateVersionDialog = React.useCallback(() => {
        setCreateVersionDialogOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showVersionsDialogOpen, setShowVersionsDialogOpen] = useReactQueryGlobalState(
        'is-show-version-dialog-open'
    );
    const [scrollToGlobalVersionId, setScrollToGlobalVersion] = useReactQueryGlobalState(
        'scroll-to-global-version-id'
    );

    const openShowVersionsDialog = React.useCallback((versionId?: number) => {
        setScrollToGlobalVersion(versionId);
        setShowVersionsDialogOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeShowVersionsDialog = React.useCallback(() => {
        setScrollToGlobalVersion(undefined);
        setShowVersionsDialogOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        createVersionDialogOpen,
        openCreateVersionDialog,
        closeCreateVersionDialog,

        isShowVersionsDialogOpen: showVersionsDialogOpen,
        openShowVersionsDialog,
        closeShowVersionsDialog,

        scrollToGlobalVersionId
    };
}
