import React from 'react';

import {StubMessage} from '../../../../../../components/stub-message';
import {Tetragon} from '../../../../../../components/tetragon';

import styles from './not-found-view.module.css';

type NotFoundViewVariant = 'document' | 'revision';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    variant: NotFoundViewVariant;
    // eslint-disable-next-line react/no-unused-prop-types
    documentId: string;
}

function getMessage({variant, documentId}: Props) {
    if (variant === 'revision') {
        return {
            caption: 'Revision not found',
            message: 'Requested revision of the document is not available or does not exist.'
        };
    }

    return {
        caption: 'Document not found',
        message: `Requested document (${documentId}) is not available or does not exist.`
    };
}

/** A component rendering an explanation of why Document can't be displayed */
export const NotFoundView = (props: Props) => {
    const {caption, message} = getMessage(props);

    return (
        <div className={styles.root}>
            <Tetragon className={styles.bg} />

            <StubMessage className={styles.message} caption={caption}>
                {message}
            </StubMessage>
        </div>
    );
};
