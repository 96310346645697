import React from 'react';
import {Core} from 'cytoscape';
import {CytoscapeGraphView} from '../../../../../components/graph/cytoscape-graph/cytoscape-graph-view';
import {GraphEdge, GraphNode} from '../../../../../components/graph/interface';
import {useNeighborhoodGraphProps} from './hooks/use-neighborhood-graph-props';
import {BrowserNotSupportedView} from '../../../../../components/graph/common-components/browser-not-supported-view';
import {CustomResizableLayout, ResizablePanel} from '../../../../../components/resizable-layout';
import {useGraphSelectionProps} from '../../../../../components/graph/use-graph-selection-props';
import style from './neighborhood-graph.module.css';
import {FactsetPanel} from './factset-panel';
import {useNeighborhoodGraphSettings} from './hooks/use-neighborhood-graph-settings';
import {useDocLayoutSettings} from '../../hooks/use-doc-layout-settings';

interface Props {
    documentId: string;
    selectedFacts: string[];
    globalVersionId: number;
    nodes: GraphNode[];
    edges: GraphEdge[];
    csRef: React.RefObject<Core>;
    graphSettings: ReturnType<typeof useNeighborhoodGraphSettings>;
    graphSelectionProps: ReturnType<typeof useGraphSelectionProps>;
    layoutSettings: ReturnType<typeof useDocLayoutSettings>;
}

export function NeighborhoodGraphLoaded(props: Props) {
    const graphProps = useNeighborhoodGraphProps({nodes: props.nodes, edges: props.edges});

    return (
        <CustomResizableLayout
            localStorageKey="documents.explore.neighborhoodGraph.factsetPanel.layoutSize"
            alignment="vertical"
            settings={{initialFirstPanelShare: 0.6, minPermittedHeight: 200}}
            fixedPanelSize={props.layoutSettings.factsetPanelFixedSize}
        >
            {(resizableLayoutProps) => (
                <React.Fragment>
                    <ResizablePanel
                        index={0}
                        className={style.neighborhoodGraphResizablePanel}
                        {...resizableLayoutProps}
                    >
                        {(function () {
                            if (
                                props.layoutSettings.isGraphPanelCollapsed ||
                                !graphProps.nodePositions
                            ) {
                                return undefined;
                            }

                            if (graphProps.isBrowserUnsupported) {
                                return <BrowserNotSupportedView />;
                            }

                            return (
                                <CytoscapeGraphView
                                    nodePositions={graphProps.nodePositions}
                                    handleNodeMoved={graphProps.handleNodeMoved}
                                    nodes={props.nodes}
                                    edges={props.edges}
                                    selectedNodes={props.graphSelectionProps.selectedNodes}
                                    selectNodes={props.graphSelectionProps.onNodesSelected}
                                    hoveredNodes={props.graphSelectionProps.hoveredNodes}
                                    setHoveredNodes={props.graphSelectionProps.setHoveredNodes}
                                    zoom={graphProps.zoom}
                                    setZoom={graphProps.setZoom}
                                    pan={graphProps.pan}
                                    setPan={graphProps.setPan}
                                    csRef={props.csRef}
                                />
                            );
                        })()}
                    </ResizablePanel>

                    {props.graphSelectionProps.selectedNodes.length ? (
                        <ResizablePanel
                            index={1}
                            className={style.factsetPanel}
                            {...resizableLayoutProps}
                        >
                            <FactsetPanel
                                documentId={props.documentId}
                                selectedFacts={props.selectedFacts}
                                globalVersionId={props.globalVersionId}
                                selectedNodeIds={props.graphSelectionProps.selectedNodes}
                                graphSettings={props.graphSettings}
                                closePanel={props.graphSelectionProps.clearNodeSelection}
                                isCollapsed={props.layoutSettings.isFactsetPanelCollapsed}
                                toggleIsCollapsed={props.layoutSettings.toggleFactsetPanelCollapsed}
                            />
                        </ResizablePanel>
                    ) : undefined}
                </React.Fragment>
            )}
        </CustomResizableLayout>
    );
}
