import React from 'react';
import {
    BulletIcon,
    ListItem,
    TextLabel,
    Typography,
    Link,
    Tooltip,
    TooltipHandler
} from '@genestack/ui';
import styles from './right-panel.module.css';
import {isSelectMultipleMouseEvent} from '../../../../../utils/get-client-os';

interface Props {
    label: string;
    count?: number;
    padding: 2 | 3;
}

interface InteractiveProps extends Props {
    graphViewNodeIds: number[];
    active: boolean;
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
}

function isInteractiveProps(props: Props | InteractiveProps): props is InteractiveProps {
    return !!(props as InteractiveProps).selectGraphNodes;
}

export function RightPanelListItem(props: Props | InteractiveProps) {
    const isInteractiveItem = isInteractiveProps(props);
    const onMouseOver = React.useCallback(
        () => isInteractiveItem && props.setHoveredNodes(props.graphViewNodeIds),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isInteractiveItem]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onMouseOut = React.useCallback(() => isInteractiveItem && props.setHoveredNodes([]), []);
    const onClick = React.useCallback(
        (e: React.MouseEvent) => {
            isInteractiveItem &&
                props.selectGraphNodes(props.graphViewNodeIds, isSelectMultipleMouseEvent(e));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isInteractiveItem && props.selectGraphNodes]
    );

    return (
        <ListItem
            interactive={isInteractiveItem}
            className={props.padding === 2 ? styles.statItemLevel2 : styles.statItemLevel3}
            onClick={isInteractiveItem ? onClick : undefined}
            active={isInteractiveItem && props.active}
            onMouseOver={isInteractiveItem ? onMouseOver : undefined}
            onMouseOut={isInteractiveItem ? onMouseOut : undefined}
        >
            <TextLabel
                caption={
                    props.count === undefined ? undefined : (
                        <Typography intent="quiet">
                            <BulletIcon />
                            {props.count}
                        </Typography>
                    )
                }
            >
                <TooltipHandler
                    tooltip={<Tooltip className={styles.labelTooltip}>{props.label}</Tooltip>}
                >
                    {isInteractiveItem ? (
                        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                        <Link>{props.label}</Link>
                    ) : (
                        <Typography>{props.label}</Typography>
                    )}
                </TooltipHandler>
            </TextLabel>
        </ListItem>
    );
}
