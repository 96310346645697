import React from 'react';
import {Divider, ScrollView, Typography} from '@genestack/ui';
import {CategorySearchData} from '../../../common/sidebar-search-field/interface';
import {useEditModeDocsInfiniteQuery} from '../../hooks/use-edit-mode-docs-infinite-query';
import {DocumentStatus} from '../../api';
import {Preloader} from '../../../../../components/preloader';
import {SidebarResultsHeader} from '../../../../../components/sidebar-results-header';
import {PaginatedList} from '../../../../../components/pagination';
import {extractInfiniteQueryItems} from '../../../../../utils/infinite-query-items';
import {useLocationParams} from '../../../../../hooks/use-location';
import {DocumentLink} from '../document-link';
import {DocumentStatusIcon} from '../../../../../components/doc-status-icon/document-status-icon';

const PAGE_SIZE = 60;

interface Props {
    searchData: CategorySearchData;
}

export function CategorySearchResult(props: Props) {
    const {documentId} = useLocationParams();

    const searchQuery = useEditModeDocsInfiniteQuery({
        queryKey: 'category-search',
        statuses: [DocumentStatus.PUBLISHED, DocumentStatus.DRAFT, DocumentStatus.EDITABLE],
        categories: [props.searchData.categoryId],
        pageSize: PAGE_SIZE
    });

    const items = extractInfiniteQueryItems(
        searchQuery,
        (response: PageResponse<DocInfo>) => response.data
    );

    const renderDocumentLink = React.useCallback(
        (doc: DocInfo) => {
            const element = (
                <DocumentLink
                    doc={doc}
                    active={doc.accession === documentId}
                    DocStatusIconComponent={DocumentStatusIcon}
                />
            );

            return [doc.accession, element] as [React.Key, React.ReactElement];
        },
        [documentId]
    );

    const totalDocumentsNumber = searchQuery.data?.pages[0]?.total;

    return (
        <Preloader show={searchQuery.isLoading}>
            {items && (
                <React.Fragment>
                    <SidebarResultsHeader
                        isLoading={searchQuery.isFetchingNextPage || searchQuery.isLoading}
                    >
                        <Typography variant="section">
                            {totalDocumentsNumber
                                ? `Found ${searchQuery.data?.pages[0]?.total} documents`
                                : 'No results found'}
                        </Typography>
                    </SidebarResultsHeader>

                    <ScrollView>
                        <PaginatedList
                            pageSize={PAGE_SIZE}
                            items={items}
                            isLoadingNextPage={searchQuery.isFetchingNextPage}
                            hasNextPage={searchQuery.hasNextPage}
                            onLoadNext={searchQuery.fetchNextPage}
                            renderItem={renderDocumentLink}
                        />

                        <Divider variant="transparent" gap={4} />
                    </ScrollView>
                </React.Fragment>
            )}
        </Preloader>
    );
}
