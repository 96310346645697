import {useQuery, useQueryClient} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {WorkingCopy} from '../interface';

interface Props {
    accession: string;
    revisionId?: string;
}

function getWorkingCopyRequest(accession: string, revision?: string) {
    return apiClient.get<WorkingCopy>({
        path: `editor-documents-service/api/documents/${accession}/wc`,
        query: {revision}
    });
}

export function useFetchWorkingCopyQuery(props: Props, enabled: boolean) {
    return useQuery({
        enabled,
        queryKey: ['working-copy', props.accession, props.revisionId],
        queryFn: () => getWorkingCopyRequest(props.accession, props.revisionId)
    });
}

export function useFetchWorkingCopyQueryCache() {
    const queryClient = useQueryClient();

    const getQueryKey = (accession: string, revision?: string) => [
        'working-copy',
        accession,
        revision
    ];

    function invalidate(accession: string, revision?: string) {
        queryClient.invalidateQueries({
            queryKey: getQueryKey(accession, revision),
            refetchInactive: true
        });
    }

    function remove(accession: string, revision?: string) {
        queryClient.removeQueries({queryKey: getQueryKey(accession, revision)});
    }

    function updateName(accession: string, nextName: string) {
        queryClient.setQueryData<WorkingCopy | undefined>(
            getQueryKey(accession),
            (currentState) => currentState && {...currentState, workingCopyName: nextName}
        );
    }

    function addDocument(docInfo: DocInfo, wc: WorkingCopy) {
        queryClient.setQueryData<WorkingCopy>(getQueryKey(docInfo.accession), () => ({...wc}));
    }

    return {invalidate, updateName, remove, addDocument};
}
