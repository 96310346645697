import {ListItem, RootRef, Typography, BulletIcon} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {Link} from 'react-router-dom';
import {saveElementRef} from '../../../../components/scroll-to-element';
import {DocumentViewMode} from '../../../../hooks/use-location';
import {CommitData} from '../../../../providers/version';
import {getFormattedDate} from '../../../../utils/common';
import {CommitText} from '../commit-text';

import styles from './version-item.module.css';

interface Props {
    commit: CommitData;
    mode?: DocumentViewMode;
    isActive: boolean;
    getSelectedRevisionLink: (revision: string) => string;
}

// eslint-disable-next-line react/display-name
export const RevisionItem = React.memo(
    ({commit, isActive, mode, getSelectedRevisionLink}: Props) => {
        const liElement = React.useRef(null);

        React.useEffect(() => {
            if (isActive) {
                /** Allows to scroll to viewport to element in sidebar */
                saveElementRef(liElement, commit.revision);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isActive]);

        const isStatic =
            commit.action === 'DELETE' || (commit.action === 'TRASH_BIN' && mode === 'explore');

        return (
            <RootRef rootRef={liElement}>
                <ListItem
                    wrap
                    interactive={!isStatic}
                    active={isActive}
                    className={classNames(styles.item, {
                        [styles.afterDeleted]: ['RESTORE', 'DELETE'].includes(commit.action),
                        [styles.deleted]: commit.action === 'TRASH_BIN'
                    })}
                    prependProps={{className: styles.prepend}}
                    prepend={<BulletIcon />}
                    as={isStatic ? 'a' : Link}
                    {...{
                        to: getSelectedRevisionLink(commit.revision)
                    }}
                >
                    <Typography>
                        {getFormattedDate({
                            date: commit.createdAt,
                            skipCurrentYear: true,
                            showTime: true
                        })}
                    </Typography>
                    <Typography intent="quiet" variant="caption">
                        <CommitText
                            action={commit.action}
                            renamedInCommit={commit.renamedInCommit}
                        />
                        <i>
                            {' '}
                            by
                            {commit.author.name || commit.author.email}
                        </i>
                    </Typography>
                    {commit.commitMessage ? (
                        <Typography variant="caption" intent="quiet" className={styles.message}>
                            {commit.commitMessage}
                        </Typography>
                    ) : null}
                </ListItem>
            </RootRef>
        );
    }
);
