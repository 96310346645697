import Rollbar from 'rollbar';

const environment = document.head
    .querySelector('meta[name="environment"]')
    ?.getAttribute('content');

if (environment) {
    window.rollbarInstance = new Rollbar({
        accessToken: '96069af297d845fc8f42a13edf644fd4',
        environment,
        captureUncaught: false,
        captureUnhandledRejections: false,
        reportLevel: 'error',
        logLevel: 'error',
        itemsPerMinute: 5
    });
}

/** Log critical errors in Rollbar (https://rollbar.com/biokb.jetbrains.com) */
export function logError(error: Error) {
    if (window.rollbarInstance) {
        window.rollbarInstance.log(error);

        return;
    }

    // eslint-disable-next-line no-console
    console.warn(error);
}
