import classNames from 'classnames';
import {Duration} from 'luxon';
import React from 'react';

import {InProgressIcon} from '@genestack/ui';

import styles from './timer.module.css';

interface TimerProps {
    duration: number;
    accented?: boolean;
}

const ShortTimer = ({duration, accented}: TimerProps) => (
    <span className={styles['short-timer']}>
        <InProgressIcon />
        <span className={classNames(styles['timer-value'], accented && styles.accented)}>
            {Duration.fromObject({milliseconds: duration}).toFormat('hh:mm:ss')}
        </span>
    </span>
);

const LongTimer = ({duration, accented}: TimerProps) => (
    <span className={styles['long-timer']}>
        <span className={classNames(styles['timer-value'], accented && styles.accented)}>
            {Duration.fromObject({milliseconds: duration}).toFormat('hh:mm:ss')}
        </span>
    </span>
);

interface TimerWrapperProps {
    duration?: number;
    short?: boolean;
    accented?: boolean;
}

/** A component rendering a timer */
export const Timer = ({duration, accented, short = false}: TimerWrapperProps) => {
    if (duration === undefined) {
        return null;
    }

    return short ? (
        <ShortTimer duration={duration} accented={accented} />
    ) : (
        <LongTimer duration={duration} accented={accented} />
    );
};
