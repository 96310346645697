import {useMutation} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

import {useDeletedDocumentsQueryCache} from './use-deleted-documents-query';
import {useDocMetadataQueryCache} from './use-doc-metadata-query';

function deleteFromTrashBinRequest() {
    return apiClient.callApi({
        path: 'editor-documents-service/api/documents/trash-bin',
        method: 'DELETE'
    });
}

/** Hook to delete all documents from trash bin */
export function useDeleteFromTrashBinMutation() {
    const deletedQueryCache = useDeletedDocumentsQueryCache();
    const docMetadataQueryCache = useDocMetadataQueryCache();

    return useMutation(deleteFromTrashBinRequest, {
        onSuccess: () => {
            deletedQueryCache.invalidate();
            docMetadataQueryCache.clearCache();
        }
    });
}
