import {FormNode} from '../../hooks/user/auth-form-query';

/** Returns node with "type": "submit" among the given nodes */
export function getSubmitNode(nodes?: FormNode[]) {
    return nodes?.find((node) => node.attributes.type === 'submit');
}

/** Returns node with "type": "hidden" among the given nodes */
export function getHiddenNodes(nodes: FormNode[]) {
    return nodes.filter((node) => node.attributes.type === 'hidden');
}

/** HTTP OK status code */
export const OK_STATUS_CODE = 200;
