import {Button} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {OntologyTreePreloader} from '../ontology-tree-preloader';
import {OntologyTreePreloaderItem} from '../ontology-tree-preloader/ontology-tree-preloader-item';

import styles from './load-more-control.module.css';

interface Props extends React.ComponentPropsWithoutRef<typeof Button> {
    isLoading?: boolean;
}

function LoadMoreControlPreloaderItem(
    props: React.ComponentPropsWithoutRef<typeof OntologyTreePreloaderItem>
) {
    return (
        <OntologyTreePreloaderItem
            {...props}
            className={classNames(styles.preloaderItem, props.className)}
        />
    );
}

/** Button component with loading state */
export function LoadMoreControl({isLoading, children, ...restProps}: Props) {
    return (
        <OntologyTreePreloader show={isLoading} wrapEach={LoadMoreControlPreloaderItem}>
            <Button
                ghost
                size="small"
                {...restProps}
                className={classNames(styles.root, styles.ghost, restProps.className)}
            >
                {children}
            </Button>
        </OntologyTreePreloader>
    );
}
