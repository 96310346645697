import {chainRefs} from '@genestack/ui';
import React from 'react';

type TargetProps = React.HTMLAttributes<HTMLDivElement>;
type OutsideClickEvent = TouchEvent | MouseEvent;

interface Props extends TargetProps {
    as?: React.ElementType;
    handler: (e: OutsideClickEvent) => void;
    disabled?: boolean;
}

/** Component for intercepting outside clicks */
export const OutsideClickWrapper = React.forwardRef<HTMLDivElement, Props>(
    function OutsideClickComponent({as = 'div', disabled, handler, ...restProps}, ref) {
        const wrapperRef = React.useRef<HTMLDivElement>(null);

        const outsideHandler = React.useCallback(
            (e: OutsideClickEvent) => {
                const {current: wrapper} = wrapperRef;

                if (!disabled && wrapper && !wrapper.contains(e.target as HTMLElement)) {
                    handler(e);
                }
            },
            [disabled, handler]
        );

        React.useEffect(() => {
            if ('ontouchstart' in window) {
                document.body.addEventListener('touchend', outsideHandler);
            } else {
                document.body.addEventListener('mouseup', outsideHandler);
            }

            return () => {
                if ('ontouchend' in window) {
                    document.body.removeEventListener('touchend', outsideHandler);
                } else {
                    document.body.removeEventListener('mouseup', outsideHandler);
                }
            };
        });

        return React.createElement(as, {
            ref: chainRefs(wrapperRef, ref),
            ...restProps
        });
    }
);
