import React, {PropsWithChildren} from 'react';
import {Button, Controls, CrossIcon, Tooltip, TooltipHandler} from '@genestack/ui';
import styles from '../../../../../components/vertical-bar/vertical-bar.module.css';
import {SidebarHeader} from '../../../../../components/sidebar-header';

interface Props {
    closePanel: () => void;
}

export function RightPanelHeader(props: PropsWithChildren<Props>) {
    return (
        <SidebarHeader>
            <Controls justify="space-between">
                {props.children}

                <TooltipHandler
                    tooltip={
                        <Tooltip placement="bottom" className={styles.tooltip}>
                            Close sidebar
                        </Tooltip>
                    }
                >
                    <Button iconEnd={<CrossIcon />} size="small" ghost onClick={props.closePanel} />
                </TooltipHandler>
            </Controls>
        </SidebarHeader>
    );
}
