import {useInfiniteQuery, useQueryClient} from 'react-query';

import {UsageNode} from '../../../../../../webeditor/biokb-editor-adapters';
import {parseNodes} from '../../utils';
import {apiClient} from '../../../../../utils/api-client';
import {CategoriesSearch, getCategoriesSearch} from '../../../../../utils/get-categories-search';

/** Page size of find usages request */
export const PAGE_SIZE = 25;

interface FindUsagesRequest {
    declarationNodeId: string | null;
    globalVersionId?: number;
    documentNodesLimit?: number; // default - 5
    nodesLimit?: number; // default - 25
    cursor?: DocumentCursor;
    categories?: string[];
    categoriesSearch?: CategoriesSearch;
}

interface FindUsagesDocument {
    accession: string;
    id: number;
    name: string;
    readOnly: boolean;
    revision: string;
    type: DocType;
    userFavorite: boolean;
}

interface UsageDocument {
    usages: UsageNode[];
    document: FindUsagesDocument;
    total: number;
    remaining: number;
    nextCursor: number;
}

/** Find usages response with document nodes type for view mode */
export interface FindUsagesWithDocNodes extends Omit<UsageDocument, 'usages'> {
    usages: DocumentNode[];
}

/** Find usages response type for view mode */
export interface FindUsagesResponse<Data = FindUsagesWithDocNodes>
    extends PageResponse<Data, number> {
    totalNodes: number;
}

interface Props {
    accession?: string;
    declarationNodeId: string | null;
    globalVersionId: number;
    categories?: string[];
    enabled?: boolean;
}

function getFindUsagesRequest(accession: string, parameters: FindUsagesRequest) {
    return apiClient
        .post<FindUsagesResponse<UsageDocument>>({
            path: `documents-revisions-service/api/viewer/documents/${accession}/usages`,
            query: {'global-version': parameters.globalVersionId},
            parameters
        })
        .then<FindUsagesResponse>(async ({data, ...restData}) => ({
            ...restData,
            data: await Promise.all(
                data.map(async (usageDocument) => ({
                    ...usageDocument,
                    usages: await parseNodes(usageDocument.document.accession, usageDocument.usages)
                }))
            )
        }));
}

/** Request for usages of a node */
export function useFindUsagesQuery(props: Props) {
    return useInfiniteQuery(
        [
            'find-usages',
            props.accession,
            props.declarationNodeId,
            props.globalVersionId,
            props.categories
        ],
        ({pageParam = {cursor: null, limit: PAGE_SIZE}}) =>
            getFindUsagesRequest(props.accession ?? '', {
                globalVersionId: props.globalVersionId,
                declarationNodeId: props.declarationNodeId,
                cursor: pageParam.cursor,
                nodesLimit: pageParam.limit,
                categoriesSearch: getCategoriesSearch(props.categories)
            }),
        {
            cacheTime: 0,
            enabled: props.enabled,
            getNextPageParam: (pageParam) => {
                const hasNextPage = Number(pageParam?.remaining) > 0;

                if (hasNextPage) {
                    return {
                        cursor: pageParam?.nextCursor,
                        limit: PAGE_SIZE
                    };
                }
            }
        }
    );
}

/** QueryCache for find usages request */
export const useFindUsagesQueryCache = () => {
    const queryClient = useQueryClient();

    function invalidate(props: {
        accession?: string;
        declarationNodeId: string | null;
        globalVersionId: number;
        categories?: string[];
    }) {
        return queryClient.invalidateQueries([
            'find-usages',
            props.accession,
            props.declarationNodeId,
            props.globalVersionId,
            props.categories
        ]);
    }

    return {invalidate};
};
