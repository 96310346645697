import {Divider} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {FindUsagesEditorEvent} from '../../../../../webeditor/biokb-editor-adapters';

import {NodeView} from './node-view';
import {useOntologyQuery} from './ontology-query';
import {OntologyViewCaption} from './ontology-view-caption';
import {OntologyViewFooter} from './ontology-view-footer';
import styles from './ontology-view.module.css';

interface Props {
    mode: 'explore' | 'edit';
    documentMetadata: DocInfo | ViewModeDocInfo;
    /** versionId is meaningful only in Explore mode */
    versionId?: number | null;
    nodeId?: string;
    onNavigate: (accession: string, nodeId: string | null) => void;
    onFindUsages: (event: FindUsagesEditorEvent) => void;
    className?: string;
}

export function OntologyView(props: Props) {
    const ontologyQuery = useOntologyQuery({
        viewer: props.mode === 'explore',
        documentId: props.documentMetadata.id,
        accession: props.documentMetadata.accession
    });

    const handleNavigate = (accession: string | null, nodeId: string | null) => {
        props.onNavigate(accession || props.documentMetadata.accession, nodeId);
    };

    return (
        <>
            <div className={classNames(styles.root, props.className)}>
                <OntologyViewCaption total={ontologyQuery.data?.totalElements} />

                <Divider variant="transparent" gap={2} />

                {props.nodeId ? (
                    <NodeView
                        mode={props.mode}
                        documentInfo={props.documentMetadata}
                        versionId={props.versionId}
                        nodeId={props.nodeId}
                        onFindUsages={props.onFindUsages}
                        onNavigate={handleNavigate}
                    />
                ) : null}
            </div>

            {ontologyQuery.data && (
                <OntologyViewFooter timestamp={ontologyQuery.data?.lastImported} />
            )}
        </>
    );
}
