import React from 'react';

import {RelationPresentation} from '../interface';

import {edgePropsByType, nodeColorDefault, nodeColorHover} from './constants';
import {GraphEntityStatus} from '../cytoscape-graph/interface';

interface DefProps {
    status: GraphEntityStatus;
}

interface ArrowMarkerDefProps extends DefProps {
    arrowType: RelationPresentation;
}

function ArrowMarker(props: ArrowMarkerDefProps) {
    const {markerUrl, color} = edgePropsByType[props.arrowType][props.status];

    return (
        <marker
            id={markerUrl}
            refX="4.5"
            refY="4"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
            markerWidth="5"
            markerHeight="5"
        >
            <path
                d="M1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM4 4L4.70711 4.70711L5.41421 4L4.70711 3.29289L4 4ZM0.292893 6.29289C-0.0976311 6.68342 -0.0976311 7.31658 0.292893 7.70711C0.683418 8.09763 1.31658 8.09763 1.70711 7.70711L0.292893 6.29289ZM0.292893 1.70711L3.29289 4.70711L4.70711 3.29289L1.70711 0.292893L0.292893 1.70711ZM3.29289 3.29289L0.292893 6.29289L1.70711 7.70711L4.70711 4.70711L3.29289 3.29289Z"
                fill={color}
            />
        </marker>
    );
}

/** The map which id and color for intermediate node by status */
export const nodeIntermediatePropsByStatus = {
    [GraphEntityStatus.default]: {
        id: 'query-results-node-intermediate',
        color: nodeColorDefault
    },
    [GraphEntityStatus.hovered]: {
        id: 'query-results-node-intermediate-hovered',
        color: nodeColorHover
    },
    [GraphEntityStatus.selected]: {
        id: 'query-results-node-intermediate-selected',
        color: nodeColorHover
    }
};

function NodeIntermediate(props: DefProps) {
    const {id, color} = nodeIntermediatePropsByStatus[props.status];

    return (
        <g id={id}>
            <path
                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill="white"
            />
            <circle cx="10" cy="10" r="6" fill={color} fillOpacity="0.6" />

            {props.status === GraphEntityStatus.selected && (
                <circle
                    r="9"
                    strokeWidth="2"
                    fill="none"
                    stroke={color}
                    strokeOpacity="0.6"
                    cx="10"
                    cy="10"
                />
            )}
        </g>
    );
}

/** The map which id and color for start node by status */
export const nodeStartPropsByStatus = {
    [GraphEntityStatus.default]: {
        id: 'query-results-node-start',
        color: nodeColorDefault
    },
    [GraphEntityStatus.hovered]: {
        id: 'query-results-node-start-hovered',
        color: nodeColorHover
    },
    [GraphEntityStatus.selected]: {
        id: 'query-results-node-start-selected',
        color: nodeColorHover
    }
};

function NodeStart(props: DefProps) {
    const {id, color} = nodeStartPropsByStatus[props.status];

    return (
        <g id={id}>
            <rect width="20" height="20" rx="2" fill="white" />
            <path
                d="M15 3C16.1046 3 17 3.89543 17 5L17 15C17 16.1046 16.1046 17 15 17L5 17C3.89543 17 3 16.1046 3 15L3 5C3 3.89543 3.89543 3 5 3L15 3Z"
                fill={color}
                fillOpacity="0.6"
            />
            <path d="M14 8L10 15L6 8L14 8Z" fill="white" />
            <rect x="11" y="3" width="5" height="2" transform="rotate(90 11 3)" fill="white" />
            {props.status === GraphEntityStatus.selected && (
                <rect
                    width="20"
                    height="20"
                    rx="2"
                    fill="none"
                    strokeWidth="2"
                    stroke={color}
                    strokeOpacity="0.6"
                />
            )}
        </g>
    );
}

/** The map which id and color for end node by status */
export const nodeEndPropsByStatus = {
    [GraphEntityStatus.default]: {
        id: 'query-results-node-end',
        color: nodeColorDefault
    },
    [GraphEntityStatus.hovered]: {
        id: 'query-results-node-end-hovered',
        color: nodeColorHover
    },
    [GraphEntityStatus.selected]: {
        id: 'query-results-node-end-selected',
        color: nodeColorHover
    }
};

function NodeEnd(props: DefProps) {
    const {id, color} = nodeEndPropsByStatus[props.status];

    return (
        <g id={id}>
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
                fill="white"
            />
            <path
                d="M15 3C16.1046 3 17 3.89543 17 5L17 15C17 16.1046 16.1046 17 15 17L5 17C3.89543 17 3 16.1046 3 15L3 5C3 3.89543 3.89543 3 5 3L15 3Z"
                fill={color}
                fillOpacity="0.6"
            />
            <path d="M14 10L14 14L6 14L6 10L14 10Z" fill="white" />
            <rect x="11" y="3" width="7" height="2" transform="rotate(90 11 3)" fill="white" />
            {props.status === GraphEntityStatus.selected && (
                <rect
                    width="20"
                    height="20"
                    rx="2"
                    fill="none"
                    strokeWidth="2"
                    stroke={color}
                    strokeOpacity="0.6"
                />
            )}
        </g>
    );
}

/** Definitions for graph svg entities */
export const GraphSvgDefs = () => (
    <svg width="0" height="0" viewBox="0 0 0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <NodeIntermediate status={GraphEntityStatus.default} />
            <NodeIntermediate status={GraphEntityStatus.hovered} />
            <NodeIntermediate status={GraphEntityStatus.selected} />

            <NodeStart status={GraphEntityStatus.default} />
            <NodeStart status={GraphEntityStatus.hovered} />
            <NodeStart status={GraphEntityStatus.selected} />

            <NodeEnd status={GraphEntityStatus.default} />
            <NodeEnd status={GraphEntityStatus.hovered} />
            <NodeEnd status={GraphEntityStatus.selected} />

            <ArrowMarker
                status={GraphEntityStatus.default}
                arrowType={RelationPresentation.ASSERTED}
            />
            <ArrowMarker
                status={GraphEntityStatus.hovered}
                arrowType={RelationPresentation.ASSERTED}
            />
            <ArrowMarker
                status={GraphEntityStatus.selected}
                arrowType={RelationPresentation.ASSERTED}
            />
            <ArrowMarker
                status={GraphEntityStatus.default}
                arrowType={RelationPresentation.REACTION}
            />
            <ArrowMarker
                status={GraphEntityStatus.hovered}
                arrowType={RelationPresentation.REACTION}
            />
            <ArrowMarker
                status={GraphEntityStatus.selected}
                arrowType={RelationPresentation.REACTION}
            />
            <ArrowMarker
                status={GraphEntityStatus.default}
                arrowType={RelationPresentation.MATCHED}
            />
            <ArrowMarker
                status={GraphEntityStatus.hovered}
                arrowType={RelationPresentation.MATCHED}
            />
            <ArrowMarker
                status={GraphEntityStatus.selected}
                arrowType={RelationPresentation.MATCHED}
            />
        </defs>
    </svg>
);
