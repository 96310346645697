import {Button, Menu, MenuCaption, MenuHandler, MoreIcon} from '@genestack/ui';
import React from 'react';

import {CommitData} from '../../../../providers/version';
import {getFormattedDate} from '../../../../utils/common';
import {getCommitMessage} from '../commit-text';

import {CopyToDraftBtnProps, VersionMenuItems} from './version-menu-items';

interface Props {
    selectedVersion: CommitData;
    copyToDraftBtnProps?: CopyToDraftBtnProps;
}

export const VersionMenu = (props: Props) => {
    const {selectedVersion, copyToDraftBtnProps} = props;

    const menu = (
        <Menu>
            <MenuCaption
                subtitle={
                    <>
                        {getCommitMessage(selectedVersion.action, selectedVersion.renamedInCommit)}{' '}
                        by {selectedVersion.author.name || selectedVersion.author.email}
                    </>
                }
            >
                <strong>
                    Revision: {getFormattedDate({date: selectedVersion.createdAt, showTime: true})}
                </strong>
            </MenuCaption>
            <VersionMenuItems copyToDraftBtnProps={copyToDraftBtnProps} />
        </Menu>
    );

    return (
        <MenuHandler menu={menu}>
            <Button data-qa="revision-label-button" ghost size="tiny" icon={<MoreIcon />} />
        </MenuHandler>
    );
};
