import {useMutation} from 'react-query';
import {apiClient} from '../../../../utils/api-client';
import {WorkingCopy} from '../interface';

interface Props {
    accession: string;
    revisionId?: string;
}

function getWorkingCopyRequest(accession: string, revision?: string) {
    return apiClient.post<WorkingCopy>({
        path: `editor-documents-service/api/documents/${accession}/wc`,
        query: {revision}
    });
}

export function useCreateWorkingCopy(props: Props) {
    return useMutation({
        mutationFn: () => {
            return getWorkingCopyRequest(props.accession, props.revisionId);
        }
    });
}
