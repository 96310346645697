import {useQuery, useQueryClient} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphPresentation} from '../interface';

export function requestPresentations(queryId: number) {
    return apiClient.get<GraphPresentation[]>({
        path: `queries-service/api/queries/${queryId}/graph-presentations`
    });
}

const baseKey = 'getPresentationsRequest';
export function useGetPresentations(queryId: number) {
    return useQuery([baseKey, queryId], () => requestPresentations(queryId!));
}

export function useGetPresentationsCache() {
    const queryClient = useQueryClient();

    function presentationAdded(queryId: number, presentation: GraphPresentation) {
        queryClient.setQueryData<GraphPresentation[] | undefined>(
            [baseKey, queryId],
            (prev) => prev && [presentation, ...prev]
        );
    }

    function setPresentations(queryId: number, presentationsList: GraphPresentation[]) {
        queryClient.setQueryData<GraphPresentation[] | undefined>(
            [baseKey, queryId],
            () => presentationsList
        );
    }

    function renamePresentation(queryId: number, presentationId: number, name: string) {
        const prevState = queryClient.getQueryData<GraphPresentation[] | undefined>([
            baseKey,
            queryId
        ]);

        queryClient.setQueryData<GraphPresentation[] | undefined>(
            [baseKey, queryId],
            (prev) =>
                prev &&
                prev.map((presentation) => ({
                    ...presentation,
                    name: presentation.id === presentationId ? name : presentation.name
                }))
        );
        return prevState;
    }

    function setFavorite(queryId: number, presentationId: number) {
        const prevState = queryClient.getQueryData<GraphPresentation[] | undefined>([
            baseKey,
            queryId
        ]);

        queryClient.setQueryData<GraphPresentation[] | undefined>(
            [baseKey, queryId],
            (prev) =>
                prev &&
                prev.map((presentation) => ({
                    ...presentation,
                    isFavorite: presentation.id === presentationId
                }))
        );
        return prevState;
    }

    function deletePresentation(queryId: number, presentationId: number) {
        const prevState = queryClient.getQueryData<GraphPresentation[] | undefined>([
            baseKey,
            queryId
        ]);
        queryClient.setQueryData<GraphPresentation[] | undefined>(
            [baseKey, queryId],
            (prev) => prev && prev.filter((p) => p.id !== presentationId)
        );
        return prevState;
    }

    return {
        presentationAdded,
        setFavorite,
        deletePresentation,
        setPresentations,
        renamePresentation
    };
}
