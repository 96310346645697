import {SubMenu, MenuItem, MenuCaption, Typography} from '@genestack/ui';
import React from 'react';

import {useUserEditingPermissions} from '../../../../hooks/user/use-has-permission';
import {docTypeToParams} from '../../../../utils/document';

const docTypeToParamsItems = Array.from(docTypeToParams);
const btbRelatedDocTypes = docTypeToParamsItems.filter(
    (it) => it[0] === 'BTB' || it[0] === 'SOURCES'
);

interface Props {
    createNew: (docType: DocType) => void;
}

/** Submenu for creating documents */
export function CreateDocumentSubmenu({createNew}: Props) {
    const {editingOnlyBTBRelatedPermitted} = useUserEditingPermissions();
    const paramsItems = editingOnlyBTBRelatedPermitted ? btbRelatedDocTypes : docTypeToParamsItems;

    const handleMenuItem = (value: DocType) => () => {
        createNew(value);
    };

    return (
        <SubMenu>
            <MenuCaption>
                <Typography variant="section">New document by type</Typography>
            </MenuCaption>

            {paramsItems.map(([type, params]) => (
                <MenuItem key={type} value={type} onClick={handleMenuItem(type)}>
                    {params.title}
                </MenuItem>
            ))}
        </SubMenu>
    );
}
