import {DividerProps, PageContent} from '@genestack/ui';
import React from 'react';

import {LabelledDivider} from '../../application/document-workflow/common/labelled-divider';

const SMALL_GAP: DividerProps = {startGap: 2, endGap: 0};

interface Props {
    page: number;
}

/** A divider for paginated lists */
export const PageDivider = ({page}: Props) => (
    <PageContent noStartDivider noEndDivider>
        <LabelledDivider
            label={`Page ${page}`}
            startDividerProps={SMALL_GAP}
            endDividerProps={SMALL_GAP}
        />
    </PageContent>
);
