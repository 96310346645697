import {
    BookmarkBorderedIcon,
    Badge,
    Typography,
    Button,
    Notification,
    Controls,
    ControlsItem,
    CollapseVerticalIcon,
    TooltipHandler,
    Tooltip,
    ExpandVerticalIcon
} from '@genestack/ui';
import React from 'react';

import {EditableTitleApi} from '../../../../../components/editable-title';
import {EditorHeader} from '../../../../../components/editor-header';
import {showNotification} from '../../../../../components/notifications-center';
import {
    NotificationElement,
    NotificationWithBtn
} from '../../../../../components/notifications-center/notifications-store';
import {useDeleteQueryMutation} from '../../../hooks/requests/use-delete-query-mutation';
import {useRestoreQueryMutation} from '../../../hooks/requests/use-restore-query-mutation';
import {useShareQuerySettings} from '../../share-query-dialog/hooks/use-share-query-settings';
import {useUpdateQueryMutation} from '../../../hooks/requests/use-update-query-mutation';
import {useLocationParams} from '../../../../../hooks/use-location';

import {QueryEditorMenu} from './query-editor-menu';
import {ShareQueryDialog} from '../../share-query-dialog/share-query-dialog';
import {isQueryCompleted} from '../../../utils';

const deleteNotificationTitle = 'The query is moved to Recycle Bin';
const deleteNotificationLabel = 'You have up to 30 days to restore it.';

interface Props {
    selectedQuery: QueryInfo;
    isBodyCollapsed: boolean;
    toggleIsBodyCollapsed: () => void;
}

export function QueryEditorHeader(props: Props) {
    const {selectedQuery} = props;
    const headerRef = React.useRef<EditableTitleApi>(null);
    const updateQuery = useUpdateQueryMutation();
    const selectedQueryId = useLocationParams().queryId as string;

    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

    const updateDeleteNotification = React.useRef<((element: NotificationElement) => void) | null>(
        null
    );

    const handleChangeDraftName = (name: string) => {
        updateQuery.mutate({id: selectedQuery.id, name});
    };

    const handleRename = () => {
        headerRef.current?.rename();
    };

    const handleShare = () => {
        setShareDialogOpen(true);
    };

    const closeShareDialog = () => {
        setShareDialogOpen(false);
    };

    const restoreQuery = useRestoreQueryMutation();
    const deleteQuery = useDeleteQueryMutation();

    const onDeleteNotificationClosed = () => {
        updateDeleteNotification.current = null;
    };

    const handleRestoreQuery = React.useCallback(() => {
        restoreQuery.mutate(selectedQuery, {
            onSuccess: () => {
                if (updateDeleteNotification.current) {
                    updateDeleteNotification.current(
                        <NotificationWithBtn
                            title={deleteNotificationTitle}
                            label={deleteNotificationLabel}
                            onClose={onDeleteNotificationClosed}
                            undoBtnProps={{
                                onClick: () => null,
                                disabled: true
                            }}
                        />
                    );
                }

                showNotification(
                    <Notification>
                        <Typography intent="success">The query is restored</Typography>
                    </Notification>
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQuery]);

    const shareQuerySettingsRequest = useShareQuerySettings(selectedQueryId);

    const handleDeleteQuery = React.useCallback(() => {
        deleteQuery.mutate(selectedQuery, {
            onSuccess: () => {
                updateDeleteNotification.current = showNotification(
                    <NotificationWithBtn
                        title={deleteNotificationTitle}
                        label={deleteNotificationLabel}
                        onClose={onDeleteNotificationClosed}
                        undoBtnProps={{
                            onClick: handleRestoreQuery
                        }}
                    />
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQuery]);

    return (
        <EditorHeader
            {...props}
            ref={headerRef}
            titleProps={{
                'data-qa': 'query-title'
            }}
            menu={
                <QueryEditorMenu
                    queryInfo={selectedQuery}
                    onRename={handleRename}
                    onRestore={handleRestoreQuery}
                    onDelete={handleDeleteQuery}
                    onShare={handleShare}
                    onlyOwnerCanShare={!!shareQuerySettingsRequest.data?.onlyOwnerCanShare}
                />
            }
            name={selectedQuery.name}
            prepend={selectedQuery.favorite ? <BookmarkBorderedIcon /> : null}
            onRename={handleChangeDraftName}
            disableEditingTitle={selectedQuery.sharedWithMe}
        >
            <Controls justify="space-between">
                {selectedQuery.trash ? (
                    <Badge disableTextTransform intent="warning">
                        This query is in the Recycle Bin
                    </Badge>
                ) : (
                    <div />
                )}

                {isQueryCompleted(selectedQuery) && (
                    <ControlsItem>
                        <TooltipHandler
                            tooltip={
                                <Tooltip placement="left">
                                    {props.isBodyCollapsed ? 'Expand' : 'Collapse'} query body
                                </Tooltip>
                            }
                        >
                            <Button
                                size="small"
                                ghost
                                iconEnd={
                                    props.isBodyCollapsed ? (
                                        <ExpandVerticalIcon />
                                    ) : (
                                        <CollapseVerticalIcon />
                                    )
                                }
                                onClick={props.toggleIsBodyCollapsed}
                            />
                        </TooltipHandler>
                    </ControlsItem>
                )}
            </Controls>

            <ShareQueryDialog
                open={shareDialogOpen}
                onClose={closeShareDialog}
                queryId={selectedQueryId}
                onlyOwnerCanShare={!!shareQuerySettingsRequest.data?.onlyOwnerCanShare}
            />
        </EditorHeader>
    );
}
