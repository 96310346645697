import {CallApiError} from '../../utils/api-client';

/**
 * Interface of JSON object that returns
 * from API gateway in exception cases
 */
export interface ApiError {
    code: number;
    message: string;
    exception: string;
    stackTrace: string;
}

interface ErrorReportOptions {
    error: Error | ErrorEvent;
    errorDateOccurred: Date;
    userEmail?: string;
}

const NAVIGATOR = `${navigator.appVersion} (vendor: ${navigator.vendor})`;

const toUTCStringWithMilliseconds = (date: Date) =>
    date.toUTCString().replace(' GMT', `.${date.getMilliseconds()} GMT`);

const getCallApiErrorReport = (error: CallApiError, options: ErrorReportOptions) => {
    const apiError = error.result.json.error as ApiError;

    const occurred = toUTCStringWithMilliseconds(options.errorDateOccurred);
    // tslint:disable-next-line no-magic-numbers
    const parameters = JSON.stringify(error.options.parameters || [], null, 4);
    const stackTrace = apiError.stackTrace ? `STACK TRACE: \n${apiError.stackTrace}` : null;

    return [
        `CODE: ${apiError.code}`,
        `MESSAGE: ${apiError.message}`,
        `EXCEPTION: ${apiError.exception}`,
        `OCCURRED: ${occurred}`,
        `HOST: ${window.location.hostname}`,
        `NAVIGATOR: ${NAVIGATOR}`,
        `URL: ${window.location.href}`,
        `METHOD: ${error.options.method}`,
        `ARGUMENTS: ${parameters}`,
        stackTrace
    ]
        .filter(Boolean)
        .join('\n');
};

interface UnhandledError {
    name?: string;
    message: string;
    stack?: string;
}

const getUnhandledErrorReport = (error: UnhandledError, options: ErrorReportOptions) => {
    const occurred = toUTCStringWithMilliseconds(options.errorDateOccurred);
    const stackTrace = error.stack ? `STACK TRACE: \n${error.stack}` : null;
    const name = error.name || 'UnknownError';

    return [
        `EXCEPTION: ${name} (${error.message})`,
        `OCCURRED: ${occurred}`,
        `HOST: ${window.location.hostname}`,
        `NAVIGATOR: ${NAVIGATOR}`,
        `URL: ${window.location.href}`,
        stackTrace
    ]
        .filter(Boolean)
        .join('\n');
};

/** Returns message text for error reporting */
export function getErrorReport(options: ErrorReportOptions) {
    const {error} = options;

    if (error instanceof CallApiError) {
        return getCallApiErrorReport(error, options);
    }

    if (error instanceof ErrorEvent) {
        return getUnhandledErrorReport(
            {
                message: error.message,
                stack: error.error.stack
            },
            options
        );
    }

    return getUnhandledErrorReport(error, options);
}

/** Returns message title for error reporting */
export const getErrorReportTitle = (error: Error | ErrorEvent) => {
    if (error instanceof CallApiError) {
        const exception = error.result.json;

        return exception.error.message;
    }

    if (error instanceof ErrorEvent) {
        return `A Javascript error occurred (${error.message})`;
    }

    return `A Javascript error occurred (${error.name}: ${error.message})`;
};
