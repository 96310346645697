import {DividerProps, Divider} from '@genestack/ui';
import React from 'react';

import styles from './labelled-divider.module.css';

interface Props {
    label: string;
    noStartDivider?: boolean;
    noEndDivider?: boolean;
    startDividerProps?: DividerProps;
    endDividerProps?: DividerProps;
}

/**
 * A horizontal line with label
 * e.g.
 * -------------- Page 3 --------------
 */
export const LabelledDivider = ({
    label,
    noStartDivider = false,
    noEndDivider = false,
    startDividerProps,
    endDividerProps
}: Props) => (
    <>
        {!noStartDivider && <Divider variant="transparent" {...startDividerProps} />}
        <div className={styles.root}>
            <div className={styles.label}>{label}</div>
        </div>
        {!noEndDivider && <Divider variant="transparent" {...endDividerProps} />}
    </>
);
