import React from 'react';
import useDebounce from 'react-use/lib/useDebounce';

import {PaginationAsyncValue} from '../../../../../../components/pagination/pagination-async-value';
import {extractInfiniteQueryItems} from '../../../../../../utils/infinite-query-items';

import {useSearchDocsQuery} from './use-edit-mode-search-query-cache';

const PAGE_SIZE = 60;
const DEBOUNCE_INTERVAL = 250;

interface UseSearchValue {
    pageSize: number;
    paginationState: PaginationAsyncValue<DocInfo>;
    onLoadNextPage: () => void;
}

/** Hook for searching documents in edit mode */
export function useSearchDocuments(props: {searchText: string}): UseSearchValue {
    const [debouncedValue, setDebouncedValue] = React.useState('');

    useDebounce(
        () => {
            setDebouncedValue(props.searchText);
        },
        DEBOUNCE_INTERVAL,
        [props.searchText]
    );

    const searchQuery = useSearchDocsQuery({
        searchText: debouncedValue,
        pageSize: PAGE_SIZE
    });

    const items = extractInfiniteQueryItems(
        searchQuery,
        (response: PageResponse<DocInfo>) => response.data
    );

    return React.useMemo<UseSearchValue>(
        () => ({
            pageSize: PAGE_SIZE,

            paginationState: {
                items,
                total: searchQuery.data?.pages[0]?.total,
                isLoading: searchQuery.isFetching,
                isLoadingNextPage: searchQuery.isFetchingNextPage,
                loadNextPage: searchQuery.fetchNextPage,
                hasNextPage: searchQuery.hasNextPage,
                reset: searchQuery.remove
            },
            onLoadNextPage: searchQuery.fetchNextPage
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchQuery]
    );
}
