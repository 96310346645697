import React from 'react';

import {cancellablePromise, useCancellablePromises} from './use-cancellable-promises';

interface Props<DomNode extends HTMLElement> {
    onClick?: React.MouseEventHandler<DomNode>;
    onDoubleClick?: React.MouseEventHandler<DomNode>;
    mouseDelay?: number;
}

const defaultMouseDelay = 300;

function delay(timeout: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

/** Hook to use click and double click together */
export function useClickPreventionOnDoubleClick<DomNode extends HTMLElement>({
    onClick,
    onDoubleClick,
    mouseDelay = defaultMouseDelay
}: Props<DomNode>) {
    const api = useCancellablePromises();

    const handleClick: React.MouseEventHandler<DomNode> = (e) => {
        if (onClick) {
            api.clearPendingPromises();
            const waitForClick = cancellablePromise(delay(mouseDelay));
            api.appendPendingPromise(waitForClick);

            return waitForClick.promise
                .then(() => {
                    api.removePendingPromise(waitForClick);
                    onClick(e);
                })
                .catch((errorInfo) => {
                    api.removePendingPromise(waitForClick);

                    if (!errorInfo.isCanceled) {
                        throw errorInfo.error;
                    }
                });
        }
    };

    const handleDoubleClick: React.MouseEventHandler<DomNode> = (e) => {
        if (onDoubleClick) {
            api.clearPendingPromises();
            onDoubleClick(e);
        }
    };

    return [handleClick, handleDoubleClick];
}
