import {useQuery, useQueryClient} from 'react-query';

interface Variables {
    'is-create-version-dialog-open': boolean;
    'is-show-version-dialog-open': boolean;
    'scroll-to-global-version-id': number | undefined;
}

const defaultState: Variables = {
    'is-create-version-dialog-open': false,
    'is-show-version-dialog-open': false,
    'scroll-to-global-version-id': undefined
};

export function useReactQueryGlobalState<K extends keyof Variables>(
    key: K
): [Variables[K], (val: Variables[K]) => void] {
    const queryClient = useQueryClient();
    const initialData = defaultState[key];

    const value = useQuery([key], () => initialData, {enabled: false, initialData}).data!;
    const setValue = (val: Variables[K]) => queryClient.setQueryData([key], val);

    return [value, setValue];
}
