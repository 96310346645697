import {Button, Typography, PlayIcon} from '@genestack/ui';
import React from 'react';

import {VersionSelect} from '../../../../../components/version-select';
import {useRunQueryMutation} from '../../../hooks/requests/use-run-query-mutation';
import {useUpdateQueryMutation} from '../../../hooks/requests/use-update-query-mutation';

import styles from './query-draft-footer.module.css';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';

interface Props {
    queryDraft: DraftQuery;
    globalVersions: GlobalVersionData[];
}

export function QueryDraftFooter(props: Props) {
    const runQuery = useRunQueryMutation();
    const updateQuery = useUpdateQueryMutation();

    const handleRunQuery = () => {
        runQuery.mutate(props.queryDraft.id);
    };

    const handleChangeDraftVersion = (globalVersionId: number) => {
        updateQuery.mutate({id: props.queryDraft.id, globalVersionId});
    };

    return (
        <div className={styles.root}>
            <div className={styles.actions}>
                {!props.queryDraft.trash ? (
                    <Button
                        icon={<PlayIcon />}
                        onClick={handleRunQuery}
                        disabled={runQuery.isLoading}
                    >
                        Run query
                    </Button>
                ) : null}
            </div>

            <div className={styles.version}>
                <Typography className={styles.versionLabel} as="span" intent="quiet">
                    KB Version:
                </Typography>
                <VersionSelect
                    value={props.queryDraft.globalVersionId}
                    onChangeValue={handleChangeDraftVersion}
                    disabled={runQuery.isLoading}
                    globalVersions={props.globalVersions}
                />
            </div>
        </div>
    );
}
