import {
    Button,
    Dialog,
    DialogBody,
    DialogHeader,
    Divider,
    Typography,
    WithSeparator,
    ScrollView,
    DarkContext
} from '@genestack/ui';
import React from 'react';

import {useCanCreateGV} from '../../../hooks/user/use-has-permission';
import {scrollToElementOnceFound} from '../../scroll-to-element';

import {DialogVersionItem as VersionItem} from './dialog-version-item';
import styles from './show-versions-dialog.module.css';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';
import {useGlobalVersionDialogsGlobalValue} from '../../../providers/version/use-global-version-dialogs-global-value';

interface Props {
    globalVersions: GlobalVersionData[] | null;
    currentGlobalVersion?: GlobalVersionData;
}

export const ShowVersionsDialog = ({globalVersions, currentGlobalVersion}: Props) => {
    const {
        scrollToGlobalVersionId,
        isShowVersionsDialogOpen,
        closeShowVersionsDialog,
        openCreateVersionDialog
    } = useGlobalVersionDialogsGlobalValue();
    const canCreateGV = useCanCreateGV();

    React.useEffect(() => {
        const scrollTo = scrollToGlobalVersionId || currentGlobalVersion?.id;

        if (isShowVersionsDialogOpen && scrollTo) {
            scrollToElementOnceFound(scrollTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowVersionsDialogOpen]);

    const versions = React.useMemo(() => globalVersions || [], [globalVersions]);

    return (
        // tslint:disable-next-line: jsx-boolean-value
        <DarkContext.Provider value>
            <Dialog
                open={isShowVersionsDialogOpen}
                onClose={closeShowVersionsDialog}
                size="large"
                scrollable
            >
                <DialogHeader>
                    <Typography variant="header">
                        Knowledge Base global versions
                        {canCreateGV && (
                            <Button
                                className={styles.headerButton}
                                onClick={openCreateVersionDialog}
                            >
                                Create new...
                            </Button>
                        )}
                    </Typography>
                </DialogHeader>
                <DialogBody as={ScrollView}>
                    {versions.length ? (
                        <WithSeparator separator={<Divider variant="transparent" gap={2} />}>
                            {versions.map((version, i) => (
                                <VersionItem
                                    key={version.id}
                                    version={version}
                                    isLatest={i === 0}
                                    isActive={currentGlobalVersion?.id === version.id}
                                />
                            ))}
                        </WithSeparator>
                    ) : (
                        <Typography intent="quiet">No global versions created yet</Typography>
                    )}
                </DialogBody>
            </Dialog>
        </DarkContext.Provider>
    );
};
