import {Css, EdgeSingular, NodeSingular} from 'cytoscape';
import {edgePropsByType} from '../biokb-graph/constants';
import {CytoscapeEdgeData, GraphEntityStatus} from './interface';
import {FactNeighborhoodNodeType} from '../interface';

function getEdgeColor(status: GraphEntityStatus) {
    return function (edge: EdgeSingular) {
        const {presentation} = edge.data() as CytoscapeEdgeData;

        return edgePropsByType[presentation][status].color;
    };
}

export function getDefaultEdgeStyles(): Css.Edge {
    return {
        'line-color': getEdgeColor(GraphEntityStatus.default),
        'target-arrow-color': getEdgeColor(GraphEntityStatus.default)
    };
}

export function getSelectedEdgeStyles(): Css.Edge {
    return {
        'line-color': getEdgeColor(GraphEntityStatus.selected),
        'target-arrow-color': getEdgeColor(GraphEntityStatus.selected)
    };
}

export function getUnselectedNodeStyles(): Css.Node {
    return {
        'background-color': (node: NodeSingular) =>
            node.data().nodeType === FactNeighborhoodNodeType.INITIAL ? '#DDDDDD' : 'white',
        color: 'black'
    };
}

export function getSelectedNodeStyles(): Css.Node {
    return {
        'background-color': 'black',
        color: 'white'
    };
}

export function getUnhoveredNodeStyles(): Css.Node {
    return {
        // @ts-ignore
        'outline-width': 0
    };
}

export function getHoveredNodeStyles(): Css.Node {
    return {
        // @ts-ignore
        'outline-width': 4
    };
}
