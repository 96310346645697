import {useMutation} from 'react-query';

import {ApiError} from '../../../../components/error-boundary/error-report';
import {apiClient} from '../../../../utils/api-client';
import {useSearchDocsCache} from '../sidebar/search-layout/hooks/use-edit-mode-search-query-cache';

import {useDocMetadataQueryCache} from './use-doc-metadata-query';
import {useOntologyDocsQueryCache} from './use-ontology-docs-query';
import {useUnpublishedDocsQueryCache} from './use-unpublished-docs-query';

function publishAllRequest(onlyMyDrafts: boolean) {
    return apiClient.post<never>({
        path: 'editor-documents-service/api/documents/wc/publish',
        query: {
            'only-user-documents': onlyMyDrafts
        }
    });
}

/** Hook to publish all drafts of the documents */
export function usePublishAllMutation() {
    const docMetadataQueryCache = useDocMetadataQueryCache();
    const searchDocsCache = useSearchDocsCache();
    const ontologyDocsQueryCache = useOntologyDocsQueryCache();
    const unpublishedDocsQueryCache = useUnpublishedDocsQueryCache();

    return useMutation<never, ApiError, boolean>(publishAllRequest, {
        onSuccess: () => {
            docMetadataQueryCache.invalidateAll();
            ontologyDocsQueryCache.invalidate();

            searchDocsCache.clearCache();
            unpublishedDocsQueryCache.clearCache();
        }
    });
}
