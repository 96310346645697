import React from 'react';

import {CategoryTreeElement} from './adapt-tree';
import {CategoryTreeItem} from './category-tree-item';

interface Props {
    tree: CategoryTreeElement[];
    onChangeFilters: (
        toAddVisual: CategoryTreeElement[],
        toRemoveVisual: CategoryTreeElement[],
        toAddApplied: string[],
        toRemoveApplied: string[]
    ) => void;
    appliedFilters: string[];
    visualFilters: CategoryTreeElement[];
}

function getSameElements(element: CategoryTreeElement, tree: CategoryTreeElement[]) {
    return tree.filter((el) => el.categoryId === element.categoryId);
}

/** UI component to render category tree for a particular node */
// eslint-disable-next-line react/display-name
export const CategoryTree = React.memo((props: Props) => {
    const handleCheckedChange = React.useCallback(
        (el: CategoryTreeElement, checked: boolean) => {
            const sameElements = getSameElements(el, props.tree);

            if (checked) {
                props.onChangeFilters(
                    sameElements,
                    props.visualFilters.filter(
                        (f) =>
                            // remove parents of chosen element
                            f.parents.includes(el.categoryId) ||
                            // remove children of chosen element
                            el.path.includes(f.id) ||
                            // remove siblings' children
                            el.childCategoryIds.includes(f.categoryId) ||
                            // remove siblings' parents
                            sameElements.some((se) => se.parents.includes(f.categoryId))
                    ),
                    [el.categoryId, ...el.childCategoryIds].filter(
                        (item, i, arr) => i === arr.indexOf(item)
                    ),
                    [
                        ...props.appliedFilters.filter((a) =>
                            sameElements.some((e) => e.parents.includes(a))
                        )
                    ]
                );
            } else {
                props.onChangeFilters(
                    [],
                    props.visualFilters.filter((f) => f.categoryId === el.categoryId),
                    [],
                    [el.categoryId, ...el.childCategoryIds].filter(
                        (item, i, arr) => i === arr.indexOf(item)
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.appliedFilters, props.visualFilters]
    );

    const [high, setHigh] = React.useState<CategoryTreeElement | null>();

    const handleHighlight = React.useCallback((item: CategoryTreeElement | null) => {
        setHigh(item);
    }, []);

    return (
        <>
            {props.tree.map((el) => (
                <CategoryTreeItem
                    item={el}
                    highlighted={
                        (high && el.path.includes(high.id)) ||
                        (el.id !== high?.id && el.categoryId === high?.categoryId)
                    }
                    key={el.id}
                    order={el.path.length}
                    checked={props.visualFilters.some((f) => f.id === el.id)}
                    onHighlight={handleHighlight}
                    onCheckedChange={handleCheckedChange}
                />
            ))}
        </>
    );
});
