import React from 'react';

import {LoadNextPageButton} from './load-next-page-button';
import {NextPageAutoExecutor} from './next-page-auto-executor';

/**
 * Pagination mode
 * `auto` — uses infinite scroll;
 * `manual` — uses "Load more" button
 */
export type PaginationMode = 'auto' | 'manual';

/** Button component for handle next page */
export type ButtonComponentType = React.ComponentType<{
    isLoadingNextPage?: boolean;
}>;

interface Props {
    mode: PaginationMode;
    component?: ButtonComponentType;
    onLoadNext: () => void;
    isLoadingNextPage?: boolean;
}

/** Pagination next page accessor */
export function NextPageExecutor(props: Props) {
    if (props.mode === 'manual') {
        const {component: Component = LoadNextPageButton} = props;

        return <Component onClick={props.onLoadNext} isLoadingNextPage={props.isLoadingNextPage} />;
    }

    if (props.isLoadingNextPage) {
        return null;
    }

    return <NextPageAutoExecutor onLoadNext={props.onLoadNext} />;
}
