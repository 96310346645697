import cytoscape, {Core, Position} from 'cytoscape';
import * as React from 'react';
import popper from 'cytoscape-popper';
import styles from './cytoscape-graph-view.module.css';

import {GraphLayout, GraphNode, GraphEdge} from '../interface';
import {useDynamicStyles} from './use-dynamic-styles';
import {graphContainerId, useCytoscapeInitialization} from './use-cytoscape-initialization';

interface Props {
    nodePositions: GraphLayout;
    handleNodeMoved: (value: GraphLayout) => void;
    nodes: GraphNode[];
    edges: GraphEdge[];
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
    selectedNodes: number[];
    hoveredNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
    csRef: React.MutableRefObject<Core | null>;
    zoom: number;
    setZoom: (val: number) => void;
    pan: Position;
    setPan: (val: Position) => void;
    isPresentationEdited?: boolean;
}

cytoscape.use(popper);

export const CytoscapeGraphView = (props: Props) => {
    const {
        nodes,
        edges,
        selectNodes,
        selectedNodes,
        hoveredNodes,
        setHoveredNodes,
        csRef,
        nodePositions,
        handleNodeMoved,
        zoom,
        setZoom,
        pan,
        setPan,
        isPresentationEdited
    } = props;
    const containerElementRef = React.useRef<HTMLDivElement>(null);

    useCytoscapeInitialization({
        csRef,
        nodes,
        edges,
        nodePositions,
        handleNodeMoved,
        zoom,
        setZoom,
        pan,
        setPan,
        setHoveredNodes,
        selectNodes,
        isPresentationEdited
    });
    useDynamicStyles({
        selectedNodes,
        hoveredNodes,
        csRef,
        containerElementRef,
        edges
    });

    return <div id={graphContainerId} className={styles.graphView} ref={containerElementRef} />;
};
