import {useQuery} from 'react-query';

import {adaptTree} from '../../sidebar/search-results/categories/adapt-tree';
import {apiClient} from '../../../../../utils/api-client';
import {CategoriesSearch, getCategoriesSearch} from '../../../../../utils/get-categories-search';

/** Structure representing the reference node */
export interface ReferencedNode {
    accession: string;
    nodeId: string;
}

/** Structure representing NodeReferenceSearch */
export interface NodeReferenceSearch extends ReferencedNode {
    type: string;
    name: string;
}

/** Element of CategoryTree */
export interface RawCategoryTreeElement {
    categoryId: string;
    /** shows if the category itself is assigned to some document */
    assigned: boolean;
    documentsCount: number;
    name: string;
    children: RawCategoryTreeElement[];
}

/** Structure representing the tree of Categories */
export interface CategoriesTree {
    root: RawCategoryTreeElement;
}

/** Structure representing String search */
export interface StringSearch {
    value: string;
    strict: boolean;
}

interface CategoriesTreesResponse {
    trees: CategoriesTree[];
    uncategorizedDocumentsCount: number;
}

interface DocumentsCategoriesSearchRequest {
    stringSearch?: StringSearch;
    types?: DocType[];
    categoriesSearch?: CategoriesSearch;
    readOnly?: boolean;
    referencedNode?: ReferencedNode;
}

interface Props {
    versionId: number;
    stringSearch?: string;
    categories?: string[];
    referenceNode?: ReferencedNode;
}

function getCategoriesTreeRequest(props: Props) {
    const request: DocumentsCategoriesSearchRequest = {
        stringSearch: props.stringSearch ? {value: props.stringSearch, strict: false} : undefined,
        categoriesSearch: getCategoriesSearch(props.categories),
        referencedNode: props.referenceNode
    };

    return apiClient
        .post<CategoriesTreesResponse>({
            path: 'documents-revisions-service/api/viewer/documents/categories/trees',
            parameters: request,
            query: {'global-version': props.versionId}
        })
        .then(({trees, uncategorizedDocumentsCount}) => ({
            trees: trees.map(adaptTree),
            uncategorizedDocumentsCount
        }));
}

/** Hook for categories tree query */
export function useCategoriesQuery(props: Props) {
    return useQuery(
        [
            'getCategoryTrees',
            props.stringSearch,
            props.categories,
            props.referenceNode,
            props.versionId
        ],
        async () => {
            if (props.stringSearch === '') {
                return null;
            }

            return getCategoriesTreeRequest(props);
        }
    );
}
