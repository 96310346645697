import {Button, Tooltip, TooltipHandler, OntologyIcon} from '@genestack/ui';
import React from 'react';

interface Props {
    expanded: boolean;
    onToggleExpand: () => void;
}

export const ExpandCategoriesButton = (props: Props) => {
    return (
        <TooltipHandler
            tooltip={
                <Tooltip>{props.expanded ? 'Hide category tree' : 'Show category tree'}</Tooltip>
            }
        >
            <Button
                icon={<OntologyIcon />}
                onClick={props.onToggleExpand}
                size="small"
                data-qa="open-categories-button"
                active={props.expanded}
            />
        </TooltipHandler>
    );
};
