import React from 'react';

import {CommitAction} from '../../../../providers/version';

interface Props {
    action: CommitAction;
    renamedInCommit?: boolean;
}

/** Provide the human readable text for CommitAction */
export function getCommitMessage(action: CommitAction, renamedInCommit?: boolean) {
    switch (action) {
        case 'DELETE':
            return 'Deleted permanently';
        case 'PUBLISH':
            return renamedInCommit ? 'Published & Renamed' : 'Published';
        case 'TRASH_BIN':
            return 'Moved to Recycle bin';
        case 'RESTORE':
            return 'Restored';
        default:
            return '';
    }
}

/** Renders commit stub-message text node depending on commit action */
export const CommitText = ({action, renamedInCommit = false}: Props) => (
    <>{React.useMemo(() => getCommitMessage(action, renamedInCommit), [action, renamedInCommit])}</>
);
