/** Data assertion */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function assert<Condition = any>(
    condition: Condition,
    errorMessage?: string
): asserts condition {
    if (!condition) {
        throw new Error(errorMessage);
    }
}
