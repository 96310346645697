import {useQueryClient, useMutation} from 'react-query';

import {
    useFindUsagesQuery,
    useFindUsagesQueryCache,
    convertFindUsagesResponse,
    UsageItemType,
    FindUsagesResponse
} from './use-find-usages-query';
import {NodeSearchData} from '../../../../common/sidebar-search-field/interface';

export function useLoadNextFindUsagesPageMutation(params: NodeSearchData) {
    const {addItems} = useFindUsagesQueryCache();
    const queryClient = useQueryClient();

    return useMutation<UsageItemType[] | null>(async () => {
        const currentState = queryClient.getQueryData<FindUsagesResponse>([
            useFindUsagesQuery.name,
            params.documentAccession,
            params.nodeId
        ]);

        if (!currentState?.hasNextPage) {
            return null;
        }

        const usagesData = await currentState.onLoadNextPage();

        if (!usagesData) {
            return null;
        }

        const items = await convertFindUsagesResponse(usagesData);
        addItems(items, params, usagesData.hasNextPage);

        return items;
    });
}
