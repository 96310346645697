import {Divider, Typography} from '@genestack/ui';
import React from 'react';
// tslint:disable-next-line: no-implicit-dependencies
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {animationDuration} from './constants';
import styles from './query-management-panel.module.css';
import {RecentQuery} from './recent-query';
import {UnresolvedQuery} from './unresolved-query';
import {useMPUnresolvedQueries} from './use-mp-unresolved-queries';
import {isQueryPending, isQueryRunning} from '../utils';

interface Props {
    unresolvedQueriesRequest: ReturnType<typeof useMPUnresolvedQueries>;
    getRecentQueryById: (id: number) => FinishedQuery | undefined;
}

export function UnresolvedQueries(props: Props) {
    const {data} = props.unresolvedQueriesRequest;

    const pendingQueries: PendingQuery[] = data?.filter(isQueryPending) || [];
    const runningQueries: RunningQuery[] = data?.filter(isQueryRunning) || [];

    return (
        <>
            <div className={styles.queryRow}>
                <Typography variant="section">Executing queries</Typography>

                <Typography intent="quiet" variant="body">
                    Author
                </Typography>

                <Typography intent="quiet" variant="body">
                    Calculation time / Found results / Status or Queue prediction
                </Typography>
            </div>

            <Divider gap={0} />

            {(function () {
                return (
                    <>
                        <TransitionGroup>
                            {pendingQueries.map((query, index) => (
                                <CSSTransition
                                    key={query.id}
                                    timeout={{enter: animationDuration, exit: 0}}
                                    unmountOnExit
                                >
                                    {(transitionStatus) => (
                                        <UnresolvedQuery
                                            key={query.id}
                                            query={query}
                                            positionInQueue={pendingQueries.length - index}
                                            isHighlighted={transitionStatus === 'entering'}
                                        />
                                    )}
                                </CSSTransition>
                            ))}
                        </TransitionGroup>

                        <TransitionGroup>
                            {runningQueries.map((query) => (
                                <CSSTransition
                                    key={query.id}
                                    timeout={{enter: animationDuration, exit: animationDuration}}
                                    exit
                                    in
                                >
                                    {(transitionStatus) => {
                                        if (transitionStatus === 'exiting') {
                                            const recentQuery = props.getRecentQueryById(query.id);

                                            return recentQuery ? (
                                                <RecentQuery query={recentQuery} isHighlighted />
                                            ) : null;
                                        }

                                        return <UnresolvedQuery key={query.id} query={query} />;
                                    }}
                                </CSSTransition>
                            ))}

                            {!pendingQueries.length && !runningQueries.length && (
                                <CSSTransition
                                    key="empty"
                                    timeout={animationDuration}
                                    unmountOnExit
                                >
                                    {(transitionStatus) => {
                                        if (transitionStatus === 'exiting') {
                                            return null;
                                        }

                                        return (
                                            <div className={styles.emptyTable}>
                                                <Typography intent="quiet">
                                                    No queries executing at the moment
                                                </Typography>
                                            </div>
                                        );
                                    }}
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </>
                );
            })()}
        </>
    );
}
