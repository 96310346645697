import React from 'react';
import {Switch, Route, RouteComponentProps} from 'react-router-dom';

import {PageLayout} from '../../../components/page-layout';
import {useLocationParams} from '../../../hooks/use-location';
import {DocRevisionsPanel} from '../common/version-history/doc-revisions-panel';

import {ViewModeActivityBar} from './activity-bar';
import {DocumentView} from './document-view';
import {ViewModeDocumentsSidebar} from './sidebar';
import {SelectedDocumentParams} from './document-view/document-view';
import {usePageLayout} from '../../../components/page-layout/use-page-layout';
import {useSidebarTabs} from '../../../hooks/use-sidebar-tabs';
import {ViewModeTab} from '../../../components/page-layout/layout-typings';
import {useDocRevisionsContext} from '../../../providers/version';
import {useAddRecordToExploreViewSearchHistoryMutation} from './hooks/requests/use-add-record-to-explore-view-search-history-mutation';
import {useSearchSidebarContext} from '../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useSearchDataFromUrl} from './hooks/use-search-data-from-url';
import {SearchData} from '../common/sidebar-search-field/interface';
import {convertSearchDataToSearchHistoryRecord} from './utils';
import {useHandleFindUsages} from '../common/sidebar-search-field/hooks/use-handle-find-usages';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';

interface Props {
    path: string;
    selectedGlobalVersion: GlobalVersionData;
    globalVersions: GlobalVersionData[];
}

export const ExploreViewLayout = (props: Props) => {
    const params = useLocationParams();

    const tabSettings = useSidebarTabs({
        layout: 'explore',
        activityTabStorageKey: 'documents.explore.lastTab',
        defaultActivityBarTab: ViewModeTab.FAVORITES
    });

    const addItemToSearchHistoryMutation = useAddRecordToExploreViewSearchHistoryMutation();
    const onSearch = React.useCallback(
        (data: SearchData) => {
            const searchHistoryItem = convertSearchDataToSearchHistoryRecord(data);

            if (searchHistoryItem) {
                addItemToSearchHistoryMutation.mutate(searchHistoryItem);
            }
        },
        [addItemToSearchHistoryMutation]
    );

    const searchSidebarContext = useSearchSidebarContext({
        useDefaultSearch: useSearchDataFromUrl,
        onSearch,
        searchDataGlobalStateVar: 'documents.explore.searchData'
    });
    const findUsagesProps = useHandleFindUsages({
        tabSettings,
        currentGlobalVersion: props.selectedGlobalVersion,
        searchSidebarContext,
        searchTab: ViewModeTab.SEARCH
    });

    const docRevisionsProps = useDocRevisionsContext();
    const isSidebarExpandable = tabSettings.activityBarTab === 'search';
    const layoutSettings = usePageLayout({
        isHasRightPanel: params.mode === 'revisions',
        layoutSizeStorageKey: 'documents.explore.layoutSize',
        layoutExpandedStorageKey: isSidebarExpandable
            ? 'documents.explore.layoutExpanded'
            : undefined
    });

    const closeRevisionsPanelLink = `/documents/explore/${params.documentId}`;

    const getSelectedRevisionLink = React.useCallback(
        (revision: string) => `/documents/explore/${params.documentId}/revisions/${revision}`,
        [params.documentId]
    );

    const getDocumentLink = React.useCallback(
        (documentId: string, nodeId?: string) =>
            `/${documentId}${nodeId ? `/@${encodeURIComponent(nodeId)}` : ''}`,
        []
    );

    return (
        <PageLayout
            rightPanel={
                <DocRevisionsPanel
                    docRevisionsProps={docRevisionsProps}
                    closePanelLink={closeRevisionsPanelLink}
                    getSelectedRevisionLink={getSelectedRevisionLink}
                    globalVersions={props.globalVersions}
                />
            }
            activityBar={<ViewModeActivityBar tabSettings={tabSettings} />}
            sidebar={
                <ViewModeDocumentsSidebar
                    tabSettings={tabSettings}
                    layoutSettings={layoutSettings}
                    searchSidebarContext={searchSidebarContext}
                    findUsagesProps={findUsagesProps}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                    getDocumentLink={getDocumentLink}
                    globalVersions={props.globalVersions}
                />
            }
            layoutSettings={layoutSettings}
        >
            <Switch>
                <Route
                    exact
                    path={[
                        `${props.path}/:documentId`,
                        `${props.path}/:documentId/@:nodeId`,
                        `${props.path}/:documentId?/:mode(revisions)`,
                        `${props.path}/:documentId?/:mode(revisions)/:revisionId`,
                        `${props.path}/:documentId?/:mode(revisions)/:revisionId/@:nodeId`
                    ]}
                    render={(routeProps) => (
                        <DocumentView
                            {...(routeProps as RouteComponentProps<SelectedDocumentParams>)}
                            findUsagesProps={findUsagesProps}
                            selectedGlobalVersion={props.selectedGlobalVersion}
                            key={`${routeProps.match.params.documentId}${props.selectedGlobalVersion.id}${docRevisionsProps.selectedRevision?.revision}`}
                        />
                    )}
                />
            </Switch>
        </PageLayout>
    );
};
