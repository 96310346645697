import classNames from 'classnames';
import React from 'react';

import {getDocTypeLabelByType, getDocTypeTitleByType} from '../../utils/document';

import styles from './document-type-label.module.css';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    variant?: 'short' | 'full';
    type: DocType;
    readOnly?: boolean;
    selected?: boolean;
}

export const DocumentTypeLabel: React.FunctionComponent<Props> = ({
    variant = 'short',
    type,
    readOnly,
    selected,
    ...restProps
}) => {
    const componentClassName = classNames(styles.label, restProps.className, {
        [styles.labelReadonly]: readOnly,
        [styles.labelSelected]: selected
    });

    return (
        <span {...restProps} className={componentClassName}>
            {variant === 'short' ? getDocTypeLabelByType(type) : getDocTypeTitleByType(type)}
        </span>
    );
};
