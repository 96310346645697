import React from 'react';

import {assert} from '../../utils/assert';

import type {CommitData} from './document-history-query';

export interface DocRevisionsContextValue {
    revisions: CommitData[] | null;
    selectedRevision: CommitData | undefined;
}

export const DocRevisionsContext = React.createContext<DocRevisionsContextValue | null>(null);

export const useDocRevisionsContext = () => {
    const value = React.useContext(DocRevisionsContext);
    assert(value, 'Could not be used outside of DocRevisionsContextProvider');

    return value;
};

export {DocRevisionsContextProvider} from './doc-revisions-provider';
