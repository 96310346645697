import React from 'react';
import {useHistory} from 'react-router-dom';

import {hashToObject} from '../../../../hooks/object-hash';
import {useUrlSearchValue, UrlSearchParam} from '../../../../hooks/use-search-params';
import {SearchData} from '../../common/sidebar-search-field/interface';

interface Props {
    setSearchData: (data: SearchData) => void;
}

export function useSearchDataFromUrl(props: Props) {
    const history = useHistory();
    const searchValue = useUrlSearchValue(UrlSearchParam.search);

    const searchDataFromUrl = React.useMemo(
        () => searchValue.value && (hashToObject(searchValue.value) as SearchData),
        [searchValue.value]
    );

    React.useEffect(() => {
        if (searchDataFromUrl) {
            props.setSearchData(searchDataFromUrl);
            history.replace({search: ''});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return searchDataFromUrl;
}
