/**
 * Creates JsonFormData from FormData
 */
export function buildFormData(form: HTMLFormElement) {
    const jsonFormData: {[key: string]: FormDataEntryValue} = {};
    const formData = new FormData(form);
    formData.forEach((value, key) => {
        jsonFormData[key] = value;
    });

    return jsonFormData;
}

/**
 * Object derived from FormData where keys are names of elements
 * and values are FormDataEntryValue
 */
export interface JsonFormData {
    [key: string]: FormDataEntryValue;
}
