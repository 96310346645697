import {useInfiniteQuery} from 'react-query';
import {UseInfiniteQueryOptions} from 'react-query/types/react/types';

import {UsageNode} from '../../../../../../webeditor/biokb-editor-adapters';
import {parseNodes} from '../../utils';
import {apiClient} from '../../../../../utils/api-client';

interface RemainingUsageNodesRequest {
    declarationNodeId: string | null;
    usageAccession: string;
    globalVersionId?: number;
    cursor: number;
    limit: number;
}

interface RemainingUsagesResponse {
    document: ViewModeDocInfo;
    nextCursor: number;
    usages: UsageNode[];
    remaining: number;
    total: number;
}

interface RemainingNodesResponse {
    nextCursor: RemainingUsagesResponse['nextCursor'];
    remaining: RemainingUsagesResponse['remaining'];
    parsedNodes: DocumentNode[];
}

interface Props extends Omit<RemainingUsageNodesRequest, 'limit'> {
    accession?: string;
}

const PAGE_SIZE = 20;

// eslint-disable-next-line default-param-last
function getRemainingNodesRequest(accession = '', parameters: RemainingUsageNodesRequest) {
    return apiClient
        .post<RemainingUsagesResponse>({
            path: `documents-revisions-service/api/viewer/documents/${accession}/usages/remaining-nodes`,
            parameters
        })
        .then<RemainingNodesResponse>(async (result) => ({
            remaining: result.remaining,
            nextCursor: result.nextCursor,
            parsedNodes: await parseNodes(result.document.accession, result.usages)
        }));
}

/** Returns remaining nodes for find usages request */
export function useRemainingNodesInfiniteQuery(
    {accession, ...restProps}: Props,
    options?: Pick<UseInfiniteQueryOptions<RemainingNodesResponse>, 'initialData' | 'onSettled'>
) {
    return useInfiniteQuery(
        [
            'remainingNodes',
            accession,
            restProps.declarationNodeId,
            restProps.usageAccession,
            restProps.globalVersionId
        ],
        ({pageParam = {cursor: restProps.cursor, limit: PAGE_SIZE}}) => {
            return getRemainingNodesRequest(accession, {
                ...restProps,
                cursor: pageParam.cursor,
                limit: pageParam.limit
            });
        },
        {
            getNextPageParam: ({nextCursor, remaining}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {cursor: nextCursor, limit: PAGE_SIZE};
                }
            },
            keepPreviousData: true,
            ...options
        }
    );
}
